import React from "react";
import { Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";

interface Props {
  variant: Variant;
  name: FontIconName;
  color?:
  | "initial"
  | "inherit"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "error";
}

export type FontIconName = "patient-report" | "report-log" | "prescribe";

export const FontIcon: React.FunctionComponent<Props> = ({ name, variant, color = "initial" }) => (
  <Typography
    component="i"
    className={`icon icon-${name}`}
    variant={variant}
    color={color}
  />
);

export default FontIcon;

import React from "react";
import { makeStyles } from "@material-ui/core";
import theme, { pxToRem } from "../../../main/theme/theme";
import Adherences from "../../domain/Adherences";
import AdherenceCalendars from "./components/AdherenceCalendars";
import AdherenceWheels from "./components/AdherenceWheels";

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 600,
  },
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.background,
    padding: pxToRem(15),
    paddingBottom: pxToRem(30),
  },
  centerText: {
    display: "inline-block",
    flex: 1,
  },
  wrapper: {
    textAlign: "center",
  },
}));

interface Props {
  adherence: Adherences;
  type: "activity" | "medication" | "measurement";
}

export const PastAdherence: React.FunctionComponent<Props> = ({ adherence, type }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <AdherenceWheels adherences={adherence} />

      <AdherenceCalendars type={type} calendar={adherence.calendar} />
    </div>
  );
};

export default PastAdherence;

import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useAppExceptionRepository from "../../store/hooks/useAppExceptionRepository";
import theme from "../theme/theme";

const useStyles = makeStyles(() => ({
  errorContainer: {
    marginBottom: theme.metrics.margin.normal,
    textAlign: "center",
  },
  error: {
    textAlign: "center",
  },
}));

export const AppExceptions: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation("exceptions");
  const { errors } = useAppExceptionRepository();

  return (
    <div className={classes.errorContainer}>
      {errors.map((error, index) => (
        <Typography
          key={index}
          className={classes.error}
          component="span"
          variant="h4"
        >
          {t(error)}
        </Typography>
      ))}
    </div>
  );
};

export default AppExceptions;

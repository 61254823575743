import SearchDrug from "../../drugs/domain/SearchDrug";
import MedicationFormData, { fromHistoricalMedication } from "./MedicationFormData";
import { v4 as uuid } from "uuid";
import { ProposedSchedulableRegimenResponse } from "consultationSummary/api/response/ProposedSchedulableRegimenResponse";
import { MedicationTreatmentPlanResponse } from "consultationSummary/api/response/MedicationTreatmentPlanResponse";

export default interface MedicationSummary {
  medication: MedicationFormData;
  drug: SearchDrug;
  localId: string;
}

export const createNewMedicationSummary = (
  medication: MedicationFormData,
  drug: SearchDrug
): MedicationSummary => ({
  localId: uuid(),
  medication,
  drug,
});

export const updateMedicationSummary = (
  medicationSummary: MedicationSummary,
  medication: MedicationFormData,
  drug: SearchDrug
): MedicationSummary => ({
  ...medicationSummary,
  medication,
  drug,
});

export const isSameMedicationSummary = (
  medicationSummary: MedicationSummary,
  otherMedicationSummary: MedicationSummary
): boolean => medicationSummary.localId === otherMedicationSummary.localId;

export const assembleFromHistoricalMedication = (
  historicalMedication: ProposedSchedulableRegimenResponse<MedicationTreatmentPlanResponse>
): MedicationSummary => {
  const medication = historicalMedication.regimen.medication;

  return {
    localId: historicalMedication.id,
    medication: fromHistoricalMedication(historicalMedication),
    drug: new SearchDrug(medication.din || "", medication.name, medication.type || "", medication.dosage.value || ""),
  };
};

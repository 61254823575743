import {
  Button,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { PropsWithChildren } from "react";

import IconButton from "../../main/components/buttons/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import PlusIcon from "@material-ui/icons/Add";
import theme from "main/theme/theme";
import { useId } from "../../main/hooks/useId";
import { useTranslation } from "react-i18next";

export const carePlanTheme = {
  spacerWidth: 80,
  spacerWidthInstruction: 90,
};

const useStyles = makeStyles(() => ({
  text: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    flex: 1,
  },
  container: {
    margin: `${theme.metrics.margin.medium} 0`,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  iconSection: {
    marginRight: "30px",
    width: "60px",
    height: "60px",

    color: theme.colors.primary.normal,
    fill: "currentColor",
  },
  iconInfo: {
    padding: "0 10px",
  },
}));

interface Props {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  text: string;
  tooltipText?: string;
  onClick?: () => void;
  disabled?: boolean;
  showDivider?: boolean;
}

export const CarePlanSection = ({
  Icon,
  onClick,
  title,
  text,
  tooltipText,
  disabled,
  showDivider = true,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const id = useId("add-button");

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          {!!Icon && <Icon className={classes.iconSection} />}

          <div className={classes.text}>
            <div>
              <Typography id={id} component="label" variant="h4">
                {title.toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography id={id} component="span" variant="h5">
                {text}
              </Typography>

              {!!tooltipText &&
                <IconButton title={tooltipText} className={classes.iconInfo}>
                  <InfoIcon />
                </IconButton>
              }
            </div>
          </div>
          {
            !!onClick && <Button
              variant="outlined"
              color="primary"
              onClick={onClick}
              disabled={disabled}
              aria-labelledby={id}
              startIcon={<PlusIcon />}
            >
              {t("add")}
            </Button>
          }
        </div>

        {children}
      </div>

      {!!showDivider && <Divider light />}
    </>
  );
};

export default CarePlanSection;

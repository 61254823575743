import React, { useState } from "react";

import { CircularProgress } from "@material-ui/core";
import Images from "../../main/assets/Images";
import PictureViewer from "../../main/components/PictureViewer";
import TreatmentRowPicture from "./TreatmentRowPicture";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useDrugPicture from "../../drugs/hooks/useDrugPicture";

const MAX_PICTURE_WIDTH = 1000;
const useStyles = makeStyles(() => ({
  picture: {
    "width": "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  loading: {
    margin: "auto",
  },
}));

interface Props {
  din: string | undefined;
}

const TreatmentMedicationPicture: React.FunctionComponent<Props> = ({ din }) => {
  const { picture, loading, error } = useDrugPicture({ din, maxWidth: MAX_PICTURE_WIDTH });
  const [displayViewer, setDisplayModal] = useState(false);
  const pictureData = picture?.asUri() || Images.NoDrugPicturePlaceholder;
  const classes = useStyles();

  if (error) {
    return null;
  }

  return (
    <TreatmentRowPicture>
      {!!loading && <CircularProgress size={30} className={classes.loading} />}

      {!!(picture || !din) &&
        <>
          <img
            alt="Medication"
            src={pictureData}
            className={classes.picture}
            onClick={(): void => setDisplayModal(true)}
          />

          <PictureViewer
            open={displayViewer}
            onClose={(): void => setDisplayModal(false)}
            pictureSrc={pictureData}
          />
        </>
      }
    </TreatmentRowPicture>
  );
};

export default TreatmentMedicationPicture;

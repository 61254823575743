import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    font-size: ${({ theme }): string => theme.fonts.defaultSize};
  }

  * {
    font-family: 'Source Sans Pro', sans-serif;
  }

  body {
    flex: 1;
    display: flex;
    background-color:${({ theme }): string => theme.colors.surface};
  }

  #root {
    flex: 1;
    display: flex;
  }

  #modal-root {
    z-index: 3;
  }

  a:hover {
    cursor: pointer;
  }

  input::-ms-clear, input::-ms-reveal {
      display: none;
  }
`;

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import theme from "../theme/theme";
import CloseModalButton from "./CloseModalButton";

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: theme.metrics.margin.normal,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  closeButton: {
    marginLeft: theme.metrics.margin.normal,
  },
}));

interface Props {
  title?: string;
  onClose: () => void;
  className?: string;
  id?: string;
}

export const ModalHeader: React.FunctionComponent<Props> = ({ id, title, onClose, children, className }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.header, className)}>
      {children ?
        children
        :
        <Typography id={id} variant="h2" component="h2">
          {title}
        </Typography>
      }
      <CloseModalButton onClose={onClose} className={classes.closeButton} />
    </div>
  );
};

export default ModalHeader;

import { DispatchAction } from "../../store/GlobalProvider";
import LogInFormErrors from "../login/domain/LogInFormErrors";
import User from "../../main/domain/User";
import { AppState } from "../../store/GlobalStore";
import { userInitialState } from "./UserStore";

export const setUser = (dispatch: DispatchAction, user: User|undefined): void =>
  dispatch({
    reducer: (state: AppState): AppState =>
      ({ ...state, user: { ...state.user, user } }),
  });

export const clearUserStore = (dispatch: DispatchAction): void =>
  dispatch({
    reducer: (state: AppState): AppState =>
      ({ ...state, user: { ...userInitialState } }),
  });

export const setErrors = (dispatch: DispatchAction, errors: LogInFormErrors): void => {
  dispatch({
    reducer: (state: AppState): AppState =>
      ({ ...state, user: { ...state.user, errors } }),
  });
};

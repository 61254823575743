import {
  TableContainer, Table, TableHead, TableRow, TableCell, Typography, TableBody, makeStyles, Paper, CircularProgress,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Connection from "../domain/Connection";
import { convertHexToRGBA } from "../../main/theme/theme";
import TableBlankSlate from "../../main/components/TableBlankSlate";
import WarningMessage from "../../main/components/WarningMessage";
import ConnectionHistoryStatus from "./ConnectionHistoryStatus";

const useStyle = makeStyles((theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: convertHexToRGBA(theme.palette.primary.main, 10),
    },
  },
  header: {
    "& th": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
    },
  },
  table: ({ hasNoContent }: { hasNoContent: boolean }): any => ({
    overflowY: "auto",
    maxHeight: "70vh",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: hasNoContent ? "300px" : undefined,
    // because ie11 https://philipwalton.com/articles/normalizing-cross-browser-flexbox-bugs/
  }),
  centeredContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
}));

interface Props {
  connections: Connection[];
  loading: boolean;
  error: boolean;
}

const ConnectionHistoryTable: React.FunctionComponent<Props> = ({ connections, loading, error }) => {
  const { t } = useTranslation("connections");
  const hasNoContent = loading || error || connections.length === 0;
  const classes = useStyle({ hasNoContent });

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow classes={{ root: classes.header }}>
            <TableCell>
              <Typography>{t("date")}</Typography>
            </TableCell>

            <TableCell>
              <Typography>{t("time")}</Typography>
            </TableCell>

            <TableCell>
              <Typography>{t("name")}</Typography>
            </TableCell>

            <TableCell>
              <Typography>{t("email")}</Typography>
            </TableCell>

            <TableCell>
              <Typography>{t("gender")}</Typography>
            </TableCell>

            <TableCell>
              <Typography>{t("age")}</Typography>
            </TableCell>

            <TableCell>
              <Typography>{t("status.columnName")}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {connections.map((connection) => (
            <TableRow key={connection.id} classes={{ root: classes.row }}>
              <TableCell>
                <Typography>{connection.date.format(t("common:dateTime.date"))}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{connection.date.format(t("common:dateTime.time"))}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{connection.getConsultedPatientName()}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{connection.getConsultedPatientEmail()}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{t(`report:${connection.getConsultedPatientGender()}`)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{connection.getConsultedPatientAge()}</Typography>
              </TableCell>

              <TableCell>
                <ConnectionHistoryStatus type={connection.type} consultationId={connection.id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!!loading && <div className={classes.centeredContent}><CircularProgress /></div>}

      {!!error &&
        <div className={classes.centeredContent}>
          <WarningMessage>
            <Typography component="p" variant="h4" align="center">
              {t("error")}
            </Typography>
          </WarningMessage>
        </div>}

      {!!(connections.length === 0 && !loading && !error) && <TableBlankSlate />}
    </TableContainer>
  );
};

export default ConnectionHistoryTable;

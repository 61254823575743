import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as MedhelperLogo } from "../../main/assets/MedhelperLogo.svg";
import Images from "../../main/assets/Images";
import theme, { pxToRem } from "../../main/theme/theme";
import MedhelperTextLogo from "./MedhelperTextLogo";

const HeaderContainer = styled.div`
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: url(${Images.SideMenuTop});
  text-align: center;
`;

const useStyle = makeStyles(() => ({
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    padding: `${theme.metrics.margin.large} 0 ${theme.metrics.margin.huge}`,
  },
}));

const LOGO_WIDTH = 60;
const TEXT_LOGO_HEIGHT = 60;

export const SideMenuHeader: React.FunctionComponent = () => {
  const classes = useStyle();

  return (
    <HeaderContainer>
      <div className={classes.logoContainer}>
        <MedhelperLogo width={pxToRem(LOGO_WIDTH)} />

        <MedhelperTextLogo height={pxToRem(TEXT_LOGO_HEIGHT)} />
      </div>
    </HeaderContainer>
  );
};

export default SideMenuHeader;

import { v4 as uuid } from "uuid";
import i18next from "i18next";
import DateTime from "../../main/dateTime/DateTime";
import Meal from "../../report/domain/measurements/bloodGlucoseMeasurement/Meal";
import TimeOfDay from "../../report/domain/measurements/bloodGlucoseMeasurement/TimeOfDay";
import Frequency from "../../schedule/domain/Frequency";
import ScheduleType from "../../schedule/domain/ScheduleType";
import SessionFormData, { SessionFormDataField } from "./SessionFormData";
import { ProposedEventResponse } from "consultationSummary/api/response/ProposedEventResponse";

export interface MeasureFormDataFields extends Omit<SessionFormDataField, "id" | "alarm"> {
  id?: string;
  timeOfDay: TimeOfDay;
  meal?: Meal;
  creationDate: DateTime;
  frequency: Frequency;
  type: ScheduleType;
  interval: number;
  amount: number;
}

export interface MeasuresForDayFormData {
  measures: MeasureFormData[];
  date: DateTime;
  id: string;
}

export default class MeasureFormData extends SessionFormData {
  public readonly timeOfDay: TimeOfDay;
  public readonly frequency: Frequency;
  public readonly type: ScheduleType;
  public readonly amount: number;
  public readonly interval: number;
  public readonly creationDate: DateTime;
  public readonly meal?: Meal | undefined;

  constructor({
    id = uuid(),
    time,
    amount,
    creationDate,
    frequency,
    type,
    interval,
    meal,
    timeOfDay,
  }: MeasureFormDataFields) {
    super({ time, id, alarm: null });

    this.timeOfDay = timeOfDay;
    this.meal = meal;
    this.creationDate = creationDate;
    this.frequency = frequency;
    this.type = type;
    this.interval = interval;
    this.amount = amount;
  }

  public setTime(newTime: DateTime): MeasureFormData {
    return new MeasureFormData({
      ...this,
      time: newTime,
    });
  }

  public displayMeasureEvent(): string {
    return `${i18next.t(`prescribe:timeOfDay.${this.timeOfDay}`)} ${this.meal !== undefined ? i18next.t(`prescribe:meal.${this.meal}`) : ""}`;
  }

  public copyAtDate(date: DateTime): MeasureFormData {
    return new MeasureFormData({
      ...this,
      time: date.setTime(
        this.time.getHour(),
        this.time.getMinutes()
      ),
    });
  }

  public static fromHistoricalEvents(
    events: ProposedEventResponse[],
  ): MeasureFormData[] {
    return events.map((event) => {
      const timeMeal = (event.description as string).split(" ");

      return new MeasureFormData({
        amount: event.amount,
        creationDate: DateTime.fromISO(event.creationDate),
        interval: 1,
        time: DateTime.fromISO(event.expectedTimestamp),
        frequency: Frequency.PUNCTUAL,
        type: ScheduleType.REGULAR,
        timeOfDay: timeMeal[0] as TimeOfDay,
        meal: timeMeal[1] as Meal | undefined,
      });
    });
  }
}

import { makeStyles, Typography } from "@material-ui/core";
import theme from "main/theme/theme";
import React from "react";
import { NotesHeaderProps } from "./NotesTable";

const NotesTableResultsHeader = ({ title }: NotesHeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Typography component="h4" variant="h4" color="textPrimary" className={classes.headerContainer}>
      {title}
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  headerContainer: {
    width: "100%",
    fontWeight: "bold",
    paddingBottom: theme.metrics.margin.small,
    borderBottom: `${theme.metrics.border.tiny} ${theme.colors.divider}`,
  },
}));

export default NotesTableResultsHeader;

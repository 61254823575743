export enum SeverityKey {
  MILD = "MILD",
  MODERATE = "MODERATE",
  SEVERE = "SEVERE"
}

export type SeverityValue = 1 | 2 | 3;

export class Severity {
  public static readonly MILD = new Severity(SeverityKey.MILD, 1);
  public static readonly MODERATE = new Severity(SeverityKey.MODERATE, 2);
  public static readonly SEVERE = new Severity(SeverityKey.SEVERE, 3);

  private constructor(private readonly key: SeverityKey, public readonly value: SeverityValue) { }

  public static fromKey(key: SeverityKey): Severity {
    switch (key) {
      case SeverityKey.MILD:
        return this.MILD;
      case SeverityKey.MODERATE:
        return this.MODERATE;
      case SeverityKey.SEVERE:
        return this.SEVERE;
    }
  }

  public static fromValue(key: SeverityValue): Severity {
    switch (key) {
      case 1:
        return this.MILD;
      case 2:
        return this.MODERATE;
      case 3:
        return this.SEVERE;
    }
  }

  public toString(): string {
    return this.key;
  }
}

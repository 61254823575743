import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, Theme } from "@material-ui/core";
import theme, { pxToRem } from "../theme/theme";
import { ReactComponent as AlertIcon } from "../assets/AlertIcon.svg";

const useStyles = makeStyles(({ palette }: Theme) => ({
  container: {
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    border: `${pxToRem(2)} solid ${palette.divider}`,
    borderRadius: `${pxToRem(7)}`,
  },
  alertIcon: {
    width: pxToRem(60),
    height: pxToRem(60),
    marginRight: theme.metrics.margin.tiny,
  },
}));

interface Props {
  className?: string;
  forwardRef?: React.RefObject<HTMLDivElement>;
}

const AlertCard: React.FunctionComponent<Props> = ({ children, className, forwardRef }) => {
  const classes = useStyles();

  return (
    <div ref={forwardRef} className={[classes.container, className].join(" ")}>
      <AlertIcon className={classes.alertIcon} />

      <Box flex={1} marginLeft="1rem">
        {children}
      </Box>
    </div>
  );
};

export default AlertCard;

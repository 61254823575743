import React from "react";
import { makeStyles } from "@material-ui/core";
import theme, { pxToRem } from "../../../../main/theme/theme";
import Percentage from "../../../../main/domain/Percentage";
import PercentIndicator from "./PercentIndicator";
import Wheel from "./Wheel";

const useStyles = makeStyles(() => ({
  percentIndicator: {
    top: "50%",
    right: `-${pxToRem(4)}`,
    position: "absolute",
    transform: "translate(0,-50%)",
  },
}));

interface Props {
  progress: Percentage;
  size: number;
  numberOfDays: number;
}

export const PercentWheel: React.FunctionComponent<Props> = ({ progress, size, numberOfDays }) => {
  const classes = useStyles();

  return (
    <Wheel
      id="adherence-wheel"
      progressValue={progress.toPercent()}
      progressText={progress.isEmpty() ? "--" : progress.toPercent().toString()}
      size={size}
      numberOfDays={numberOfDays}
      startColor={theme.colors.progressWheel.start}
      middleColor={theme.colors.progressWheel.middle}
      endColor={theme.colors.progressWheel.end}
    >
      <PercentIndicator className={classes.percentIndicator} />
    </Wheel>
  );
};

export default PercentWheel;

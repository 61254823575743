import DrugPicture from "../domain/DrugPicture";
import { DrugPictureResponse } from "./DrugPictureApi";

class DrugPictureAssembler {

  public static assembleToInternal(response: DrugPictureResponse): DrugPicture {
    return new DrugPicture({ picture: response.jpegBase64 });
  }
}

export default DrugPictureAssembler;

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router";
import theme from "../main/theme/theme";
import usePatientReport from "../home/hooks/usePatientReport";
import analyticsClient, { AnalyticCategory, AnalyticEvent } from "../main/services/analytics";
import ReportHeader from "./components/ReportHeader";
import ReportFooter from "./components/ReportFooter";
import PatientTreatmentReport from "./components/PatientTreatmentReport";
import PatientReportError from "./components/PatientReportError";

const REPORT_MAX_WIDTH = 960;

const useStyles = makeStyles((muiTheme: Theme) => ({
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: `${theme.metrics.margin.medium} ${theme.metrics.margin.huge}`,
    [muiTheme.breakpoints.down("sm")]: {
      padding: theme.metrics.margin.medium,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: REPORT_MAX_WIDTH,
    width: "100%",
    minHeight: "100vh",
  },
  loadingContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
  },
}));

const ViewReportPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const endSession = (): void => window.close();
  const { code } = useParams();
  const { report } = usePatientReport(code);

  analyticsClient.logEvent(AnalyticCategory.REPORT, AnalyticEvent.VIEW_REPORT);

  if (report === undefined) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div>
          <ReportHeader onEndSession={endSession} />

          {report ?
            <PatientTreatmentReport report={report} />
            :
            <PatientReportError />
          }
        </div>

        <ReportFooter />
      </div>
    </div>
  );
};

export default ViewReportPage;

export default class Percentage {
  private value: number | null;

  constructor(value: number | null) {
    this.value = value;
  }

  public static fromDecimal(decimal: number): Percentage {
    return new Percentage(decimal * 100);
  }

  public isEmpty(): boolean {
    return this.value === null || this.value === undefined;
  }

  public toPercent(): number {
    return this.value || 0;
  }
}

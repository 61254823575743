import Client from "../../main/services/ApiClient";
import SearchDrugs from "../domain/SearchDrugs";
import SearchByNameRequest from "./SearchByNameRequest";
import SearchDrugsResponse from "./SearchDrugsResponse";
import SearchDrugsAssembler from "./SearchDrugsAssembler";
import SearchByDinRequest from "./SearchByDinRequest";

class DrugsApi {
  public async searchByName(searchByNameRequest: SearchByNameRequest): Promise<SearchDrugs> {
    const response = await new Client({
      endpoint: `/doctors/drugs/search/name/${searchByNameRequest.name}`,
      queryParams: {
        numberOfItems: searchByNameRequest.numberOfItems?.toFixed() ?? "10",
        offset: searchByNameRequest.offset?.toFixed() ?? "0",
        language: searchByNameRequest.language,
      },
    })
      .get<SearchDrugsResponse>();

    return SearchDrugsAssembler.toDomain(response);
  }

  public async searchByDin(searchByDinRequest: SearchByDinRequest): Promise<SearchDrugs> {
    const response = await new Client({
      endpoint: `/doctors/drugs/search/din/${searchByDinRequest.din}`,
      queryParams: {
        language: searchByDinRequest.language,
      },
    })
      .get<SearchDrugsResponse>();

    return SearchDrugsAssembler.toDomain(response);
  }
}

export default new DrugsApi();

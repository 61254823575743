import { makeStyles } from "@material-ui/core";
import DoseFormData from "medication/domain/DoseFormData";
import React from "react";
import { useTranslation } from "react-i18next";
import TimePicker from "../../main/components/inputs/TimePicker";
import RequiredFieldLabel from "../../main/components/RequiredFieldLabel";
import PlusMinusInput from "../../main/components/inputs/PlusMinusInput";
import NumericStepper from "../../main/domain/NumericStepper";
import DateTime from "../../main/dateTime/DateTime";
import theme from "../../main/theme/theme";
import AlarmSection from "../../schedule/components/AlarmSection";
import { ReminderForm } from "../../prescribe/components/ReminderForm";

const MIN_VALUE = 0.5;
const MAX_VALUE = 99;

interface Props {
  dose: DoseFormData;
  onChange: (dose: DoseFormData) => void;
  index: number;
  canDelete: boolean;
  onDelete: (dose: DoseFormData) => void;
}

export const DoseInput = ({ dose, onChange, index, canDelete, onDelete }: Props): JSX.Element => {
  const { t } = useTranslation("medication");
  const classes = useStyle();

  return (
    <ReminderForm
      session={dose}
      label="Dose"
      index={index}
      canDelete={canDelete}
      onDelete={onDelete}
      ariaLabel="dose-input"
    >
      <div className={classes.row}>
        <RequiredFieldLabel id="timePicker" label={t("time")} />

        <TimePicker
          time={dose.time}
          onChange={(newTime: DateTime): void => onChange(new DoseFormData({ ...dose, time: newTime }))}
          labelledBy="timePicker"
        />
      </div>

      <div className={classes.row}>
        <RequiredFieldLabel id="quantityPicker" label={t("quantity")} />

        <PlusMinusInput
          max={MAX_VALUE}
          min={MIN_VALUE}
          labelledBy="quantityPicker"
          value={dose.quantity}
          stepper={new NumericStepper({ 0.5: 0.5, 5: 1 })}
          onChange={(newValue: number): void => onChange(new DoseFormData({ ...dose, quantity: newValue }))}
        />
      </div>

      <AlarmSection
        id={dose.id}
        alarm={dose.alarm}
        onChange={(alarm): void => onChange(new DoseFormData({ ...dose, alarm }))}
      />
    </ReminderForm>
  );
};

const useStyle = makeStyles(() => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.metrics.margin.small} 0`,
  },
}));

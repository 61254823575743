import CustomProgramOwner from "../../prescribe/domain/CustomProgramOwner";
import CustomProgram from "../domain/CustomProgram";
import CustomProgramResponse from "./responses/CustomProgramResponse";

class CustomProgramAssembler {
  public assembleToInternal(customProgram: CustomProgramResponse): CustomProgram {
    return new CustomProgram({
      id: customProgram.id,
      owner: CustomProgramOwner[customProgram.owner as keyof typeof CustomProgramOwner],
    });
  }
}

export default new CustomProgramAssembler();

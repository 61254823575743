import MedicationSummary from "../../medication/domain/MedicationSummary";
import React from "react";
import SearchDrugCard from "./SearchDrugCard";
import { TreatmentPlanRegimenSummary } from "../../prescribe/components/TreatmentPlanRegimenSummary";
import { carePlanTheme } from "prescribe/components/CarePlanSection";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  isReadOnly: boolean;
  medicationSummary: MedicationSummary;
  onEditClick: (medicationSummary: MedicationSummary) => void;
  onDeleteClick: (medicationSummary: MedicationSummary) => void;
}

const MedicationSummarySection: React.FC<Props> = ({ isReadOnly, medicationSummary, onEditClick, onDeleteClick }) => {
  const classes = useStyle();
  const { t } = useTranslation("medication");

  return (
    <TreatmentPlanRegimenSummary
      isReadOnly={isReadOnly}
      instructions={medicationSummary.medication.instructions}
      schedule={medicationSummary.medication.schedule}
      onEditClick={(): void => onEditClick(medicationSummary)}
      onDeleteClick={(): void => onDeleteClick(medicationSummary)}
      editTitle={t("editMedication")}
      deleteTitle={t("deleteMedication")}
    >
      <div className={classes.spacer} />

      <SearchDrugCard
        drug={medicationSummary.drug}
        className={classes.medicationSection}
      />
    </TreatmentPlanRegimenSummary>
  );
};

export default MedicationSummarySection;

const useStyle = makeStyles(() => ({
  medicationSection: {
    flex: 5,
  },
  spacer: {
    width: carePlanTheme.spacerWidth + "px",
  },
  button: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
  },
}));

import { v4 as uuid } from "uuid";
import DateTime from "../../main/dateTime/DateTime";
import AlarmFormData from "./AlarmFormData";
import { ProposedEventResponse } from "consultationSummary/api/response/ProposedEventResponse";

export interface SessionFormDataField {
  time: DateTime;
  id: string;
  alarm: AlarmFormData | null;
}

export default class SessionFormData {
  public readonly time: DateTime;
  public readonly id: string;
  public readonly alarm: AlarmFormData | null;

  constructor({ time, id, alarm }: SessionFormDataField) {
    this.time = time;
    this.id = id;
    this.alarm = alarm;
  }

  public static assembleFromProposedEvents(
    events: ProposedEventResponse[]
  ): SessionFormData[] {
    return events.map(SessionFormData.assembleFromProposedEvent);
  }

  protected static assembleFromProposedEvent(
    event: ProposedEventResponse
  ): SessionFormData {
    const alarm: SessionFormDataField["alarm"] = event.alarm
      ? { intervalInMinutes: event.alarm.intervalInMinutes }
      : null;

    return new SessionFormData({
      id: event.identifier,
      time: DateTime.fromISO(event.expectedTimestamp),
      alarm,
    });
  };
}

export const createEmpty = (): SessionFormData => new SessionFormData({
  time: DateTime.now(),
  id: uuid(),
  alarm: null,
});

import Connection from "../domain/Connection";
import Client from "../../main/services/ApiClient";
import ConnectionAssembler from "./ConnectionAssembler";
import ConnectionResponse from "./ConnectionResponse";
import { getPatientProfileKey } from "main/selectors/AppContextSelector";

interface ConnectionsResponse {
  consultations: ConnectionResponse[];
}

class ConnectionsApi {
  public async fetchConnectionHistory(): Promise<Connection[]> {
    const response = await new Client({
      endpoint: "/doctors/consultations",
      queryParams: {
        profileKey: getPatientProfileKey(),
      },
    })
      .get<ConnectionsResponse>();

    return response.consultations.map(ConnectionAssembler.toInternal);
  }
}

export default new ConnectionsApi();

import { v4 as uuid } from "uuid";
import SchedulableRegimenFormData from "./SchedulableRegimenFormData";
import CustomProgramOwner from "./CustomProgramOwner";
import CustomProgramFormData from "./CustomProgramFormData";
import PendingSchedulableMeasurementFormData from "./PendingSchedulableMeasurementFormData";
import MeasureFormData from "./MeasureFormData";
import TimeOfDay from "../../report/domain/measurements/bloodGlucoseMeasurement/TimeOfDay";
import Frequency from "../../schedule/domain/Frequency";
import ScheduleType from "../../schedule/domain/ScheduleType";
import Meal from "../../report/domain/measurements/bloodGlucoseMeasurement/Meal";
import ScheduleFormData, { createEmpty as createEmptySchedule } from "../../schedule/domain/ScheduleFormData";
import DateTime from "../../main/dateTime/DateTime";

export const createProximDefaultSchedule = (): ScheduleFormData<MeasureFormData> => {
  const now = DateTime.now().formatWithTimeZone();
  const tomorrow = now.addDays(1);
  const eightDaysAfterStartDate = tomorrow.addDays(7);

  return createEmptySchedule({
    events: createProximDefaultEvents(),
    frequency: Frequency.DAILY,
    type: ScheduleType.REGULAR,
    duration: {
      startDate: tomorrow,
      endDate: eightDaysAfterStartDate,
    },
  });
};

export const createProximDefaultSchedulableRegimen = (): SchedulableRegimenFormData => ({
  regimen: new PendingSchedulableMeasurementFormData("BLOOD_GLUCOSE"),
  schedule: createProximDefaultSchedule(),
});

export const createProximDefaultCustomProgram = (): CustomProgramFormData => ({
  id: uuid(),
  schedulableRegimen: createProximDefaultSchedulableRegimen(),
  owner: CustomProgramOwner.PROXIM,
});

const createProximDefaultEvents = (): MeasureFormData[] => {
  const now = DateTime.now().formatWithTimeZone();
  return [
    new MeasureFormData({
      time: now.setTime(7, 0),
      timeOfDay: TimeOfDay.UPON_WAKING,
      meal: undefined,
      creationDate: now,
      frequency: Frequency.PUNCTUAL,
      type: ScheduleType.REGULAR,
      interval: 1,
      amount: 1,
    }),
    new MeasureFormData({
      time: now.setTime(8, 0),
      timeOfDay: TimeOfDay.BEFORE_MEAL,
      meal: Meal.BREAKFAST,
      creationDate: now,
      frequency: Frequency.PUNCTUAL,
      type: ScheduleType.REGULAR,
      interval: 1,
      amount: 1,
    }),
    new MeasureFormData({
      time: now.setTime(10, 0),
      timeOfDay: TimeOfDay.AFTER_MEAL,
      meal: Meal.BREAKFAST,
      creationDate: now,
      frequency: Frequency.PUNCTUAL,
      type: ScheduleType.REGULAR,
      interval: 1,
      amount: 1,
    }),
    new MeasureFormData({
      time: now.setTime(11, 30),
      timeOfDay: TimeOfDay.BEFORE_MEAL,
      meal: Meal.LUNCH,
      creationDate: now,
      frequency: Frequency.PUNCTUAL,
      type: ScheduleType.REGULAR,
      interval: 1,
      amount: 1,
    }),
    new MeasureFormData({
      time: now.setTime(14, 0),
      timeOfDay: TimeOfDay.AFTER_MEAL,
      meal: Meal.LUNCH,
      creationDate: now,
      frequency: Frequency.PUNCTUAL,
      type: ScheduleType.REGULAR,
      interval: 1,
      amount: 1,
    }),
    new MeasureFormData({
      time: now.setTime(17, 30),
      timeOfDay: TimeOfDay.BEFORE_MEAL,
      meal: Meal.DINNER,
      creationDate: now,
      frequency: Frequency.PUNCTUAL,
      type: ScheduleType.REGULAR,
      interval: 1,
      amount: 1,
    }),
    new MeasureFormData({
      time: now.setTime(21, 0),
      timeOfDay: TimeOfDay.AFTER_MEAL,
      meal: Meal.DINNER,
      creationDate: now,
      frequency: Frequency.PUNCTUAL,
      type: ScheduleType.REGULAR,
      interval: 1,
      amount: 1,
    }),
    new MeasureFormData({
      time: now.setTime(22, 0),
      timeOfDay: TimeOfDay.AT_BEDTIME,
      meal: undefined,
      creationDate: now,
      frequency: Frequency.PUNCTUAL,
      type: ScheduleType.REGULAR,
      interval: 1,
      amount: 1,
    }),
  ];
};

const translations = {
  en: {
    sideMenu: {
      connectWithPatient: "Connect With a Patient",
      connectionHistory: "Connection History",
      inviteAPatient: "Invite a Patient",
    },
  },
  fr: {
    sideMenu: {
      connectWithPatient: "Connecter avec un patient",
      connectionHistory: "Historique des connexions",
      inviteAPatient: "Inviter un patient",
    },
  },
};

export default translations;

import { InstructionHtmlContent } from "prescribe/components/InstructionHtmlContent";
import { Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";
import theme from "main/theme/theme";

interface Props {
  instructions?: string;
  titleClassname: string;
}

const InstructionSummary = ({ instructions, titleClassname }: Props): JSX.Element => {
  const { t } = useTranslation("consultationSummary");
  const classes = useStyles();

  return (
    <>
      <Typography component="label" variant="h3" className={titleClassname}>
        {t("additionalInfo")}
      </Typography>

      {
        instructions
          ? <div className={classes.instructions}>
            <InstructionHtmlContent text={instructions} />
          </div>
          : <Typography component="div" variant="h5">{t("noInstructions")}</Typography>
      }
    </>
  );
};

const useStyles = makeStyles(() => ({
  instructions: {
    marginLeft: theme.metrics.margin.large,
  },
}));

export default InstructionSummary;

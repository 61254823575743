import PatientAssembler from "./PatientAssembler";
import ActivityResultsResponse from "./responses/ActivityResultsResponse";
import OtherActivityResults from "report/domain/otherActivity/OtherActivityResults";
import DateTime from "main/dateTime/DateTime";
import NoteAssembler from "./NoteAssembler";

export default class ActivityResultsAssembler {
  public static fromResponse(response: ActivityResultsResponse): OtherActivityResults {
    return new OtherActivityResults(
      PatientAssembler.fromResponse(response.patientProfile),
      response.results.map(({ expectedTimestamp, actualTimestamp }) => ({
        actualTimestamp: DateTime.fromISO(actualTimestamp),
        expectedTimestamp: DateTime.fromISO(expectedTimestamp),
      })),
      response.activityName,
      NoteAssembler.fromResultsResponse(response.notes)
    );
  }
}

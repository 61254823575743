import { CSSTransition, SwitchTransition } from "react-transition-group";
import React, { useMemo } from "react";

import Dot from "./Dot";
import { Step } from "prescribe/PrescribePage";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  dot: {
    "width": "1.5rem",
    "height": "1.5rem",
    "display": "inline-block",
    "marginRight": "1rem",
    "verticalAlign": "top",
    "marginTop": "0.4rem",
    "transition": "background-color .2s, border-color .2s",
    "&.fade-enter": {
      opacity: 0.1,
    },
    "&.fade-enter-active": {
      opacity: 1,
      transition: "opacity 200ms",
    },
    "&..fade-exit": {
      opacity: 1,
    },
    "&.fade-exit-active": {
      opacity: 0.1,
      transition: "opacity 200ms",
    },
  },
  line: {
    "borderLeft": "solid 2px",
    "borderColor": theme.colors.primary.normal,
    "flexDirection": "row",
    "display": "flex",
    "minHeight": "12rem",
    "height": "100%",
    "transition": "border-color .4s, opacity .4s",
    "&.disabled": {
      opacity: 0.5,
      pointerEvents: "none",
    },
    "&.error, &.incomplete": {
      borderColor: theme.colors.disabled,
    },
  },
  step: {
    flexDirection: "row",
    whiteSpace: "nowrap",
    padding: "0 4rem 0 1rem",
  },
}));

export enum StepRequirement {
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

interface Props {
  step: Step;
  disabled?: boolean;
  isCompleted: boolean;
  isError?: boolean;
  isCurrent: boolean;
  requirement: StepRequirement;
}

const StepListItem: React.FunctionComponent<Props> = ({
  step,
  disabled,
  isCurrent,
  isCompleted,
  isError,
  children,
  requirement,
}) => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();

  const stateClass = useMemo((): "error"|"completed"|"current"|"incomplete" => {
    if (isError) {
      return "error";
    }

    if (isCompleted) {
      return "completed";
    }

    if (isCurrent) {
      return "current";
    }

    return "incomplete";
  }, [isCurrent, isCompleted, isError]);

  return (
    <div id="line" className={[classes.line, stateClass, disabled && "disabled"].join(" ")}>
      <div className={classes.step}>
        <SwitchTransition>
          <CSSTransition
            key={stateClass}
            timeout={300}
            unmountOnExit
            classNames="fade"
          >
            <Dot
              id={`step-dot-${stateClass}`}
              state={stateClass}
              className={[classes.dot, disabled && "disabled"].join(" ")}
            />
          </CSSTransition>
        </SwitchTransition>
        <div style={{ display: "inline-block" }}>
          <Typography variant="h4">
            {t(`step.${step}`)}
          </Typography>

          <Typography style={{ fontWeight: 350 }}>
            {t(`stepRequirements.${requirement}`)}
          </Typography>
        </div>
      </div>

      {children}
    </div>
  );
};

export default StepListItem;

import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  requiredFieldContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    "margin": 0,
    "fontSize": theme.fonts.h5.size,
    "&.bold": {
      fontWeight: "bold",
    },
  },
  requiredAdornment: {
    color: theme.colors.error,
  },
}));

interface Props {
  label: string;
  bold?: boolean;
  id?: string;
  className?: string;
}

const RequiredFieldLabel: React.FC<Props> = ({ label, id, bold, className }) => {
  const classes = useStyles();
  return (
    <div className={classes.requiredFieldContainer}>
      <Typography
        component="label"
        variant="h4"
        className={classNames(classes.label, { bold }, className)}
        id={id}
      >
        {label}
      </Typography>

      <Typography
        component="label"
        variant="h4"
        className={classes.requiredAdornment}
      >
        *
      </Typography>
    </div>

  );
};

export default RequiredFieldLabel;

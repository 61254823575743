import SearchDrug from "./SearchDrug";

export default class SearchDrugs {
  public drugs: SearchDrug[];

  constructor(
    drugs: SearchDrug[]
  ) {
    this.drugs = drugs;
  }
}

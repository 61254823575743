import ScheduleEvent from "../ScheduleEvent";
import i18next from "i18next";
import { DateTime as LuxonDateTime } from "luxon";

interface ReminderScheduleAttributes {
  recurrenceRule: string;
  dailySessions: number;
  events: ScheduleEvent[];
}

export default class ReminderSchedule {
  public recurrenceRule: string;
  public dailySessions: number;
  public events: ScheduleEvent[];

  constructor(plain: ReminderScheduleAttributes) {
    this.recurrenceRule = plain.recurrenceRule;
    this.dailySessions = plain.dailySessions;
    this.events = plain.events;
  }

  public toText(): string {
    const event = this.events[0];

    return event.eventDate.formatLocale(LuxonDateTime.DATE_FULL) + this.getEventTime(event);
  }

  private getEventTime(event: ScheduleEvent): string {
    const alarmDescription = !!event.alarmIntervalInMinutes
      ? " (" + i18next.t("schedule:alarmDescription", { interval: event.alarmIntervalInMinutes }) + ")"
      : "";

    return " " + i18next.t("schedule:at")
    + " " + event.eventDate.formatLocale(LuxonDateTime.TIME_SIMPLE)
    + alarmDescription;
  }
}

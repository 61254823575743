import { CognitoUser } from "amazon-cognito-identity-js";

export class PasswordExpiredFormData {
  public readonly user?: CognitoUser;
  public readonly newPassword: string;

  constructor({ user, newPassword }: {
    user?: CognitoUser;
    newPassword: string;
  }) {
    this.user = user;
    this.newPassword = newPassword;
  }

  public static from(user?: CognitoUser): PasswordExpiredFormData {
    return new PasswordExpiredFormData({ user, newPassword: "" });
  }

  public static empty(): PasswordExpiredFormData {
    return new PasswordExpiredFormData({ user: undefined, newPassword: "" });
  }

  public isFilled(): boolean {
    return !!this.newPassword;
  }
}

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { pxToRem } from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  pictureWrapper: {
    display: "flex",
    height: pxToRem(90),
    width: pxToRem(135),
    justifyContent: "center",
    alignItems: "center",
  },
}));

const TreatmentRowPicture: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.pictureWrapper}>
      {children}
    </div>
  );
};

export default TreatmentRowPicture;

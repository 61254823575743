import AuthenticationError from "../domain/AuthenticationError";

const authenticationErrors = {
  en: {
    authenticationErrors: {
      [AuthenticationError.UsernameExistsException]: "This username is already taken.",
      [AuthenticationError.InvalidEmail]: "Email is invalid.",
      [AuthenticationError.EmptyEmail]: "Email should not be empty.",
      [AuthenticationError.EmptyPassword]: "Password should not be empty.",
      [AuthenticationError.InvalidPassword]: "Password must be at least 8 characters in length. " +
"It must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.",
      [AuthenticationError.InvalidPasswordException]: "Password must be at least 8 characters in length. " +
"It must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.",
      [AuthenticationError.InvalidPasswordConfirmed]: "The password and confirmation password do not match.",
      [AuthenticationError.PasswordResetRequiredException]: "The password must be reset.",
      [AuthenticationError.AuthenticationDefault]: "Something went wrong with the server. Please try later.",
      [AuthenticationError.NetworkError]: "Connection error. Please try again later.",
      [AuthenticationError.NotAuthorizedException]: "Username and password do not match.",
      [AuthenticationError.UserNotFoundException]: "Username and password do not match.",
      [AuthenticationError.UserNotFoundExceptionResetPassword]: "The email address you entered is not valid.",
      [AuthenticationError.UserNotFoundExceptionExpiredPassword]: "An error has occurred. Please try again later.",
      [AuthenticationError.CodeMismatchException]: "The code provided is not valid.",
      [AuthenticationError.InvalidParameterException]: "The information you entered is not in a valid format.",
      [AuthenticationError.InvalidParameterExceptionResetPassword]: "The email address you entered is not valid.",
      [AuthenticationError.UserIsDisabledException]: "Your account is locked. Please <0>contact your administrator</0> to unlock your account.",
      [AuthenticationError.LoginAttemptsUserDisabled]: "Your account has reached the maximum number of failed login attempts and is locked. Please <0>contact your administrator</0> to unlock your account.",
    },
  },
  fr: {
    authenticationErrors: {
      [AuthenticationError.UsernameExistsException]: "Cet identifiant est déjà utilisé.",
      [AuthenticationError.InvalidEmail]: "Le courriel est invalide.",
      [AuthenticationError.EmptyEmail]: "Le courriel ne doit pas être vide.",
      [AuthenticationError.EmptyPassword]: "Le mot de passe ne doit pas être vide.",
      [AuthenticationError.InvalidPassword]: "Le mot de passe doit contenir au moins 8 caractères, " +
"des lettres majuscules, des lettres minuscules, des nombres et des caractères spéciaux.",
      [AuthenticationError.InvalidPasswordException]: "Le mot de passe doit contenir au moins 8 caractères, " +
"des lettres majuscules, des lettres minuscules, des nombres et des caractères spéciaux.",
      [AuthenticationError.InvalidPasswordConfirmed]: "Le mot de passe et le mot de passe de confirmation " +
"sont différents.",
      [AuthenticationError.PasswordResetRequiredException]: "Le mot de passe doit être réinitialisé.",
      [AuthenticationError.AuthenticationDefault]: "Quelque chose ne va pas avec le serveur. " +
"Veuillez réessayer plus tard.",
      [AuthenticationError.NetworkError]: "Erreur de connexion. Veuillez réessayer plus tard.",
      [AuthenticationError.NotAuthorizedException]: "La combinaison de l'identifiant et " +
"du mot de passe est invalide.",
      [AuthenticationError.UserNotFoundException]: "La combinaison de l'identifiant et " +
"du mot de passe est invalide.",
      [AuthenticationError.UserNotFoundExceptionResetPassword]: "L'adresse courriel fournie n'est pas valide.",
      [AuthenticationError.UserNotFoundExceptionExpiredPassword]: "Une erreur est survenue. Veuillez réessayer plus tard.",
      [AuthenticationError.CodeMismatchException]: "Le code n'est pas valide.",
      [AuthenticationError.InvalidParameterException]: "L'information saisie n'est pas dans un format valide.",
      [AuthenticationError.InvalidParameterExceptionResetPassword]: "L'adresse courriel fournie n'est pas valide.",
      [AuthenticationError.UserIsDisabledException]: "Votre compte est verrouillé. Veuillez <0>contacter votre administrateur</0> pour déverrouiller votre compte.",
      [AuthenticationError.LoginAttemptsUserDisabled]: "Votre compte a atteint le nombre maximum de tentatives de connexion et est verrouillé. Veuillez <0>contacter votre administrateur</0> pour déverrouiller votre compte.",
    },
  },
};

export default authenticationErrors;

import SessionFormData, { createEmpty as createEmptySession, SessionFormDataField } from "../../prescribe/domain/SessionFormData";
import { ProposedEventResponse } from "consultationSummary/api/response/ProposedEventResponse";

interface DoseFormDataField extends SessionFormDataField {
  quantity: number;
}

export default class DoseFormData extends SessionFormData {
  public readonly quantity: number;

  constructor({ quantity, ...rest }: DoseFormDataField) {
    super(rest);
    this.quantity = quantity;
  }

  public static assembleFromProposedEvents = (
    events: ProposedEventResponse[]
  ): DoseFormData[] => events.map((event) => new DoseFormData({
    ...DoseFormData.assembleFromProposedEvent(event),
    quantity: event.preciseAmount || 0,
  }));
}

export const createEmptyDoseFormData = (): DoseFormData => new DoseFormData({
  ...createEmptySession(),
  quantity: 1,
});

import { useState } from "react";
import AuthenticationService from "../../services/authentication";
import PasswordResetFormErrors from "../domain/PasswordResetFormErrors";
import PasswordResetFormData from "../domain/ResetPasswordFormData";
import PasswordResetFormValidator from "../domain/PasswordResetFormValidator";
import FailedToResetPasswordException from "../domain/FailedToResetPasswordException";

export interface ResetPasswordResult {
  resetPassword: (email: string) => Promise<void>;
  onChange: (form: PasswordResetFormData) => void;
  errors: PasswordResetFormErrors;
  form: PasswordResetFormData;
}

interface Props {
  onSuccess: () => void;
}

const usePasswordResetForm = ({ onSuccess }: Props): ResetPasswordResult => {
  const [errors, setErrors] = useState<PasswordResetFormErrors>(PasswordResetFormErrors.empty());
  const [form, setForm] = useState<PasswordResetFormData>(PasswordResetFormData.empty());

  const resetPassword = async (email: string): Promise<void> => {
    const validationErrors = PasswordResetFormValidator.validate(form);
    if (!validationErrors.isEmpty()) {
      setErrors(validationErrors);
      return;
    }

    setErrors(PasswordResetFormErrors.empty());

    try {
      await AuthenticationService.resetPassword(email, form.code.describe(), form.newPassword);
      setErrors(PasswordResetFormErrors.empty());
      setForm(PasswordResetFormData.empty());
      onSuccess();
    } catch (error) {
      setErrors(new PasswordResetFormErrors({
        password: [],
        body: [(error as FailedToResetPasswordException).authenticationErrorWithLink],
      }));
    }
  };

  return { errors, form, onChange: setForm, resetPassword };
};

export default usePasswordResetForm;

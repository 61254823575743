import { useCallback, useState } from "react";
import ChangePasswordFormData from "../domain/ChangePasswordFormData";
import AuthenticationService from "../../services/authentication";
import FailedToChangePasswordException from "../../resetPassword/domain/FailedToChangePasswordException";
import ChangePasswordFormValidator from "../domain/ChangePasswordFormValidator";
import ChangePasswordFormErrors from "../domain/ChangePasswordFormErrors";
import ChangePasswordError from "../domain/ChangePasswordError";

export interface UseChangePasswordFormResult {
  errors: ChangePasswordFormErrors;
  changePasswordFormData: ChangePasswordFormData;
  onSubmit: () => void;
  onChange: (updatedChangePasswordFormData: ChangePasswordFormData) => void;
}

interface Props {
  onPasswordChange: () => void;
}

export const useChangePasswordForm = ({ onPasswordChange }: Props): UseChangePasswordFormResult => {
  const [changePasswordFormData, setChangePasswordFormData] =
    useState<ChangePasswordFormData>(ChangePasswordFormData.empty());
  const [errors, setErrors] = useState<ChangePasswordFormErrors>(ChangePasswordFormErrors.empty());

  const onChange = useCallback((updatedChangePasswordFormData: ChangePasswordFormData): void => {
    setChangePasswordFormData(updatedChangePasswordFormData);
  }, []);

  const onSubmit = useCallback(async (): Promise<void> => {
    setErrors(ChangePasswordFormErrors.empty());

    const validationErrors = ChangePasswordFormValidator.validate(changePasswordFormData);

    if (!validationErrors.isEmpty()) {
      setErrors(validationErrors);
      return;
    }

    try {
      await AuthenticationService.changePassword(
        changePasswordFormData.currentPassword,
        changePasswordFormData.newPassword
      );
      onPasswordChange();
    } catch (e) {
      if (e instanceof FailedToChangePasswordException) {
        const newErrors = new ChangePasswordFormErrors({ body: [ChangePasswordError.DefaultError] });
        setErrors(newErrors);
      }
    }
  }, [setErrors, changePasswordFormData, onPasswordChange]);

  return { onChange, onSubmit, changePasswordFormData, errors };
};

export default useChangePasswordForm;

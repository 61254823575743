import DetailFormData from "medication/domain/DetailFormData";
import {
  Collapse, makeStyles, Switch, TextField, Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
  },
  sectionTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.metrics.margin.small,
  },
  sectionTitle: {
    fontWeight: "bold",
  },
  textFieldTitle: {
    marginBottom: theme.metrics.margin.small,
  },
}));

interface Props {
  details: DetailFormData;
  onChangeFormData: (key: keyof DetailFormData, value: string) => void;
}

const DetailsSection: React.FC<Props> = ({ details, onChangeFormData }) => {
  const [displayed, setDisplayed] = useState(details.displayName !== "");
  const [displayName, setDisplayName] = useState(details.displayName);
  const { t } = useTranslation("medication");
  const classes = useStyles();

  const toggleSection = (): void => {
    setDisplayed(!displayed);
    setDisplayName("");
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updatedDisplayName = event.target.value;
    setDisplayName(updatedDisplayName);
    onChangeFormData("displayName", updatedDisplayName);
  };

  return (
    <div className={classes.container}>
      <div className={classes.sectionTitleContainer}>
        <Typography className={classes.sectionTitle} variant="h4" id="detailsToggle">
          {t("details")}
        </Typography>

        <Switch
          checked={displayed}
          color="primary"
          inputProps={{
            "aria-labelledby": "detailsToggle",
          }}
          onChange={toggleSection}
        />
      </div>

      <section aria-labelledby="detailsToggle" aria-expanded={displayed}>
        <Collapse in={displayed}>
          <Typography className={classes.textFieldTitle} variant="h4">
            {t("alias")}
          </Typography>

          <TextField
            inputProps={{
              "aria-labelledby": "detailsToggle",
            }}
            placeholder={t("asShownInNotifications")}
            onChange={onChangeText}
            fullWidth
            variant="outlined"
            value={displayName}
          />
        </Collapse>
      </section>
    </div>
  );
};

export default DetailsSection;

import DateTime from "../../main/dateTime/DateTime";

export default interface DurationFormData {
  startDate: DateTime;
  endDate: DateTime | null;
};

export const createEmpty = (
  startDate: DateTime = DateTime.now(),
  endDate: DateTime | null = null
): DurationFormData => ({
  startDate,
  endDate,
});

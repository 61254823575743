import { ReactComponent as DeleteSvg } from "../../main/assets/Delete.svg";
import { ReactComponent as EditSvg } from "../../main/assets/Edit.svg";
import IconButton from "../../main/components/buttons/IconButton";
import InstructionFormData from "main/domain/InstructionFormData";
import { InstructionsAccordion } from "./InstructionsAccordion";
import React from "react";
import ScheduleFormData from "../../schedule/domain/ScheduleFormData";
import ScheduleSummarySection from "../../schedule/components/ScheduleSummarySection";
import SessionFormData from "../domain/SessionFormData";
import { makeStyles } from "@material-ui/core";
import theme from "../../main/theme/theme";

interface Props {
  isReadOnly: boolean;
  schedule?: ScheduleFormData<SessionFormData>;
  instructions?: InstructionFormData;
  onEditClick?: () => void;
  onDeleteClick: () => void;
  editTitle?: string;
  deleteTitle: string;
  children: React.ReactNode;
}

export const TreatmentPlanRegimenSummary = ({
  isReadOnly,
  schedule,
  instructions,
  onEditClick,
  onDeleteClick,
  editTitle,
  deleteTitle,
  children,
}: Props): JSX.Element => {
  const classes = useStyle();

  return (
    <>
      <div className={classes.container}>
        {children}

        {
          !!schedule
          && <ScheduleSummarySection
            schedule={schedule}
            className={classes.scheduleSection}
          />
        }

        {!isReadOnly &&
          <div className={classes.actions}>

            {!!editTitle &&
              <IconButton
                title={editTitle}
                onClick={onEditClick}
                type="primary"
              >
                <EditSvg width={20} height={20} />
              </IconButton>
            }

            <IconButton
              type="danger"
              title={deleteTitle}
              onClick={onDeleteClick}
            >
              <DeleteSvg width={16} height={16} />
            </IconButton>
          </div>
        }
      </div>
      {
        !!instructions?.text
        && <InstructionsAccordion instructions={instructions.text} />
      }
    </>
  );
};

const useStyle = makeStyles(() => ({
  scheduleSection: {
    display: "flex",
    flex: 5,
  },
  actions: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    margin: `${theme.metrics.margin.medium} 0`,
  },
}));

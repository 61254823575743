import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  CircularProgress, Grid, Theme,
} from "@material-ui/core";
import { useParams } from "react-router";
import theme from "../main/theme/theme";
import ReportHeader from "./components/ReportHeader";
import ReportFooter from "./components/ReportFooter";
import PatientInfo from "./components/PatientInfo";
import useReminderResults from "./hooks/useReminderResults";
import { ReminderResultsTable } from "./components/ReminderResultsTable";

const REPORT_MAX_WIDTH = 960;

const ReminderResultsPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const { code, reportCode } = useParams<{ code: string; reportCode: string }>();

  const { results: reminder } = useReminderResults({ reportCode, code });

  if (reminder === null) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div>
          <ReportHeader />

          <Grid container className={classes.row}>
            <Grid item sm={12} md={6}>
              <PatientInfo patient={reminder?.patient} />
            </Grid>
          </Grid>

          <ReminderResultsTable
            reminders={reminder?.results}
            notes={reminder.notes}
            name={reminder.reminderName}
          />
        </div>

        <ReportFooter />
      </div>
    </div>
  );
};

export default ReminderResultsPage;

const useStyles = makeStyles((muiTheme: Theme) => ({
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: `${theme.metrics.margin.medium} ${theme.metrics.margin.huge}`,
    [muiTheme.breakpoints.down("sm")]: {
      padding: theme.metrics.margin.medium,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: REPORT_MAX_WIDTH,
    width: "100%",
    minHeight: "100vh",
  },
  loadingContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  row: {
    margin: `${theme.metrics.margin.medium} 0`,
  },
  notes: {
    margin: `${theme.metrics.margin.huge} 0`,
  },
}));

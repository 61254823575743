import { Divider, makeStyles } from "@material-ui/core";
import MedicationFormModal, { MedicationFormModalRef } from "../../medication/components/MedicationFormModal";
import MedicationSummary, { isSameMedicationSummary } from "../../medication/domain/MedicationSummary";
import React, { useRef } from "react";

import CarePlanSection from "./CarePlanSection";
import MedicationSummarySection from "../../medication/components/MedicationSummarySection";
import { ReactComponent as RxPillIcon } from "medication/assets/rxPill.svg";
import { useTranslation } from "react-i18next";

interface Props {
  medications: MedicationSummary[];
  onMedicationsChange?: (medications: MedicationSummary[]) => void;
  onMedicationDelete?: (onConfirm: () => void) => void;
  disabled?: boolean;
}

export const MedicationsSection = ({
  medications,
  onMedicationsChange,
  onMedicationDelete,
  disabled,
}: Props): JSX.Element => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();
  const medicationFormModalRef = useRef<MedicationFormModalRef | null>(null);
  const isReadOnly = !Boolean(onMedicationsChange);

  const submitMedicationsUpdate = (medicationSummaries: MedicationSummary[]): void => {
    onMedicationsChange?.(medicationSummaries);
    medicationFormModalRef.current?.close();
  };

  const addMedication = (medicationSummary: MedicationSummary): void => {
    submitMedicationsUpdate([...medications, medicationSummary]);
  };

  const editMedication = (updatedMedicationSummary: MedicationSummary): void => {
    const updatedMedications = medications.map(
      (medicationSummary: MedicationSummary) => isSameMedicationSummary(medicationSummary, updatedMedicationSummary)
        ? updatedMedicationSummary
        : medicationSummary
    );

    submitMedicationsUpdate(updatedMedications);
  };

  const deleteMedication = (summary: MedicationSummary): void => {
    const updatedMedications: MedicationSummary[] = medications.filter(
      (formDataMedication) => formDataMedication.medication.id !== summary.medication.id
    );

    onMedicationsChange?.(updatedMedications);
  };

  const onAddClick = (): void => medicationFormModalRef.current?.open();
  const onEditClick = (medicationSummary: MedicationSummary): void => {
    medicationFormModalRef.current?.open(medicationSummary);
  };
  const onDeleteClick = (medicationSummary: MedicationSummary): void => {
    onMedicationDelete?.(() => deleteMedication(medicationSummary));
  };

  return (
    <CarePlanSection
      Icon={RxPillIcon}
      title={t("section.title.medication")}
      text={t("section.subText.medication")}
      tooltipText={t("tooltip.medication")}
      onClick={isReadOnly ? undefined : onAddClick}
      disabled={disabled}
      showDivider={false}
    >
      <MedicationFormModal
        ref={medicationFormModalRef}
        onAddMedication={addMedication}
        onEditMedication={editMedication}
      />

      <ul className={classes.medications}>
        {medications.map((medicationSummary: MedicationSummary, index: number) => (
          <li
            aria-labelledby={medicationSummary.drug.id}
            key={medicationSummary.localId}
          >
            <MedicationSummarySection
              isReadOnly={isReadOnly}
              medicationSummary={medicationSummary}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />

            {index !== medications.length - 1 &&
              <Divider light />
            }
          </li>
        ))}
      </ul>
    </CarePlanSection>
  );
};

const useStyles = makeStyles(() => ({
  medications: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
}));

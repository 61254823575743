import ScheduleEvent from "report/domain/ScheduleEvent";
import { PatientTreatmentScheduleEventResponse } from "./responses/TreatmentResponse";
import DateTime from "main/dateTime/DateTime";

class ScheduleEventAssembler {
  public static fromResponses(events: PatientTreatmentScheduleEventResponse[]): ScheduleEvent[] {
    return events.map(ScheduleEventAssembler.fromResponse);
  }

  public static fromResponse({
    amount,
    alarmIntervalInMinutes,
    eventDate,
  }: PatientTreatmentScheduleEventResponse): ScheduleEvent {
    return {
      amount,
      alarmIntervalInMinutes,
      eventDate: DateTime.fromISO(eventDate),
    };
  }
}

export default ScheduleEventAssembler;

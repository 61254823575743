import React from "react";
import { TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InputMask from "react-input-mask";

const useStyles = makeStyles(() => ({
  phoneFieldsRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  phoneField: {
    width: "49%",
  },
}));

export interface PhoneNumberValue {
  value: string;
  extension: string;
}

interface Props {
  label: string;
  error?: string;
  extensionLabel: string;
  onChange: (phoneNumber: PhoneNumberValue) => void;
  phoneNumber: PhoneNumberValue;
}
const DELIMITER = " ";
const DIGIT_MASK_CHAR = "X";
const REGEXP_MATCH_ALL = "g";

const PhoneNumberInput: React.FunctionComponent<Props> = ({ label, extensionLabel, phoneNumber, onChange, error }) => {
  const classes = useStyles();

  const removePhoneNumberMask = (value: string): string => value
    .replace(new RegExp(DELIMITER, REGEXP_MATCH_ALL), "")
    .replace(new RegExp(DIGIT_MASK_CHAR, REGEXP_MATCH_ALL), "");

  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = removePhoneNumberMask(event.target.value);
    onChange({ ...phoneNumber, value });
  };
  const onExtensionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const extension = event.target.value.replace(/\D/g, "");
    onChange({ ...phoneNumber, extension });
  };

  return (
    <div className={classes.phoneFieldsRow}>
      <TextField
        className={classes.phoneField}
        id="officePhoneNumber"
        variant="outlined"
        fullWidth
        label={label}
        name="officePhoneNumber"
        value={phoneNumber.value}
        onChange={onPhoneNumberChange}
        error={!!error}
        helperText={error}
        InputProps={{
          inputComponent: PhoneNumberInputComponent,
        }}
      />

      <TextField
        className={classes.phoneField}
        id="officePhoneNumberExtension"
        variant="outlined"
        type="text"
        fullWidth
        label={extensionLabel}
        name="officePhoneNumberExtension"
        value={phoneNumber.extension}
        onChange={onExtensionChange}
        error={false}
      />
    </div>
  );
};

const PhoneNumberInputComponent: React.FunctionComponent = (props) =>
  (<InputMask mask={`999${DELIMITER}999${DELIMITER}9999`} maskChar={DIGIT_MASK_CHAR} {...props} />);

export default PhoneNumberInput;

import { v4 as uuid } from "uuid";
import DurationFormData, { createEmpty as createEmptyDuration } from "./DurationFormData";
import Frequency, { RRuleFreqToFrequency } from "./Frequency";
import ScheduleType from "./ScheduleType";
import Weekday, { RRuleWeekDayToWeekDay } from "./Weekday";
import SessionFormData from "prescribe/domain/SessionFormData";
import { ProposedSchedulableRegimenResponse } from "consultationSummary/api/response/ProposedSchedulableRegimenResponse";
import DateTime from "main/dateTime/DateTime";
import { ProposedEventResponse } from "consultationSummary/api/response/ProposedEventResponse";
import { RRule, Weekday as RRuleWeekDay } from "rrule";

export default interface ScheduleFormData<T extends SessionFormData> {
  interval: number | null;
  frequency: Frequency;
  events: T[];
  weekdays: Weekday[];
  monthDays: number[];
  duration: DurationFormData;
  type: ScheduleType;
  localId: string;
}

interface EmptySessionFormData<T extends SessionFormData> {
  events?: T[];
  frequency?: Frequency;
  type?: ScheduleType;
  duration?: DurationFormData;
}

export const createEmpty = <T extends SessionFormData>({
  events = [],
  frequency = Frequency.PUNCTUAL,
  type = ScheduleType.AS_NEEDED,
  duration = createEmptyDuration(),
}: EmptySessionFormData<T> = {}): ScheduleFormData<T> => ({
  frequency,
  interval: null,
  events,
  weekdays: [],
  monthDays: [],
  duration,
  type,
  localId: uuid(),
});

export const assembleScheduleFormData = <T, S extends SessionFormData>(
  schedulableRegimen: ProposedSchedulableRegimenResponse<T>,
  assembleEvents: (events: ProposedEventResponse[], regimen: T) => S[]
): ScheduleFormData<S> => {
  const scheduleFormData = createEmpty<S>({
    duration: {
      startDate: DateTime.fromISO(schedulableRegimen.schedule.duration.startDate),
      endDate: schedulableRegimen.schedule.duration.endDate
        ? DateTime.fromISO(schedulableRegimen.schedule.duration.endDate)
        : null,
    },
  });

  if (schedulableRegimen.schedule.events.length !== 0) {
    const event = schedulableRegimen.schedule.events[0];
    const recurrence = RRule.parseString(event.recurrence);
    const weekDays = recurrence.byweekday as RRuleWeekDay[] || [];
    const monthDays = recurrence.bymonthday as number[] || [];

    scheduleFormData.frequency = RRuleFreqToFrequency(recurrence.count === 1 ? undefined : recurrence.freq);
    scheduleFormData.interval = recurrence.interval || null;
    scheduleFormData.events = assembleEvents(schedulableRegimen.schedule.events, schedulableRegimen.regimen);

    weekDays.forEach(({ weekday }) => {
      scheduleFormData.weekdays.push(RRuleWeekDayToWeekDay(weekday));
    });

    scheduleFormData.monthDays = monthDays;
  }

  return scheduleFormData;

};

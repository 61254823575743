import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CustomMeasurementIcon } from "../assets/measurement.svg";
import { ReactComponent as MigraineIcon } from "../assets/migraine.svg";
import { ReactComponent as MoodIcon } from "../assets/mood.svg";
import { ReactComponent as BloodPressureIcon } from "../assets/bloodPressure.svg";
import { MeasurementType } from "measurement/domain/MeasurementType";

interface Props {
  measurementType: MeasurementType;
  iconClassName?: string;
}

const getIconComponent = (measurementType: MeasurementType): typeof CustomMeasurementIcon | null => {
  switch (measurementType) {
    case MeasurementType.CUSTOM:
      return CustomMeasurementIcon;
    case MeasurementType.MIGRAINE:
      return MigraineIcon;
    case MeasurementType.MOOD:
      return MoodIcon;
    case MeasurementType.BLOOD_PRESSURE:
      return BloodPressureIcon;
    default:
      return null;
  }
};

export const MeasurementIcon = ({ measurementType, iconClassName }: Props): JSX.Element | null => {
  const { t } = useTranslation("measurement");
  const Icon = getIconComponent(measurementType);

  if (Icon) {
    return <Icon className={iconClassName} title={t("measurementType." + measurementType)} />;
  }

  return null;
};

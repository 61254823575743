import DateTime from "../../main/dateTime/DateTime";
import HealthProfession from "../../authentication/profile/domain/HealthProfession";
import PhoneNumber from "../../main/domain/PhoneNumber";

export interface ConsultationAttributes {
  identifier: string;
  date: DateTime;
  officeNumber: PhoneNumber;
  professionalName: string;
  profession: HealthProfession;
  professionalIdentifier: string;
}

export default class Consultation {
  public identifier: string;
  public date: DateTime;
  public officeNumber: PhoneNumber;
  public professionalName: string;
  public profession: HealthProfession;
  public professionalIdentifier: string;

  constructor(attributes: ConsultationAttributes) {
    this.identifier = attributes.identifier;
    this.date = attributes.date;
    this.officeNumber = attributes.officeNumber;
    this.professionalName = attributes.professionalName;
    this.profession = attributes.profession;
    this.professionalIdentifier = attributes.professionalIdentifier;
  }
}

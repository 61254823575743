import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import envConfig from "../config/envConfig";
import theme, { pxToRem } from "../theme/theme";
import { Typography } from "@material-ui/core";
import classNames from "classnames";

interface Props {
  className?: string;
}

export const AppVersion = ({ className }: Props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Typography className={classNames(classes.text, className)}>
      {t("version")}{t(` ${envConfig.appVersion}`)}
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  text: {
    fontSize: pxToRem(15),
    color: theme.colors.text.secondary,
  },
}));

import {
  Collapse, makeStyles, MenuItem, Select, Switch, Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Frequency from "../domain/Frequency";
import theme from "../../main/theme/theme";
import ScheduleFormData from "../domain/ScheduleFormData";
import SessionFormData from "../../prescribe/domain/SessionFormData";
import NumericInput from "../../main/components/NumericInput";
import Weekday from "../domain/Weekday";
import WeeklyFrequencySection from "./WeeklyFrequencySection";
import MonthlyFrequencyPicker from "./MonthlyFrequencyPicker";
import { TextButton } from "../../main/components/buttons/TextButton";

const MAX_VALUE = 90;
const MIN_VALUE = 1;

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
  },
  sectionTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: `${theme.metrics.margin.small} 0`,
  },
  sectionTitle: {
    fontWeight: "bold",
  },
  numericInput: {
    alignItems: "center",
    display: "flex",
    flex: 1,
  },
  repeatContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  frequencySelect: {
    flex: 1,
    marginLeft: theme.metrics.margin.large,
  },
  eventList: {
    listStyleType: "none",
    paddingLeft: 0,
    margin: 0,
  },
  addDoseButtonContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.metrics.margin.normal,
  },
}));

interface Props<M extends SessionFormData> {
  formData: ScheduleFormData<M>;
  renderEvent: (item: M, index: number) => JSX.Element;
  active: boolean;
  onToggle: () => void;
  onAddSession: () => void;
  addEventLabel: string;
  onChangeFrequency: (frequency: Frequency) => void;
  onChangeInterval: (interval: number | null) => void;
  onChangeWeekdays: (weekdays: Weekday[]) => void;
  onChangeMonthDays: (monthDays: number[]) => void;
}

const ReminderSection = <T extends SessionFormData>({
  formData,
  active,
  onToggle,
  renderEvent,
  onAddSession,
  addEventLabel,
  onChangeFrequency,
  onChangeInterval,
  onChangeMonthDays,
  onChangeWeekdays,
}: Props<T>): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(["schedule"]);

  const frequencies = [
    Frequency.DAILY,
    Frequency.WEEKLY,
    Frequency.MONTHLY,
  ];

  return (
    <div className={classes.container}>
      <div className={classes.sectionTitleContainer}>
        <Typography className={classes.sectionTitle} variant="h4" id="reminderToggle">
          {t("setAReminder")}
        </Typography>

        <Switch
          checked={active}
          inputProps={{
            "aria-labelledby": "reminderToggle",
          }}
          color="primary"
          onChange={onToggle}
        />
      </div>

      <section aria-labelledby="reminderToggle" aria-expanded={active}>
        <Collapse in={active}>
          <div className={classes.repeatContainer}>
            <NumericInput
              minValue={MIN_VALUE}
              maxValue={MAX_VALUE}
              title={t("repeatEvery")}
              value={formData.interval || MIN_VALUE}
              onValueChange={onChangeInterval}
              className={classes.numericInput}
              id="repeatEveryInput"
            />

            <label id="selectFrequency" style={{ display: "none" }}>{t("selectFrequency")}</label>

            <Select
              inputProps={{
                "aria-hidden": !active,
              }}
              labelId="selectFrequency"
              value={formData.frequency === Frequency.PUNCTUAL ? Frequency.DAILY : formData.frequency}
              variant="outlined"
              className={classes.frequencySelect}
              onChange={(event): void => onChangeFrequency(event.target.value as Frequency)}
            >
              {frequencies.map((frequency) => (
                <MenuItem value={frequency} key={frequency}>
                  <Typography>
                    {t(`schedule:frequency.${frequency}`)}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>

          {formData.frequency === Frequency.WEEKLY &&
            <WeeklyFrequencySection formData={formData.weekdays} onChange={onChangeWeekdays} />
          }

          {formData.frequency === Frequency.MONTHLY &&
            <MonthlyFrequencyPicker
              days={formData.monthDays}
              onChange={onChangeMonthDays}
            />
          }

          <ul className={classes.eventList}>
            {formData.events.map((event: T, index: number) => (
              <li key={event.id}>
                {renderEvent(event, index)}
              </li>
            ))}
          </ul>

          <div
            className={classes.addDoseButtonContainer}
          >
            <TextButton onClick={onAddSession}>
              {addEventLabel}
            </TextButton>
          </div>
        </Collapse>
      </section>
    </div>
  );
};

export default ReminderSection;

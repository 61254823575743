import OtherActivity from "../domain/otherActivity/OtherActivity";
import OtherActivitySchedule from "../domain/otherActivity/OtherActivitySchedule";
import { OtherActivityResponse } from "./responses/TreatmentResponse";
import AdherencesAssembler from "./AdherencesAssembler";
import DateTime from "main/dateTime/DateTime";
import ScheduleEventAssembler from "./ScheduleEventAssembler";

class TreatmentOtherActivityAssembler {
  public static toDomain(activity: OtherActivityResponse): OtherActivity {
    return new OtherActivity({
      name: activity.name,
      schedule: activity.schedule && new OtherActivitySchedule({
        recurrenceRule: activity.schedule.recurrenceType.rule,
        dailySessions: activity.schedule.dailySessions,
        events: ScheduleEventAssembler.fromResponses(activity.schedule.events),
      }),
      adherence: AdherencesAssembler.assembleToInternal(activity.adherence),
      reportCode: {
        code: activity.code.code,
        expiry: DateTime.fromISO(activity.code.expiry),
      },
    });
  }
}

export default TreatmentOtherActivityAssembler;

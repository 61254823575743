import { EventAssembler } from "./EventAssembler";
import SessionFormData from "../../../domain/SessionFormData";
import ScheduleFormData from "../../../../schedule/domain/ScheduleFormData";
import ProposedEventDto from "../../dtos/ProposedEventDto";
import Frequency from "../../../../schedule/domain/Frequency";
import DateTime from "../../../../main/dateTime/DateTime";

class ProposedSessionEventAssembler extends EventAssembler<SessionFormData> {
  public toDto(eventFormData: SessionFormData, schedule: ScheduleFormData<SessionFormData>): ProposedEventDto {
    return {
      id: eventFormData.id,
      frequency: Frequency.DAILY,
      amount: 0,
      interval: 1,
      creationDate: DateTime.now().formatToUtc(),
      expectedTimestamp: DateTime.fromISO(schedule.duration.startDate.toISO())
        .setTime(eventFormData.time.getHour(), eventFormData.time.getMinutes())
        .formatToLocalDateTime(),
      weekdays: [],
      monthDays: [],
      alarmIntervalInMinutes: eventFormData.alarm?.intervalInMinutes,
    };
  }
}

export default new ProposedSessionEventAssembler();

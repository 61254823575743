import { useState } from "react";
import DateTime from "../../main/dateTime/DateTime";
import CodeFormData from "../../main/domain/CodeFormData";
import CodeFormValidator from "../../main/domain/CodeFormValidator";
import { fetchPatientReport } from "../../report/api/PatientCodeApi";

export interface PatientCodeFormResult {
  submit: (code: CodeFormData) => Promise<void>;
  errors: string[];
}

export interface PatientCodeValidationProps {
  onSuccess: () => void;
}

export const usePatientCodeForm = (props: PatientCodeValidationProps): PatientCodeFormResult => {
  const [errors, setErrors] = useState<string[]>([]);

  const submit = async (code: CodeFormData): Promise<void> => {
    setErrors([]);
    const timeZone = DateTime.getCurrentTimezone();
    const validationErrors = CodeFormValidator.validate(code);
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    };

    try {
      await fetchPatientReport(code.describe(), timeZone);
      props.onSuccess();
    } catch {
      setErrors(["invalidCode"]);
    }
  };
  return { submit, errors };
};

import { v4 as uuid } from "uuid";
import { MeasuresForDayFormData } from "./MeasureFormData";
import CustomProgramFormData from "./CustomProgramFormData";

class MeasuresForDayFactory {
  public create(customProgram: CustomProgramFormData): MeasuresForDayFormData[] {
    const { events, duration } = customProgram.schedulableRegimen.schedule;
    const inBetweenDates =
      duration.startDate.getInBetweenDates(customProgram.schedulableRegimen.schedule.duration.endDate);
    const scheduleMeasures: MeasuresForDayFormData[] = [];
    let index = 0;
    inBetweenDates.forEach((date): void => {
      const id = uuid();
      const scheduleMeasure = {
        date,
        measures: [events[index], events[index + 1]],
        id,
      };
      scheduleMeasures.push(scheduleMeasure);
      index += 2;
    });
    return scheduleMeasures;
  };
}

export default new MeasuresForDayFactory();

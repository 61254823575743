import React from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Trans, useTranslation } from "react-i18next";
import theme from "../../../main/theme/theme";
import Consultation from "../../domain/PreviousConsultation";
import ExpandableTableRow from "../../../main/components/ExpandableTableRow";
import displayPhoneNumber from "../../../main/helpers/displayPhoneNumber";

const useStyles = makeStyles(() => ({
  content: {
    flexDirection: "row",
    padding: `${theme.metrics.margin.normal} 0`,
  },
  boldText: {
    fontWeight: "bold",
  },
  emphasis: {
    fontWeight: 350,
  },
}));

interface Props {
  previousConsultation: Consultation;
}

const PreviousConsultationTableRow: React.FunctionComponent<Props> = ({ previousConsultation }) => {
  const { t } = useTranslation(["report", "profile"]);
  const classes = useStyles();

  return (
    <ExpandableTableRow className={classes.content}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={6} lg={6}>
              <Typography component="strong" variant="h5" className={classes.boldText}>
                {t("consultationTitle",
                  {
                    date: previousConsultation.date.format(t("dateTime.date")),
                    professional: previousConsultation.professionalName,
                    profession: t(`profile:professions.${previousConsultation.profession}`),
                  })
                }
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Trans
                t={t}
                i18nKey="officeNumber"
                components={[
                  <Typography key="0" component="span" variant="h5" className={classes.boldText} />,
                  <Typography key="1" component="span" variant="h5" className={classes.emphasis} />,
                ]}
                values={{ number: displayPhoneNumber(previousConsultation.officeNumber, t) }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ExpandableTableRow>
  );
};

export default PreviousConsultationTableRow;

import useUserRepository from "../store/hooks/useUserRepository";
import useProfileRepository from "../store/hooks/useProfileRepository";
import AuthenticationService from "../services/authentication";
import ProfileService from "../profile/services/profile";
import CrashReportingService from "../../main/services/CrashReportingService";
import analyticsClient, { AnalyticCustomProperty, AnalyticCustomPropertyValues } from "../../main/services/analytics";
import Profile from "authentication/profile/domain/Profile";
import { useCallback } from "react";

export interface UseUserProfileResult {
  initUserProfile: (organizationName?: string) => Promise<void>;
  updateUserProfileStore: () => Promise<Profile | undefined>;
}

export const useUserProfile = (): UseUserProfileResult => {
  const { setUser } = useUserRepository();
  const { setProfile } = useProfileRepository();

  const updateUserProfileStore = useCallback(async (): Promise<Profile | undefined> => {
    const currentUser = await AuthenticationService.getUser();
    const profile = await ProfileService.findProfile();

    setProfile(profile);
    setUser(currentUser);

    return profile;
  }, [setProfile, setUser]);

  const initUserProfile = useCallback(async (organizationName?: string): Promise<void> => {
    const profile = await updateUserProfileStore();
    const partnerCompany = [
      profile?.partnerCompany?.toString(), organizationName,
    ].filter(Boolean).join(" - ");

    CrashReportingService.setUser(profile?.id);

    analyticsClient.setUserProperty(
      AnalyticCustomProperty.PARTNER_COMPANY,
      partnerCompany || AnalyticCustomPropertyValues.GENERAL_USER
    );
  }, [updateUserProfileStore]);

  return {
    initUserProfile,
    updateUserProfileStore,
  };
};

import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as ReminderIcon } from "reminders/assets/alarm.svg";
import { TreatmentRow } from "./TreatmentRow";
import PastAdherence from "./adherence/PastAdherence";
import { generatePath, useParams } from "react-router";
import NavigationPath from "router/NavigationPath";
import Reminder from "report/domain/reminders/Reminder";
import TreatmentRowIcon from "./TreatmentRowIcon";
import analyticsClient, {
  AnalyticCategory, AnalyticEvent, AnalyticTreatment,
} from "main/services/analytics";

interface Props {
  reminder: Reminder;
  expanded: boolean;
  toggleExpanded: () => void;
}

export const TreatmentReminder: React.FunctionComponent<Props> = ({ reminder, expanded, toggleExpanded }) => {
  const { t } = useTranslation("report");
  const { code } = useParams<{ code: string }>();

  const viewResults = (): void => {
    analyticsClient.logEvent(
      AnalyticCategory.REPORT,
      AnalyticEvent.VIEW_RESULTS,
      { care_plan_items: AnalyticTreatment.REMINDER }
    );

    const reportCode = reminder.reportCode.code;

    window.open(generatePath(NavigationPath.PATIENT_REPORT_REMINDERS, { code, reportCode }));
  };

  return (
    <TreatmentRow
      title={t("reminder")}
      subtitle={reminder.name}
      schedule={reminder.getFormattedSchedule()}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      onViewResult={viewResults}
      picture={<TreatmentRowIcon Icon={ReminderIcon} />}
    >
      <Grid container>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <PastAdherence
            adherence={reminder.adherence}
            type="activity"
          />
        </Grid>
      </Grid>
    </TreatmentRow>
  );
};

export default TreatmentReminder;

import isPhoneNumberValid from "../../services/validators/phoneNumberValidator";
import ProfileFormData from "./ProfileFormData";
import { ProfileFormErrors } from "./ProfileFormErrors";
import ProfileError from "./ProfileError";

class ProfileValidator {

  public static validate(profileFormData: ProfileFormData): ProfileFormErrors {
    let errors = ProfileFormErrors.empty();

    if (profileFormData.firstName.length === 0) {
      errors = new ProfileFormErrors({
        ...errors,
        firstName: [...errors.firstName, ProfileError.EmptyFirstName],
      });
    }

    if (profileFormData.lastName.length === 0) {
      errors = new ProfileFormErrors({
        ...errors,
        lastName: [...errors.lastName, ProfileError.EmptyLastName],
      });
    }

    if (!isPhoneNumberValid(profileFormData.officeNumber, profileFormData.extension)) {
      errors = new ProfileFormErrors({
        ...errors,
        officeNumber: [...errors.officeNumber, ProfileError.EmptyOfficeNumber],
      });
    }

    if (!profileFormData.profession) {
      errors = new ProfileFormErrors({
        ...errors,
        profession: [...errors.profession, ProfileError.EmptyProfession],
      });
    }

    return errors;
  }
}

export default ProfileValidator;

import { LocalSessionContext } from "authentication/clientConnect/domain/SessionContext";
import { LocalStorageKeys } from "main/domain/LocalStorageKeys";
import { getJSON } from "main/utilities/StringUtilities";
import { SessionStorageKeys } from "../domain/SessionStorageKeys";
import TemporarySessionContextDocumentDto from "authentication/clientConnect/api/response/TemporarySessionContextDocumentDto";

export const getClientToken = (): string | null => sessionStorage.getItem(SessionStorageKeys.CLIENT_CONNECT_TOKEN);
export const getSessionContext = (): LocalSessionContext | null =>
  getJSON(localStorage.getItem(LocalStorageKeys.CLIENT_CONNECT_CONTEXT) || "");

export const getPatientProfileKey = (): string => sessionStorage.getItem(SessionStorageKeys.CLIENT_CONNECT_PATIENT_ID) || "";
export const getPatientDocuments = (): TemporarySessionContextDocumentDto[] =>
  getJSON(sessionStorage.getItem(SessionStorageKeys.CLIENT_CONNECT_PATIENT_DOCUMENTS) || "") || [];

export const isClientConnectContext = (): boolean =>
  !!sessionStorage.getItem(SessionStorageKeys.CLIENT_CONNECT_TOKEN)
  || !!localStorage.getItem(LocalStorageKeys.CLIENT_CONNECT_CONTEXT);
export const isStandaloneContext = (): boolean => !isClientConnectContext();


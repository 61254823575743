import React, { useEffect, useState } from "react";
import { AuthenticatorChildrenProps, AuthenticatorOverridingViewProps } from "aws-amplify-react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import useLoginForm from "../login/hooks/useLoginForm";
import ConfirmSignUpForm from "../components/ConfirmSignUpForm";
import useConfirmSignUp from "../confirmSignUp/hooks/useConfirmSignUp";
import LogInFormData from "../login/domain/LogInFormData";
import useUserRepository from "../store/hooks/useUserRepository";
import { CognitoUser } from "amazon-cognito-identity-js";
import NavigationPath from "router/NavigationPath";

type Props = AuthenticatorOverridingViewProps;

type InjectedProps = Props & AuthenticatorChildrenProps;

interface CustomAuthenticatorProps {
  email: string;
  password: string;
}
const DEFAULT_AUTHENTICATOR_PROPS = { email: "", password: "" };

const AmplifiedConfirmSignUp: React.FunctionComponent<Props> = (props) => {
  const { authState, authData, onStateChange } = props as InjectedProps;
  const { email, password } = authData as CustomAuthenticatorProps || DEFAULT_AUTHENTICATOR_PROPS;
  const { t } = useTranslation("authentication");
  const [codeSentMessage, setCodeSentMessage] = useState<string | undefined>();
  const goToRequireNewPassword = (user: CognitoUser): void => onStateChange("requireNewPassword", { user });
  const { onSubmit, errors: loginErrors } = useLoginForm({ onRequireNewPassword: goToRequireNewPassword });
  const { authenticated } = useUserRepository();

  const onSuccess = async (): Promise<void> => onSubmit(new LogInFormData({ email, password }));

  useEffect(() => {
    if (!loginErrors.isEmpty()) {
      onStateChange("signIn", {});
    }
  }, [loginErrors, onStateChange]);

  const onCodeSentSuccess = (): void => setCodeSentMessage(t("codeSent"));
  const {
    error: confirmError,
    onConfirmSignUp,
    onResendConfirmationCode,
  } = useConfirmSignUp({ onSuccess, onCodeSentSuccess });

  if (authState !== "confirmSignUp") {
    return null;
  }

  if (authenticated) {
    return (
      <Redirect to={NavigationPath.COMPLETE_PROFILE} />
    );
  }

  const errorText: string | undefined = confirmError && t(`errors.${confirmError}`);

  return (
    <ConfirmSignUpForm
      onConfirmSignUp={onConfirmSignUp}
      onResendCode={(): Promise<void> => onResendConfirmationCode(email.toLowerCase())}
      emailSentTo={email.toLowerCase()}
      error={errorText}
      message={codeSentMessage}
    />
  );
};

export default AmplifiedConfirmSignUp;

import ModalHeaderCarePlan from "../../main/modal/ModalHeaderCarePlan";
import Modal from "../../main/modal/Modal";
import React, {
  Ref, useImperativeHandle, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ReminderForm } from "./forms/ReminderForm";
import { makeStyles } from "@material-ui/core";
import { ModalBody } from "main/modal/ModalBody";
import { pxToRem } from "main/theme/theme";
import analyticsClient, {
  AnalyticCategory, AnalyticEvent, AnalyticTreatment,
} from "../../main/services/analytics";
import ReminderFormData from "reminders/domain/ReminderFormData";

export interface ReminderModalRef {
  show: (customActivity?: ReminderFormData) => void;
  close: () => void;
}

interface Props {
  onReminderAdd: (formData: ReminderFormData) => void;
  onEditReminder: (customActivity: ReminderFormData) => void;
}

export const ReminderFormModal = React.forwardRef((
  { onReminderAdd, onEditReminder }: Props,
  ref: Ref<ReminderModalRef>
): JSX.Element => {
  const [open, setOpened] = useState(false);
  const classes = useStyle();
  const { t } = useTranslation("reminder");
  const [reminderToEdit, setReminderToEdit] = useState<ReminderFormData | undefined>();

  const show = (reminder?: ReminderFormData): void => {
    setReminderToEdit(reminder);
    setOpened(true);
  };

  const close = (): void => setOpened(false);

  useImperativeHandle(ref, () => ({
    show,
    close,
  }));

  const submitReminder = (formData: ReminderFormData): void => {
    onReminderAdd(formData);

    analyticsClient.logEvent(
      AnalyticCategory.CARE_PLAN,
      AnalyticEvent.ADD_CAREPLAN,
      { care_plan_items: AnalyticTreatment.REMINDER }
    );

    close();
  };

  const submitEditReminder = (reminder: ReminderFormData): void => {
    if (!reminderToEdit) {
      return;
    }

    const newReminder = { ...reminder };
    onEditReminder(newReminder);
  };

  return (
    <Modal
      isOpen={open}
      onClose={close}
      disableOutsideClick
      className={classes.modal}
      labelledBy="reminderFormModal"
    >
      <ModalHeaderCarePlan
        id="reminderFormModal"
        onClose={close}
        title={reminderToEdit ? t("editReminder") : t("addReminder")}
      />

      <ModalBody className={classes.body}>
        <ReminderForm
          reminder={reminderToEdit}
          submitLabel={reminderToEdit
            ? t("save")
            : t("add")
          }
          onSubmit={reminderToEdit ? submitEditReminder : submitReminder}
        />
      </ModalBody>
    </Modal>
  );
});

const useStyle = makeStyles(() => ({
  modal: {
    padding: 0,
  },
  body: {
    width: pxToRem(500),
  },
}));

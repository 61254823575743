import React from "react";
import { ReactComponent as CheckMark } from "../assets/checkMark.svg";
import { ReactComponent as CrossMark } from "../assets/crossMark.svg";
import { ReactComponent as CurrentMark } from "../assets/currentMark.svg";
import { ReactComponent as IncompleteMark } from "../assets/incompleteMark.svg";

interface Props {
  state: "error"|"completed"|"incomplete"|"current";
  id?: string;
  className?: string;
}

export const Dot: React.FunctionComponent<Props> = ({ state, id, className }) => {
  switch (state) {
    case "error": {
      return <CrossMark id={id} className={className} />;
    }

    case "completed": {
      return <CheckMark id={id} className={className} />;
    }

    case "current": {
      return <CurrentMark id={id} className={className} />;
    }

    case "incomplete": {
      return <IncompleteMark id={id} className={className} />;
    }
  }
};

export default Dot;

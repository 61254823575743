import { ConsultationDocumentDto } from "document/api/dto/ConsultationDocumentDto";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

interface Props {
  documents?: ConsultationDocumentDto[];
  titleClassname: string;
}

const DocumentSummary = ({ documents, titleClassname }: Props): JSX.Element => {
  const { t } = useTranslation("consultationSummary");

  return (
    <>
      <Typography component="label" variant="h3" className={titleClassname}>
        {t("documents")}
      </Typography>

      {
        documents?.length
          ? <ul>
            {documents.map((document, index) => (
              <li key={"document-" + index}>
                <Typography id="carePlanTitle" component="span">{document.name}</Typography>
              </li>
            ))}
          </ul>
          : <Typography component="div" variant="h5">{t("noDocuments")}</Typography>
      }
    </>
  );
};

export default DocumentSummary;

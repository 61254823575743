import React from "react";
import { makeStyles } from "@material-ui/core";
import NotesTable from "./NotesTable";
import NotesReport from "../../domain/note/NotesReport";
import { NotesTableHeader } from "./NotesTableHeader";

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface Props {
  notes: NotesReport;
  className?: string;
}

const Notes: React.FunctionComponent<Props> = ({ notes, className }) => {
  const classes = useStyles();

  return (
    <div className={[classes.tableContainer, className].filter(Boolean).join(" ")}>
      <NotesTable
        notes={notes}
        CustomHeader={NotesTableHeader}
      />
    </div>
  );
};
export default Notes;

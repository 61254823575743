import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import theme from "../main/theme/theme";
import useConnectionHistory from "./hooks/useConnectionHistory";
import ConnectionHistoryTable from "./components/ConnectionHistoryTable";

const useStyle = makeStyles({
  wrapper: {
    height: "100%",
    display: "flex",
    marginTop: theme.metrics.margin.normal,
    justifyContent: "center",
  },
});

const ConnectionHistory: React.FunctionComponent = () => {
  const { connections, loading, error } = useConnectionHistory();
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <Grid item md={10} sm={12}>
        <ConnectionHistoryTable connections={connections} loading={loading} error={error} />
      </Grid>
    </div>
  );
};

export default ConnectionHistory;

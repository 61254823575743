import React, { ChangeEvent } from "react";
import {
  Link, makeStyles, TextField, Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CodeFormData from "../domain/CodeFormData";

interface Props extends StyleProps {
  code: CodeFormData;
  onChange: (newValue: CodeFormData) => void;
  withClearButton?: boolean;
}

interface Size {
  width: number;
  height: number;
}

const SUPPORTED_SIZES: { [key: string]: Size } = {
  small: {
    width: 3.85,
    height: 1.85,
  },
  large: {
    width: 7,
    height: 4.5,
  },
};

interface StyleProps {
  size?: keyof typeof SUPPORTED_SIZES;
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  codeCharacter: ({ size = "small" }: StyleProps): any => ({
    margin: "0 0.5rem",
    width: `${SUPPORTED_SIZES[size].width}rem`,
    textAlign: "center",
  }),
  codeCharacterInput: {},
  outline: {},
  inputSize: ({ size = "small" }: StyleProps): any => ({
    fontSize: `${SUPPORTED_SIZES[size].height}rem`,
    textAlign: "center",
    padding: "1rem 0",
  }),
  container: {
    "& $codeCharacterInput[value]:not([value = \"\"]) ~ $outline": {
      borderColor: muiTheme.palette.primary.light,
    },
    "display": "flex",
    "flexDirection": "row",
    "alignItems": "flex-end",
    "position": "relative",
  },
  clearLink: {
    position: "absolute",
    right: 0,
    bottom: 0,
    transform: "translate(105%, 0%)",
  },
}));

export const CodeInput: React.FunctionComponent<Props> = ({ code, onChange, size = "small", withClearButton }) => {
  const { t } = useTranslation("common");
  const refArray: HTMLDivElement[] = [];

  const focus = (index: number): void => {
    if (index < 0) {
      refArray[0].focus();

    } else if (index >= code.length) {
      refArray[code.length - 1].focus();

    } else {
      refArray[index].focus();

    }
  };

  const addRef = (index: number) => (ref: HTMLDivElement | null): void => {
    if (ref) {
      refArray[index] = ref;
    }
  };

  const keyPress = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const key = event.key;
    if (key === "Backspace") {
      focus(index - 1);
    }
  };

  const selectAllText = (event: React.FocusEvent<HTMLInputElement>): void => {
    event.target.select();
  };

  const clearInput = (event: React.FormEvent): void => {
    event.preventDefault();
    onChange(code.clear());
    focus(0);
  };

  const shouldDisplayClearButton = !!withClearButton && !code.isEmpty();

  const classes = useStyles({ size });

  return (
    <div className={classes.container}>
      {code.codeCharacters.map((char, index) => (
        <TextField
          key={index}
          className={classes.codeCharacter}
          variant="outlined"
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            onChange(code.withCharacter(event.target.value, index));
            if (event.target.value) {
              focus(index + 1);
            }
          }}
          inputRef={addRef(index)}
          inputProps={{
            maxLength: 1,
            value: char,
            onFocus: selectAllText,
            onKeyUp: keyPress(index),
          }}
          InputProps={{
            classes: {
              input: classes.inputSize,
            },
          }}
        />
      ))}

      {!!shouldDisplayClearButton &&
        <Link component="button" type="button" variant="h4" onClick={clearInput} className={classes.clearLink}>
          {t("clear")}
        </Link>
      }
    </div>
  );
};

export default CodeInput;

import PatientReport from "../domain/PatientReport";
import PatientReportResponse from "./responses/PatientReportResponse";
import PatientAssembler from "./PatientAssembler";
import TreatmentAssembler from "./TreatmentAssembler";
import ConsultationAssembler from "./ConsultationAssembler";
import NoteAssembler from "./NoteAssembler";

class PatientReportAssembler {
  public static fromResponse(response: PatientReportResponse): PatientReport {
    return new PatientReport(
      PatientAssembler.fromResponse(response.patient),
      TreatmentAssembler.fromResponse(response.treatment),
      ConsultationAssembler.fromResponse(response.previousConsultations),
      NoteAssembler.fromResponse(response.notes),
    );
  }
}

export default PatientReportAssembler;

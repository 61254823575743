import { useState, useEffect } from "react";
import ConsultationSummaryApi from "consultationSummary/api/ConsultationSummaryApi";
import Patient from "report/domain/Patient";
import PendingTreatmentPlan from "prescribe/domain/PendingTreatmentPlan";
import HistoricalInvitationResponse from "consultationSummary/api/response/HistoricalInvitationResponse";
import PatientAssembler from "report/api/PatientAssembler";
import PendingTreatmentPlanAssembler from "prescribe/api/assemblers/PendingTreatmentPlanAssembler";
import { ConsultationDocumentDto } from "document/api/dto/ConsultationDocumentDto";

interface ConsultationSummary {
  patient: Patient;
  treatmentPlan: PendingTreatmentPlan | null;
  instructions: string;
  documents: ConsultationDocumentDto[];
}

type ConsultationSummaryResult = Partial<ConsultationSummary> & {};

const useConsultationSummary = (consultationId: string): ConsultationSummaryResult => {
  const [consultationSummary, setConsultationSummary] = useState<ConsultationSummary>();

  const assemblePatient = (summary: HistoricalInvitationResponse): Patient => PatientAssembler.fromResponse({
    firstName: summary.profile.firstName || null,
    lastName: summary.profile.lastName || null,
    allergies: summary.profile.allergies || null,
    birthdate: summary.profile.birthDate || null,
    email: summary.email,
    gender: summary.profile.gender || null,
    picture: null,
  });

  useEffect(() => {
    const fetchReport = async (): Promise<void> => {
      try {
        const summary = await ConsultationSummaryApi.fetchConsultationSummary(consultationId);
        const treatmentPlan = summary.pendingTreatmentPlan
          && PendingTreatmentPlanAssembler.fromProposedTreatmentPlan(summary.pendingTreatmentPlan);

        setConsultationSummary({
          patient: assemblePatient(summary),
          treatmentPlan,
          instructions: summary.instructions,
          documents: summary.documents,
        });
      } catch {
        setConsultationSummary(undefined);
      }
    };

    fetchReport();
  }, [consultationId]);

  return {
    ...consultationSummary,
  };
};

export default useConsultationSummary;

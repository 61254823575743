import React from "react";
import { makeStyles, Menu } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import Avatar from "../../../components/Avatar";
import Profile from "../../../../authentication/profile/domain/Profile";
import theme, { pxToRem } from "../../../theme/theme";
import UserName from "./components/UserName";
import ProfileMenuItem from "./components/ProfileMenuItem";
import NavigationPath from "router/NavigationPath";

const AVATAR_SIZE = 55;
const ARROW_WIDTH = 10;
const MENU_BORDER_WIDTH = 2;
const MENU_MIN_WIDTH = 200;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "right",
  },
  avatar: {
    "&:hover": {
      border: "1px solid white",
    },
  },
  avatarButton: {
    "backgroundColor": "transparent",
    "borderRadius": "50%",
    "alignItems": "center",
    "padding": 0,
    "border": `${pxToRem(4)} solid transparent`,
    "marginLeft": theme.metrics.margin.normal,
    "&:focus, &:active, &:hover, &.active": {
      "outline": "none",
      "borderColor": theme.colors.divider,
      "& $avatar": {
        border: `${pxToRem(1)} solid white`,
      },
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  menu: {
    "border": `${pxToRem(MENU_BORDER_WIDTH)} solid ${theme.colors.divider}`,
    "borderRadius": pxToRem(8),
    "overflow": "visible",
    "minWidth": `${pxToRem(MENU_MIN_WIDTH)}`,
    "&::before": {
      content: "\"\"",
      height: 0,
      position: "absolute",
      width: 0,
      top: `${pxToRem(-(2 * ARROW_WIDTH + MENU_BORDER_WIDTH))}`,
      right: pxToRem(((AVATAR_SIZE) / 2) - (ARROW_WIDTH - MENU_BORDER_WIDTH)),
      border: `${pxToRem(ARROW_WIDTH)} solid transparent`,
      borderBottomColor: theme.colors.divider,
    },
  },
  menuList: {
    padding: `${pxToRem(4)} 0`,
  },
  menuLink: {
    textDecoration: "none",
  },
}));

interface Props {
  profile: Profile;
  onLogout: () => void;
}

export const ProfileMenu: React.FunctionComponent<Props> = ({ profile, onLogout }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation(["authentication", "profile"]);
  const classes = useStyles();

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (): void => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.container}>
      <UserName firstName={profile.firstName} lastName={profile.lastName} />

      <button
        onClick={openMenu}
        type="button"
        aria-controls="profile-menu"
        data-testid="profile-menu-button"
        className={[classes.avatarButton, !!anchorEl ? "active" : ""].join(" ")}
      >
        <Avatar size={AVATAR_SIZE} className={classes.avatar} picture={null} />
      </button>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        style={{ marginTop: theme.metrics.margin.normal }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!anchorEl}
        elevation={0}
        classes={{ list: classes.menuList }}
        PopoverClasses={{ paper: classes.menu }}
        onClose={closeMenu}
      >
        <Link to={NavigationPath.MY_ACCOUNT} className={classes.menuLink} key="profile-link">
          <ProfileMenuItem text={t("profile:myAccount")} onClick={closeMenu} />
        </Link>

        <Divider key="profile-link-divider" />

        <ProfileMenuItem onClick={onLogout} text={t("logout")} />
      </Menu>
    </div>
  );
};

export default ProfileMenu;

import HealthProfession from "./HealthProfession";
import PartnerCompany from "./PartnerCompany";
import PartnerCompanyEmailParser from "./PartnerCompanyEmailParser";
import User from "../../../main/domain/User";

export default class ProfileFormData {
  public firstName: string;
  public lastName: string;
  public officeAddress: string;
  public officeNumber: string;
  public extension: string;
  public profession?: HealthProfession;
  public partnerCompany?: PartnerCompany;

  constructor({ firstName, lastName, officeAddress, officeNumber, extension, profession, partnerCompany }: {
    firstName: string;
    lastName: string;
    officeAddress: string;
    officeNumber: string;
    extension: string;
    profession?: HealthProfession;
    partnerCompany?: PartnerCompany;
  }) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.officeAddress = officeAddress;
    this.officeNumber = officeNumber;
    this.extension = extension;
    this.profession = profession;
    this.partnerCompany = partnerCompany;
  }

  public static empty(user: User | undefined): ProfileFormData {
    return new ProfileFormData({
      firstName: "",
      lastName: "",
      officeAddress: "",
      officeNumber: "",
      extension: "",
      profession: undefined,
      partnerCompany: user && PartnerCompanyEmailParser.parse(user.email),
    });
  }

  public isEqualTo(profileFormData: ProfileFormData): boolean {
    return this.firstName === profileFormData.firstName &&
      this.lastName === profileFormData.lastName &&
      this.officeAddress === profileFormData.officeAddress &&
      this.officeNumber === profileFormData.officeNumber &&
      this.extension === profileFormData.extension &&
      this.profession === profileFormData.profession &&
      this.partnerCompany === profileFormData.partnerCompany;
  }
}

// inspired by
// https://material.io/design/color/the-color-system.html#color-theme-creation

const DEFAULT_TEXT_SIZE = 14;

export const pxToRem = (px: number): string => `${px / DEFAULT_TEXT_SIZE}rem`;

export const convertHexToRGBA = (hex: string, opacity: number): string => {
  const tempHex = hex.replace("#", "");
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

const theme = {
  boxShadow: `0 0 ${pxToRem(5)} 0 rgba(90, 90, 90, 0.1)`,

  colors: {
    background: "#F2F2F2",
    error: "#c10000",
    backgroundError: "rgba(193,0,0,0.1)",

    darkOverlay: "rgba(0,0,0,0.375)",
    paleOverlay: "rgba(255,255,255,0.5)",

    primary: {
      normal: "#198AB9",
      light: "#0ba5be",
      paleBlue: "#F2FAFC",
    },

    secondary: "#f50056",
    surface: "#FFFFFF",
    disabled: "#CCC",
    divider: "rgba(0, 0, 0, 0.14)",

    text: {
      primary: "rgba(00,00,00,0.8)",
      contrast: "#FFF",
      secondary: "#ababab",
      surface: "#424242",
      title: "#424242",
      emphasis: "#6b6b6b",
      success: "#54C215",
      disabled: "rgba(0, 0, 0, 0.6)",
    },
    button: {
      primary: {
        background: "linear-gradient(#90ed4e, #54C215)",
        borderRadius: "20rem",
        color: "white",
        padding: "0.5rem 2.5rem",
        boxShadow: "inherit",
        disabled: {
          background: "linear-gradient(#ebebeb, #b5b5b5)",
        },
      },
      secondary: {
        background: "#FFFFFF",
        borderRadius: "20rem",
        border: `solid ${pxToRem(1)} #6b6b6b`,
        color: "#6b6b6b",
        padding: "0.5rem 2.5rem",
        boxShadow: "none",
      },
      error: {
        backgroundColor: "#c10000 !important",
        borderRadius: "20rem",
        color: "white",
        padding: "0.5rem 2.5rem",
        boxShadow: "inherit",
      },
      icon: {
        background: "transparent",
        color: "#198AB9",
        hovered: {
          color: "#0ba5be",
        },
        disabled: {
          background: convertHexToRGBA("#000000", 38),
        },
      },
    },
    progressWheel: {
      start: "#2485B5",
      middle: "#16abbf",
      end: "#13D3DE",
    },
    table: {
      header: "#7ECFE4",
      row: "#F2FAFC",
    },
    charts: {
      primary: "#0ba5be",
      second: "#d48422",
      third: "#666",
    },
  },

  fonts: {
    family: "Source Sans Pro, sans-serif",
    defaultSize: `${DEFAULT_TEXT_SIZE}px`,

    button: {
      letterSpacing: pxToRem(0.16),
      size: pxToRem(18),
      weight: "normal" as const,
    },

    textField: {
      letterSpacing: pxToRem(0.16),
      size: pxToRem(16),
      weight: "bold" as const,
    },

    h1: {
      size: pxToRem(32),
      weight: "normal" as const,
      letterSpacing: pxToRem(0.375),
    },
    h2: {
      size: pxToRem(24),
      weight: "normal" as const,
      letterSpacing: pxToRem(0.285),
    },
    h3: {
      size: pxToRem(21),
      weight: "normal" as const,
      letterSpacing: pxToRem(0.255),
    },
    h4: {
      size: pxToRem(18),
      weight: "normal" as const,
      letterSpacing: pxToRem(0.195),
    },
    h5: {
      size: pxToRem(16),
      weight: "normal" as const,
      letterSpacing: pxToRem(0.195),
    },
    h6: {
      size: pxToRem(13),
      weight: "normal" as const,
      letterSpacing: pxToRem(0.165),
    },
    legal: {
      size: pxToRem(11),
      weight: "normal" as const,
      letterSpacing: pxToRem(0.165),
    },
  },

  metrics: {
    breakpoints: {
      small: 600,
      normal: 1200,
    },
    margin: {
      tiny: pxToRem(5),
      small: pxToRem(10),
      normal: pxToRem(16),
      medium: pxToRem(24),
      large: pxToRem(32),
      huge: pxToRem(64),
    },
    border: {
      tiny: "solid 1px",
    },
    headerIconSize: pxToRem(40),
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: "5px",
      backgroundColor: "transparent",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#C2C2C2",
      borderRadius: "10px",
    },
    "scrollbar-color": "#C2C2C2 transparent",
    "scrollbar-width": "thin",
  },
};

export type Theme = typeof theme;

export default theme;

import { useEffect, useState } from "react";
import DateTime from "../../main/dateTime/DateTime";
import { fetchReminderResults } from "../api/ReportResultsApi";
import ReminderResults from "report/domain/reminders/ReminderResults";

export interface ReminderResultsResult {
  errors: boolean;
  results: ReminderResults | null;
}

export interface ReminderResultResultsProps {
  code: string;
  reportCode: string;
}

const useReminderResults = ({
  reportCode,
  code,
}: ReminderResultResultsProps): ReminderResultsResult => {
  const [results, setResults] = useState<ReminderResults | null>(null);
  const [errors, setErrors] = useState<boolean>(false);

  useEffect(() => {
    const fetchReminder = async (): Promise<void> => {
      setErrors(false);
      const timeZone = DateTime.getCurrentTimezone();
      try {
        const reminderResults = await fetchReminderResults(reportCode, code, timeZone);
        setResults(reminderResults);
      } catch (e) {
        setErrors(true);
      }
    };

    fetchReminder();
  }, [code, reportCode]);

  return { results, errors };

};

export default useReminderResults;

import LogInFormErrors from "../login/domain/LogInFormErrors";
import User from "../../main/domain/User";

interface UserStore {
  user: User | undefined;
  errors: LogInFormErrors;
}

export const userInitialState: UserStore = {
  user: undefined,
  errors: LogInFormErrors.empty(),
};

export default UserStore;

import { ProposedMeasurementDto, ProposedSchedulableMeasurementDto } from "../dtos/ProposedSchedulableMeasurementDto";
import { MeasurementFormData } from "../../../measurement/domain/MeasurementFormData";
import { MeasurementDetail } from "../../../measurement/domain/MeasurementDetail";
import ProposedScheduleAssembler from "./ProposedScheduleAssembler";
import ProposedSessionEventAssembler from "./events/ProposedSessionEventAssembler";
import { CustomMeasurementDetail } from "measurement/domain/CustomMeasurementDetail";

const toDto = (formData: MeasurementFormData<MeasurementDetail>): ProposedSchedulableMeasurementDto => ({
  schedule: ProposedScheduleAssembler.toDto(formData.schedule, ProposedSessionEventAssembler),
  measurement: assembleMeasurementToDto(formData.details.measurementDetail),
  instructions: formData.instructions.text,
});

const assembleMeasurementToDto = (measurementDetail: MeasurementDetail): ProposedMeasurementDto => {
  if (measurementDetail instanceof CustomMeasurementDetail) {
    return {
      type: measurementDetail.type,
      name: measurementDetail.name,
    };
  }

  return {
    type: measurementDetail.type,
  };
};

export const ProposedSchedulableMeasurementAssembler = {
  toDto,
};

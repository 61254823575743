import ClientConnectError from "authentication/clientConnect/domain/ClientConnectError";
import FailedToConsumeToken from "authentication/clientConnect/domain/exceptions/FailedToConsumeToken";
import merge from "deepmerge";
import authenticationErrors from "../../authentication/internationalization/authenticationErrors";
import FailedToFindProfileError from "../../authentication/profile/domain/exceptions/FailedToFindProfileError";

const exceptions = {
  en: {
    exceptions: {
      [FailedToFindProfileError.name]: "Unknown server error. Please refresh this page.",
      [FailedToConsumeToken.name]: "Unable to connect to your account. Please close the tab and try again.",
      [ClientConnectError.FailedToSignIn]: "Unable to connect to your account. Please close the tab and try again.",
    },
  },
  fr: {
    exceptions: {
      [FailedToFindProfileError.name]: "Une erreur est survenue. Veuillez rafraîchir la page.",
      [FailedToConsumeToken.name]: "Incapable de vous connecter à votre compte. Veuillez fermer l'onglet et réessayer.",
      [ClientConnectError.FailedToSignIn]: "Incapable de vous connecter à votre compte. Veuillez fermer l'onglet et réessayer.",
    },
  },
};

export default merge.all([exceptions, authenticationErrors]);

import Measure, { MeasureAttributes } from "../Measure";
import Meal from "./Meal";
import TimeOfDay from "./TimeOfDay";

interface BloodGlucoseMeasureAttributes extends MeasureAttributes {
  value: number;
  targetRange: string;
  measureTime: TimeOfDay;
  meal?: Meal | undefined;
}

export default class BloodGlucoseMeasure extends Measure {
  public value: number;
  public targetRange: string;
  public measureTime: TimeOfDay;
  public meal?: Meal | undefined;

  constructor(plain: BloodGlucoseMeasureAttributes) {
    super({ id: plain.id, actualTimestamp: plain.actualTimestamp });
    this.value = plain.value;
    this.targetRange = plain.targetRange;
    this.measureTime = plain.measureTime;
    this.meal = plain.meal;
  }
}

import HealthProfession from "./HealthProfession";
import PartnerCompany from "./PartnerCompany";
import PhoneNumber from "../../../main/domain/PhoneNumber";

export default class NewProfile {
  public firstName: string;
  public lastName: string;
  public officeAddress?: string;
  public officeNumber: PhoneNumber;
  public profession: HealthProfession;
  public partnerCompany?: PartnerCompany;
  public agreementDate?: string;

  constructor(
    firstName: string,
    lastName: string,
    officeAddress: string | undefined,
    officeNumber: PhoneNumber,
    profession: HealthProfession,
    partnerCompany?: PartnerCompany,
    agreementDate?: string,
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.officeAddress = officeAddress;
    this.officeNumber = officeNumber;
    this.profession = profession;
    this.partnerCompany = partnerCompany;
    this.agreementDate = agreementDate;
  }
}

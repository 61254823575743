import React, { ChangeEvent } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import DateTime from "../../main/dateTime/DateTime";

const useStyles = makeStyles(() => ({
  textField: {
    margin: 0,
    flex: 1,
  },
}));

interface Props {
  id?: string;
  date: DateTime | null;
  onChange?: (value: string) => void;
  isDisabled?: boolean;
  onBlur?: () => void;
  testId?: string;
  minValue?: string;
  required?: boolean;
  ariaLabelledBy?: string;
}

const DurationDatePicker: React.FunctionComponent<Props> = ({
  date,
  onChange,
  isDisabled,
  onBlur,
  testId,
  required,
  minValue,
  id,
  ariaLabelledBy,
}) => {
  const classes = useStyles();

  const change = (event: ChangeEvent<HTMLInputElement>): void =>
    onChange && onChange(event.target.value);

  return (
    <TextField
      className={classes.textField}
      type="date"
      variant="standard"
      value={date?.toISODate() || ""}
      onBlur={onBlur}
      required={required}
      inputProps={{
        "data-testid": testId,
        "min": minValue,
        "max": "9999-12-31",
        "aria-labelledby": ariaLabelledBy,
      }}
      id={id}
      onChange={change}
      disabled={isDisabled}
    />
  );
};

export default DurationDatePicker;

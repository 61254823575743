import MeasurementSchedule from "../domain/measurements/MeasurementSchedule";
import DateTime from "../../main/dateTime/DateTime";
import { MeasurementScheduleResponse } from "./responses/TreatmentResponse";
import ScheduleEventAssembler from "./ScheduleEventAssembler";

class MeasurementScheduleAssembler {
  public toDomain(measurementSchedule: MeasurementScheduleResponse): MeasurementSchedule {
    return new MeasurementSchedule({
      recurrenceRule: measurementSchedule.recurrenceType.rule,
      duration: {
        startDate: DateTime.fromISO(measurementSchedule.duration.startDate),
        endDate: DateTime.fromISO(measurementSchedule.duration.endDate),
      },
      dailySessions: measurementSchedule.dailySessions,
      events: ScheduleEventAssembler.fromResponses(measurementSchedule.events),
    });
  }
}

export default new MeasurementScheduleAssembler();

import { Grid } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: `${theme.metrics.margin.normal} 0`,
  },
  label: {
    width: "150px",
  },
}));

interface Props {
  name: string;
  htmlFor?: string;
}

export const PatientProfileField: React.FunctionComponent<Props> = ({ name, htmlFor, children }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} lg={5} alignItems="center" className={classes.wrapper}>
      <Typography component="label" variant="h4" htmlFor={htmlFor} className={classes.label}>
        {name}
      </Typography>

      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  );
};

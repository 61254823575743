import React from "react";
import { useTranslation } from "react-i18next";
import {
  makeStyles, Theme, Typography,
} from "@material-ui/core";
import { pxToRem } from "../../main/theme/theme";
import WarningMessage from "../../main/components/WarningMessage";

const useStyles = makeStyles(({ palette }: Theme) => ({
  container: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  wrapper: {
    border: `${pxToRem(2)} solid ${palette.divider}`,
    borderRadius: `${pxToRem(7)}`,
  },
}));

export const PatientReportError: React.FunctionComponent = () => {
  const { t } = useTranslation("report");
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <WarningMessage className={classes.wrapper}>
        <Typography component="p" variant="h4" align="justify">
          {t("thereWasAProblem")}
        </Typography>
      </WarningMessage>
    </div>
  );
};
export default PatientReportError;

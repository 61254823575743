import Measure, { MeasureAttributes } from "../Measure";
import { MoodFeeling } from "./MoodFeeling";

interface MoodMeasureAttributes extends MeasureAttributes {
  value: MoodFeeling;
}

class MoodMeasure extends Measure {

  public readonly feeling: MoodFeeling;

  constructor({ id, value, actualTimestamp }: MoodMeasureAttributes) {
    super({ id, actualTimestamp });
    this.feeling = value;
  }

}

export default MoodMeasure;

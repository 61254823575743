import React from "react";
import AddIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import {
  IconButton, makeStyles, Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import NumericStepper from "../../domain/NumericStepper";
import theme from "../../theme/theme";

const useStyle = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  quantityIndicator: {
    display: "flex",
    justifyContent: "center",
    width: "30px",
    margin: `0 ${theme.metrics.margin.tiny}`,
    fontSize: theme.fonts.textField.size,
  },
  button: {
    "padding": theme.metrics.margin.tiny,
    "color": theme.colors.button.icon.color,
    "fontSize": "26px",
    "&:hover:not(:disabled)": {
      color: theme.colors.button.icon.hovered.color,
      cursor: "pointer",
    },
    "&:active:not(:disabled)": {
      opacity: 0.7,
    },
    "&.disabled": {
      color: theme.colors.button.icon.disabled.background,
    },
  },
}));

const getNumberOfDecimal = (value = 1): number => {
  const separatedFloat = (value.toString() + "").split(".");
  return separatedFloat.length > 1 ? separatedFloat[1].length : 0;
};

const formatValue = (valueToFormat: number, gap: number): string => valueToFormat.toFixed(getNumberOfDecimal(gap));

const roundValue = (valueToRound: number, precision: number): number => parseFloat(valueToRound.toFixed(precision));

interface Props {
  max: number;
  min: number;
  value: number;
  onChange: (newValue: number, previousValue: number) => void;
  stepper?: NumericStepper;
  labelledBy?: string;
}

const PlusMinusInput: React.FC<Props> = (props) => {
  const {
    max,
    min,
    value,
    onChange,
    stepper = new NumericStepper(),
    labelledBy,
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();

  const increment = (): void => {
    const incrementedValue = stepper.incrementValue(value);

    onChange(roundValue(incrementedValue, getNumberOfDecimal(incrementedValue - value)), value);
  };

  const decrement = (): void => {
    const decrementedValue = stepper.decrementValue(value);

    onChange(roundValue(decrementedValue, getNumberOfDecimal(value - decrementedValue)), value);
  };

  const disabledMaxButton = value >= max;
  const disabledMinButton = value <= min;

  return (
    <section aria-labelledby={labelledBy} className={classes.container}>
      <IconButton
        disabled={disabledMinButton}
        onClick={decrement}
        aria-label={t("decrement")}
        className={classNames(classes.button, { disabled: disabledMinButton })}
      >
        <RemoveIcon fontSize="inherit" />
      </IconButton>

      <Typography className={classes.quantityIndicator}>
        {formatValue(value, stepper.getCurrentStep(value))}
      </Typography>

      <IconButton
        disabled={disabledMaxButton}
        onClick={increment}
        aria-label={t("increment")}
        className={classNames(classes.button, { disabled: disabledMaxButton })}
      >
        <AddIcon fontSize="inherit" />
      </IconButton>
    </section>
  );
};

export default PlusMinusInput;

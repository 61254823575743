import { Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomMeasurement from "../domain/measurements/customMeasurement/CustomMeasurement";
import PastAdherence from "./adherence/PastAdherence";
import CustomMeasurementIcon from "./CustomMeasurementIcon";
import { useParams } from "react-router";
import { MeasurementTreatmentRow } from "./MeasurementTreatmentRow";
import { MeasurementType } from "../../measurement/domain/MeasurementType";
import useLanguageSelection from "main/hooks/useLanguageSelection";

interface Props {
  measurement: CustomMeasurement;
  expanded: boolean;
  toggleExpanded: () => void;
}

const TreatmentCustomMeasurement: React.FunctionComponent<Props> = ({
  measurement,
  expanded,
  toggleExpanded,
}) => {
  const { t } = useTranslation("report");
  const { currentLanguage } = useLanguageSelection();
  const { code } = useParams<{ code: string }>();
  const reportCode = measurement.reportCode.code;

  return (
    <MeasurementTreatmentRow
      title={t("measurement")}
      subtitle={measurement.displayName()}
      schedule={measurement.getFormattedSchedule(currentLanguage)}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      picture={<CustomMeasurementIcon />}
      code={code}
      reportCode={reportCode}
      type={MeasurementType.CUSTOM}
    >
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          <PastAdherence
            adherence={measurement.adherence}
            type="measurement"
          />
        </Grid>
      </Grid>
    </MeasurementTreatmentRow>
  );

};

export default TreatmentCustomMeasurement;

import {
  IconButton as MUIIconButton,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";

import React from "react";
import classNames from "classnames";
import theme from "../../theme/theme";

const useStyle = makeStyles(() => ({
  button: {
    "background": "transparent",
    "boxShadow": "0px 0px 0px transparent",
    "border": "0px solid transparent",
    "textShadow": "0px 0px 0px transparent",
    "&.primary": {
      color: theme.colors.primary.normal,
    },
    "&.danger": {
      color: theme.colors.error,
    },
  },
}));

interface Props {
  title: string;
  onClick?: () => void;
  className?: string;
  type?: "primary" | "danger";
}

const IconButton: React.FC<Props> = (props) => {
  const {
    children,
    type = "primary",
    className,
    onClick,
    title,
  } = props;
  const classes = useStyle();

  return (
    <Tooltip arrow title={<Typography style={{ whiteSpace: "pre-wrap" }}>{title}</Typography>}>
      <MUIIconButton
        aria-label={title}
        className={classNames(classes.button, type, className)}
        onClick={onClick}
      >
        {children}
      </MUIIconButton>
    </Tooltip>
  );
};

export default IconButton;

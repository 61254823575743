import Patient from "../../Patient";
import Measure from "../Measure";
import { MeasurementType } from "../../../../measurement/domain/MeasurementType";
import NotesReport from "report/domain/note/NotesReport";

export default class MeasurementResults {
  public patient: Patient;
  public results: Measure[];
  public name: string;
  public type: MeasurementType;
  public notes: NotesReport;

  constructor(
    patient: Patient,
    results: Measure[],
    name: string,
    type: MeasurementType,
    notes: NotesReport
  ) {
    this.patient = patient;
    this.results = results;
    this.name = name;
    this.type = type;
    this.notes = notes;
  }
}

import useLanguageSelection from "main/hooks/useLanguageSelection";
import { getPatientProfileKey, isClientConnectContext } from "main/selectors/AppContextSelector";
import PrescribeFormDataAssembler from "prescribe/api/assemblers/PrescribeFormDataAssembler";
import TemporaryPatientApi from "prescribe/api/TemporaryPatientApi";
import PrescribeFormData from "prescribe/domain/PrescribeFormData";
import { getPrescribeFormData } from "prescribe/store/selectors/PrescribeFormSelector";
import { useEffect, useRef } from "react";
import useGlobalState from "store/hooks/useGlobalState";

interface Props {
  setPrescribeFormData: (prescribeForm: PrescribeFormData) => void;
}

const usePatientProfile = ({ setPrescribeFormData }: Props): void => {
  const { state } = useGlobalState();
  const { currentLanguage } = useLanguageSelection();
  const prescribeFormData = useRef(PrescribeFormData.empty().withLanguage(currentLanguage));

  useEffect(() => {
    const fetchPatientProfile = async (): Promise<void> => {
      const patient = await TemporaryPatientApi.get(getPatientProfileKey());
      const formData = PrescribeFormDataAssembler.fromResponse(patient, prescribeFormData.current);

      setPrescribeFormData(formData);
    };

    if (isClientConnectContext() && !getPrescribeFormData(state)) {
      fetchPatientProfile();
    }
  }, [prescribeFormData, setPrescribeFormData, state]);
};

export default usePatientProfile;

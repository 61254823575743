import ReactGA4 from "react-ga4";

import AnalyticsClient from "./AnalyticsClient";

const SITE_SPEED_SAMPLE_RATE = 100; // all users will be tracked for performance

export default class GoogleAnalyticsClient implements AnalyticsClient {

  public initialize(): void {
    ReactGA4.initialize("G-Q32PS3DVYS", {
      gaOptions: {
        siteSpeedSampleRate: SITE_SPEED_SAMPLE_RATE,
      },
    });

    ReactGA4.set({ page: window.location.pathname + window.location.search });
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }

  public logEvent(category: string, action: string, params: Record<string, unknown> = {}): void {
    ReactGA4.event(
      action,
      { category, ...params } );
  }

  public setUserProperty(property: string, value: string): void {

    ReactGA4.gtag("set", "user_properties", {
      [property]: value,
    });
  }
}

import HealthProfession from "../domain/HealthProfession";
import PartnerCompany from "../domain/PartnerCompany";
import PhoneNumber from "../../../main/domain/PhoneNumber";
import Profile from "../domain/Profile";
import ProfileResponse from "./responses/ProfileResponse";

class ProfileAssembler {
  public static fromResponse(response: ProfileResponse): Profile {
    return new Profile(
      response.identifier,
      response.firstName,
      response.lastName,
      response.officeAddress,
      PhoneNumber.from(response.officeNumber.value, response.officeNumber.extension),
      HealthProfession[response.profession as keyof typeof HealthProfession],
      response.partnerCompany ?
        PartnerCompany[response.partnerCompany as keyof typeof PartnerCompany] :
        undefined
    );
  }
}

export default ProfileAssembler;

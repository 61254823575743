import { useCallback } from "react";
import useGlobalState from "../../../store/hooks/useGlobalState";
import * as ProfileActions from "../ProfileActions";
import Profile from "../../profile/domain/Profile";
import { getProfile } from "../selectors/ProfileSelectors";

export interface UseProfileRepositoryResult {
  setProfile: (profile: Profile | undefined) => void;
  clearProfileStore: () => void;
  profile: Profile | undefined;
}

const useProfileRepository = (): UseProfileRepositoryResult => {
  const { dispatch, state } = useGlobalState();
  const profile = getProfile(state);

  const setProfile = useCallback((newProfile: Profile | undefined): void =>
    ProfileActions.setProfile(dispatch, newProfile), [dispatch]);

  const clearProfileStore = useCallback((): void =>
    ProfileActions.clearProfileStore(dispatch), [dispatch]);

  return {
    setProfile,
    clearProfileStore,
    profile,
  };
};

export default useProfileRepository;

import React from "react";
import {
  makeStyles, TextField, Typography,
} from "@material-ui/core";
import theme from "../theme/theme";

const useStyles = makeStyles(() => ({
  textFieldTitle: {
    marginBottom: theme.metrics.margin.small,
  },
}));

interface Props {
  minValue: number;
  maxValue: number;
  title: string;
  value: number;
  placeholder?: string;
  onValueChange: (value: number | null) => void;
  className?: string;
  id: string;
}

const NumericInput: React.FC<Props> = ({
  minValue,
  maxValue,
  title,
  placeholder,
  value,
  onValueChange,
  className,
  id,
}) => {
  const classes = useStyles();

  const onBlur = (): void => {
    if (value && value > maxValue) {
      onValueChange(maxValue);
    }
    if (value && value < minValue) {
      onValueChange(minValue);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const updatedDisplayName = event.target.value;
    const numberValue = parseInt(updatedDisplayName.replace(/.,/, ""), 10);
    if (Number.isNaN(numberValue)) {
      onValueChange(null);
    } else {
      onValueChange(numberValue);
    }
  };
  return (
    <div className={className}>
      <Typography id={id} className={classes.textFieldTitle} variant="h4">
        {title}
      </Typography>

      <TextField
        onChange={(event): void => {
          onBlur();
          onChange(event);
        }}
        fullWidth
        type="number"
        inputProps={{
          "min": minValue,
          "max": maxValue,
          "aria-labelledby": id,
        }}
        variant="outlined"
        placeholder={placeholder}
        onBlur={onBlur}
        value={value}
      />
    </div>
  );
};

export default NumericInput;

const customActivity = {
  en: {
    customActivity: {
      save: "Save",
      add: "Add",
      addCustomActivity: "Add an activity",
      editCustomActivity: "Edit custom activity",
      customActivityName: "Custom activity name",
      deleteCustomActivity: "Delete custom activity",
      name: "Name",
      addReminder: "Add a reminder",
      reminder: "Reminder",
    },
  },
  fr: {
    customActivity: {
      save: "Sauvegarder",
      add: "Ajouter",
      addCustomActivity: "Ajouter une activité",
      editCustomActivity: "Modifier une activité",
      customActivityName: "Nom de l'activité",
      deleteCustomActivity: "Supprimer l'activité",
      name: "Nom",
      addReminder: "Ajouter un rappel",
      reminder: "Rappel",
    },
  },
};

export default customActivity;

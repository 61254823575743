import CustomProgramFormData from "prescribe/domain/CustomProgramFormData";
import CustomProgramSummary from "./CustomProgramSummary";
import React from "react";
import { Divider, makeStyles } from "@material-ui/core";
import theme from "main/theme/theme";

interface Props {
  customPrograms: CustomProgramFormData[];
  onCustomProgramChange?: (customProgram: CustomProgramFormData) => void;
  onCustomProgramDelete?: (customProgram: CustomProgramFormData) => void;
}

export const CustomProgramSummaries = ({
  customPrograms,
  onCustomProgramChange,
  onCustomProgramDelete,
}: Props): JSX.Element => {
  const classes = useStyles();
  const isReadOnly = !Boolean(onCustomProgramChange);

  return (
    <>
      {
        customPrograms.map((customProgram: CustomProgramFormData, index: number) => (
          <div key={customProgram.id} className={classes.customProgram} data-testid={`customProgram${index + 1}`}>
            <Divider light />
            <CustomProgramSummary
              isReadOnly={isReadOnly}
              customProgram={customProgram}
              onEditClick={(): void => onCustomProgramChange?.(customProgram)}
              onDeleteClick={(): void => onCustomProgramDelete?.(customProgram)}
            />

          </div>
        ))
      }
    </>
  );
};

const useStyles = makeStyles(() => ({
  customProgram: {
    margin: `${theme.metrics.margin.medium} 0`,
  },
}));

import { createTheme } from "@material-ui/core/styles";
import theme from "./theme";

export default createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        "backgroundImage": theme.colors.button.primary.background,
        "borderRadius": theme.colors.button.primary.borderRadius,
        "color": theme.colors.button.primary.color,
        "padding": theme.colors.button.primary.padding,
        "boxShadow": theme.colors.button.primary.boxShadow,
        "&$disabled": {
          background: theme.colors.button.primary.disabled.background,
          color: theme.colors.button.primary.color,
        },
      },
      containedSecondary: {
        "backgroundColor": theme.colors.button.secondary.background,
        "boxShadow": theme.colors.button.secondary.boxShadow,
        "borderRadius": theme.colors.button.secondary.borderRadius,
        "color": theme.colors.button.secondary.color,
        "border": theme.colors.button.secondary.border,
        "padding": theme.colors.button.secondary.padding,
        "&:hover": {
          backgroundColor: theme.colors.button.secondary.background,
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderWidth: 2,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: theme.fonts.h6.size,
      },
    },
    MuiDivider: {
      root: {
        height: 1.5,
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "initial",
        },
      },
    },
  },
  palette: {
    primary: {
      main: theme.colors.primary.normal,
      contrastText: theme.colors.text.contrast,
    },
    secondary: {
      main: theme.colors.secondary,
      contrastText: theme.colors.text.secondary,
      dark: theme.colors.text.emphasis,
    },
    error: {
      main: theme.colors.error,
    },
    text: {
      primary: theme.colors.text.primary,
      secondary: theme.colors.text.secondary,
      disabled: theme.colors.text.disabled,
    },
    divider: theme.colors.divider,
  },
  typography: {
    fontFamily: theme.fonts.family,
    button: {
      fontSize: theme.fonts.button.size,
      fontWeight: theme.fonts.button.weight,
      letterSpacing: theme.fonts.button.letterSpacing,
    },

    h1: {
      fontSize: theme.fonts.h1.size,
      fontWeight: theme.fonts.h1.weight,
      letterSpacing: theme.fonts.h1.letterSpacing,
    },

    h2: {
      fontSize: theme.fonts.h2.size,
      fontWeight: theme.fonts.h2.weight,
      letterSpacing: theme.fonts.h2.letterSpacing,
    },

    h3: {
      fontSize: theme.fonts.h3.size,
      fontWeight: theme.fonts.h3.weight,
      letterSpacing: theme.fonts.h3.letterSpacing,
    },

    h4: {
      fontSize: theme.fonts.h4.size,
      fontWeight: theme.fonts.h4.weight,
      letterSpacing: theme.fonts.h4.letterSpacing,
    },

    h5: {
      fontSize: theme.fonts.h5.size,
      fontWeight: theme.fonts.h5.weight,
      letterSpacing: theme.fonts.h5.letterSpacing,
    },

    h6: {
      fontSize: theme.fonts.h6.size,
      fontWeight: theme.fonts.h6.weight,
      letterSpacing: theme.fonts.h6.letterSpacing,
    },
  },
});

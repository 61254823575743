import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import theme from "../theme/theme";

const useStyles = makeStyles(() => ({
  closeButton: {
    "height": "fit-content",
    "width": "fit-content",
    "border": "none",
    "backgroundColor": "transparent",
    "borderRadius": "50%",
    "padding": "0.25rem",
    "lineHeight": "0.5rem",
    "textAlign": "center",
    "&:hover": {
      backgroundColor: theme.colors.background,
      cursor: "pointer",
    },
  },
  closeIcon: {
    marginLeft: "-1px",
  },
}));

interface Props {
  className?: string;
  onClose: () => void;
}

const CloseModalButton: React.FunctionComponent<Props> = ({ className, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <button type="button" className={[classes.closeButton, className].join(" ")} onClick={onClose}>
      <CloseIcon className={classes.closeIcon} titleAccess={t("close")} />
    </button>
  );
};

export default CloseModalButton;

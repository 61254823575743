import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import theme from "../../main/theme/theme";
import AuthenticationIcon from "../components/AuthenticationIcon";
import AuthenticationForm from "../components/AuthenticationForm";
import PasswordField from "../components/PasswordField";
import AuthenticationTextField from "../components/AuthenticationTextField";
import AuthenticationPageWrapper from "../components/AuthenticationPageWrapper";
import LogInFormData, { LogInFormDataField } from "./domain/LogInFormData";
import LogInFormErrors from "./domain/LogInFormErrors";
import { AuthenticationFormErrorsWithLink } from "../components/AuthenticationFormErrorsWithLink";

const useStyles = makeStyles(() => ({
  forgotPassword: {
    textAlign: "right",
    width: "100%",
    marginBottom: theme.metrics.margin.medium,
  },
  signUpMessage: {
    marginTop: theme.metrics.margin.large,
  },
  link: {
    textDecoration: "underline",
    marginLeft: theme.metrics.margin.tiny,
  },
  textField: {
    margin: `${theme.metrics.margin.normal} 0`,
  },
  loginText: {
    marginBottom: theme.metrics.margin.large,
  },
}));

export interface Props {
  goToForgotPassword: () => any;
  goToSignUp: () => any;
  onSubmit: () => void;
  onChange: (formData: LogInFormData) => void;
  logInFormData: LogInFormData;
  errors: LogInFormErrors;
  authenticating: boolean;
}

const LogInForm: React.FunctionComponent<Props> = ({
  goToForgotPassword,
  goToSignUp,
  onSubmit,
  onChange,
  logInFormData,
  errors,
  authenticating,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation("authentication");

  const submitForm = (event: React.ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit();
  };

  const changeForm = (field: keyof LogInFormDataField) => (value: string): void => {
    if (field === "email") {
      onChange(logInFormData.set(field, value.toLowerCase()));
    } else {
      onChange(logInFormData.set(field, value));
    }
  };

  return (
    <AuthenticationPageWrapper>
      <Box marginBottom={theme.metrics.margin.small}>
        <AuthenticationIcon name="lock" />
      </Box>

      <Typography component="h2" variant="h4" className={classes.loginText}>
        {t("login")}
      </Typography>

      <AuthenticationForm onSubmit={submitForm}>
        <AuthenticationTextField
          id="email"
          label={t("email")}
          name="email"
          autoComplete="email"
          autoFocus
          value={logInFormData.email.toLowerCase()}
          onChange={changeForm("email")}
          error={errors.email.length > 0 ? t(`authenticationErrors:${errors.email[0]}`) : ""}
        />

        <PasswordField
          className={classes.textField}
          name="password"
          label={t("password")}
          password={logInFormData.password}
          onChange={changeForm("password")}
          error={errors.password.length > 0 ? t(`authenticationErrors:${errors.password[0]}`) : ""}
        />

        <AuthenticationFormErrorsWithLink errors={errors.body} />

        <div className={classes.forgotPassword}>
          <Link onClick={goToForgotPassword} href="#">
            <Typography className={classes.link} component="span" variant="h5">
              {t("forgotPassword")}
            </Typography>
          </Link>
        </div>

        <Button
          role="button"
          type="submit"
          variant="contained"
          color="primary"
          disabled={authenticating}
        >
          {t("loginAction")}
        </Button>
      </AuthenticationForm>

      <div className={classes.signUpMessage}>
        <Typography component="span" variant="h4" color="textSecondary">
          {t("signUpMessage")}
        </Typography>

        <Link onClick={goToSignUp} href="#" variant="h4" className={classes.link}>
          {t("signUpAction")}
        </Link>
      </div>
    </AuthenticationPageWrapper>
  );
};

export default LogInForm;

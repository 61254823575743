import DateTime from "../../main/dateTime/DateTime";
import Gender from "./Gender";

export interface PatientAttributes {
  firstName: string | null;
  lastName: string | null;
  birthdate: DateTime | null;
  gender: Gender | null;
  picture: string | null;
  allergies: string | null;
  email: string | null;
}

class Patient {
  public firstName: string | null;
  public lastName: string | null;
  public birthdate: DateTime | null;
  public gender: Gender | null;
  public picture: string | null;
  public allergies: string | null;
  public email: string | null;

  constructor({
    firstName,
    lastName,
    birthdate,
    gender,
    picture,
    allergies,
    email,
  }: PatientAttributes) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.birthdate = birthdate;
    this.gender = gender;
    this.picture = picture;
    this.allergies = allergies;
    this.email = email;
  }

  public calculateAge(): string {
    if (this.birthdate) {
      const now = DateTime.now();

      return Math.floor(now.diff(this.birthdate, "year")).toString();
    }

    return "";
  }
}

export default Patient;

import { DispatchAction } from "../../store/GlobalProvider";
import Profile from "../profile/domain/Profile";
import { AppState } from "../../store/GlobalStore";
import { profileInitialState } from "./ProfileStore";

export const setProfile = (dispatch: DispatchAction, profile: Profile | undefined): void => {
  dispatch({
    reducer: (state: AppState): AppState =>
      ({ ...state, profile: { ...state.profile, profile } }),
  });
};

export const clearProfileStore = (dispatch: DispatchAction): void => {
  dispatch({
    reducer: (state: AppState): AppState =>
      ({ ...state, profile: { ...profileInitialState } }),
  });
};

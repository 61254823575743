import "chartjs-adapter-luxon";
import React from "react";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import theme from "main/theme/theme";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import DateTime from "main/dateTime/DateTime";
import BloodPressureMeasure from "report/domain/measurements/bloodPressureMeasurement/BloodPressureMeasure";
import { getDatasetStyle, getGuidelineStyle } from "./measureBaseChartOptions";
import ChartDateRange from "../../domain/ChartDateRange";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  Legend,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

interface Props {
  dateRange: ChartDateRange;
  measures: BloodPressureMeasure[];
}

const MAX_SYSTOLIC = 140;
const MAX_DIASTOLIC = 90;

export const BloodPressureChart = ({ dateRange, measures }: Props): JSX.Element => {
  const { t } = useTranslation("report");

  const minDate = DateTime.now().addDays(-dateRange.valueOf()).getTimestamp();
  const maxDate = DateTime.now().getTimestamp();

  const data: ChartData<"line"> = {
    datasets: [
      {
        label: t("bloodPressure.maxSystolic"),
        ...getGuidelineStyle(),
        data: [
          { x: minDate, y: MAX_SYSTOLIC },
          { x: maxDate, y: MAX_SYSTOLIC },
        ],
      },
      {
        label: t("bloodPressure.maxDiastolic"),
        ...getGuidelineStyle(theme.colors.charts.second),
        data: [
          { x: minDate, y: MAX_DIASTOLIC },
          { x: maxDate, y: MAX_DIASTOLIC },
        ],
      },
      {
        label: t("bloodPressure.systolic"),
        ...getDatasetStyle(),
        data: measures.map((bloodPressure) => ({
          x: bloodPressure.actualTimestamp.getTimestamp(),
          y: bloodPressure.systolic,
        })),
      },
      {
        label: t("bloodPressure.diastolic"),
        ...getDatasetStyle(theme.colors.charts.second),
        data: measures.map((bloodPressure) => ({
          x: bloodPressure.actualTimestamp.getTimestamp(),
          y: bloodPressure.diastolic,
        })),
      },
      {
        label: t("bloodPressure.pulse"),
        ...getDatasetStyle(theme.colors.charts.third),
        data: measures.map((bloodPressure) => ({
          x: bloodPressure.actualTimestamp.getTimestamp(),
          y: bloodPressure.pulse,
        })),
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    font: {
      family: theme.fonts.family,
      size: 14,
    },

    layout: {
      padding: { bottom: 20, top: 20 },
    },

    plugins: {
      legend: {
        position: "right",
        align: "start",
        labels: {
          font: {
            family: theme.fonts.family,
            size: 14,
          },
        },
      },
    },

    showLine: false,
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        adapters: {
          date: {
            locale: i18next.language,
          },
        },
        min: minDate,
        max: maxDate,
        title: {
          display: true,
          text: t("dateOnly"),
          font: {
            size: 16,
            weight: "bold",
          },
          padding: { top: 20 },
        },
        offset: true,
      },
      y: {
        max: 200,
        min: 40,
        ticks: {
          padding: 20,
          stepSize: 10,
          font: {
            size: 14,
            family: theme.fonts.family,
          },
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

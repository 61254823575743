import React, { useMemo } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import theme from "../../../main/theme/theme";
import AdherenceStatus from "../../domain/AdherenceStatus";
import Adherences from "../../domain/Adherences";
import CustomProgramDuration from "../../domain/measurements/CustomProgramDuration";
import AdherenceWheel from "./components/AdherenceWheel";
import AdherenceCalendar from "./components/AdherenceCalendar";
import AdherenceCalendarLegend from "./components/AdherenceCalendarLegend";

const useStyles = makeStyles(() => ({
  wrapper: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    borderLeft: `${theme.metrics.border.tiny} ${theme.colors.divider}`,
  },
  adherenceWheelContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.background,
    justifyContent: "space-around",
    padding: theme.metrics.margin.normal,
    paddingBottom: theme.metrics.margin.large,
  },
  adherenceCalendarContainer: {
    marginTop: theme.metrics.margin.normal,
    display: "flex",
    flexDirection: "row",
    padding: `${theme.metrics.margin.medium} ${theme.metrics.margin.normal}`,
    justifyContent: "space-around",
  },
  legendsRow: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.metrics.margin.small,
  },
}));

interface Props {
  adherences: Adherences;
  duration: CustomProgramDuration;
}

const WHEEL_SIZE = 80;

const NUMBER_OF_DAYS = 8;

const ADHERENCE_TYPE = "measurement";

export const CustomProgramPastAdherence: React.FunctionComponent<Props> = ({ adherences, duration }) => {
  const classes = useStyles();
  const groups = useMemo(() => adherences.calendar.makeGroupsOf(NUMBER_OF_DAYS), [adherences.calendar]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.adherenceWheelContainer}>
        {adherences.adherences.map((adherence) => (
          <>
            <AdherenceWheel
              key={adherence.type}
              progress={adherence.value}
              size={WHEEL_SIZE}
              numberOfDays={NUMBER_OF_DAYS}
            />

            <Typography variant="h4">
              {duration.startDate.format("yyyy/MM/dd")} - {duration.endDate.format("yyyy/MM/dd")}
            </Typography>
          </>
        )
        )}
      </div>

      <div className={classes.adherenceCalendarContainer}>
        <AdherenceCalendar rowSize={NUMBER_OF_DAYS} columnSize={1} items={groups[0].reverse()} />
      </div>

      <div className={classes.legendsRow}>
        <AdherenceCalendarLegend status={AdherenceStatus.COMPLETED} type={ADHERENCE_TYPE} />

        <AdherenceCalendarLegend status={AdherenceStatus.FAILED} type={ADHERENCE_TYPE} />

        <AdherenceCalendarLegend status={AdherenceStatus.ONGOING} type={ADHERENCE_TYPE} />
      </div>
    </div>
  );
};

export default CustomProgramPastAdherence;

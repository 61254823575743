import { Frequency as Freq } from "rrule";

enum Frequency {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  PUNCTUAL = "PUNCTUAL",
}

export const RRuleFreqToFrequency = (frequency?: Freq): Frequency => {
  switch (frequency) {
    case Freq.DAILY:
      return Frequency.DAILY;
    case Freq.WEEKLY:
      return Frequency.WEEKLY;
    case Freq.MONTHLY:
      return Frequency.MONTHLY;
    default:
      return Frequency.PUNCTUAL;
  }
};

export default Frequency;

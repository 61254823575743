import React from "react";
import { makeStyles } from "@material-ui/core";
import DrugLeaflet from "../../../domain/DrugLeaflet";
import DrugLabels from "../../../domain/DrugLabels";
import theme from "../../../../main/theme/theme";
import DrugSheetNotFound from "./DrugSheetNotFound";
import DrugSheetLabels from "./DrugSheetLabels";
import DrugSheetLeaflet from "./DrugSheetLeaflet";
import DrugSheetLoading from "./DrugSheetLoading";

const useStyles = makeStyles(() => ({
  drugSheetLabels: {
    marginBottom: theme.metrics.margin.small,
  },
  content: {
    maxHeight: "70vh",
    overflow: "auto",
    padding: `0 ${theme.metrics.margin.normal} `,
    ...theme.scrollbar,
  },
}));

interface Props {
  leafletLoading: boolean;
  labelsLoading: boolean;
  leaflet: DrugLeaflet | null;
  drugLabels: DrugLabels | null;
}

export const DrugSheetContent: React.FunctionComponent<Props> = ({
  leaflet,
  leafletLoading,
  labelsLoading,
  drugLabels,
}) => {
  const classes = useStyles();

  const contentIsLoading = labelsLoading || leafletLoading;

  return (
    <div>
      {contentIsLoading ?
        <DrugSheetLoading loading={contentIsLoading} />
        :
        <div className={classes.content}>
          <DrugSheetLabels
            className={classes.drugSheetLabels}
            drugLabels={drugLabels}
          />

          {!leaflet ?
            <DrugSheetNotFound />
            :
            <DrugSheetLeaflet leaflet={leaflet} />
          }
        </div>
      }
    </div>
  );
};

export default DrugSheetContent;

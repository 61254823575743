import {
  RRule, Frequency as Freq, WeekdayStr, Weekday as RRuleWeekDay,
} from "rrule";
import { GetText } from "rrule/dist/esm/nlp/totext";
import SupportedLanguages from "../../main/domain/SupportedLanguages";
import { dates as frenchDates, tokens as frenchTokens } from "./rrule/internationalization/french";
import { dates as englishDates, tokens as englishTokens } from "./rrule/internationalization/english";
import Frequency from "schedule/domain/Frequency";
import DateTime from "main/dateTime/DateTime";
import Weekday from "schedule/domain/Weekday";

const getFrenchText: GetText = (id) => frenchTokens[id as keyof typeof frenchTokens] ?? id as any;
const getEnglishText: GetText = (id) => englishTokens[id as keyof typeof englishTokens] ?? id as any;

export const recurrenceStringToText = (rule: string, language: SupportedLanguages): string =>
  recurrenceToText(RRule.fromString(rule), language);

export const recurrenceToText = (rule: RRule, language: SupportedLanguages): string => {
  if (language.includes("fr")) {
    return rule.toText(getFrenchText, frenchDates, (year: number, month: string, day: number) => `${day} ${month} ${year}`);
  }
  return rule.toText(getEnglishText, englishDates);
};

export const createRRule = (
  frequency: Frequency,
  interval: number,
  startDate: DateTime,
  endDate?: DateTime | null,
  daysOfWeek: Weekday[] = [],
  daysOfMonths: number[] = []
): RRule => new RRule({
  freq: getRRuleFrequency(frequency),
  interval,
  count: frequency === Frequency.PUNCTUAL ? 1 : undefined,
  byweekday: daysOfWeek.map((weekDay) => RRuleWeekDay.fromStr(getRRuleWeekDay(weekDay))),
  bymonthday: daysOfMonths,
  dtstart: startDate.getRRuleDatetime(),
  until: endDate?.getRRuleDatetime(),
});

const getRRuleFrequency = (frequency: Frequency): Freq => {
  switch (frequency) {
    case Frequency.DAILY: return Freq.DAILY;
    case Frequency.WEEKLY: return Freq.WEEKLY;
    case Frequency.MONTHLY: return Freq.MONTHLY;
    case Frequency.PUNCTUAL: return Freq.DAILY;
  }
};

const getRRuleWeekDay = (weekDay: Weekday): WeekdayStr => {
  switch (weekDay) {
    case Weekday.MONDAY: return "MO";
    case Weekday.TUESDAY: return "TU";
    case Weekday.WEDNESDAY: return "WE";
    case Weekday.THURSDAY: return "TH";
    case Weekday.FRIDAY: return "FR";
    case Weekday.SATURDAY: return "SA";
    case Weekday.SUNDAY: return "SU";
  }
};


export const ALARM_INTERVAL_MIN_VALUE = 5;
export const ALARM_INTERVAL_MAX_VALUE = 9999;

export default interface AlarmFormData {
  intervalInMinutes: number;
}

export const createEmptyAlarmFormData = (): AlarmFormData => ({
  intervalInMinutes: ALARM_INTERVAL_MIN_VALUE,
});

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import theme from "../../../../main/theme/theme";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
    marginBottom: theme.metrics.margin.tiny,
  },
}));

export const DrugSheetTitle: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.title} variant="h3" component="h4">
      {children}
    </Typography>
  );
};

export default DrugSheetTitle;

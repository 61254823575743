import { makeStyles, Typography } from "@material-ui/core";
import theme from "main/theme/theme";
import React from "react";
import { NotesHeaderProps } from "./NotesTable";

export const NotesTableHeader = ({ title }: NotesHeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Typography component="h2" variant="h3" color="textPrimary" className={classes.headerContainer}>
      {title}
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  headerContainer: {
    width: "100%",
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: theme.metrics.margin.small,
    marginBottom: theme.metrics.margin.small,
    borderBottom: `${theme.metrics.border.tiny} black`,
  },
}));

import { Typography, makeStyles } from "@material-ui/core";

import CustomActivityFormData from "../../customActivities/domain/CustomActivityFormData";
import React from "react";
import { TreatmentPlanRegimenSummary } from "./TreatmentPlanRegimenSummary";
import { carePlanTheme } from "./CarePlanSection";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";

interface Props {
  isReadOnly: boolean;
  customActivity: CustomActivityFormData;
  onDeleteClick: (customActivity: CustomActivityFormData) => void;
  onEditClick: (customActivity: CustomActivityFormData) => void;
}

const CustomActivitiesSummarySection = ({
  isReadOnly,
  customActivity,
  onDeleteClick,
  onEditClick,
}: Props): JSX.Element => {

  const classes = useStyles();
  const { t } = useTranslation("customActivity");

  return (
    <TreatmentPlanRegimenSummary
      isReadOnly={isReadOnly}
      instructions={customActivity.instructions}
      schedule={customActivity.schedule}
      onDeleteClick={(): void => onDeleteClick(customActivity)}
      deleteTitle={t("deleteCustomActivity")}
      editTitle={t("editCustomActivity")}
      onEditClick={(): void => onEditClick(customActivity)}
    >

      <div className={classes.spacer} />

      <div className={classes.detail}>
        <Typography
          component="h4"
          variant="h4"
          className={classes.name}
        >
          {customActivity.details.name}
        </Typography>
      </div>

    </TreatmentPlanRegimenSummary>
  );
};

const useStyles = makeStyles(() => ({
  detail: {
    display: "flex",
    alignItems: "center",
    flex: 3,
  },
  name: {
    flex: 2,
    fontWeight: "bold",
    color: theme.colors.text.primary,
    wordBreak: "break-word",
  },
  spacer: {
    width: carePlanTheme.spacerWidth + "px",
  },
}));

export default CustomActivitiesSummarySection;

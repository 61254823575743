import React from "react";
import {
  Button, makeStyles, Theme, Typography,
} from "@material-ui/core";
import useLanguageSelection from "../../hooks/useLanguageSelection";
import { pxToRem } from "../../theme/theme";
import SupportedLanguages from "../../domain/SupportedLanguages";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  separator: {
    border: `${pxToRem(1)} solid ${theme.palette.text.secondary}`,
    height: "1.4rem",
  },
  languageButton: {
    "padding": "0.5rem 1rem",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:hover .languageText": {
      color: `${theme.palette.secondary.dark}`,
    },
    "&.active .languageText": {
      "color": `${theme.palette.secondary.dark}`,
      "font-weight": "bold",
    },
    "&.active:hover .languageText": {
      color: `${theme.palette.text.secondary}`,
    },
  },
}));

const Separator: React.FunctionComponent<{ className: string }> = ({ className }) =>
  <div className={className} />;

const LanguageSelector: React.FunctionComponent = () => {
  const classes = useStyles();
  const { changeLanguage, currentLanguage, supportedLanguages } = useLanguageSelection();

  const languageButtonClick = (language: SupportedLanguages) => (): void => {
    changeLanguage(language);
  };

  return (
    <div className={classes.container}>
      {supportedLanguages.map((language, index) => (
        <React.Fragment key={language}>
          {index > 0 && <Separator className={classes.separator} />}
          <Button
            type="button"
            className={[classes.languageButton, currentLanguage === language && "active"].join(" ")}
            onClick={languageButtonClick(language)}
          >
            <Typography component="span" variant="h3" color="textSecondary" className="languageText">
              {language.toUpperCase()}
            </Typography>
          </Button>
        </React.Fragment>
      ))
      }
    </div>
  );
};
export default LanguageSelector;

import { useState } from "react";
import FailedToConfirmSignUpException from "../domain/exceptions/FailedToConfirmSignUpException";
import AuthenticationService from "../../services/authentication";
import { ConfirmSignUpError } from "../../domain/ConfirmSignUpError";
import CodeFormData from "../../../main/domain/CodeFormData";

interface UseConfirmSignUpProps {
  onSuccess: () => void;
  onCodeSentSuccess: () => void;
}

export interface UseConfirmSignUpFormResult {
  error: ConfirmSignUpError | undefined;
  onConfirmSignUp: (email: string, code: CodeFormData) => Promise<void>;
  onResendConfirmationCode: (email: string) => Promise<void>;
}

const useConfirmSignUp = ({ onSuccess, onCodeSentSuccess }: UseConfirmSignUpProps): UseConfirmSignUpFormResult => {
  const [error, setError] = useState<ConfirmSignUpError | undefined>();

  const onConfirmSignUp = async (email: string, code: CodeFormData): Promise<void> => {
    try {
      await AuthenticationService.confirmSignUp(email, code.describe());
      onSuccess();
      setError(undefined);
    } catch (exception) {
      const errorCode = (exception as FailedToConfirmSignUpException).errorCode;
      setError(errorCode || ConfirmSignUpError.DefaultError);
    }
  };

  const onResendConfirmationCode = async (email: string): Promise<void> => {
    try {
      await AuthenticationService.resendConfirmationCode(email);
      onCodeSentSuccess();
    } catch {
      setError(ConfirmSignUpError.DefaultError);
    }
  };

  return { error, onConfirmSignUp, onResendConfirmationCode };
};

export default useConfirmSignUp;

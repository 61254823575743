import { Auth } from "aws-amplify";
import Profile from "../../domain/Profile";
import FailedToFindProfileError from "../../domain/exceptions/FailedToFindProfileError";
import FailedToUpdateProfileError from "../../domain/exceptions/FailedToUpdateProfileError";
import ProfileApi from "../../api/ProfileApi";
import NewProfile from "../../domain/NewProfile";
import ProfileService from "./ProfileService";
import AmplifyProfileAssembler from "./AmplifyProfileAssembler";

class AmplifyHybridProfileService implements ProfileService {
  public async updateProfile(profile: NewProfile): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = AmplifyProfileAssembler.assembleToExternal(profile);
      await Auth.updateUserAttributes(user, attributes);
    } catch (error) {
      throw new FailedToUpdateProfileError();
    }
  }

  public async findProfile(): Promise<Profile | undefined> {
    try {
      return await ProfileApi.fetchProfile();
    } catch (error) {
      throw new FailedToFindProfileError();
    }
  }
}

export default new AmplifyHybridProfileService();

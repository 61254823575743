import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AdherenceStatus from "../../../domain/AdherenceStatus";
import theme, { pxToRem } from "../../../../main/theme/theme";
import getAdherenceStatusColor from "../../../helpers/getAdherenceStatusColor";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  circle: ({ status }: { status: AdherenceStatus }): any => ({
    display: "inline-block",
    width: pxToRem(15),
    height: pxToRem(15),
    borderRadius: "50%",
    backgroundColor: getAdherenceStatusColor(status),
  }),
  text: {
    marginLeft: theme.metrics.margin.small,
    fontWeight: "bold",
  },
}));

interface Props {
  status: AdherenceStatus;
  type: "activity" | "medication" | "measurement";
}

const AdherenceCalendarLegend: React.FunctionComponent<Props> = ({ status, type }) => {
  const { t } = useTranslation("report");
  const classes = useStyles({ status });
  const translationPrefix = type === "activity" ? "activityAdherenceStatus" : "medicationAdherenceStatus";

  return (
    <div className={classes.container}>
      <span className={classes.circle} />

      <Typography component="span" variant="h6" className={classes.text} color="textPrimary">
        {t(`${translationPrefix}.${status}`)}
      </Typography>
    </div>
  );
};

export default AdherenceCalendarLegend;

import AuthenticationError from "../../domain/AuthenticationError";
import { AuthenticationErrorWithLink } from "../../domain/AuthenticationErrorWithLink";

export class PasswordExpiredFormErrors {
  public readonly password: AuthenticationError[];
  public readonly body: AuthenticationErrorWithLink[];

  constructor({ password, body }: {
    password: AuthenticationError[];
    body: AuthenticationErrorWithLink[];
  }) {
    this.password = password;
    this.body = body;
  }

  public static empty(): PasswordExpiredFormErrors {
    return new PasswordExpiredFormErrors({ password: [], body: [] });
  }

  public isEmpty(): boolean {
    return this.password.length === 0 && this.body.length === 0;
  }
}

/* eslint-disable @typescript-eslint/camelcase */
import Gender from "../domain/Gender";
import Images from "../../main/assets/Images";
import AdherenceStatus from "../domain/AdherenceStatus";
import CustomProgramOwner from "../../prescribe/domain/CustomProgramOwner";
import TimeOfDay from "../domain/measurements/bloodGlucoseMeasurement/TimeOfDay";
import { MeasurementType } from "../../measurement/domain/MeasurementType";
import { Severity } from "../domain/measurements/migraineMeasurement/Severity";
import { MoodFeeling } from "../domain/measurements/moodMeasurement/MoodFeeling";
import ChartDateRange from "../domain/ChartDateRange";

const report = {
  en: {
    report: {
      notesError: "An error occurred while loading the notes. Please retry later or contact us if the problem persists.",
      medicationsError: "An error occurred while loading the medications. Please retry later or contact us if the problem persists.",
      activitiesError: "An error occurred while loading the activities. Please retry later or contact us if the problem persists.",
      measurementsError: "An error occurred while loading the measurements. Please retry later or contact us if the problem persists.",
      remindersError: "An error occurred while loading the reminders. Please retry later or contact us if the problem persists.",
      MedHelperLogo: Images.MedHelperLogoSvgEn,
      endSession: "END SESSION",
      previousSessions: "Previous Sessions",
      disclaimer: "Disclaimer: MedHelper Inc. does not guarantee the quality or accuracy of the information " +
        "contained in this report which is provided on a voluntary and best effort basis by the end user. " +
        "Accordingly, the information is shared without warranty or representation of any kind " +
        "(expressed, implied or statutory). " +
        "The responsibility for the interpretation and use of the information provided hereby lies with the reader. " +
        "In no event shall MedHelper Inc. be liable for damages arising from the use or misuse of such information.",
      firstName: "<0>First Name:</0> <1>{{firstName}}</1>",
      lastName: "<0>Last Name:</0> <1>{{lastName}}</1>",
      gender: "<0>Gender:</0> <1>{{gender}}</1>",
      age: "<0>Age:</0> <1>{{age}}</1>",
      allergies: "<0>Allergies:</0> <1>{{allergies}}</1>",
      noAllergiesReported: "None Reported",
      notReported: "Not Reported",
      notAvailable: "Not Available",
      [Gender.FEMALE]: "Female",
      [Gender.MALE]: "Male",
      [Gender.INTERSEX]: "Intersex",
      treatmentOverview: "Treatment Overview",
      reportedData: "Patient Reported Data",
      purpose: "Purpose",
      schedule: "Schedule",
      dose: "dose",
      dose_plural: "doses",
      daily: "daily",
      asNeeded: "As Needed",
      expandAll: "Expand All",
      collapseAll: "Collapse All",
      din: "DIN",
      prescribingDoctor: "Prescribing Doctor",
      medicationLeaflet: "Medication Leaflet",
      descriptionNotAvailableMessage: "The description for this medication is not available.",
      medicationDetailSubtitles: {
        description: "Description",
        instructions: "Instructions",
        sideEffects: "Side Effects",
        labels: "Labels",
        additionalInformation: "Additional Information",
        storageInformation: "Storage Information",
        generalInformation: "General Information",
      },
      activity: "Activity",
      reminder: "Reminder",
      officeNumber: "<0>Office:</0> <1>{{number}}</1>",
      consultationTitle: "{{date}} - {{professional}}: {{profession}}",
      phoneNumberWithExtension: "{{value}} ext. {{extension}}",
      previousConsultations: "Previous Consultation",
      previousConsultations_plural: "Previous Consultations",
      noPreviousConsultations: "No previous consultations found",
      previousDay: "Today",
      previousDay_plural: "Previous {{count}} Days",
      dailyAdherence: "<0>DAILY</0> <0>ADHERENCE</0>",
      events: "<0>EVENTS</0>",
      notes: "Patient Notes",
      less: "Less",
      more: "More",
      onlyMyConsultations: "Only Show My Consultations",
      thereWasAProblem: "There was a problem downloading the patient information, re-enter the code and try " +
        "connecting again. Please let us know if this issue persists.",
      medicationAdherenceStatus: {
        [AdherenceStatus.NOT_APPLICABLE]: "No Events",
        [AdherenceStatus.ONGOING]: "Future days",
        [AdherenceStatus.COMPLETED]: "Days Taken",
        [AdherenceStatus.FAILED]: "Days Missed",
      },
      activityAdherenceStatus: {
        [AdherenceStatus.NOT_APPLICABLE]: "No Events",
        [AdherenceStatus.ONGOING]: "Future days",
        [AdherenceStatus.COMPLETED]: "Days Completed",
        [AdherenceStatus.FAILED]: "Days Missed",
      },
      measurement: "Measurement",
      measurementType: {
        [MeasurementType.BLOOD_GLUCOSE]: "Blood Glucose",
        [MeasurementType.MIGRAINE]: "Migraine",
        [MeasurementType.MOOD]: "Mood",
        [MeasurementType.BLOOD_PRESSURE]: "Blood Pressure",
      },
      severity: {
        label: "Severity",
        [Severity.MILD.toString()]: "Mild",
        [Severity.MODERATE.toString()]: "Moderate",
        [Severity.SEVERE.toString()]: "Severe",
      },
      mood: {
        label: "Mood",
        [MoodFeeling.GREAT.toString()]: "Great",
        [MoodFeeling.GOOD.toString()]: "Good",
        [MoodFeeling.OK.toString()]: "Ok",
        [MoodFeeling.BAD.toString()]: "Bad",
        [MoodFeeling.TERRIBLE.toString()]: "Terrible",
      },
      bloodPressure: {
        label: "Blood Pressure",
        systolic: "Systolic",
        diastolic: "Diastolic",
        pulse: "Pulse",
        maxSystolic: "Max Systolic",
        maxDiastolic: "Max Diastolic",
      },
      scheduleMeasurementType: {
        [CustomProgramOwner.PROXIM]: "Proxim Blood Glucose Monitoring Program",
      },
      viewResults: "View results",
      dateAndTime: "Date and Time",
      dateOnly: "Date",
      timeOnly: "Time",
      scheduleTime: "Scheduled Time",
      completedTime: "Completed Time",
      takenTime: "Taken Time",
      status: "Status",
      taken: "Taken",
      completed: "Completed",
      value: "Value",
      targetRange: "Target range",
      doseColumn: "Dose",
      bloodGlucoseResults: "Blood Glucose Results",
      customMeasureResults: "Custom Measurement Results",
      measureTime: {
        [TimeOfDay.UPON_WAKING]: "Upon Waking",
        [TimeOfDay.BEFORE_MEAL]: "Before Meal",
        [TimeOfDay.AFTER_MEAL]: "After Meal",
        [TimeOfDay.AT_BEDTIME]: "At Bedtime",
      },
      noteMeasurement: {
        [MeasurementType.BLOOD_GLUCOSE]: "Blood Glucose",
        [MeasurementType.BLOOD_PRESSURE]: "Blood Pressure",
        [MeasurementType.MIGRAINE]: "Migraine",
        [MeasurementType.MOOD]: "Mood",
      },
      chartDateRange: {
        label: "Date Range :",
        [ChartDateRange.LAST30DAYS]: "Last 30 Days",
        [ChartDateRange.LAST60DAYS]: "Last 60 Days",
        [ChartDateRange.LAST90DAYS]: "Last 90 Days",
      },
      average: {
        systolic: "Average Systolic",
        diastolic: "Average Diastolic",
        pulse: "Average Pulse",
      },
    },
  },
  fr: {
    report: {
      notesError: "Il s'est produit une erreur lors du chargement des notes. Veuillez réessayer plus tard ou nous contacter si le problème persiste.",
      medicationsError: "Il s'est produit une erreur lors du chargement des médicaments. Veuillez réessayer plus tard ou nous contacter si le problème persiste.",
      activitiesError: "Il s'est produit une erreur lors du chargement des activités. Veuillez réessayer plus tard ou nous contacter si le problème persiste.",
      measurementsError: "Il s'est produit une erreur lors du chargement des mesurements. Veuillez réessayer plus tard ou nous contacter si le problème persiste.",
      remindersError: "Il s'est produit une erreur lors du chargement des rappels. Veuillez réessayer plus tard ou nous contacter si le problème persiste.",
      MedHelperLogo: Images.MedHelperLogoSvgFr,
      endSession: "FERMER LA SESSION",
      previousSessions: "Sessions Précédentes",
      disclaimer: "Avertissement: MedHelper Inc. ne garantit pas la qualité ou l'exactitude des informations " +
        "contenues dans ce rapport qui sont fournies sur une base volontaire et au meilleur de l'effort par " +
        "l'utilisateur. Par conséquent, les informations sont partagées sans garantie ni représentation " +
        "de quelque nature qu'elle soit (exprimée, implicite ou statutaire). " +
        "La responsabilité de l'interprétation et de l'utilisation des informations fournies " +
        "par les présentes incombe au lecteur. " +
        "En aucun cas, MedHelper Inc. ne sera tenu responsable des dommages résultant " +
        "de l'utilisation ou de l'abus de ces informations.",
      firstName: "<0>Prénom:</0> <1>{{firstName}}</1>",
      lastName: "<0>Nom:</0> <1>{{lastName}}</1>",
      gender: "<0>Genre:</0> <1>{{gender}}</1>",
      age: "<0>Âge:</0> <1>{{age}}</1>",
      allergies: "<0>Allergies:</0> <1>{{allergies}}</1>",
      noAllergiesReported: "Aucune déclarée",
      notReported_female: "Non déclarée",
      notReported_male: "Non déclaré",
      notAvailable: "Non disponible",
      [Gender.FEMALE]: "Femme",
      [Gender.MALE]: "Homme",
      [Gender.INTERSEX]: "Intersex",
      treatmentOverview: "Résumé du traitement",
      reportedData: "Données déclarées par le patient",
      purpose: "Raison",
      schedule: "Horaire",
      dose: "dose",
      dose_plural: "doses",
      daily: "par jour",
      asNeeded: "Au besoin",
      expandAll: "Agrandir tout",
      collapseAll: "Réduire tout",
      din: "DIN",
      prescribingDoctor: "Médecin traitant",
      medicationLeaflet: "Feuillet du médicament",
      descriptionNotAvailableMessage: "La description pour ce médicament n'est pas disponible.",
      medicationDetailSubtitles: {
        description: "Description",
        instructions: "Mode d'emploi",
        sideEffects: "Effets indésirables",
        labels: "Étiquettes",
        additionalInformation: "Information additionnelle",
        storageInformation: "Conservation",
        generalInformation: "Notes générales",
      },
      activity: "Activité",
      reminder: "Rappel",
      officeNumber: "<0>Bureau:</0> <1>{{number}}</1>",
      consultationTitle: "{{date}} - {{professional}}: {{profession}}",
      phoneNumberWithExtension: "{{value}} poste {{extension}}",
      previousConsultations: "Consultation précédente",
      previousConsultations_plural: "Consultations précédentes",
      noPreviousConsultations: "Aucune consultation précédente",
      previousDay: "Aujourd'hui",
      previousDay_plural: "{{count}} jours précédents",
      dailyAdherence: "<0>COMPLIANCE</0> <0>QUOTIDIENNE</0>",
      events: "<0>ÉVÉNEMENTS</0>",
      notes: "Notes de patient",
      less: "Voir moins",
      more: "Voir plus",
      onlyMyConsultations: "Afficher uniquement mes consultations",
      thereWasAProblem: "Un problème est survenu lors du téléchargement des informations du patient, entrez à " +
        "nouveau le code et réessayez de vous connecter. Veuillez nous informer si ce problème persiste.",
      medicationAdherenceStatus: {
        [AdherenceStatus.NOT_APPLICABLE]: "Jours libres",
        [AdherenceStatus.ONGOING]: "Prochains jours",
        [AdherenceStatus.COMPLETED]: "Jours pris",
        [AdherenceStatus.FAILED]: "Jours manqués",
      },
      activityAdherenceStatus: {
        [AdherenceStatus.NOT_APPLICABLE]: "Jours libres",
        [AdherenceStatus.ONGOING]: "Prochains jours",
        [AdherenceStatus.COMPLETED]: "Jours complétés",
        [AdherenceStatus.FAILED]: "Jours manqués",
      },
      measurement: "Mesure",
      measurementType: {
        [MeasurementType.BLOOD_GLUCOSE]: "Glycémie",
        [MeasurementType.MIGRAINE]: "Migraine",
        [MeasurementType.MOOD]: "Humeur",
        [MeasurementType.BLOOD_PRESSURE]: "Tension artérielle",
      },
      severity: {
        label: "Intensité",
        [Severity.MILD.toString()]: "Légère",
        [Severity.MODERATE.toString()]: "Modérée",
        [Severity.SEVERE.toString()]: "Sévère",
      },
      mood: {
        label: "Humeur",
        [MoodFeeling.GREAT.toString()]: "Très bien",
        [MoodFeeling.GOOD.toString()]: "Bien",
        [MoodFeeling.OK.toString()]: "Moyen",
        [MoodFeeling.BAD.toString()]: "Mal",
        [MoodFeeling.TERRIBLE.toString()]: "Très mal",
      },
      bloodPressure: {
        label: "Tension artérielle",
        systolic: "Systolique",
        diastolic: "Diastolique",
        pulse: "Pouls",
        maxSystolic: "Max Systolique",
        maxDiastolic: "Max Diastolique",
      },
      scheduleMeasurementType: {
        [CustomProgramOwner.PROXIM]: "Programme de suivi de glycémie de Proxim",
      },
      viewResults: "Voir les résultats",
      dateAndTime: "Temps et date",
      dateOnly: "Date",
      timeOnly: "Temps",
      scheduleTime: "Heure prévue",
      completedTime: "Heure complétée",
      takenTime: "Heure de prise",
      status: "Statut",
      taken: "Prise",
      completed: "Complétée",
      value: "Valeur",
      targetRange: "Plage cible",
      doseColumn: "Dose",
      bloodGlucoseResults: "Résultats de glycémie",
      customMeasureResults: "Résultats de mesure personnalisée",
      measureTime: {
        [TimeOfDay.UPON_WAKING]: "Au lever",
        [TimeOfDay.BEFORE_MEAL]: "Avant un repas",
        [TimeOfDay.AFTER_MEAL]: "Après un repas",
        [TimeOfDay.AT_BEDTIME]: "Au coucher",
      },
      noteMeasurement: {
        [MeasurementType.BLOOD_GLUCOSE]: "Suivi de glycémie",
        [MeasurementType.BLOOD_PRESSURE]: "Tension artérielle",
        [MeasurementType.MIGRAINE]: "Migraine",
        [MeasurementType.MOOD]: "Humeur",
      },
      chartDateRange: {
        label: "Intervalle :",
        [ChartDateRange.LAST30DAYS]: "Dernier 30 jours",
        [ChartDateRange.LAST60DAYS]: "Dernier 60 jours",
        [ChartDateRange.LAST90DAYS]: "Dernier 90 jours",
      },
      average: {
        systolic: "Systolique moyen",
        diastolic: "Diastolique moyen",
        pulse: "Pouls moyen",
      },
    },
  },
};

export default report;

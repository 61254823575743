import CustomProgramFormData from "../domain/CustomProgramFormData";
import { useState } from "react";
import MeasureFormData from "../domain/MeasureFormData";
import ScheduleFormData from "../../schedule/domain/ScheduleFormData";
import { createProximDefaultCustomProgram } from "../domain/ProximDefaultCustomProgram";
import DurationFormData from "../../schedule/domain/DurationFormData";

export interface UseCustomProgramFormDataResult {
  rescheduleWithSelectedDates: () => CustomProgramFormData;
  formData: CustomProgramFormData;
  updateSchedule: (updatedSchedule: ScheduleFormData<MeasureFormData>) => void;
  updateEvent: (eventToUpdate: MeasureFormData) => void;
  updateDuration: (newDuration: DurationFormData) => void;
  initializeForm: (customProgram?: CustomProgramFormData) => void;
}

const useCustomProgramFormData = (): UseCustomProgramFormDataResult => {
  const [formData, setFormData] = useState(createProximDefaultCustomProgram());

  const initializeForm = (customProgramToEdit?: CustomProgramFormData): void => {
    setFormData(customProgramToEdit || createProximDefaultCustomProgram());
  };

  const updateDuration = (newDuration: DurationFormData): void => {
    const updatedSchedule = {
      ...formData.schedulableRegimen.schedule,
      duration: newDuration,
    };

    updateSchedule(updatedSchedule);
  };

  const updateEvent = (eventToUpdate: MeasureFormData): void => {
    const schedule = formData.schedulableRegimen.schedule;
    const updatedEvents = schedule.events.map((event) => event.id === eventToUpdate.id ? eventToUpdate : event);
    const updatedSchedule = { ...schedule, events: updatedEvents };

    updateSchedule(updatedSchedule);
  };

  const updateSchedule = (updatedSchedule: ScheduleFormData<MeasureFormData>): CustomProgramFormData => {
    const updatedForm = {
      ...formData,
      schedulableRegimen: {
        ...formData.schedulableRegimen,
        schedule: updatedSchedule,
      },
    };

    setFormData(updatedForm);
    return updatedForm;
  };

  const scheduleEventsWithSelectedDates = (): MeasureFormData[] => {
    const inBetweenDates = formData.schedulableRegimen.schedule.duration.startDate.getInBetweenDates(
      formData.schedulableRegimen.schedule.duration.endDate
    );

    return inBetweenDates.reduce((measures: MeasureFormData[], date, firstEventIndex) => {
      const secondEventIndex = formData.schedulableRegimen.schedule.events.length - (firstEventIndex + 1);
      const firstEvent = formData.schedulableRegimen.schedule.events[firstEventIndex];
      const secondEvent = formData.schedulableRegimen.schedule.events[secondEventIndex];

      const scheduledFirstEvent = firstEvent.copyAtDate(date);
      const scheduledSecondEvent = secondEvent.copyAtDate(date);

      if (firstEventIndex < secondEventIndex) {
        return [...measures, scheduledFirstEvent, scheduledSecondEvent];
      } else {
        return [...measures, scheduledSecondEvent, scheduledFirstEvent];
      }
    }, []);
  };

  const rescheduleWithSelectedDates = (): CustomProgramFormData => {
    const measures = scheduleEventsWithSelectedDates();
    const updatedSchedule = { ...formData.schedulableRegimen.schedule, events: measures };
    return updateSchedule(updatedSchedule);
  };

  return {
    formData,
    updateSchedule,
    rescheduleWithSelectedDates,
    updateEvent,
    updateDuration,
    initializeForm,
  };
};

export default useCustomProgramFormData;


import React from "react";

interface Props {
  id: string;
  progress: number;
  size: number;
  strokeWidth: number;
  startColor: string;
  endColor: string;
  middleColor: string;
  emptyColor: string;
  withSnail?: boolean;
  classes?: {
    indicator: {
      container?: string;
    };
  };
}

const DIAMETER = 50;
const halfCircumference = ((Math.PI * 2) * (DIAMETER / 2) / 2);

const CircularProgress: React.FunctionComponent<Props> = ({
  id,
  size,
  progress,
  strokeWidth,
  emptyColor,
  startColor,
  endColor,
  middleColor,
  withSnail,
  classes,
  children,
}) => {

  const width = DIAMETER + strokeWidth;
  const firstHalfProgress = progress > DIAMETER ? 1 : progress / DIAMETER;
  const secondHalfProgress = progress <= DIAMETER ? 0 : (progress - DIAMETER) / DIAMETER;

  return (
    <div
      className={["CircularProgress", classes?.indicator.container].join(" ")}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <svg viewBox={`0 0 ${width} ${width}`}>
        <defs>
          <linearGradient id={`firstHalfGradient${id}`} x1="50%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={startColor} />
            <stop offset="90%" stopColor={middleColor} />
          </linearGradient>

          <linearGradient id={`secondHalfGradient${id}`} x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="0%" stopColor={endColor} />
            <stop offset="90%" stopColor={middleColor} />
          </linearGradient>
        </defs>

        <path
          fill="none"
          stroke={emptyColor}
          d={`
              M ${strokeWidth / 2} ${width / 2}
              a ${DIAMETER / 2} ${DIAMETER / 2} 0 1 1 ${DIAMETER} 0
              a ${DIAMETER / 2} ${DIAMETER / 2} 0 1 1 -${DIAMETER} 0
            `}
          strokeWidth={strokeWidth}
        />

        {progress > 0 &&
          <path
            fill="none"
            stroke={`url(#firstHalfGradient${id})`}
            strokeDasharray={`${firstHalfProgress * halfCircumference},${halfCircumference}`}
            strokeLinecap="round"
            d={`
              M ${width / 2} ${strokeWidth / 2}
              a ${DIAMETER / 2} ${DIAMETER / 2} 0 1 1 0 ${DIAMETER}
            `}
            strokeWidth={strokeWidth}
          />
        }

        {progress >= 50 &&
          <path
            fill="none"
            stroke={`url(#secondHalfGradient${id})`}
            strokeDasharray={`${secondHalfProgress * halfCircumference},${halfCircumference}`}
            strokeLinecap="round"
            d={`
              M ${width / 2} ${width - strokeWidth / 2}
              a ${DIAMETER / 2} ${DIAMETER / 2} 0 0 1 0 -${DIAMETER}
            `}
            strokeWidth={strokeWidth}
          />
        }

        {!!withSnail &&
          <circle
            cx={width / 2}
            cy={strokeWidth / 2}
            r={strokeWidth / 4}
            fill="white"
            transform={`rotate(${360 * (progress / 100)}, ${width / 2}, ${width / 2})`}
          />
        }
      </svg>

      {children}
    </div>
  );
};

export default CircularProgress;

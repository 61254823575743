import { useCallback } from "react";
import useUserRepository from "../../authentication/store/hooks/useUserRepository";
import useProfileRepository from "../../authentication/store/hooks/useProfileRepository";
import useAppLoadingRepository from "../../store/hooks/useAppLoadingRepository";
import AuthenticationService from "../../authentication/services/authentication";
import FailedToFindProfileError from "../../authentication/profile/domain/exceptions/FailedToFindProfileError";
import useAppExceptionRepository from "../../store/hooks/useAppExceptionRepository";
import CrashReportingService from "./CrashReportingService";
import { useUserProfile } from "authentication/hooks/useUserProfile";
import { useClientConnect } from "authentication/clientConnect/hooks/useClientConnect";
import FailedToConsumeToken from "authentication/clientConnect/domain/exceptions/FailedToConsumeToken";

export interface UseSessionServiceResult {
  restoreSession: () => Promise<void>;
  clearSession: () => Promise<void>;
}

const useSessionService = (): UseSessionServiceResult => {
  const { setUser, clearUserStore } = useUserRepository();
  const { setProfile, clearProfileStore } = useProfileRepository();
  const { startLoading, stopLoading } = useAppLoadingRepository();
  const { addException, clearExceptions } = useAppExceptionRepository();
  const { updateUserProfileStore } = useUserProfile();
  const { consumeToken, clearSessionToken } = useClientConnect();

  const restoreSession = useCallback(async (): Promise<void> => {
    startLoading();
    clearExceptions();

    try {
      await consumeToken();
      await updateUserProfileStore();
    } catch (e) {
      setProfile(undefined);
      setUser(undefined);

      if (
        e instanceof FailedToFindProfileError
        || e instanceof FailedToConsumeToken
      ) {
        addException(e.constructor.name);
        return;
      }
    } finally {
      stopLoading();
    }
  }, [
    startLoading,
    clearExceptions,
    consumeToken,
    updateUserProfileStore,
    setProfile,
    setUser,
    addException,
    stopLoading,
  ]);

  const clearSession = useCallback(async (): Promise<void> => {
    clearSessionToken();
    await AuthenticationService.logout();
    clearUserStore();
    clearProfileStore();
    CrashReportingService.clearUser();
  }, [clearProfileStore, clearSessionToken, clearUserStore]);

  return {
    restoreSession,
    clearSession,
  };
};

export default useSessionService;

import Connection from "../domain/Connection";
import PatientAssembler from "../../report/api/PatientAssembler";
import DateTime from "../../main/dateTime/DateTime";
import ConnectionResponse from "./ConnectionResponse";

export default class ConnectionAssembler {
  public static toInternal({ patient, date, identifier, type }: ConnectionResponse): Connection {
    const connectionPatient = PatientAssembler.fromResponse(patient);
    const connectionDate = DateTime.fromISO(date);

    return new Connection({
      patient: connectionPatient,
      date: connectionDate,
      identifier,
      type,
    });
  }
}

import { ConsultationDocument } from "./ConsultationDocument";
import { ConsultationDocumentDto } from "document/api/dto/ConsultationDocumentDto";
import { getPatientDocuments } from "main/selectors/AppContextSelector";
import ConsultationDocumentAssembler from "document/api/dto/ConsultationDocumentAssembler";

export interface ConsultationDocumentsFormData {
  valid: ConsultationDocument[];
  invalid: ConsultationDocument[];
  imported: ConsultationDocumentDto[];
}

export const createEmptyConsultationDocuments = (): ConsultationDocumentsFormData => ({
  valid: [],
  invalid: [],
  imported: ConsultationDocumentAssembler.fromTemporaryDocuments(getPatientDocuments()),
});

export const createConsultationDocumentsFrom = (
  files: File[]
): ConsultationDocumentsFormData => files.reduce<ConsultationDocumentsFormData>((prevConsultationDocuments, file) => {
  const consultationDocument = new ConsultationDocument(file);
  return consultationDocument.isValid()
    ? { ...prevConsultationDocuments, valid: [...prevConsultationDocuments.valid, consultationDocument] }
    : { ...prevConsultationDocuments, invalid: [...prevConsultationDocuments.invalid, consultationDocument] };
}, { valid: [], invalid: [], imported: [] });

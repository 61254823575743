import CustomProgramOwner from "prescribe/domain/CustomProgramOwner";

interface CustomProgramAttributes {
  id: string;
  owner: CustomProgramOwner;
}

export default class CustomProgram {
  public id: string;
  public owner: CustomProgramOwner;

  constructor({ id, owner }: CustomProgramAttributes) {
    this.id = id;
    this.owner = owner;
  }
}

export default class PhoneNumber {
  public readonly value: string;
  public readonly extension?: string;

  private constructor(value: string, extension?: string) {
    this.value = value;
    this.extension = extension;
  }

  public static from(rawNumber: string, extension?: string): PhoneNumber {
    return new PhoneNumber(rawNumber, extension);
  }
}

import React from "react";
import {
  MenuItem, Typography, makeStyles,
} from "@material-ui/core";
import Icon from "@material-ui/icons/ChevronRight";
import theme, { pxToRem } from "../../../../theme/theme";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: "1rem",
    marginLeft: theme.metrics.margin.small,
  },
  menuItem: {
    flex: 1,
    justifyContent: "flex-end",
    paddingRight: pxToRem(10),
  },
}));

interface Props {
  text: string;
  onClick?: () => void;
  className?: string;
}

export const ProfileMenuItem: React.FunctionComponent<Props> = React.forwardRef(({ text, onClick }, ref) => {
  const classes = useStyles();

  return (
    <MenuItem innerRef={ref} onClick={onClick} className={classes.menuItem}>
      <Typography color="textPrimary" component="span" variant="h4">
        {text}
      </Typography>
      <Icon className={classes.icon} />
    </MenuItem>
  );
});

export default ProfileMenuItem;

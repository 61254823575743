export interface PatientTreatmentMeasureResultResponse {
  id: string;
  "@type": PatientTreatmentMeasureType;
}

export enum PatientTreatmentMeasureType {
  MIGRAINE = "PatientTreatmentMigraineMeasureResultResponse",
  MOOD = "PatientTreatmentMoodMeasureResultResponse",
  BLOOD_PRESSURE = "PatientTreatmentBloodPressureMeasureResultResponse",
  BLOOD_GLUCOSE = "BloodGlucose",
  CUSTOM = "Custom"
}

export interface PatientTreatmentBloodGlucoseMeasureResultResponse extends PatientTreatmentMeasureResultResponse {
  id: string;
  "@type": PatientTreatmentMeasureType;
  actualTimestamp: string;
  value: number;
  targetRange: string;
  meal?: string;
  time: string;
}

export interface PatientTreatmentMigraineMeasureResultResponse extends PatientTreatmentMeasureResultResponse {
  id: string;
  "@type": PatientTreatmentMeasureType;
  actualTimestamp: string;
  value: string;
}
export interface PatientTreatmentMoodMeasureResultResponse extends PatientTreatmentMeasureResultResponse {
  id: string;
  "@type": PatientTreatmentMeasureType;
  actualTimestamp: string;
  value: string;
}

export interface PatientTreatmentBloodPressureMeasureResultResponse extends PatientTreatmentMeasureResultResponse {
  id: string;
  "@type": PatientTreatmentMeasureType;
  actualTimestamp: string;
  systolic: number;
  diastolic: number;
  pulse: number;
}

export interface PatientTreatmentCustomMeasureResultResponse extends PatientTreatmentMeasureResultResponse {
  id: string;
  "@type": PatientTreatmentMeasureType;
  actualTimestamp: string;
  value: string;
}

import SchedulableRegimenFormData from "../../domain/SchedulableRegimenFormData";
import UnableToAssemblePendingSchedulableRegimenException
  from "../../domain/exceptions/UnableToAssemblePendingSchedulableRegimenException";
import PendingSchedulableMeasurementFormData from "../../domain/PendingSchedulableMeasurementFormData";
import PendingSchedulableRegimenDto from "../dtos/PendingSchedulableRegimenDto";
import PendingMeasurementDto from "../dtos/PendingMeasurementDto";
import ProposedScheduleAssembler from "./ProposedScheduleAssembler";
import ProposedBloodGlucoseEventAssembler from "./events/ProposedBloodGlucoseEventAssembler";

class PendingSchedulableRegimenAssembler {
  public toDto(pendingSchedulableRegimen: SchedulableRegimenFormData): PendingSchedulableRegimenDto {
    const { regimen, schedule } = pendingSchedulableRegimen;
    if (pendingSchedulableRegimen.regimen instanceof PendingSchedulableMeasurementFormData) {
      return {
        regimen: new PendingMeasurementDto(regimen.name),
        schedule: ProposedScheduleAssembler.toDto(schedule, ProposedBloodGlucoseEventAssembler),
      };
    } else {
      throw new UnableToAssemblePendingSchedulableRegimenException();
    }
  }

}

export default new PendingSchedulableRegimenAssembler();

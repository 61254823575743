import AuthenticationError from "../../domain/AuthenticationError";
import LogInFormData from "./LogInFormData";
import LogInFormErrors from "./LogInFormErrors";

class LogInFormValidator {
  public static validate(logInFormData: LogInFormData): LogInFormErrors {
    let errors = LogInFormErrors.empty();

    if (!logInFormData.email) {
      errors = errors.addEmailError(AuthenticationError.EmptyEmail);
    }

    if (!logInFormData.password) {
      errors = errors.addPasswordError(AuthenticationError.EmptyPassword);
    }

    return errors;
  }
}

export default LogInFormValidator;

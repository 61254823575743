/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import envConfig from "../config/envConfig";

class CrashReportingService {
  public init(): void {
    if (envConfig.sentryDsn !== "") {
      Sentry.init({
        dsn: envConfig.sentryDsn,
        environment: envConfig.environmentName,
      });
    }
  }

  public setUser(accountId: string | undefined): void {
    Sentry.setUser({ id: `${accountId}` });
  }

  public clearUser(): void {
    Sentry.setUser(null);
  }

  public logError(error: object): void {
    console.log(error);
    Sentry.captureException(error);
  }

}

export default new CrashReportingService();

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import theme from "../../main/theme/theme";

interface Props {
  errors: string[];
}

const useStyles = makeStyles(() => ({
  errors: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.metrics.margin.normal,
  },
}));

export const AuthenticationFormErrors: React.FunctionComponent<Props> = ({ errors }) => {
  const classes = useStyles();

  return (
    <div className={classes.errors}>
      {errors.map((error) => (
        <Typography key={error} component="span" color="error">
          {error}
        </Typography>
      ))}
    </div>
  );
};

export default AuthenticationFormErrors;

import "chartjs-adapter-luxon";
import React from "react";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  LinearScale,
  LineElement,
  Plugin,
  PointElement,
  ScatterDataPoint,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import veryGood from "../../assets/mentalHealth/VeryGood.svg";
import good from "../../assets/mentalHealth/Good.svg";
import neutral from "../../assets/mentalHealth/Neutral.svg";
import bad from "../../assets/mentalHealth/Bad.svg";
import veryBad from "../../assets/mentalHealth/VeryBad.svg";
import theme from "main/theme/theme";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import MoodMeasure from "../../domain/measurements/moodMeasurement/MoodMeasure";
import { MoodFeeling, MoodValue } from "../../domain/measurements/moodMeasurement/MoodFeeling";
import DateTime from "main/dateTime/DateTime";
import { getDatasetStyle } from "./measureBaseChartOptions";
import ChartDateRange from "../../domain/ChartDateRange";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

interface Props {
  dateRange: ChartDateRange;
  measures: MoodMeasure[];
}

export const MoodChart = ({ dateRange, measures }: Props): JSX.Element => {
  const { t } = useTranslation("report");
  const data: ChartData<"line"> = {
    datasets: [
      {
        ...getDatasetStyle(),
        data: measures.map((mood) => ({
          x: mood.actualTimestamp.getTimestamp(),
          y: mood.feeling.value,
        })),
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    font: {
      family: theme.fonts.family,
      size: 14,
    },
    layout: {
      padding: { bottom: 20, top: 20 },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (item): string => getMoodFeelingStringFromValue((item.raw as ScatterDataPoint).y),
        },
      },
    },

    showLine: false,
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        adapters: {
          date: {
            locale: i18next.language,
          },
        },
        min: DateTime.now().addDays(-dateRange.valueOf()).getTimestamp(),
        max: DateTime.now().getTimestamp(),
        title: {
          display: true,
          text: t("dateOnly"),
          font: {
            size: 16,
            weight: "bold",
          },
          padding: { top: 20 },
        },
        offset: true,
      },
      y: {
        title: {
          display: true,
          text: t("mood.label"),
          font: {
            size: 16,
            weight: "bold",
          },
        },
        suggestedMax: 5,
        suggestedMin: 0,
        ticks: {
          padding: 40,
          stepSize: 1,
          font: {
            size: 14,
            family: theme.fonts.family,
          },
          callback: getMoodFeelingStringFromValue,
        },
      },
    },
  };

  return <Line data={data} options={options} plugins={plugins} />;
};

const getMoodFeelingStringFromValue = (value: number | string): string => i18next.t([
  "report:mood." + MoodFeeling.fromValue(value as MoodValue),
  "",
]);

const getImage = (imageSrc: string): HTMLImageElement => {
  const image = new Image();
  image.src = imageSrc;

  return image;
};

const feelingImages = {
  [MoodFeeling.GREAT.value]: getImage(veryGood),
  [MoodFeeling.GOOD.value]: getImage(good),
  [MoodFeeling.OK.value]: getImage(neutral),
  [MoodFeeling.BAD.value]: getImage(bad),
  [MoodFeeling.TERRIBLE.value]: getImage(veryBad),
};

const plugins: Plugin<"line">[] = [
  {
    id: "images",
    afterDraw: ({ ctx, scales }): void => {
      const xAxis = scales.x;
      const yAxis = scales.y;
      yAxis.ticks.forEach(({ value }, index) => {
        if (feelingImages[value]) {
          const y = yAxis.getPixelForTick(index);

          ctx.drawImage(feelingImages[value], xAxis.left - 40, y - 13, 25, 25);
        }
      });
    },
  },
];

import React from "react";
import {
  CircularProgress, makeStyles, Theme,
} from "@material-ui/core";
import theme from "main/theme/theme";
import { useParams } from "react-router";
import useConsultationSummary from "./hooks/useConsultationSummary";
import PatientInfo from "report/components/PatientInfo";
import { useTranslation } from "react-i18next";
import { CarePlanSummary } from "./components/CarePlanSummary";
import InstructionSummary from "./components/InstructionSummary";
import DocumentSummary from "./components/DocumentSummary";

const ConsultationSummary: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation("consultationSummary");
  const { consultationId } = useParams<{ consultationId: string }>();
  const {
    patient,
    treatmentPlan,
    instructions,
    documents,
  } = useConsultationSummary(consultationId);

  if (!patient) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress aria-describedby={t("loading")} />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.patientInfo}>
          <PatientInfo patient={patient} />
        </div>

        <CarePlanSummary treatmentPlan={treatmentPlan} titleClassname={classes.sectionTitle} />

        <InstructionSummary instructions={instructions} titleClassname={classes.sectionTitle} />

        <DocumentSummary documents={documents} titleClassname={classes.sectionTitle} />
      </div>
    </div>
  );
};

const CONTAINER_MAX_WIDTH = 960;
const useStyles = makeStyles((muiTheme: Theme) => ({
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: `${theme.metrics.margin.large} ${theme.metrics.margin.huge}`,
    [muiTheme.breakpoints.down("sm")]: {
      padding: theme.metrics.margin.medium,
    },
  },
  container: {
    flex: 1,
    maxWidth: CONTAINER_MAX_WIDTH,
  },
  loadingContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  patientInfo: {
    marginBottom: theme.metrics.margin.large,
  },
  sectionTitle: {
    display: "inline-block",
    fontWeight: "bold",
    margin: `${theme.metrics.margin.small} 0`,
  },
}));

export default ConsultationSummary;

import DrugLabel from "../domain/DrugLabel";
import DrugLabelResponse from "./responses/DrugLabelResponse";

class DrugLabelAssembler {
  public static formResponse(plain: DrugLabelResponse): DrugLabel {
    return new DrugLabel({
      priority: plain.priority,
      color: plain.color,
      text: plain.text,
    });
  };
}

export default DrugLabelAssembler;

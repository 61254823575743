import Measure, { MeasureAttributes } from "../Measure";

export interface BloodPressureMeasureAttributes extends MeasureAttributes {
  systolic: number;
  diastolic: number;
  pulse: number;
}

class BloodPressureMeasure extends Measure {

  public systolic: number;
  public diastolic: number;
  public pulse: number;

  constructor(plain: BloodPressureMeasureAttributes) {
    super({ id: plain.id, actualTimestamp: plain.actualTimestamp });
    this.systolic = plain.systolic;
    this.diastolic = plain.diastolic;
    this.pulse = plain.pulse;
  }

}

export default BloodPressureMeasure;

export default class UserSignUpFormData {

  public readonly email: string;
  public readonly password: string;

  constructor({ email, password }: { email: string; password: string }) {
    this.email = email;
    this.password = password;
  }

  public static empty(): UserSignUpFormData {
    return new UserSignUpFormData({ email: "", password: "" });
  }

  public withEmail(email: string): UserSignUpFormData {
    return new UserSignUpFormData(({ ...this, email }));
  }

  public withPassword(password: string): UserSignUpFormData {
    return new UserSignUpFormData(({ ...this, password }));
  }
}

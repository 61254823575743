import SearchDrugs from "../domain/SearchDrugs";
import SearchDrug from "../domain/SearchDrug";
import SearchDrugsResponse from "./SearchDrugsResponse";

class SearchDrugsAssembler {
  public toDomain(searchDrugsResponse: SearchDrugsResponse): SearchDrugs {
    const drugs = searchDrugsResponse.drugs.map((drug) =>
      new SearchDrug(drug.id, drug.name, drug.type, drug.dosage?.value)
    );
    return new SearchDrugs(drugs);
  }
}

export default new SearchDrugsAssembler();

import DailyAdherenceCalendar from "./DailyAdherenceCalendar";
import Adherence from "./Adherence";

interface AdherencesAttributes {
  adherences: Adherence[];
  calendar: DailyAdherenceCalendar;
}

export default class Adherences {
  public adherences: Adherence[];
  public calendar: DailyAdherenceCalendar;

  constructor({ adherences, calendar }: AdherencesAttributes) {
    this.adherences = adherences;
    this.calendar = calendar;
  }
}

import { MeasurementType } from "measurement/domain/MeasurementType";
import { MeasurementTypeCard } from "./MeasurementTypeCard";
import PartnerCompany from "authentication/profile/domain/PartnerCompany";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "main/theme/theme";
import useProfileRepository from "authentication/store/hooks/useProfileRepository";

interface Props {
  onSelect: (type: MeasurementType) => void;
}

export const MeasurementTypeSelector = ({ onSelect }: Props): JSX.Element => {
  const { profile } = useProfileRepository();
  const styles = useStyle();

  return (
    <div className={styles.list} data-testid="measureSelector">
      {
        profile?.partnerCompany !== PartnerCompany.DENTITEK
        && <>
          <MeasurementTypeCard onSelect={onSelect} measurementType={MeasurementType.MIGRAINE} />
          <MeasurementTypeCard onSelect={onSelect} measurementType={MeasurementType.MOOD} />
          <MeasurementTypeCard onSelect={onSelect} measurementType={MeasurementType.BLOOD_PRESSURE} />
        </>
      }
      <MeasurementTypeCard onSelect={onSelect} measurementType={MeasurementType.CUSTOM} />
    </div>
  );
};

const useStyle = makeStyles(() => ({
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.metrics.margin.normal,
    margin: `0 ${theme.metrics.margin.small}`,
  },
}));

import React from "react";
import styled from "styled-components";
import { pxToRem } from "../main/theme/theme";
import { FontIconName } from "../main/FontIcon";
import SideMenuItems from "./components/SideMenuItems";
import SideMenuItem from "./components/SideMenuItem";
import SideMenuHeader from "./components/SideMenuHeader";

interface Props {
  menuItems: MenuItem[];
}

const SideMenuContainer = styled.div`
  width: ${pxToRem(340)};
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

export interface MenuItem {
  to: string;
  text: string;
  iconName: FontIconName;
}

export const SideMenu: React.FunctionComponent<Props> = ({ menuItems }: Props) => (
  <SideMenuContainer>
    <SideMenuHeader />

    <SideMenuItems>
      {menuItems.map((item) => (
        <SideMenuItem
          key={item.to}
          {...item}
        />
      ))}
    </SideMenuItems>
  </SideMenuContainer>
);

export default SideMenu;

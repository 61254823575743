import Patient from "../../report/domain/Patient";
import DateTime from "../../main/dateTime/DateTime";
import ProfessionalConsultationType from "./ProfessionnalConsultationType";

export interface ConnectionAttributes {
  identifier: string;
  date: DateTime;
  patient: Patient;
  type: ProfessionalConsultationType;
}

export default class Connection {
  public id: string;
  public date: DateTime;
  private patient: Patient;
  public readonly type: ProfessionalConsultationType;

  constructor(plain: ConnectionAttributes) {
    this.date = plain.date;
    this.patient = plain.patient;
    this.id = plain.identifier;
    this.type = plain.type;
  }

  public getConsultedPatientName(): string {
    return [this.patient.lastName, this.patient.firstName].filter(Boolean).join(", ");
  }
  public getConsultedPatientAge(): string {
    return this.patient.calculateAge();
  }
  public getConsultedPatientGender(): string {
    return this.patient.gender || "";
  }

  public getConsultedPatientEmail(): string {
    return this.patient.email || "";
  }
}

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Treatment from "../domain/Treatment";
import theme from "../../main/theme/theme";
import TableBlankSlate from "../../main/components/TableBlankSlate";
import { useExpandableTreatment } from "../hooks/useExpandableTreatment";
import Medication from "../domain/medications/Medication";
import TreatmentMedication from "./TreatmentMedication";
import ToggleExpandAllButton from "./ToggleExpandAllButton";
import TreatmentActivity from "./TreatmentActivity";
import TreatmentMeasurement from "./TreatmentMeasurement";
import TreatmentRegimenStatus from "../domain/TreatmentRegimenStatus";
import TreatmentRegimenError from "./TreatmentRegimenError";
import TreatmentReminder from "./TreatmentReminder";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  treatment: {
    flex: 1,
  },
  medications: {
    borderTop: `${theme.metrics.border.tiny} black`,
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.metrics.margin.tiny,
  },
  subtitle: {
    fontWeight: "bold",
  },
  titleSection: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: theme.metrics.margin.tiny,
    justifyContent: "space-between",
  },
  expandAllButton: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  treatment: Treatment;
  openDrugSheet: (medication: Medication) => void;
}

export const TreatmentOverview: React.FunctionComponent<Props> = ({ treatment, openDrugSheet }) => {
  const classes = useStyles();
  const { t } = useTranslation("report");

  const expandableTreatmentHook = useExpandableTreatment({ treatment });
  const {
    toggleExpandAll,
    toggleExpandMedication,
    toggleExpandActivity,
    toggleExpandMeasurement,
    toggleExpandReminder,
    expandableTreatment,
    allExpanded,
  } = expandableTreatmentHook;

  const isRegimenSectionValid = (status: TreatmentRegimenStatus): boolean => status === TreatmentRegimenStatus.OK;

  return (
    <div className={classes.container}>
      <div className={classes.treatment}>
        <div className={classes.titleSection}>
          <div>
            <Typography component="h2" variant="h3" className={classes.title}>
              {t("treatmentOverview")}
            </Typography>

            <Typography component="h3" variant="h5" className={classes.subtitle}>{
              t("reportedData")}
            </Typography>
          </div>

          <div className={classes.expandAllButton}>
            <ToggleExpandAllButton onClick={toggleExpandAll} allExpanded={allExpanded} />
          </div>
        </div>

        <div className={classes.medications}>
          {treatment.isEmpty() &&
            <TableBlankSlate />
          }

          <div className={classes.table}>
            {isRegimenSectionValid(treatment.medications.status) ?
              expandableTreatment.medications.map(({ expanded, medication }) => (
                <TreatmentMedication
                  medication={medication}
                  key={medication.toString()}
                  expanded={expanded}
                  toggleExpanded={(): void => toggleExpandMedication(medication)}
                  openDrugSheet={openDrugSheet}
                />
              )) : <TreatmentRegimenError regimen={t("medicationsError")} />
            }
            {isRegimenSectionValid(treatment.activities.status) ?
              expandableTreatment.activities.map(({ expanded, activity }) => (
                <TreatmentActivity
                  activity={activity}
                  expanded={expanded}
                  toggleExpanded={(): void => toggleExpandActivity(activity)}
                  key={activity.toString()}
                />
              )) : <TreatmentRegimenError regimen={t("activitiesError")} />
            }
            {isRegimenSectionValid(treatment.measurements.status) ?
              expandableTreatment.measurements.map(({ expanded, measurement }) => (
                <TreatmentMeasurement
                  measurement={measurement}
                  expanded={expanded}
                  toggleExpanded={(): void => toggleExpandMeasurement(measurement)}
                  key={measurement.toString()}
                />
              )) : <TreatmentRegimenError regimen={t("measurementsError")} />
            }
            {isRegimenSectionValid(treatment.reminders.status) ?
              expandableTreatment.reminders.map(({ expanded, reminder }) => (
                <TreatmentReminder
                  reminder={reminder}
                  expanded={expanded}
                  toggleExpanded={(): void => toggleExpandReminder(reminder)}
                  key={reminder.toString()}
                />
              )) : <TreatmentRegimenError regimen={t("remindersError")} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentOverview;

import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import CircularProgress from "../../../../main/components/circularProgress/CircularProgress";
import theme, { pxToRem } from "../../../../main/theme/theme";
import DaysTooltip from "./DaysTooltip";

const useStyles = makeStyles(() => ({
  circularProgress: {
    position: "relative",
    display: "inline-block",
    backgroundColor: "white",
    borderRadius: "50%",
    padding: pxToRem(4.5),
    boxShadow: `0 0 ${pxToRem(10)} ${pxToRem(1)} rgba(0,0,0,0.3)`,
  },
  circularTextContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  days: {
    left: "50%",
    bottom: `-${pxToRem(18)}`,
    position: "absolute",
    transform: "translate(-50%,0)",
  },
}));

interface Props {
  id: string;
  progressValue: number;
  progressText: string;
  size: number;
  numberOfDays: number;
  startColor: string;
  middleColor: string;
  endColor: string;
}

export const Wheel: React.FunctionComponent<Props> = (props) => {
  const { id, progressValue, progressText, size, numberOfDays, startColor, middleColor, endColor, children } = props;

  const classes = useStyles();

  return (
    <CircularProgress
      id={id}
      progress={progressValue}
      size={size}
      emptyColor={theme.colors.background}
      startColor={startColor}
      middleColor={middleColor}
      endColor={endColor}
      strokeWidth={5}
      classes={{
        indicator: {
          container: classes.circularProgress,
        },
      }}
    >
      <div className={classes.circularTextContent}>
        <Typography component="span" variant="h1">
          {progressText}
        </Typography>
      </div>

      {children}

      <DaysTooltip numberOfDays={numberOfDays} className={classes.days} />
    </CircularProgress>
  );
};

export default Wheel;

import React from "react";
import {
  FormControl, InputLabel, makeStyles, MenuItem, Select,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import theme from "../../main/theme/theme";
import Gender from "../../report/domain/Gender";
import { PatientProfileField } from "./PatientProfileField";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  icon: {
    marginRight: theme.metrics.margin.small,
  },
}));

interface Props {
  value: Gender | undefined;
  onChange?: (value: Gender) => void;
}

export const GenderField: React.FunctionComponent<Props> = ({ value, onChange }) => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();

  const onGenderChange = (event: React.ChangeEvent<{ value: unknown }>): void =>
    onChange?.(Gender[event.target.value as keyof typeof Gender]);

  return (
    <PatientProfileField name={t("gender")}>
      <FormControl variant="outlined" className={classes.container}>
        <InputLabel id="gender-picker-label">{t("selectGender")}</InputLabel>

        <Select
          labelId="gender-picker-label"
          value={value || ""}
          label={t("selectGender")}
          role="button"
          onChange={onGenderChange}
          disabled={!onChange}
          IconComponent={(): JSX.Element => <KeyboardArrowDownIcon className={classes.icon} fontSize="large" />}
        >

          <MenuItem
            value={undefined}>
            {t("notSpecified")}
          </MenuItem>

          {(Object.keys(Gender) as Gender[]).map((gender) =>
            (
              <MenuItem
                value={gender}
                key={gender}>
                {t(`common:genders.${gender}`)}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </PatientProfileField>
  );
};

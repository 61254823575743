const reminder = {
  en: {
    reminder: {
      save: "Save",
      add: "Add",
      addReminder: "Add a reminder",
      editReminder: "Edit reminder",
      reminderName: "Reminder name",
      deleteReminder: "Delete reminder",
      name: "Name",
      reminder: "Reminder",
      date: "Date",
    },
  },
  fr: {
    reminder: {
      save: "Sauvegarder",
      add: "Ajouter",
      addReminder: "Ajouter un rappel",
      editReminder: "Modifier un rappel",
      reminderName: "Nom du rappel",
      deleteReminder: "Supprimer le rappel",
      name: "Nom",
      reminder: "Rappel",
      date: "Date",
    },
  },
};

export default reminder;

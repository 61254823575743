import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Medication from "../../../domain/medications/Medication";
import ModalHeader from "../../../../main/modal/ModalHeader";

interface Props {
  medication: Medication;
  onClose: () => void;
  className?: string;
}

export const DrugSheetHeader: React.FunctionComponent<Props> = ({ medication, onClose, className }) => {
  const { t } = useTranslation("report");

  const displayNameWithDosage = (): string => {
    const str = [];
    if (medication.type) {
      str.push(t(`drugTypes:${medication.type}`));
    }
    if (medication.dosage) {
      str.push(medication.dosage.toString());
    }
    return `${medication.name} ${str.filter(String).join(" - ")}`;
  };

  return (
    <ModalHeader className={className} onClose={onClose}>
      <Typography variant="h2" component="h2">
        {displayNameWithDosage()}
      </Typography>

      <div>
        <Typography variant="h2" component="h2" display="inline">
          {`${t("din")}: `}
        </Typography>

        <Typography variant="h2" component="h2" display="inline">
          {medication.din}
        </Typography>
      </div>
    </ModalHeader>
  );
};

export default DrugSheetHeader;


import { makeStyles } from "@material-ui/core";
import theme from "main/theme/theme";

export const useCompleteProfileStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    alignItems: "center",
    padding: `0 ${theme.metrics.margin.huge}`,
  },
  alertCard: {
    padding: `${theme.metrics.margin.medium} ${theme.metrics.margin.large}`,
    marginBottom: theme.metrics.margin.huge,
  },
  boldMessage: {
    fontWeight: "bold",
  },
  createProfileIcon: {
    marginBottom: theme.metrics.margin.normal,
  },
  createProfileText: {
    marginBottom: theme.metrics.margin.large,
  },
}));

import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../../main/theme/theme";
import DurationFormData from "../../schedule/domain/DurationFormData";
import DateTime from "../../main/dateTime/DateTime";
import RequiredFieldLabel from "../../main/components/RequiredFieldLabel";
import DurationDatePicker from "./DurationDatePicker";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    padding: `${theme.metrics.margin.small} 0`,
  },
  dateTitle: {
    margin: `${theme.metrics.margin.small} 0`,
    fontSize: theme.fonts.h5.size,
    fontWeight: "bold",
  },
  endDateDisabled: {
    marginHorizontal: `${theme.metrics.margin.normal} 0`,
    fontSize: theme.fonts.defaultSize,
    color: theme.colors.text.secondary,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    padding: `0 ${theme.metrics.margin.small}`,
  },
}));

interface Props {
  isEndDateEditable: boolean;
  durationFormData: DurationFormData;
  onStartDateChange: (newDate: string) => void;
  onEndDateChange?: (newDate: string) => void;
  onBlur?: () => void;
  dateContainerStyle?: string;
}

const DurationSection: React.FunctionComponent<Props> = ({
  isEndDateEditable,
  durationFormData,
  onStartDateChange,
  onEndDateChange,
  onBlur,
  dateContainerStyle,
}) => {
  const { t } = useTranslation("schedule");
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={[classes.dateContainer, dateContainerStyle].join(" ")}>
        <RequiredFieldLabel id="startDatePicker" bold label={t("startDate")} />

        <DurationDatePicker
          ariaLabelledBy="startDatePicker"
          date={durationFormData.startDate}
          onChange={onStartDateChange}
          onBlur={onBlur}
          required
          minValue={DateTime.now().toISODate()}
        />
      </div>

      <Typography
        component="label"
        variant="h4"
        className={[classes.dateTitle, classes.dateContainer, dateContainerStyle].join(" ")}
      >
        {t("endDate")}
        {isEndDateEditable ?
          <DurationDatePicker
            data-testid="endDatePicker"
            date={durationFormData.endDate}
            onChange={onEndDateChange}
            minValue={durationFormData.startDate.addDays(1).toISODate()}
          />
          :
          <Typography data-testid="endDateValue" component="label" variant="h4" className={classes.endDateDisabled}>
            {durationFormData?.endDate?.format("yyyy-MM-dd")}
          </Typography>
        }
      </Typography>
    </div>
  );
};

export default DurationSection;

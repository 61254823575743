import React from "react";
import { Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CreateCSSProperties } from "@material-ui/core/styles/withStyles";
import { pxToRem } from "../theme/theme";
import Modal from "../modal/Modal";

const useStyles = makeStyles((muiTheme: Theme) => ({
  modal: {
    minWidth: pxToRem(800),
    height: "70%",
    [muiTheme.breakpoints.down("sm")]: {
      minWidth: pxToRem(600),
    },
    [muiTheme.breakpoints.down("sm")]: {
      minWidth: pxToRem(400),
      height: "60%",
    },
  },
  modalPicture: ({ picture }: { picture?: string }): CreateCSSProperties => ({
    width: "100%",
    height: "100%",
    backgroundImage: `url(${picture})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundOrigin: "content-box",
  }),
}));

interface Props {
  open: boolean;
  onClose: () => void;
  pictureSrc: string;
}

const PictureViewer: React.FunctionComponent<Props> = ({ open, onClose, pictureSrc }) => {
  const classes = useStyles({ picture: pictureSrc });

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      className={classes.modal}
      closable
    >
      <div
        data-testid="pictureModal"
        className={classes.modalPicture}
      />
    </Modal>
  );
};

export default PictureViewer;

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AuthenticationContainer from "../containers/AuthenticationContainer";
import AmplifyAuthenticationContainer from "./AmplifyAuthenticationContainer";
import { Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import NavigationPath from "router/NavigationPath";

interface Props {
  authenticated: boolean;
}

const useStyles = makeStyles(() => ({
  authContainer: {
    flex: 1,
  },
}));

const AuthenticationPage: React.FunctionComponent<Props> = ({ authenticated }) => {
  const { state } = useLocation<{from: string}>();
  const classes = useStyles();

  if (authenticated) {
    return <Redirect to={state?.from ?? NavigationPath.CONNECT_WITH_PATIENT} />;
  }

  return (
    <AuthenticationContainer>
      <div className={classes.authContainer}>
        <AmplifyAuthenticationContainer />
      </div>
    </AuthenticationContainer>
  );
};

export default AuthenticationPage;

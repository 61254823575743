import DateTime from "../../main/dateTime/DateTime";
import BloodGlucoseMeasure from "../domain/measurements/bloodGlucoseMeasurement/BloodGlucoseMeasure";
import TimeOfDay from "../domain/measurements/bloodGlucoseMeasurement/TimeOfDay";
import Meal from "../domain/measurements/bloodGlucoseMeasurement/Meal";
import {
  PatientTreatmentBloodGlucoseMeasureResultResponse,
  PatientTreatmentBloodPressureMeasureResultResponse,
  PatientTreatmentCustomMeasureResultResponse,
  PatientTreatmentMeasureResultResponse,
  PatientTreatmentMeasureType,
  PatientTreatmentMigraineMeasureResultResponse,
  PatientTreatmentMoodMeasureResultResponse,
} from "./responses/PatientTreatmentMeasureResultResponse";
import Measure from "../domain/measurements/Measure";
import CustomMeasure from "../domain/measurements/customMeasurement/CustomMeasure";
import MigraineMeasure from "report/domain/measurements/migraineMeasurement/MigraineMeasure";
import { Severity, SeverityKey } from "report/domain/measurements/migraineMeasurement/Severity";
import { MoodFeeling, MoodKey } from "../domain/measurements/moodMeasurement/MoodFeeling";
import MoodMeasure from "../domain/measurements/moodMeasurement/MoodMeasure";
import BloodPressureMeasure from "../domain/measurements/bloodPressureMeasurement/BloodPressureMeasure";

export default class MeasureAssembler {
  public static fromResponse(response: PatientTreatmentMeasureResultResponse): Measure {
    if (response["@type"] === PatientTreatmentMeasureType.BLOOD_GLUCOSE) {
      const bloodGlucoseMeasureResponse = (response as PatientTreatmentBloodGlucoseMeasureResultResponse);
      return new BloodGlucoseMeasure({
        id: bloodGlucoseMeasureResponse.id,
        value: bloodGlucoseMeasureResponse.value,
        targetRange: bloodGlucoseMeasureResponse.targetRange,
        actualTimestamp: DateTime.fromISO(bloodGlucoseMeasureResponse.actualTimestamp),
        measureTime: TimeOfDay[bloodGlucoseMeasureResponse.time as keyof typeof TimeOfDay],
        meal: Meal[bloodGlucoseMeasureResponse.meal as keyof typeof Meal],
      });
    } else if (response["@type"] === PatientTreatmentMeasureType.MIGRAINE) {
      const migraineMeasureResponse = (response as PatientTreatmentMigraineMeasureResultResponse);
      return new MigraineMeasure({
        id: migraineMeasureResponse.id,
        value: Severity.fromKey(migraineMeasureResponse.value as SeverityKey),
        actualTimestamp: DateTime.fromISO(migraineMeasureResponse.actualTimestamp),
      });
    } else if (response["@type"] === PatientTreatmentMeasureType.MOOD) {
      const moodMeasureResponse = (response as PatientTreatmentMoodMeasureResultResponse);
      return new MoodMeasure({
        id: moodMeasureResponse.id,
        value: MoodFeeling.fromKey(moodMeasureResponse.value as MoodKey),
        actualTimestamp: DateTime.fromISO(moodMeasureResponse.actualTimestamp),
      });
    } else if (response["@type"] === PatientTreatmentMeasureType.BLOOD_PRESSURE) {
      const bloodPressureMeasureResponse = (response as PatientTreatmentBloodPressureMeasureResultResponse);
      return new BloodPressureMeasure({
        id: bloodPressureMeasureResponse.id,
        systolic: bloodPressureMeasureResponse.systolic,
        diastolic: bloodPressureMeasureResponse.diastolic,
        pulse: bloodPressureMeasureResponse.pulse,
        actualTimestamp: DateTime.fromISO(bloodPressureMeasureResponse.actualTimestamp),
      });
    } else {
      const customMeasureResponse = (response as PatientTreatmentCustomMeasureResultResponse);
      return new CustomMeasure({
        id: customMeasureResponse.id,
        value: customMeasureResponse.value,
        actualTimestamp: DateTime.fromISO(customMeasureResponse.actualTimestamp),
      });
    }

  }
}

import PhoneNumberInput, { PhoneNumberValue } from "../../../main/components/PhoneNumberInput";

import AuthenticationForm from "../../components/AuthenticationForm";
import AuthenticationFormErrors from "../../components/AuthenticationFormErrors";
import AuthenticationReadOnlyField from "../../components/AuthenticationReadOnlyField";
import AuthenticationTextField from "../../components/AuthenticationTextField";
import Button from "@material-ui/core/Button";
import HealthProfession from "../domain/HealthProfession";
import ProfessionPicker from "./ProfessionPicker";
import ProfileFormData from "../domain/ProfileFormData";
import { ProfileFormErrors } from "../domain/ProfileFormErrors";
import React from "react";
import ReadOnlyTextField from "../../components/ReadOnlyTextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../../main/theme/theme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  phoneFieldsRow: {
    width: "100%",
  },
  formRow: {
    margin: `${theme.metrics.margin.small} 0`,
  },
  userEmail: {
    marginBottom: theme.metrics.margin.normal,
  },
}));

interface Props {
  userEmail?: string;
  profileFormData: ProfileFormData;
  withOfficeAddress?: boolean;
  errors: ProfileFormErrors;
  onChange: (updatedProfileFormData: ProfileFormData) => void;
  canSubmit?: boolean;
  onSubmit: () => void;
}

const ProfileForm: React.FunctionComponent<Props> =
  ({ profileFormData, onChange, onSubmit, errors, withOfficeAddress, userEmail, canSubmit = true }) => {
    const classes = useStyles();
    const { t } = useTranslation("profile");
    const onSubmitForm = (event: React.ChangeEvent<HTMLFormElement>): void => {
      event.preventDefault();
      onSubmit();
    };

    return (
      <AuthenticationForm onSubmit={onSubmitForm}>
        <AuthenticationTextField
          id="firstName"
          label={t("firstName")}
          name="firstName"
          autoFocus
          value={profileFormData.firstName}
          onChange={(value: string): void => onChange(new ProfileFormData({
            ...profileFormData,
            firstName: value,
          }))}
          error={errors.firstName.length > 0 ? t(`errors.${errors.firstName[0]}`) : ""}
        />

        <AuthenticationTextField
          id="lastName"
          label={t("lastName")}
          name="lastName"
          value={profileFormData.lastName}
          onChange={(value: string): void => onChange(new ProfileFormData({
            ...profileFormData,
            lastName: value,
          }))}
          error={errors.lastName.length > 0 ? t(`errors.${errors.lastName[0]}`) : ""}
        />

        {!!withOfficeAddress &&
          <AuthenticationTextField
            id="officeAddress"
            variant="outlined"
            label={t("officeAddressOptional")}
            name="officeAddress"
            value={profileFormData.officeAddress}
            onChange={(value: string): void => onChange(new ProfileFormData({
              ...profileFormData,
              officeAddress: value,
            }))}
          />
        }

        <div className={[classes.formRow, classes.phoneFieldsRow].join(" ")}>
          <PhoneNumberInput
            error={errors.officeNumber.length > 0 ? t(`errors.${errors.officeNumber[0]}`) : ""}
            label={t("officePhoneNumber")}
            extensionLabel={t("extension")}
            phoneNumber={{
              value: profileFormData.officeNumber,
              extension: profileFormData.extension,
            }}
            onChange={(phoneNumber: PhoneNumberValue): void => onChange(new ProfileFormData({
              ...profileFormData,
              officeNumber: phoneNumber.value,
              extension: phoneNumber.extension,
            }))}
          />
        </div>

        <ProfessionPicker
          className={classes.formRow}
          label={t("selectProfession")}
          onChange={(updatedProfession: HealthProfession): void => onChange(new ProfileFormData({
            ...profileFormData,
            profession: updatedProfession,
          }))}
          profession={profileFormData.profession}
          error={errors.profession.length > 0 ? t(`errors.${errors.profession[0]}`) : ""}
        />

        {!!profileFormData.partnerCompany && <ReadOnlyTextField
          id="company"
          variant="outlined"
          label={t("company")}
          name="company"
          value={t(`partnerCompany.${profileFormData.partnerCompany}`)}
        />
        }

        {!!userEmail && <AuthenticationReadOnlyField label={t("emailAddress")} value={userEmail} className={classes.userEmail} />
        }

        <AuthenticationFormErrors errors={errors.body.map((error) => t(`errors.${error}`))} />

        <Button
          className={classes.formRow}
          type="submit"
          disabled={!canSubmit}
          variant="contained"
          color="primary"
        >
          {t("save")}
        </Button>
      </AuthenticationForm>
    );
  };

export default ProfileForm;

import PartnerCompany from "./PartnerCompany";

class PartnerCompanyEmailParser {
  public IA_EMAIL = "ia.ca";
  public SSQ_EMAIL = "ssq.ca";
  public LA_CAPITALE_EMAIL = "lacapitale.com";
  public MC_KESSON_EMAIL = "mckesson.ca";
  public HUMANIS_EMAIL = "humanisrx.ca";

  public parse(email: string): PartnerCompany | undefined {
    const emailSuffix = email.split("@")[1];
    if (this.isIA(emailSuffix)) {
      return PartnerCompany.IA;
    }

    if (this.isMcKesson(emailSuffix)) {
      return PartnerCompany.MC_KESSON;
    }

    if (this.isBeneva(emailSuffix)) {
      return PartnerCompany.BENEVA;
    }

    if (this.isHumanis(emailSuffix)) {
      return PartnerCompany.HUMANIS;
    }

    return undefined;
  }

  private isIA(emailSuffix: string): boolean {
    return this.IA_EMAIL === emailSuffix;
  }

  private isMcKesson(emailSuffix: string): boolean {
    return this.MC_KESSON_EMAIL === emailSuffix;
  }

  private isHumanis(emailSuffix: string): boolean {
    return this.HUMANIS_EMAIL === emailSuffix;
  }

  private isBeneva(emailSuffix: string): boolean {
    return this.SSQ_EMAIL === emailSuffix || this.LA_CAPITALE_EMAIL === emailSuffix;
  }
}

export default new PartnerCompanyEmailParser();


enum NavigationPath {
  AUTHENTICATION = "/auth",
  COMPLETE_PROFILE = "/complete-profile",
  PRESCRIBE = "/prescribe",
  CONNECTION_HISTORY = "/connections",
  CONNECT_WITH_PATIENT = "/home",
  MY_ACCOUNT = "/profile",
  CONSULTATION_SUMMARY = "/consultation/:consultationId",
  PATIENT_REPORT = "/patient-report/:code",
  PATIENT_REPORT_MEASUREMENTS = "/patient-report/:code/measurements/:reportCode",
  PATIENT_REPORT_ACTIVITIES = "/patient-report/:code/activities/:reportCode",
  PATIENT_REPORT_REMINDERS = "/patient-report/:code/reminders/:reportCode",
  PATIENT_REPORT_MEDICATIONS = "/patient-report/:code/medications/:reportCode"
}

export default NavigationPath;

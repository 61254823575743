/* eslint-disable */
const termsOfService =
    `<0>Conditions d'utilisation - MédAide pour les professionnels</0>
Dernière modification : 18 juillet 2022

Veuillez examiner attentivement ces conditions (l' »accord »). En accédant ou en utilisant le service offert par MedHelper Inc. (« MedHelper », « MédAide », « nous » ou « notre »), vous confirmez que vous avez lu, compris et accepté le présent accord. Le présent accord peut être mis à jour de temps en temps, comme indiqué à la section 24 ci-dessous. Il vous incombe de consulter régulièrement la version la plus récente du présent accord, qui est publiée à l’adresse suivante : <1>https://medhelper.com/fr/politiques-medaide-pour-les-professionnels/</1>, mais nous vous informerons de toute modification qui, à notre seule discrétion, aura une incidence matérielle sur le présent accord. La poursuite de l’utilisation du Service après que de telles modifications aient été apportées constituera votre consentement à ces modifications. Lorsque nous modifierons le présent accord, nous mettrons à jour la date de « dernière modification » ci-dessus.

Le présent accord s’applique au « Service », MedHelper for Professionals, qui est un portail en ligne où les médecins et autres professionnels de la santé peuvent consulter les données déclarées par les patients.

<0>En utilisant notre Service, vous confirmez que :</0>

    A. Vous n’utiliserez nos services en libre-service que pour des raisons commerciales et professionnelles;

    B. Vous avez au moins 18 ans ou êtes en mesure de conclure un accord juridiquement contraignant ;

    C. Vous acceptez et respecterez le présent accord ;

    D. Vous êtes un professionnel médical certifié et/ou vous avez le droit légal de consulter les informations sur les médicaments de votre patient ; et

    E. Vous êtes responsable de toute personne qui utilise notre service par l’intermédiaire de votre compte, comme vos employés, consultants ou contractants (« utilisateurs autorisés »).

<0>MédAide pour les professionnels</0>

    1. Sous réserve du reste du présent accord, nous vous permettrons, ainsi qu’à vos utilisateurs autorisés, d’accéder à notre service et de l’utiliser de la manière prévue dans le plan auquel vous avez souscrit. Vous ne pouvez pas utiliser ou accéder au Service de toute autre manière, comme par exemple en utilisant des robots, des araignées, des crawlers et des technologies de grattage.

    2. Notre Service est en constante évolution. Nous pouvons modifier notre service, par exemple en changeant, ajoutant ou supprimant des fonctionnalités à tout moment, pour quelque raison que ce soit. Nous pouvons ou non vous informer de ces changements. Nous ne serons pas responsables envers vous ou tout autre tiers de la modification, du changement de prix, de la suspension ou de l’interruption de toute partie de nos services.

<0>Règles d'utilisation acceptable</0>

    3. Vous et tout utilisateur autorisé utilisant votre compte devez respecter les règles d’utilisation acceptable et toutes les lois. Si nous pensons raisonnablement que vous avez enfreint les règles d’utilisation acceptable ou toute loi applicable, nous pouvons, à tout moment et sans préavis, supprimer une partie de votre contenu et suspendre, restreindre ou résilier votre compte ou votre accès au service.

<0>Contenu du client</0>

    4. Vous êtes seul responsable du Contenu Client. Nous pouvons, mais nous ne sommes pas obligés, d’examiner, de filtrer, de bloquer ou de supprimer le Contenu, y compris le Contenu Client.

<0>Contenu du patient</0>

    5. Vous utilisez le Service pour accéder à des informations personnelles et médicales sur un patient (« Contenu Patient »), vous reconnaissez et acceptez que :

        (A) le Service ne vous aide pas à vous conformer aux lois, règles ou règlements qui peuvent s’appliquer à votre collecte ou à votre utilisation du Contenu Patient, qui est sous votre seule responsabilité ; et

        (B) nous ne serons pas responsables envers vous ou tout autre tiers du Contenu Patient, et nous ne garantissons pas que tout Contenu Patient obtenu ou affiché par le biais du Service est exact ou complet.

<0>Informations sur le compte</0>

    6. Vous êtes responsable de l’exhaustivité, de la sécurité, de la confidentialité et de l’exactitude des informations sur les comptes que vous nous fournissez. Vous nous informerez rapidement de tout accès ou utilisation non autorisé de vos identifiants ou de votre compte.


<0>Confidentialité</0>

    7. Si nous partageons avec vous des informations non publiques concernant MedHelper ou notre service, vous devez les garder confidentielles et utiliser des mesures de sécurité raisonnables pour empêcher toute divulgation ou tout accès non autorisé à ces informations.

<0>Propriété intellectuelle</0>

    La propriété du service, et du logiciel qui l’alimente, restera à tout moment la propriété de MedHelper. MedHelper conservera tous les droits, titres, droits d’auteur, secrets commerciaux, brevets, marques déposées et autres droits de propriété et de propriété intellectuelle sur le service et le logiciel qui l’alimente. Vous n’acquérez aucun droit sur le service ou le logiciel qui l’alimente, autre que ceux spécifiés dans les présentes conditions. Vous ne supprimerez aucun droit d’auteur, brevet, marque commerciale, droit des dessins et modèles, secret commercial ou tout autre droit de propriété ou légende du service.

<0>Conditions de paiement, essais et renouvellement</0>

    8. Frais et procès. Vous devez payer tous les frais pour le service que vous achetez, sauf pour les services que nous offrons expressément gratuitement.
    9. Renouvellement automatique du service. Les plans de service se renouvelleront automatiquement à la fin de la durée (annuellement), et nous vous facturerons automatiquement au moment du renouvellement, sauf si vous annulez notre service en vertu de l’article 23 ci-dessous.

    10. Taxes. Vous êtes responsable du paiement de toutes les taxes sur tous les frais que vous nous payez. Les taxes locales peuvent varier en fonction de votre mode de paiement.

<0>Annulation et cessation du service</0>

  11. Vous pouvez annuler votre plan de service à tout moment en nous contactant à l’adresse info@medhlper.com. Si vous annulez votre abonnement au Service, le présent accord prendra fin à la fin de la période pour laquelle vous vous êtes engagé à payer des frais et vous acceptez de cesser d’utiliser le Service. Tous les frais que vous vous êtes engagé à payer doivent encore être payés. Vous êtes seul responsable de contacter MedHelper pour annuler le Service ou demander la suppression de votre compte. MedHelper supprimera vos données dans les six mois suivant la résiliation de votre abonnement au service ou, si cela intervient plus tôt, à la réception d’une demande écrite de suppression de vos données.
  
  12. Nous pouvons refuser de fournir un service (y compris le Service) à quiconque pour quelque raison que ce soit, à tout moment. Nous pouvons également suspendre ou résilier immédiatement votre accès à notre service pour toute raison, à tout moment, sans vous en informer au préalable. Si nous mettons fin au présent accord parce que vous avez violé les règles d’utilisation acceptable ou toute autre partie du présent accord, vous ne serez pas remboursé. Si nous résilions votre accès au Service en vertu de la présente section sans raison, nous vous rembourserons les Services que vous n’avez pas encore reçus.

<0>Exclusion de garanties et limitation de responsabilité</0>

    13. Nous offrons notre Service « tel quel » et ne faisons aucune garantie, condition, déclaration ou garantie d’aucune sorte, qu’elle soit expresse, implicite, statutaire ou autre, y compris toutes les garanties expresses ou implicites de conception, de qualité marchande, d’adéquation à un usage particulier, de titre, de qualité et de non-contrefaçon, que notre Service répondra à vos exigences ou que notre Service sera toujours disponible, accessible, ininterrompu, opportun, sûr, précis, complet ou sans erreur. Vous utilisez notre service à vos propres risques. Par la présente, vous nous libérez, ainsi que nos sociétés affiliées et les directeurs, responsables, employés, agents, concédants et prestataires de services de MedHelper et de nos sociétés affiliées (les « parties MedHelper ») de toute réclamation, connue ou inconnue, que vous pourriez avoir à leur encontre.

    14. Nous ne serons pas responsables des dommages indirects, punitifs, accessoires, spéciaux, consécutifs ou exemplaires, ni des pertes de profits, de clientèle ou de données ou autres pertes intangibles, qui résultent de l’utilisation ou de l’incapacité à utiliser notre service ou tout autre aspect du présent accord. En aucun cas nous ne serons responsables de tiers, de toute connexion ou transmission à partir d’Internet, ou de tout dommage, perte ou blessure résultant d’un piratage, d’une altération, d’un vol ou de tout autre accès non autorisé à notre service ou à votre compte ou à l’utilisation de ceux-ci ou des informations contenues dans votre compte.

    15. Notre responsabilité globale envers vous pour toutes les pertes ou dommages résultant du présent accord ou de votre utilisation de notre service, même si nous avons été informés de la possibilité de telles pertes, ne dépassera pas le montant le plus élevé entre les frais que nous avons reçus de vous pour notre service et qui font l’objet de la réclamation pendant la période de trois mois précédant immédiatement la date à laquelle les dommages sont survenus et 100 dollars canadiens. Les limitations de responsabilité dans cette section s’appliquent également aux parties de MedHelper et aux réclamations fondées sur toute cause d’action, y compris la rupture de contrat, les délits (y compris la négligence), la loi ou toute autre théorie juridique ou équitable. S’il existe une loi qui limite la manière dont la limitation de responsabilité de cette section s’applique à vous, notre responsabilité sera limitée dans toute la mesure permise par la loi.

    16. Aucun conseil ou information, qu’il soit oral ou écrit, obtenu de notre part ou d’ailleurs, ne créera de garantie ou de condition qui ne soit pas expressément stipulée dans le présent accord.

    17. Nos prix sont fixés sur la base des limitations de responsabilité et des exclusions de garantie de la présente section. Vous reconnaissez qu’ils survivront et s’appliqueront même s’il s’avère qu’ils n’ont pas atteint leur objectif essentiel.

    18. Si vous n’êtes pas satisfait de notre service ou si vous pensez que vous avez été lésé par votre utilisation de notre service ou par le présent accord, vous pouvez mettre fin à votre utilisation de notre service (comme indiqué ci-dessus). Cette résiliation est votre seul et unique recours (et notre seule et unique responsabilité) dans le cadre du présent accord.

<0>Réclamations et litiges</0>

    19. Les présentes conditions seront régies par les lois de la province de Québec et les lois du Canada applicables au Québec. Chaque partie accepte que les tribunaux de la province de Québec soient compétents. Les parties conviennent que les dispositions de la Convention des Nations Unies sur les contrats de vente internationale de marchandises ne s’appliquent pas aux présentes conditions.

    20. Vous vous engagez à indemniser et à dégager de toute responsabilité les parties de MedHelper contre toutes pertes, dommages, pénalités, responsabilité et coûts, y compris les frais juridiques raisonnables, de toute nature ou de tout type liés à toute réclamation contre une partie de MedHelper : (a) au contenu du patient ; (b) à votre violation du présent accord ou de toute loi ; ou (c) à votre utilisation de notre service.

    21. Si vous enfreignez le présent accord ou violez nos droits ou les droits d’une autre personne, nous pouvons demander à un tribunal de vous arrêter ; si nous introduisons une réclamation contre vous, vous nous rembourserez nos frais juridiques raisonnables pour cette réclamation.

    22. Toute procédure de règlement des litiges sera menée uniquement sur une base individuelle et non dans le cadre d’une action collective, consolidée ou représentative. MedHelper et vous renoncez chacun à tout droit à un procès devant un jury.

<0>Divers</0>

    23. Conformité des exportations. Vous n’utiliserez pas ou n’accéderez pas à notre service si vous êtes situé dans une juridiction dans laquelle la fourniture de notre service est interdite en vertu des lois canadiennes, américaines ou autres (une « juridiction interdite ») et vous ne donnerez pas accès à notre service à un gouvernement, une entité ou un individu situé dans une juridiction interdite. Vous confirmez que vous ne figurez sur aucune liste du gouvernement canadien ou américain de personnes ou d’entités interdites de transaction avec toute personne canadienne ou américaine ; (b) vous n’êtes pas un ressortissant d’une juridiction interdite ou une société enregistrée dans une telle juridiction ; (c) vous ne permettrez pas aux utilisateurs autorisés d’accéder ou d’utiliser notre service en violation de toute loi canadienne, américaine ou autre. S. ou d’autres embargos, interdictions ou restrictions à l’exportation ; et (d) vous respecterez toutes les lois concernant la transmission de données exportées du pays dans lequel vous (ou vos utilisateurs autorisés) êtes situés vers le Canada et les États-Unis.

    24. Amendement. Nous pouvons modifier toute partie du présent accord (y compris tout terme ou document incorporé par référence dans le présent accord) à tout moment en publiant les termes révisés sur le site Web de MedHelper, et votre utilisation du service après la date d’entrée en vigueur de l’accord mis à jour ou d’autres termes constitue votre accord sur l’accord mis à jour ou d’autres termes, selon le cas. Il est important que vous preniez connaissance du présent accord avant d’utiliser notre service et de temps à autre. L’accord mis à jour entrera en vigueur au moment de sa publication, ou à une date ultérieure spécifiée dans l’accord mis à jour, et s’appliquera à votre utilisation du service à partir de ce moment.

    25. Affectation. Vous ne pouvez pas céder vos droits en vertu du présent accord à une autre personne. Nous pouvons céder nos droits à toute autre personne physique ou morale.

    26. Séparabilité. Si un tribunal déclare une partie du présent accord non valable, le reste continuera à s’appliquer avec les modifications minimales requises pour supprimer la partie non valable.

    27. Force Majeure. Nous ne serons pas tenus responsables envers vous ou un tiers pour tout manquement à nos obligations en vertu du présent accord si ce manquement résulte d’une cause indépendante de notre volonté, telle que les éléments, l’incendie, l’inondation, les intempéries, les tremblements de terre, le vandalisme, les accidents, le sabotage, les pannes de courant, les attaques par déni de service ou autres attaques similaires ou autres actes de pirates informatiques, les pannes ou interruptions d’Internet ou de réseau, les catastrophes naturelles et l’ennemi public ; les actes de guerre ; les actes de terrorisme ; les émeutes ; les troubles civils ou publics ; les grèves, lock-out ou interruptions de travail ; les lois, ordres, règles, règlements, actes ou restrictions de tout gouvernement ou organisme ou autorité gouvernementale, civile ou militaire, y compris les ordres et jugements des tribunaux ; ou si une autre personne modifie ses services ou ses conditions ou n’offre plus ses services à vous ou à MedHelper selon des conditions que nous jugeons raisonnables.

    28. Recours. Si nous ne parvenons pas à faire appliquer le présent accord, nous ne renonçons pas à notre droit de le faire. Les recours dont nous disposons en vertu du présent accord ne sont pas exclusifs des autres recours dont nous pouvons disposer en vertu du présent accord, en droit ou autrement.

    29. Avis à vous. MedHelper peut vous adresser une notification en plaçant une bannière de notification sur la plateforme MedHelper for Professionals ou sur le site web de MedHelper. Nous pouvons également vous contacter ou contacter vos utilisateurs autorisés par le biais de votre compte MedHelper ou des informations de contact que vous nous fournissez, telles que le courrier électronique ou le téléphone. Si vous ne conservez pas des informations précises sur votre compte, telles que vos coordonnées, vous risquez de ne pas recevoir d’informations essentielles sur notre service ou sur le présent accord.

    30. Avis à MedHelper. Pour toute notification à MedHelper que vous donnez dans le cadre ou à propos du présent accord, vous devez en informer MedHelper par courrier électronique à legal@medhelper.com avec un double envoyé par courrier recommandé à l’adresse suivante : MedHelper Inc, 6500, autoroute Transcanadienne, 4e étage, Pointe-Claire, Québec, Canada H9R 0A5.

    31. Accord intégral. Le présent accord, y compris les autres documents mentionnés comme applicables au service dans le présent accord, constitue l’intégralité de l’accord entre vous et MedHelper pour votre utilisation de notre service. Les accords, déclarations ou conventions antérieurs (oraux ou écrits) ne s’appliquent pas, y compris les conditions supplémentaires que vous pouvez présenter (telles que les conditions figurant dans une notification unilatérale que vous nous avez adressée ou imprimée sur un bon de commande ou tout autre document généré par vous). Le présent accord lie les parties et leurs successeurs et ayants droit autorisés.
`;

export default termsOfService;

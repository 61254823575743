import {
  Divider, makeStyles, TableBody, TableCell, TableRow, Typography,
} from "@material-ui/core";
import theme, { convertHexToRGBA } from "../../main/theme/theme";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomMeasure from "../domain/measurements/customMeasurement/CustomMeasure";
import { ReportResultsTable } from "./ReportResultsTable";
import { ReportResultsTableHead } from "./ReportResultsTableHead";
import { ReportResultsTableHeaderTitle } from "./ReportResultsTableHeaderTitle";
import { weekdays } from "../../schedule/domain/Weekday";
import { DateTime as LuxonDateTime } from "luxon";
import NotesTableResults from "./notes/NotesTableResults";
import NotesReport from "report/domain/note/NotesReport";

interface Props {
  measures: CustomMeasure[] | null;
  notes: NotesReport;
  testID?: string;
  name: string;
}

export const CustomMeasureResultsTable: React.FunctionComponent<Props> = ({ measures, notes, name }) => {
  const styles = useStyles();
  const { t } = useTranslation("report");
  const sortedMeasures = measures?.slice().sort(
    (a, b) => a.actualTimestamp.isBefore(b.actualTimestamp)
      ? 1 : -1
  );

  return (
    <>
      <Typography component="span" variant="h3" color="textPrimary" style={{ fontWeight: "bold" }}>
        {name}
      </Typography>

      <Divider className={styles.divider} />

      <NotesTableResults notes={notes} />

      <ReportResultsTable>
        <ReportResultsTableHead>
          <TableCell />

          <ReportResultsTableHeaderTitle title={t("dateOnly")} />

          <ReportResultsTableHeaderTitle title={t("timeOnly")} />

          <ReportResultsTableHeaderTitle title={t("value")} />
        </ReportResultsTableHead>

        <TableBody>
          {sortedMeasures?.map((measure) => (
            <TableRow key={measure.id} classes={{ root: styles.row }}>
              <TableCell>
                <Typography>{t(`weekdays:${weekdays[measure.actualTimestamp.getDayOfWeek()]}`)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{measure.actualTimestamp.formatLocale(LuxonDateTime.DATE_SHORT)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{measure.actualTimestamp.formatLocale(LuxonDateTime.TIME_SIMPLE)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{`${measure.value}`}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ReportResultsTable>
    </>
  );
};

const useStyles = makeStyles(() => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: convertHexToRGBA(theme.colors.table.row, 100),
    },
  },
  divider: {
    marginBottom: theme.metrics.margin.large,
  },
}));

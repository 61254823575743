import { TFunction } from "i18next";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import PhoneNumber from "../domain/PhoneNumber";

const displayPhoneNumber = (phoneNumber: PhoneNumber, t: TFunction): string => {
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber.value);
  if (phoneNumber.extension) {
    return t("phoneNumberWithExtension", {
      value: formattedPhoneNumber,
      extension: phoneNumber.extension,
    });
  }

  return formattedPhoneNumber;
};

const formatPhoneNumber = (phoneNumberValue: string): string => {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();

  const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneNumberValue, "CA");

  return phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
};

export default displayPhoneNumber;

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AuthenticationContainer from "../../containers/AuthenticationContainer";
import { Redirect, useLocation } from "react-router-dom";
import NavigationPath from "router/NavigationPath";
import ClientConnectAuthenticationContainer from "./ClientConnectAuthenticationContainer";
import useAppExceptionRepository from "store/hooks/useAppExceptionRepository";
import AppExceptions from "main/components/AppExceptions";

export interface Props {
  authenticated: boolean;
}

const useStyles = makeStyles(() => ({
  authContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

const AuthenticationPage: React.FunctionComponent<Props> = ({ authenticated }) => {
  const { state } = useLocation<{from: string}>();
  const { errors } = useAppExceptionRepository();
  const classes = useStyles();

  if (authenticated) {
    return <Redirect to={state?.from ?? NavigationPath.PRESCRIBE} />;
  }

  return (
    <AuthenticationContainer>
      <div className={classes.authContainer}>
        {
          errors.length
            ? <AppExceptions />
            : <ClientConnectAuthenticationContainer />
        }
      </div>
    </AuthenticationContainer>
  );
};

export default AuthenticationPage;

import React from "react";
import Modal from "../../../main/modal/Modal";
import ModalHeaderCarePlan from "main/modal/ModalHeaderCarePlan";
import { makeStyles } from "@material-ui/core";
import { ModalBody } from "main/modal/ModalBody";

interface Props {
  isOpen: boolean;
  title: string;
  onClose: (open: boolean) => void;
}

const CustomProgramModal: React.FunctionComponent<Props> = ({ isOpen, onClose, title, children }) => {
  const style = useStyle();
  const closeModal = (): void => {
    onClose(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} className={style.modal}>
      <ModalHeaderCarePlan title={title} onClose={closeModal} />

      <ModalBody>
        {children}
      </ModalBody>
    </Modal>
  );
};

export default CustomProgramModal;

const useStyle = makeStyles(() => ({
  modal: {
    padding: 0,
  },
}));


import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import Avatar from "../../main/components/Avatar";
import Patient from "../domain/Patient";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "row",
  },
  patientInfo: {
    width: "100%",
    marginLeft: theme.metrics.margin.medium,
  },
  fields: {
    display: "flex",
    flexDirection: "column",
  },
  field: {
    fontWeight: "bold",
  },
  ageField: {
    marginLeft: theme.metrics.margin.medium,
  },
  allergiesField: {
    wordBreak: "break-all",
  },
}));

interface Props {
  patient: Patient;
}

const PatientInfo: React.FunctionComponent<Props> = ({ patient }) => {
  const { t } = useTranslation("report");
  const classes = useStyles();
  const allergies = patient.allergies || t("noAllergiesReported");

  return (
    <div className={classes.content}>
      <Avatar picture={patient.picture} />
      <div className={classes.patientInfo}>
        <div className={classes.fields}>
          <span>
            <Trans
              t={t}
              i18nKey="firstName"
              components={[
                <Typography key="0" component="span" variant="h5" color="textPrimary" className={classes.field} />,
                <Typography key="1" component="span" variant="h5" color="textPrimary" />,
              ]}
              values={{ firstName: patient.firstName }}
            />
          </span>

          <span>
            <Trans
              t={t}
              i18nKey="lastName"
              components={[
                <Typography key="0" component="span" variant="h5" color="textPrimary" className={classes.field} />,
                <Typography key="1" component="span" variant="h5" color="textPrimary" />,
              ]}
              values={{ lastName: patient.lastName }}
            />
          </span>

          <span>
            <Trans
              t={t}
              i18nKey="gender"
              components={[
                <Typography key="0" component="span" variant="h5" color="textPrimary" className={classes.field} />,
                <Typography key="1" component="span" variant="h5" color="textPrimary" />,
              ]}
              values={{ gender: t(patient.gender || "") }}
            />

            <span className={classes.ageField}>
              <Trans
                t={t}
                i18nKey="age"
                components={[
                  <Typography key="0" component="span" variant="h5" color="textPrimary" className={classes.field} />,
                  <Typography key="1" component="span" variant="h5" color="textPrimary" />,
                ]}
                values={{ age: patient.calculateAge() }}
              />
            </span>
          </span>

          <span>
            <Trans
              t={t}
              i18nKey="allergies"
              components={[
                <Typography key="0" component="span" variant="h5" color="textPrimary" className={classes.field} />,
                <Typography
                  key="1"
                  component="span"
                  display="block"
                  variant="h5"
                  color="textPrimary"
                  className={classes.allergiesField}
                />,
              ]}
              values={{ allergies }}
            />
          </span>
        </div>

      </div>
    </div>
  );
};

export default PatientInfo;

import i18next, { i18n } from "i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { I18N_LANGUAGE_KEY } from "../hooks/useLanguageSelection";
import translations from "./translations";

export const initI18n = (): i18n => {
  i18next
    .use(i18nextBrowserLanguagedetector)
    .use(initReactI18next)
    .init({
      fallbackLng: "en",
      debug: false,

      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ["br", "strong", "i", "em", "u", "nobr"],
      },

      resources: translations as any,
      fallbackNS: "common",

      interpolation: {
        escapeValue: false,
      },

      detection: {
        order: ["querystring", "localStorage", "navigator", "htmlTag"],

        lookupQuerystring: I18N_LANGUAGE_KEY,
        lookupLocalStorage: I18N_LANGUAGE_KEY,

        caches: ["localStorage"],
        excludeCacheFor: ["cimode"],
      },
    });
  return i18next;
};

initI18n();

import React from "react";
import {
  TextField, makeStyles, TextFieldProps,
} from "@material-ui/core";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  textField: {
    margin: `${theme.metrics.margin.small} 0`,
  },
}));

export const ReadOnlyTextField: React.FunctionComponent<TextFieldProps> = ({
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <TextField
      className={[classes.textField, className].join(" ")}
      variant="outlined"
      fullWidth
      disabled
      {...rest}
    />
  );
};
export default ReadOnlyTextField;

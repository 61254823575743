import React from "react";
import RequiredFieldLabel from "../../../main/components/RequiredFieldLabel";
import {
  Divider, makeStyles, TextField,
} from "@material-ui/core";
import ReminderSection from "../../../schedule/components/ReminderSection";
import { useTranslation } from "react-i18next";
import theme, { pxToRem } from "../../../main/theme/theme";
import CustomActivityFormData from "../../domain/CustomActivityFormData";
import useCustomActivityForm from "../../hooks/useCustomActivityForm";
import { useScheduleForm } from "../../../medication/hooks/useScheduleForm";
import { createEmpty as createEmptySession } from "../../../prescribe/domain/SessionFormData";
import { ReminderSessionRenderer } from "../../../prescribe/components/ReminderSessionRenderer";
import { RegimenForm } from "../../../prescribe/components/RegimenForm";
import DurationSection from "../../../prescribe/components/DurationSection";
import { useDuration } from "../../../prescribe/hooks/useDuration";

interface Props {
  onSubmit: (formData: CustomActivityFormData) => void;
  submitLabel: string;
  customActivity?: CustomActivityFormData;
}

export const CustomActivityForm = ({ onSubmit, submitLabel, customActivity }: Props): JSX.Element => {
  const { t } = useTranslation("customActivity");
  const classes = useStyles();
  const { formData, onChange, canSubmit } = useCustomActivityForm(customActivity);

  const {
    toggle,
    active,
    addNewEvent,
    updateEvent,
    deleteEvent,
    changeFrequency,
    changeInterval,
    changeWeekdays,
    changeMonthDays,
  } = useScheduleForm(
    formData.schedule,
    (field, value) => onChange("schedule")(field)(value),
    createEmptySession,
  );

  const { onStartDateChange, onEndDateChange } = useDuration(
    formData.schedule,
    (field, value) => onChange("schedule")(field)(value),
  );

  return (
    <RegimenForm
      instructions={formData.instructions}
      onChangeInstruction={(instructions): void => onChange("instructions")("text")(instructions)}
      submitLabel={submitLabel}
      onSubmit={(): void => onSubmit(formData)}
      canSubmit={canSubmit}
    >
      <RequiredFieldLabel
        id="nameLabel"
        bold
        label={t("customActivityName")}
        className={classes.textFieldTitle}
      />

      <TextField
        inputProps={{
          "aria-labelledby": "nameLabel",
        }}
        placeholder={t("name")}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onChange("details")("name")(event.target.value)}
        variant="outlined"
        required
        fullWidth
        value={formData.details.name}
      />

      <Divider light />

      <DurationSection
        isEndDateEditable
        dateContainerStyle={classes.durationSection}
        durationFormData={formData.schedule.duration}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />

      <ReminderSection
        formData={formData.schedule}
        renderEvent={(session, index): JSX.Element => (
          <ReminderSessionRenderer
            session={session}
            onChange={updateEvent}
            index={index}
            canDelete={formData.schedule.events.length > 1}
            onDelete={deleteEvent}
          />
        )}
        active={active}
        onToggle={toggle}
        onAddSession={addNewEvent}
        addEventLabel={t("addReminder")}
        onChangeFrequency={changeFrequency}
        onChangeInterval={changeInterval}
        onChangeWeekdays={changeWeekdays}
        onChangeMonthDays={changeMonthDays}
      />
    </RegimenForm>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: `0 ${theme.metrics.margin.normal} ${theme.metrics.margin.normal}`,
    width: pxToRem(500),
    overflowY: "auto",
    maxHeight: "70vh",
    overflowX: "hidden",
    ...theme.scrollbar,
  },
  textFieldTitle: {
    marginBottom: theme.metrics.margin.small,
  },
  addButton: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.metrics.margin.normal,
  },
  durationSection: {
    padding: "0",
  },
}));

import React, { useState } from "react";
import SearchDrug from "../drugs/domain/SearchDrug";
import { MedicationFormDetails } from "./components/MedicationFormDetails";
import MedicationFormData from "./domain/MedicationFormData";
import MedicationFormSearch from "./components/MedicationFormSearch";
import useMedicationForm from "./hooks/useMedicationForm";
import MedicationSummary from "./domain/MedicationSummary";

interface Props {
  onSubmit: (medication: MedicationFormData, drug: SearchDrug) => void;
  medicationSummary?: MedicationSummary;
  submitLabel: string;
}

const MedicationForm: React.FC<Props> = ({ onSubmit, medicationSummary, submitLabel }) => {
  const [selectedDrug, setSelectedDrug] = useState<SearchDrug | undefined>(medicationSummary?.drug);
  const { formData, onChange } = useMedicationForm(medicationSummary?.medication);

  if (!selectedDrug) {
    return (
      <MedicationFormSearch onSelectDrug={setSelectedDrug} />
    );
  }

  return (
    <MedicationFormDetails
      submitLabel={submitLabel}
      onSubmit={onSubmit}
      formData={formData}
      onChange={onChange}
      drug={selectedDrug}
    />
  );
};

export default MedicationForm;

import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useProfileForm from "../hooks/useProfileForm";
import theme from "../../../main/theme/theme";
import EditProfileForm from "../components/EditProfileForm";
import Snackbar from "../../../main/components/Snackbar";
import ChangePasswordForm from "../components/ChangePasswordForm";
import useChangePasswordForm from "../hooks/useChangePasswordForm";
import ChangePasswordFormData from "../domain/ChangePasswordFormData";
import { isStandaloneContext } from "main/selectors/AppContextSelector";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  myAccountText: {
    alignSelf: "flex-start",
    marginBottom: theme.metrics.margin.medium,
  },
  profileInformationText: {
    fontWeight: "bold",
    alignSelf: "flex-start",
  },
}));

const ProfilePage: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation("profile");
  const [snackbarText, setSnackbarText] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const onProfileUpdated = (): void => {
    setSnackbarText(t("profileUpdated"));
    setShowSnackbar(true);
  };

  const { profileFormData, onChange, onSubmit, errors, hasChanged } = useProfileForm({ onProfileUpdated });
  const onPasswordChange = (): void => {
    onChangePasswordChange(ChangePasswordFormData.empty());
    setSnackbarText(t("passwordChanged"));
    setShowSnackbar(true);
  };
  const {
    changePasswordFormData,
    onChange: onChangePasswordChange,
    onSubmit: onChangePasswordSubmit,
    errors: changePasswordErrors,
  } = useChangePasswordForm({ onPasswordChange });

  return (
    <>
      <div className={classes.container}>
        <Typography component="h2" variant="h3" color="textPrimary" className={classes.myAccountText}>
          {t("myAccount")}
        </Typography>

        <Typography component="h2" variant="h5" color="textPrimary" className={classes.profileInformationText}>
          {t("profileInformation")}
        </Typography>

        <EditProfileForm
          profileFormData={profileFormData}
          errors={errors}
          canSubmit={hasChanged}
          onChange={onChange}
          onSubmit={onSubmit}
        />

        {
          isStandaloneContext() &&
            <ChangePasswordForm
              changePasswordFormData={changePasswordFormData}
              errors={changePasswordErrors}
              onChange={onChangePasswordChange}
              onSubmit={onChangePasswordSubmit}
            />
        }
      </div>

      <Snackbar visible={showSnackbar} onClose={(): void => setShowSnackbar(false)} type="success">
        {snackbarText}
      </Snackbar>
    </>
  );
};

export default ProfilePage;

import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { Amplify, Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core";
import Router from "./router/Router";
import ClientConnectRouter from "./router/ClientConnectRouter";
import theme from "./main/theme/theme";
import MuiTheme from "./main/theme/MuiTheme";
import { GlobalStyle } from "./main/theme/Stylesheet";
import { amplifyConfig } from "./main/config/amplifyConfig";
import GlobalStateProvider from "./store/GlobalProvider";
import { createStore } from "./store/GlobalStore";
import useSessionService from "./main/services/useSessionService";
import analyticsClient from "./main/services/analytics";
import CrashReportingService from "./main/services/CrashReportingService";
import { isStandaloneContext } from "main/selectors/AppContextSelector";
import {
  BrowserRouter, useHistory, useLocation,
} from "react-router-dom";
import { SessionStorageKeys } from "main/domain/SessionStorageKeys";

CrashReportingService.init();

analyticsClient.initialize();

Amplify.configure({ ...amplifyConfig, Analytics: { disabled: true } });

const useStyles = makeStyles(() => ({
  appContainer: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    backgroundColor: theme.colors.surface,
  },
}));

const store = createStore();

const App: React.FunctionComponent = () => {
  const classes = useStyles();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { restoreSession } = useSessionService();

  Auth.configure({ authenticationFlowType: isStandaloneContext() ? "USER_PASSWORD_AUTH" : "CUSTOM_AUTH" });

  useEffect(() => {
    const params = new URLSearchParams(search);
    const keys = Object.values(SessionStorageKeys) as string[];

    params.forEach((value, key) => {
      if (keys.includes(key)) {
        sessionStorage.setItem(key, value);
      };
    });

    history.replace(pathname);

    restoreSession();
  // eslint-disable-next-line
  }, []);

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <div className={classes.appContainer}>
          {
            isStandaloneContext()
              ? <Router />
              : <ClientConnectRouter />
          }
        </div>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

const WrappedApp = (): JSX.Element => (
  <GlobalStateProvider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </GlobalStateProvider>
);

export default WrappedApp;

import React from "react";
import MeasureFormData from "../domain/MeasureFormData";
import DateTime from "../../main/dateTime/DateTime";
import { makeStyles, Typography } from "@material-ui/core";
import theme from "../../main/theme/theme";
import { SessionInput } from "./SessionInput";

interface Props {
  event: MeasureFormData;
  onChange: (event: MeasureFormData) => void;
}

const MeasureReminder: React.FunctionComponent<Props> = ({ event, onChange }) => {
  const classes = useStyles();

  const measureTime = event.displayMeasureEvent();

  const onTimeChange = (newValue: DateTime): void => {
    const updatedEvent = event.setTime(newValue);
    onChange(updatedEvent);
  };

  return (
    <SessionInput
      event={event}
      onTimeChange={onTimeChange}
      testID={measureTime}
    >
      <Typography component="label" variant="h4" className={classes.eventTitle}>
        {measureTime}
      </Typography>

    </SessionInput>
  );
};

export default MeasureReminder;

const useStyles = makeStyles(() => ({
  eventTitle: {
    margin: `${theme.metrics.margin.normal} 0`,
    fontWeight: "bold",
    fontSize: theme.fonts.h5.size,
  },
}));

import { ConsultationDocument } from "../domain/ConsultationDocument";
import React from "react";
import { TreatmentPlanRegimenSummary } from "prescribe/components/TreatmentPlanRegimenSummary";
import { ValidConsultationDocumentSummary } from "./ValidConsultationDocumentSummary";
import { carePlanTheme } from "prescribe/components/CarePlanSection";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";

interface Props {
  consultationDocuments: ConsultationDocument[];
  onDeleteClick: (onConfirm: () => void) => void;
  onDeleteConsultationDocument: (consultationDocument: ConsultationDocument) => void;
}

export const ValidConsultationDocuments = ({
  consultationDocuments,
  onDeleteClick,
  onDeleteConsultationDocument,
}: Props): JSX.Element => {
  const { t } = useTranslation("consultationDocument");
  const classes = useStyles();

  return (
    <ul className={classes.consultationDocuments}>
      {
        consultationDocuments.map((consultationDocument, index) => (
          <li
            key={consultationDocument.localId}
            className={classes.consultationDocumentsListItem}
            aria-label={`${consultationDocument.name} ${index}`}
          >
            <TreatmentPlanRegimenSummary
              isReadOnly={false}
              deleteTitle={t("deleteDocument")}
              onDeleteClick={(): void => onDeleteClick(
                () => onDeleteConsultationDocument(consultationDocument)
              )}
            >
              <div className={classes.spacer} />

              <ValidConsultationDocumentSummary name={consultationDocument.name} />
            </TreatmentPlanRegimenSummary>

          </li>
        ))
      }
    </ul>
  );
};

const useStyles = makeStyles(() => ({
  consultationDocuments: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  consultationDocumentsListItem: {
    "marginTop": theme.metrics.margin.medium,
    "&:first-child": {
      marginTop: 0,
    },
  },
  spacer: {
    width: carePlanTheme.spacerWidth + "px",
  },
}));

import {
  useEffect, useState, useCallback,
} from "react";
import DrugsApi from "../api/DrugsApi";
import SearchDrugs from "../domain/SearchDrugs";
import useLanguageSelection from "../../main/hooks/useLanguageSelection";
import SearchDrug from "../domain/SearchDrug";
import CrashReportingService from "../../main/services/CrashReportingService";

export const SearchCriteria = ["name", "din"] as const;
export type SearchCriteriaKeys = typeof SearchCriteria[number];

export interface UseDrugSearchResult {
  results: SearchDrug[];
  loading: boolean;
  startNewSearch: (newCriteria: SearchCriteriaKeys, searchText: string) => void;
  reset: () => void;
  getNextPage: () => void;
}

const NUMBER_OF_ITEMS = 10;
const MINIMUM_DIN_LENGTH_TO_START_PADDING = 6;
const DIN_LENGTH = 8;

const useDrugSearch = (): UseDrugSearchResult => {
  const [results, setResults] = useState<SearchDrug[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteriaKeys>("name");

  const { currentLanguage } = useLanguageSelection();

  const sanitizeDin = (din: string): string => {
    let filteredDin;
    filteredDin = din.replace(/[^0-9]/g, "");
    if (filteredDin.length < DIN_LENGTH) {
      filteredDin = filteredDin.padStart(DIN_LENGTH, "0");
    }
    return filteredDin;
  };

  const fetchByCriteria: Record<SearchCriteriaKeys, (text: string) => Promise<SearchDrugs>> = {
    name: (text) =>
      DrugsApi.searchByName({
        name: text,
        numberOfItems: NUMBER_OF_ITEMS,
        offset: results.length,
        language: currentLanguage,
      }),
    din: (text) =>
      DrugsApi.searchByDin({
        din: sanitizeDin(text),
        language: currentLanguage,
      }),
  };

  const fetchDrugs = useCallback(async (
    criteria: SearchCriteriaKeys,
    text: string
  ): Promise<void> => {
    try {
      setLoading(true);
      const newDrugs = await fetchByCriteria[criteria](text);

      if (newDrugs.drugs.length > 0) {
        setResults((oldDrugs: SearchDrug[]): SearchDrug[] => {
          const mergedDrugs = [...oldDrugs, ...newDrugs.drugs];
          return Array.from(
            new Map(mergedDrugs.map(
              (item: SearchDrug) => [item.id, item])
            ).values());
        });
      }
    } catch (e) {
      CrashReportingService.logError(e);
    } finally {
      setLoading(false);
    }
  }, [fetchByCriteria]);

  const startNewSearch = (criteria: SearchCriteriaKeys, text: string): void => {
    setSearchText(text);
    setSearchCriteria(criteria);
    setResults([]);
    setLoadMore(true);
  };

  useEffect(() => {
    if (
      !loadMore ||
      searchText.length === 0 ||
      (searchCriteria === "din" && searchText.length < MINIMUM_DIN_LENGTH_TO_START_PADDING)
    ) {
      return;
    }

    setLoadMore(false);
    fetchDrugs(searchCriteria, searchText);
  }, [fetchDrugs, loadMore, searchCriteria, searchText]);

  const reset = (): void => {
    setResults([]);
    setSearchText("");
    setLoading(false);
  };

  const getNextPage = (): void => {
    if (!searchText || searchText.length === 0) {
      setLoading(false);
      setResults([]);
      return;
    }

    if (results.length % NUMBER_OF_ITEMS === 0) {
      setLoadMore(true);
    }
  };

  return {
    results,
    getNextPage,
    loading,
    startNewSearch,
    reset,
  };
};

export default useDrugSearch;

import Weekday from "../../schedule/domain/Weekday";

const translations = {
  en: {
    weekdays: {
      [Weekday.SUNDAY]: "Sunday",
      [Weekday.MONDAY]: "Monday",
      [Weekday.TUESDAY]: "Tuesday",
      [Weekday.WEDNESDAY]: "Wednesday",
      [Weekday.THURSDAY]: "Thursday",
      [Weekday.FRIDAY]: "Friday",
      [Weekday.SATURDAY]: "Saturday",
    },
  },
  fr: {
    weekdays: {
      [Weekday.SUNDAY]: "Dimanche",
      [Weekday.MONDAY]: "Lundi",
      [Weekday.TUESDAY]: "Mardi",
      [Weekday.WEDNESDAY]: "Mercredi",
      [Weekday.THURSDAY]: "Jeudi",
      [Weekday.FRIDAY]: "Vendredi",
      [Weekday.SATURDAY]: "Samedi",
    },
  },
};

export default translations;

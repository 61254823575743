import React from "react";
import { AuthenticatorChildrenProps, AuthenticatorOverridingViewProps } from "aws-amplify-react";
import PasswordResetForm from "../resetPassword/containers/PasswordResetForm";
import usePasswordResetForm from "../resetPassword/hooks/usePasswordResetForm";

type Props = AuthenticatorOverridingViewProps;

type InjectedProps = Props & AuthenticatorChildrenProps;

interface CustomAuthenticatorProps {
  email: string;
  title: string;
}
const DEFAULT_AUTHENTICATOR_PROPS = { email: "" };

const AmplifiedResetPassword: React.FunctionComponent<Props> = (props) => {
  const { authData, authState, onStateChange } = props as InjectedProps;
  const { email, title } = authData as CustomAuthenticatorProps || DEFAULT_AUTHENTICATOR_PROPS;

  const goToLogin = (): void => onStateChange("signIn", {});
  const { resetPassword, errors, form, onChange } = usePasswordResetForm({ onSuccess: goToLogin });

  const submit = async (): Promise<void> => await resetPassword(email);

  if (authState !== "resetPassword") {
    return null;
  }

  return (
    <PasswordResetForm
      form={form}
      title={title}
      errors={errors}
      onSubmit={submit}
      onChange={onChange}
    />
  );
};

export default AmplifiedResetPassword;

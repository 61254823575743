import { DateTime as LuxonDateTime, Settings } from "luxon";
import { datetime } from "rrule";
import i18next from "i18next";

export type DurationUnit =
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "year";

class DateTime {
  private dateTime: string;

  private constructor(dateTime: string) {
    this.dateTime = dateTime;
  }

  public static setDefaultZone(zone: string): void {
    Settings.defaultZoneName = zone;
  }

  public static now(): DateTime {
    const dateTime = this.fromLuxon(LuxonDateTime.local());
    return new DateTime(dateTime);
  }

  public formatWithTimeZone(): DateTime {
    return new DateTime(
      this.toLuxon().setZone("UTC", { keepLocalTime: true }).toISO()
    );
  }

  public static fromISO(dateString: string): DateTime {
    return new DateTime(dateString);
  }

  public static fromFormat(dateString: string, format: string): DateTime {
    const dateTime = this.fromLuxon(
      LuxonDateTime.fromFormat(dateString, format)
    );

    return new DateTime(dateTime);
  }

  public static fromJSDate(date: Date): DateTime {
    const dateTime = this.fromLuxon(LuxonDateTime.fromJSDate(date));

    return new DateTime(dateTime);
  }

  public isSame(other: DateTime, granularity: DurationUnit): boolean {
    return this.toLuxon().hasSame(other.toLuxon(), granularity);
  }

  public isBefore(other: DateTime): boolean {
    return this.toLuxon() < other.toLuxon();
  }

  public isAfter(other: DateTime): boolean {
    return this.toLuxon() >= other.toLuxon();
  }

  public diff(other: DateTime, unit: DurationUnit): number {
    return this.toLuxon().diff(other.toLuxon()).as(unit);
  }

  public getDayOfWeek(): number {
    return this.toLuxon().weekday;
  }

  public getDayOfMonth(): number {
    return this.toLuxon().day;
  }

  public getDate(): Date {
    return this.toLuxon().toJSDate();
  }

  public getTimestamp(): number {
    return this.toLuxon().toMillis();
  }

  public toISO({
    includeOffset,
    suppressMilliseconds,
  }: { includeOffset?: boolean; suppressMilliseconds?: boolean } = {}): string {
    return this.toLuxon().toISO({ includeOffset, suppressMilliseconds });
  }

  public toISODate(): string {
    return this.toLuxon().toISODate();
  }

  public format(format: string = "yyyy-MM-dd HH:mm:ss"): string {
    return this.toLuxon().setZone("local").toFormat(format);
  }

  public formatLocale(format: Intl.DateTimeFormatOptions = LuxonDateTime.DATETIME_SHORT): string {
    const language = i18next.language.split("-")[0] + "-CA";
    return this.toLuxon().setZone("local").setLocale(language).toLocaleString(format);
  }

  public formatToUtc(): string {
    return this.toLuxon().setZone("utc", { keepLocalTime: true }).toISO();
  }

  public formatToLocalDateTime(): string {
    const formattedDate = this.format("yyyy-MM-dd")
      .concat("T")
      .concat(this.format("T"));
    if (formattedDate.substr(11, 2) === "24") {
      return formattedDate.slice(0, 11).concat("00:00");
    }
    return formattedDate;
  }

  public toUTC(): DateTime {
    return new DateTime(this.toLuxon().toUTC().toISO());
  }

  public isEqual(other: DateTime): boolean {
    return this.toISO() === other.toISO();
  }

  private toLuxon(): LuxonDateTime {
    return LuxonDateTime.fromISO(this.dateTime, { setZone: true });
  }

  private static fromLuxon(dateTime: LuxonDateTime): string {
    return dateTime.toISO({ includeOffset: true });
  }

  public static getCurrentTimezone(): string {
    return LuxonDateTime.local().zoneName;
  }

  public addDays(quantity: number): DateTime {
    return new DateTime(
      this.toLuxon().toLocal().plus({ days: quantity }).toUTC().toISO()
    );
  }

  public setTime(hours: number, minutes: number): DateTime {
    return new DateTime(
      this.toLuxon().toLocal().set({ hour: hours, minute: minutes }).toString()
    );
  }

  public getTime(): string {
    return this.dateTime.substr(11, 5);
  }

  public getHour(): number {
    return parseInt(this.dateTime.substr(11, 2), 10);
  }

  public getMinutes(): number {
    return parseInt(this.dateTime.substr(14, 2), 10);
  }

  public getRRuleDatetime(): Date {
    const date = this.toLuxon();
    return datetime(date.year, date.month, date.day, date.hour, date.minute, date.second);
  }

  public getInBetweenDates(otherDate: DateTime | null): DateTime[] {
    const inBetweenDates = [];
    if (otherDate) {
      let currentDate = new DateTime(this.toLuxon().toUTC().toISO());
      while (
        currentDate.isBefore(otherDate) ||
        currentDate.isEqual(otherDate)
      ) {
        inBetweenDates.push(currentDate);
        currentDate = currentDate.addDays(1);
      }
    }
    return inBetweenDates;
  }
}

export default DateTime;

import {
  ACCEPTED_FILE_EXTENSION,
  ConsultationDocument,
  MAX_FILE_SIZE_MB,
} from "../domain/ConsultationDocument";
import {
  ConsultationDocumentsFormData,
  createConsultationDocumentsFrom,
} from "../domain/ConsultationDocumentsFormData";
import React, { ChangeEvent, useRef } from "react";
import analyticsClient, { AnalyticCategory, AnalyticEvent } from "../../main/services/analytics";

import CarePlanSection from "prescribe/components/CarePlanSection";
import { ReactComponent as DocumentIcon } from "document/assets/document.svg";
import { FileInput } from "../../main/components/FileInput";
import { InvalidConsultationDocuments } from "./InvalidConsultationDocuments";
import { ValidConsultationDocuments } from "./ValidConsultationDocuments";
import { useTranslation } from "react-i18next";
import { ClientConnectImportedDocuments } from "./ClientConnectImportedDocuments";
import { ConsultationDocumentDto } from "document/api/dto/ConsultationDocumentDto";

interface Props {
  disabled?: boolean;
  consultationDocuments: ConsultationDocumentsFormData;
  onConsultationDocumentsChange: (consultationDocuments: ConsultationDocumentsFormData) => void;
  onDeleteClick: (onConfirm: () => void) => void;
}

const emptyInput = (event: ChangeEvent<HTMLInputElement>): void => {
  event.target.value = "";
};

export const ConsultationDocumentSection = ({
  disabled,
  consultationDocuments,
  onConsultationDocumentsChange,
  onDeleteClick,
}: Props): JSX.Element => {
  const { t } = useTranslation("consultationDocument");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const changeFiles = (event: ChangeEvent<HTMLInputElement>): void => {
    const fileList = event.currentTarget.files;

    if (!fileList) {
      return;
    }

    const fileListArray = Array.from(fileList);
    const newConsultationDocuments = createConsultationDocumentsFrom(fileListArray);

    const updatedConsultationDocuments: ConsultationDocumentsFormData = {
      valid: [...consultationDocuments.valid, ...newConsultationDocuments.valid],
      invalid: newConsultationDocuments.invalid,
      imported: consultationDocuments.imported,
    };

    onConsultationDocumentsChange(updatedConsultationDocuments);
    emptyInput(event);
  };

  const deleteConsultationDocument = (consultationDocument: ConsultationDocument): void => {
    const updatedConsultationDocuments = consultationDocuments.valid.filter(
      (consultationDocumentFormData) => consultationDocumentFormData.localId !== consultationDocument.localId
    );
    onConsultationDocumentsChange({ ...consultationDocuments, valid: updatedConsultationDocuments });
  };

  const deleteImportedDocument = (consultationDocument: ConsultationDocumentDto): void => {
    const imported = consultationDocuments.imported.filter(
      (consultationDocumentFormData) => consultationDocumentFormData !== consultationDocument
    );
    onConsultationDocumentsChange({ ...consultationDocuments, imported });
  };

  const deleteInvalidConsultationDocuments = (): void => {
    onConsultationDocumentsChange({ ...consultationDocuments, invalid: [] });
  };

  const onAddFileButtonClicked = (): void => {
    analyticsClient.logEvent(AnalyticCategory.CARE_PLAN, AnalyticEvent.ADD_DOCUMENT);
    fileInputRef.current?.click();
  };

  return (
    <CarePlanSection
      Icon={DocumentIcon}
      title={t("sectionTitle")}
      text={t("sectionSubText")}
      onClick={onAddFileButtonClicked}
      disabled={disabled}
      tooltipText={
        t("acceptedFormat", { formats: ACCEPTED_FILE_EXTENSION.join(" ") }) +
        "\n" +
        t("acceptedSize", { maxFileSize: MAX_FILE_SIZE_MB })
      }
    >
      <FileInput
        hidden
        multiple
        inputRef={fileInputRef}
        onValueChange={changeFiles}
        extensions={ACCEPTED_FILE_EXTENSION}
        title={t("uploadFiles")}
        disabled={disabled}
      />

      {consultationDocuments.invalid.length > 0 &&
        <InvalidConsultationDocuments
          consultationDocuments={consultationDocuments.invalid}
          onDeleteInvalidDocumentsErrors={deleteInvalidConsultationDocuments}
        />
      }

      {consultationDocuments.valid.length > 0 &&
        <ValidConsultationDocuments
          consultationDocuments={consultationDocuments.valid}
          onDeleteClick={onDeleteClick}
          onDeleteConsultationDocument={deleteConsultationDocument}
        />
      }

      {consultationDocuments.imported.length > 0 &&
        <ClientConnectImportedDocuments
          consultationDocuments={consultationDocuments.imported}
          onDeleteClick={onDeleteClick}
          onDeleteConsultationDocument={deleteImportedDocument}
        />
      }
    </CarePlanSection>
  );
};

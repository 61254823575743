import { useState, useCallback } from "react";
import DrugLabels from "../domain/DrugLabels";
import { fetchDrugLabels } from "../api/DrugSheetApi";

export interface UseDrugLabelsServiceResult {
  labelsLoading: boolean;
  getLabels: (din: string) => Promise<DrugLabels|null>;
}

const useDrugLabelsService = (language: string): UseDrugLabelsServiceResult => {
  const [cache, setDrugLabels] = useState<{drugId: string; labels: DrugLabels}[]>([]);
  const [labelsLoading, setLoading] = useState(true);

  const fromCache = useCallback((din: string): DrugLabels|undefined =>
    cache.find((drugLabels) => drugLabels.drugId === din)?.labels
  , [cache]);

  const getLabels = async (dinToFetch: string): Promise<DrugLabels|null> => {
    const labelsFromCache = fromCache(dinToFetch);
    if (!!labelsFromCache) {
      return labelsFromCache;
    }

    try {
      setLoading(true);
      const drugLabels = await fetchDrugLabels(dinToFetch, language);
      if (drugLabels) {
        setDrugLabels([...cache, { drugId: dinToFetch, labels: drugLabels }]);
      }
      setLoading(false);

      return drugLabels;
    } catch {
      setLoading(false);

      return null;
    }
  };

  return {
    labelsLoading,
    getLabels,
  };
};

export default useDrugLabelsService;

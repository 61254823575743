import Measure, { MeasureAttributes } from "../Measure";
import { Severity } from "./Severity";

interface MigraineMeasureAttributes extends MeasureAttributes {
  value: Severity;
}

class MigraineMeasure extends Measure {

  public readonly severity: Severity;

  constructor({ id, value, actualTimestamp }: MigraineMeasureAttributes) {
    super({ id, actualTimestamp });
    this.severity = value;
  }

}

export default MigraineMeasure;

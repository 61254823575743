import React from "react";
import { Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import AlertCard from "../../../main/components/AlertCard";
import AuthenticationIcon from "../../components/AuthenticationIcon";
import useProfileForm from "../hooks/useProfileForm";
import ProfileForm from "./ProfileForm";
import { useCompleteProfileStyles } from "../styles/CompleteProfileStyle";

const CompleteProfileForm: React.FunctionComponent = () => {
  const classes = useCompleteProfileStyles();
  const { t } = useTranslation("profile");
  const { profileFormData, errors, onChange, onSubmit } = useProfileForm();

  return (
    <div className={classes.container}>
      <AlertCard className={classes.alertCard}>
        <Trans
          t={t}
          i18nKey="congratulationsMessage"
          components={[
            <Typography
              key="0"
              component="span"
              variant="h5"
              display="block"
              className={classes.boldMessage}
            />,
            <Typography
              key="1"
              component="span"
              variant="h5"
              display="block"
            />,
          ]}
        />
      </AlertCard>

      <AuthenticationIcon className={classes.createProfileIcon} />

      <Typography component="h2" variant="h4" className={classes.createProfileText}>
        {t("createProfile")}
      </Typography>

      <ProfileForm
        profileFormData={profileFormData}
        errors={errors}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default CompleteProfileForm;

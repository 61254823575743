import Reasons from "../domain/Reasons";

const translations = {
  en: {
    reasons: {
      [Reasons.ALZHEIMER_DEMENTIA]: "Alzheimer, dementia",
      [Reasons.ARTHRITIS]: "Arthritis",
      [Reasons.ASTHMA]: "Asthma",
      [Reasons.ATTENTION_HYPERACTIVITY_DEFICIT]: "Attention and/or hyperactivity deficit ",
      [Reasons.CANCER]: "Cancer",
      [Reasons.CHOLESTEROL]: "Cholesterol",
      [Reasons.COPD_RESPIRATORY_PROBLEMS]: "COPD (respiratory problems)",
      [Reasons.DEPRESSION_ANXIETY_MOOD_DISORDERS]: "Depression, anxiety, mood disorders",
      [Reasons.DIABETES]: "Diabetes",
      [Reasons.EPILEPSY_SEIZURES]: "Epilepsy, seizures",
      [Reasons.GERD_GASTRO_ESOPHAGEAL_REFLUX]: "GERD (gastro-esophageal reflux)",
      [Reasons.HEART_DISEASE_CARDIOVASCULAR]: "Heart disease (cardiovascular)",
      [Reasons.HYPERTENSION_BLOOD_PRESSURE]: "Hypertension (blood pressure)",
      [Reasons.OSTEOPOROSIS]: "Osteoporosis",
      [Reasons.THYROID]: "Thyroid ",
      [Reasons.ALLERGY]: "Allergy",
      [Reasons.ALS]: "ALS",
      [Reasons.AUTISM]: "Autism",
      [Reasons.BRONCHITIS]: "Bronchitis",
      [Reasons.CEREBRAL_PALSY]: "Cerebral palsy",
      [Reasons.CONDITION_RELATED_TO_FEMALE]: "Condition related to female",
      [Reasons.CONDITION_RELATED_TO_MALE]: "Condition related to male",
      [Reasons.CROHNS_AND_COLITIS]: "Crohn’s and colitis",
      [Reasons.CVA_CEREBROVASCULAR_ACCIDENT]: "CVA (cerebrovascular accident)",
      [Reasons.CYSTIC_FIBROSIS]: "Cystic fibrosis",
      [Reasons.DIGESTIVE_SYSTEM]: "Digestive system",
      [Reasons.EYES]: "Eyes",
      [Reasons.HEART_FAILURE]: "Heart failure",
      [Reasons.HEMOPHILIA]: "Hemophilia",
      [Reasons.HEPATITIS]: "Hepatitis",
      [Reasons.HIV_AIDS]: "HIV / AIDS",
      [Reasons.HUNTINGTON]: "Huntington",
      [Reasons.INFECTION_EAR_URINARY]: "Infection (ear, urinary, etc.)",
      [Reasons.INFLUENZA]: "Influenza",
      [Reasons.INJURY]: "Injury",
      [Reasons.KIDNEY_DISEASE]: "Kidney disease",
      [Reasons.LEUKEMIA]: "Leukemia",
      [Reasons.LIVER]: "Liver",
      [Reasons.LYME]: "Lyme",
      [Reasons.MENOPAUSE_SYMPTOMS]: "Menopause symptoms",
      [Reasons.MULTIPLE_SCLEROSIS]: "Multiple sclerosis",
      [Reasons.MUSCULAR_DYSTROPHY]: "Muscular dystrophy",
      [Reasons.NAIL_FUNGUS]: "Nail fungus",
      [Reasons.OBESITY_AND_BODY_WEIGHT]: "Obesity and body weight",
      [Reasons.PAIN_BACK_OR_NECK]: "Pain - back or neck",
      [Reasons.PAIN_CHRONIC_MALAISE_FATIGUE]: "Pain - chronic, discomfort, fatigue",
      [Reasons.PARKINSON]: "Parkinson",
      [Reasons.PREGNANCY]: "Pregnancy",
      [Reasons.SCHIZOPHRENIA]: "Schizophrenia",
      [Reasons.SEXUALLY_TRANSMITTED_DISEASES]: "Sexually transmitted diseases",
      [Reasons.SINUSITIS]: "Sinusitis",
      [Reasons.SKIN]: "Skin",
      [Reasons.SORE_THROAT]: "Sore throat",
      [Reasons.SUBSTANCE_ABUSE]: "Substance abuse",
      OTHER: "Other",
    },
  },
  fr: {
    reasons: {
      [Reasons.ALZHEIMER_DEMENTIA]: "Alzheimer, démence",
      [Reasons.ARTHRITIS]: "Arthrite",
      [Reasons.ASTHMA]: "Asthme",
      [Reasons.ATTENTION_HYPERACTIVITY_DEFICIT]: "Déficit d'attention et/ou hyperactivité",
      [Reasons.CANCER]: "Cancer",
      [Reasons.CHOLESTEROL]: "Cholestérol",
      [Reasons.COPD_RESPIRATORY_PROBLEMS]: "MPOC (problèmes respiratoires)",
      [Reasons.DEPRESSION_ANXIETY_MOOD_DISORDERS]: "Dépression, anxiété, troubles de l'humeur",
      [Reasons.DIABETES]: "Diabète",
      [Reasons.EPILEPSY_SEIZURES]: "Épilepsie, convulsions",
      [Reasons.GERD_GASTRO_ESOPHAGEAL_REFLUX]: "RGO (reflux gastro-oesophagien)",
      [Reasons.HEART_DISEASE_CARDIOVASCULAR]: "Maladie cardiovasculaire",
      [Reasons.HYPERTENSION_BLOOD_PRESSURE]: "Hypertension (pression artérielle)",
      [Reasons.OSTEOPOROSIS]: "Ostéoporose",
      [Reasons.THYROID]: "Thyroïde ",
      [Reasons.ALLERGY]: "Allergie",
      [Reasons.ALS]: "SLA",
      [Reasons.AUTISM]: "Autisme",
      [Reasons.BRONCHITIS]: "Bronchite",
      [Reasons.CEREBRAL_PALSY]: "Paralysie cérébrale",
      [Reasons.CONDITION_RELATED_TO_FEMALE]: "Condition liée à la femme",
      [Reasons.CONDITION_RELATED_TO_MALE]: "Condition liée à l'homme",
      [Reasons.CROHNS_AND_COLITIS]: "Crohn et colite",
      [Reasons.CVA_CEREBROVASCULAR_ACCIDENT]: "AVC (accident vasculaire cérébral)",
      [Reasons.CYSTIC_FIBROSIS]: "Fibrose kystique",
      [Reasons.DIGESTIVE_SYSTEM]: "Système digestif",
      [Reasons.EYES]: "Yeux",
      [Reasons.HEART_FAILURE]: "Arrêt cardiaque",
      [Reasons.HEMOPHILIA]: "Hémophilie",
      [Reasons.HEPATITIS]: "Hépatite",
      [Reasons.HIV_AIDS]: "VIH / SIDA",
      [Reasons.HUNTINGTON]: "Huntington",
      [Reasons.INFECTION_EAR_URINARY]: "Infection (oreille, urinaire, etc.)",
      [Reasons.INFLUENZA]: "Grippe",
      [Reasons.INJURY]: "Blessure",
      [Reasons.KIDNEY_DISEASE]: "Reins",
      [Reasons.LEUKEMIA]: "Leucémie",
      [Reasons.LIVER]: "Foie",
      [Reasons.LYME]: "Lyme",
      [Reasons.MENOPAUSE_SYMPTOMS]: "Ménopause",
      [Reasons.MULTIPLE_SCLEROSIS]: "Sclérose en plaque",
      [Reasons.MUSCULAR_DYSTROPHY]: "Dystrophie musculaire",
      [Reasons.NAIL_FUNGUS]: "Ongles (champignons)",
      [Reasons.OBESITY_AND_BODY_WEIGHT]: "Obésité et poids corporel",
      [Reasons.PAIN_BACK_OR_NECK]: "Douleur - au dos ou au cou",
      [Reasons.PAIN_CHRONIC_MALAISE_FATIGUE]: "Douleur - chronique, malaise, fatigue",
      [Reasons.PARKINSON]: "Parkinson",
      [Reasons.PREGNANCY]: "Grossesse",
      [Reasons.SCHIZOPHRENIA]: "Schizophrénie",
      [Reasons.SEXUALLY_TRANSMITTED_DISEASES]: "Maladie transmise sexuellement",
      [Reasons.SINUSITIS]: "Sinusite",
      [Reasons.SKIN]: "Peau",
      [Reasons.SORE_THROAT]: "Gorge",
      [Reasons.SUBSTANCE_ABUSE]: "Abus de substance",
      OTHER: "Autre",
    },
  },
};

export default translations;

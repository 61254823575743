import Client from "../../../main/services/ApiClient";
import Profile from "../domain/Profile";
import ProfileAssembler from "./ProfileAssembler";
import ProfileResponse from "./responses/ProfileResponse";

class ProfileApi {

  public static async fetchProfile(): Promise<Profile | undefined> {
    try {
      const response = await new Client({
        endpoint: "/doctors/profile",
      })
        .get<ProfileResponse>();

      return ProfileAssembler.fromResponse(response);
    } catch (e) {
      if (e.status === 404) {
        return undefined;
      }

      throw e;
    }
  }
}
export default ProfileApi;

import { Language } from "rrule/dist/esm/nlp/i18n";
import { TokenTranslations } from "./types";

export const dates: Language = {
  dayNames: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thurday",
    "Friday",
    "Saturday",
  ],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  // `tokens` are only needed for `RRule.fromText`
  tokens: {},
};

export const tokens: TokenTranslations = {
  "every": "every",
  "until": "until",
  "day": "day",
  "days": "days",
  "week": "week",
  "weeks": "weeks",
  "on": "on",
  "month": "month",
  "months": "months",
  "and": "and",
  "on the": "on the",
  "(~ approximately)": "(~ approximately)",
  "at": "at",
  "for": "for",
  "hour": "hour",
  "hours": "hours",
  "in": "in",
  "last": "last",
  "minutes": "minutes",
  "or": "or",
  "st": "st",
  "nd": "nd",
  "rd": "rd",
  "th": "th",
  "the": "the",
  "time": "time",
  "times": "times",
  "weekday": "weekday",
  "weekdays": "weekdays",
  "year": "year",
  "years": "years",
};

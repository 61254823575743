import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import theme from "../../main/theme/theme";
import { FooterLink } from "../../main/components/FooterLink";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.metrics.margin.normal,
  },
  text: {
    fontSize: theme.fonts.legal.size,
  },
  poweredByWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    whiteSpace: "pre-wrap",
  },
}));

const ReportFooter: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation("report");

  return (
    <div>
      <Divider variant="fullWidth" />

      <Grid container className={classes.container}>
        <Grid item xs={12} sm={6}>
          <Typography component="p" color="textSecondary" className={classes.text}>
            {t("disclaimer")}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.poweredByWrapper}>
          <Trans
            t={t}
            i18nKey="poweredByMedHelper"
            components={[
              <Typography key="0" component="span" color="textSecondary" className={classes.text} />,
              <FooterLink key="1" href={t("MedHelperWebsite")} className={classes.text} />,
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportFooter;

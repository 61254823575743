import UserSignUpFormData from "../../signup/domain/UserSignUpFormData";
import LogInFormData from "../../login/domain/LogInFormData";
import DateTime from "../../../main/dateTime/DateTime";
import User from "../../../main/domain/User";
import { CognitoUser } from "amazon-cognito-identity-js";

export const PASSWORD_CHANGE_DAYS_THRESHOLD = 90;

interface AuthenticationService {
  getUser: () => Promise<User>;
  getUserToken: () => Promise<string>;
  signUp: (formData: UserSignUpFormData, agreementDate: DateTime) => Promise<void>;
  confirmSignUp: (email: string, code: string) => Promise<void>;
  resendConfirmationCode: (email: string) => Promise<void>;
  login: (formData: LogInFormData, onRequireNewPassword: (user: CognitoUser) => void) => Promise<void>;
  logout: () => Promise<void>;
  requestPasswordReset: (email: string) => Promise<void>;
  isRequiredToChangePassword: (latestPasswordChange: string) => boolean;
  resetPassword: (email: string, code: string, password: string) => Promise<void>;
  completeNewPassword: (user: CognitoUser, newPassword: string) => Promise<void>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>;
}

export default AuthenticationService;

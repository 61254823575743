import PrescribeError from "./PrescribeError";

interface PrescribeFormErrorsResult {
  email?: PrescribeError[];
  birthDate?: PrescribeError[];
}

export class PrescribeFormErrors {

  public readonly email: PrescribeError[];
  public readonly birthDate: PrescribeError[];

  constructor({ email, birthDate }: PrescribeFormErrorsResult = {}) {
    this.email = email || [];
    this.birthDate = birthDate || [];
  }

  public static empty(): PrescribeFormErrors {
    return new PrescribeFormErrors({ email: [], birthDate: [] });
  }

  public isEmpty(): boolean {
    return this.email.length === 0 && this.birthDate.length === 0;
  }

  public add(field: keyof PrescribeFormErrorsResult, error: PrescribeError): PrescribeFormErrors {
    return new PrescribeFormErrors({ ...this, [field]: [...this[field], error] });
  }
}

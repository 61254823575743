import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Images from "../assets/Images";

const useStyles = makeStyles(() => ({
  avatar: (props: { size: number; avatarUrl: string }): any => ({
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    border: "solid 1px gray",
    minWidth: props.size,
    height: props.size,
    backgroundImage: `url('${props.avatarUrl}')`,
  }),
}));

interface Props {
  size?: number;
  picture: string|null;
  className?: string;
}

const Avatar: React.FunctionComponent<Props> = ({ size = 70, picture, className }) => {
  const avatarUrl = picture || Images.ProfessionalIcon;

  const classes = useStyles({ avatarUrl, size });

  return (
    <div className={[classes.avatar, className].join(" ")} />
  );
};

export default Avatar;

import DateTime from "../../../main/dateTime/DateTime";
import NoteRegimenType from "./NoteRegimenType";

export interface NoteAttributes {
  identifier: string;
  regimenName?: string;
  regimenType?: NoteRegimenType;
  createdAt: DateTime;
  text: string;
}

class Note {

  public readonly identifier: string;
  public readonly regimenName?: string;
  public readonly regimenType?: NoteRegimenType;
  public readonly createdAt: DateTime;
  public readonly text: string;

  constructor({ identifier, regimenName, createdAt, text, regimenType }: NoteAttributes) {
    this.identifier = identifier;
    this.regimenName = regimenName;
    this.regimenType = regimenType;
    this.createdAt = createdAt;
    this.text = text;
  }
}

export default Note;

import React from "react";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as BloodPressureMeasurementIcon } from "../../measurement/assets/bloodPressure.svg";
import TreatmentRowPicture from "./TreatmentRowPicture";

const useStyles = makeStyles(() => ({
  icon: {
    width: "90%",
    maxHeight: "80%",
    height: "auto",
  },
}));

export const BloodPressureMeasurementIconWrapper: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <TreatmentRowPicture>
      <BloodPressureMeasurementIcon className={classes.icon} />
    </TreatmentRowPicture>
  );
};

export default BloodPressureMeasurementIconWrapper;


import React, { useState } from "react";
import {
  Collapse,
  makeStyles, Switch, Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../main/theme/theme";
import NumericInput from "../../main/components/NumericInput";
import AlarmFormData, { ALARM_INTERVAL_MAX_VALUE, ALARM_INTERVAL_MIN_VALUE } from "../../prescribe/domain/AlarmFormData";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
  },
  sectionTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: `${theme.metrics.margin.small} 0`,
  },
  sectionTitle: {
    fontWeight: "bold",
  },
  collapseContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  numericInput: {
    alignItems: "center",
    display: "flex",
    flex: 1,
  },
  minutesLabel: {
    marginLeft: `${theme.metrics.margin.small}`,
  },
}));

interface Props {
  id: string;
  alarm: AlarmFormData | null;
  onChange: (alarm: AlarmFormData | null) => void;
}

const AlarmSection: React.FC<Props> = ({
  id,
  alarm,
  onChange,
}: Props) => {
  const [isOpen, setOpen] = useState(alarm !== null);
  const classes = useStyles();
  const { t } = useTranslation("schedule");

  const change = (newValue: number | null): void => {
    if (newValue) {
      onChange({ ...alarm, intervalInMinutes: newValue });
    } else {
      onChange(null);
    }
  };

  const toggle = (): void => {
    if (!isOpen) {
      onChange({ intervalInMinutes: ALARM_INTERVAL_MIN_VALUE });
    } else {
      onChange(null);
    }
    setOpen(!isOpen);
  };

  return (
    <div className={classes.container}>
      <div className={classes.sectionTitleContainer}>
        <Typography className={classes.sectionTitle} variant="h5" id={`alarmSectionTitle-${id}`}>
          {t("setAnAlarm")}
        </Typography>

        <Switch
          checked={isOpen}
          inputProps={{
            "aria-labelledby": `alarmSectionTitle-${id}`,
          }}
          color="primary"
          onChange={toggle}
        />
      </div>

      <section aria-labelledby={`alarmSectionTitle-${id}`} aria-expanded={isOpen}>
        <Collapse in={isOpen}>
          <div className={classes.collapseContent}>
            <NumericInput
              minValue={ALARM_INTERVAL_MIN_VALUE}
              maxValue={ALARM_INTERVAL_MAX_VALUE}
              title={t("repeatEvery")}
              value={alarm?.intervalInMinutes || ALARM_INTERVAL_MIN_VALUE}
              onValueChange={change}
              className={classes.numericInput}
              id={`alarmSectionTitle-${id}`}
            />

            <Typography className={classes.minutesLabel} variant="h4">
              {t("minutes")}
            </Typography>
          </div>
        </Collapse>
      </section>
    </div>
  );
};

export default AlarmSection;

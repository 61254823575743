interface ChangePasswordFormDataFields {
  currentPassword: string;
  newPassword: string;
}

class ChangePasswordFormData {
  public currentPassword: string;
  public newPassword: string;

  public static empty(): ChangePasswordFormData {
    return new ChangePasswordFormData({
      currentPassword: "",
      newPassword: "",
    });
  }

  constructor({ currentPassword, newPassword }: ChangePasswordFormDataFields) {
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
  }

  public isEmpty(): boolean {
    return !this.currentPassword && !this.newPassword;
  }
}

export default ChangePasswordFormData;

import React from "react";
import { FormGroup } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SupportedLanguages from "../../main/domain/SupportedLanguages";
import RadioButtonOption from "./RadioButtonOption";
import { PatientProfileField } from "./PatientProfileField";

interface Props {
  options: readonly SupportedLanguages[];
  value: SupportedLanguages;
  onChange: (language: SupportedLanguages) => void;
}

export const LanguageField: React.FunctionComponent<Props> = ({
  value,
  options,
  onChange,
}) => {
  const { t } = useTranslation("prescribe");

  return (
    <PatientProfileField name={t("communicationLanguage")}>
      <FormGroup row>
        {options.map((languageOption) => (
          <RadioButtonOption
            key={languageOption}
            value={languageOption}
            label={t(`languages.${languageOption}`)}
            checked={value === languageOption}
            onChange={onChange}
          />
        ))}
      </FormGroup>
    </PatientProfileField>
  );
};

export default LanguageField;

import React, { useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme, { pxToRem } from "../../../../main/theme/theme";
import AdherenceStatus from "../../../domain/AdherenceStatus";
import DailyAdherenceCalendar from "../../../domain/DailyAdherenceCalendar";
import AdherenceCalendar from "./AdherenceCalendar";
import AdherenceCalendarLegend from "./AdherenceCalendarLegend";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.metrics.margin.normal,
    borderLeft: `${theme.metrics.border.tiny} ${theme.colors.divider}`,
  },
  calendarsRow: {
    display: "flex",
    justifyContent: "space-around",
  },
  calendarWrapper: {
    width: pxToRem(90),
    height: pxToRem(90),
  },
  legendsRow: {
    display: "flex",
    justifyContent: "space-around",
    padding: `0 ${theme.metrics.margin.medium}`,
    marginTop: theme.metrics.margin.small,
  },
}));

const ROW_SIZE = 6;
const COLUMN_SIZE = 5;
const NUMBER_OF_DAYS_IN_GROUP = ROW_SIZE * COLUMN_SIZE;

interface Props {
  type: "medication" | "activity" | "measurement";
  calendar: DailyAdherenceCalendar;
}

const AdherenceCalendars: React.FunctionComponent<Props> = ({ type, calendar }) => {
  const classes = useStyles();
  const groups = useMemo(() => calendar.makeGroupsOf(NUMBER_OF_DAYS_IN_GROUP), [calendar]);

  return (
    <div className={classes.container}>
      <div className={classes.calendarsRow}>
        {groups.map((group, index) => (
          <div key={index} className={classes.calendarWrapper}>
            <AdherenceCalendar rowSize={ROW_SIZE} columnSize={COLUMN_SIZE} items={group} />
          </div>
        ))}
      </div>

      <div className={classes.legendsRow}>
        <AdherenceCalendarLegend status={AdherenceStatus.COMPLETED} type={type} />

        <AdherenceCalendarLegend status={AdherenceStatus.FAILED} type={type} />

        <AdherenceCalendarLegend status={AdherenceStatus.NOT_APPLICABLE} type={type} />
      </div>
    </div>
  );
};

export default AdherenceCalendars;

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core";
import { AuthenticationFooter } from "../components/AuthenticationFooter";
import AuthenticationHeader from "../components/AuthenticationHeader";
import Images from "../../main/assets/Images";
import theme, { pxToRem } from "../../main/theme/theme";
import { ReactComponent as MedhelperLogo } from "../../main/assets/MedhelperLogo.svg";
import MedhelperSubText from "../components/MedhelperSubText";

const useStyles = makeStyles((muiTheme: Theme) => ({
  container: {
    width: "100%",
    height: "100vh",
  },
  imageContainer: {
    backgroundImage: `url(${Images.Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "left 0",
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  formWrapper: {
    height: "100vh",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    [muiTheme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  footer: {
    padding: `0 ${theme.metrics.margin.huge}`,
    paddingBottom: theme.metrics.margin.medium,
  },
  backgroundText: {
    paddingTop: theme.metrics.margin.medium,
  },
  logo: {
    padding: `0 ${theme.metrics.margin.large}`,
  },
  subtextContainer: {
    borderTop: `${pxToRem(1)} solid white`,
    padding: `${theme.metrics.margin.small} ${theme.metrics.margin.large} 0 ${theme.metrics.margin.large}`,
  },
}));

const LOGO_WIDTH = 230;
const SUBTEXT_HEIGHT = 80;

const AuthenticationContainer: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      component="main"
    >
      <CssBaseline />

      <Hidden smDown>
        <Grid
          className={classes.imageContainer}
          item
          xs={false}
          sm={false}
          md={7}
        >
          <div className={classes.backgroundText}>
            <MedhelperLogo width={pxToRem(LOGO_WIDTH)} className={classes.logo} />

            <div className={classes.subtextContainer}>
              <MedhelperSubText height={pxToRem(SUBTEXT_HEIGHT)} />
            </div>
          </div>
        </Grid>
      </Hidden>

      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        component={Paper}
        className={classes.formWrapper}
      >
        <AuthenticationHeader />

        {children}

        <Hidden mdUp>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ textAlign: "center" }}
          >
            <img
              src={Images.BlueLogo}
              width="50%"
              alt="logo"
            />
          </Grid>
        </Hidden>

        <AuthenticationFooter className={classes.footer} />
      </Grid>
    </Grid>
  );
};

export default AuthenticationContainer;

import Measurement, { MeasurementAttributes } from "../Measurement";
import { MeasurementType } from "measurement/domain/MeasurementType";

export default class CustomMeasurement extends Measurement {

  constructor({ name, schedule, adherence, reportCode }: Omit<MeasurementAttributes, "type">) {
    super({ name, schedule, adherence, type: MeasurementType.CUSTOM, reportCode });
  }

  public displayName(): string {
    return this.name;
  }
}

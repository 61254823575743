import React from "react";
import { Redirect } from "react-router-dom";
import AuthenticationContainer from "../../containers/AuthenticationContainer";
import useAppExceptionRepository from "../../../store/hooks/useAppExceptionRepository";
import AppExceptions from "../../../main/components/AppExceptions";
import useUserRepository from "../../store/hooks/useUserRepository";
import NavigationPath from "router/NavigationPath";
import CompleteClientConnectProfileForm from "../components/CompleteClientConnectProfileForm";

const CompleteProfilePage: React.FunctionComponent = () => {
  const { errors } = useAppExceptionRepository();
  const { authenticated, user } = useUserRepository();

  if (!authenticated) {
    return <Redirect to={NavigationPath.AUTHENTICATION} />;
  }

  if (user?.agreementDate) {
    return <Redirect to={NavigationPath.PRESCRIBE} />;
  }

  return (
    <AuthenticationContainer>
      {
        errors.length > 0
          ? <AppExceptions />
          : <CompleteClientConnectProfileForm />
      }
    </AuthenticationContainer>
  );
};

export default CompleteProfilePage;

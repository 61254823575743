type Step = number;

interface Steps {
  [threshold: number]: Step;
}

class NumericStepper {
  public steps: Steps;

  constructor(steps?: Steps) {
    this.steps = steps || { 0: 1 };
  }

  public decrementValue = (value: number): number => {
    const closestThreshold = this.getCurrentStepThreshold(value);
    let closestStep = this.steps[closestThreshold];

    if (value === closestThreshold) {
      closestStep = this.steps[this.getPreviousThreshold(closestThreshold)];
    }

    return value - closestStep;
  };

  public incrementValue = (value: number): Step => value + this.steps[this.getCurrentStepThreshold(value)];

  public getCurrentStep = (value: number): Step => this.steps[this.getCurrentStepThreshold(value)];

  private getCurrentStepThreshold = (value: number): number => this.getThresholds().reduce((previous, current) =>
    value >= current ? current : previous,
  );

  private getPreviousThreshold = (value: number): Step => {
    const thresholds = this.getThresholds();
    const indexOfPreviousStep = thresholds.indexOf(value);

    const index = indexOfPreviousStep <= 0
      ? indexOfPreviousStep
      : indexOfPreviousStep - 1;

    return this.steps[thresholds[index]];
  };

  private getThresholds = (): number[] => Object.keys(this.steps).sort().map(Number);
}

export default NumericStepper;

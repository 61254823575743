import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../main/theme/theme";
import DateTime from "../../main/dateTime/DateTime";
import TimePicker from "../../main/components/inputs/TimePicker";
import SessionFormData from "../domain/SessionFormData";
import RequiredFieldLabel from "../../main/components/RequiredFieldLabel";

interface Props {
  event: SessionFormData;
  onTimeChange: (newValue: DateTime) => void;
  testID?: string;
}

export const SessionInput = ({ event, onTimeChange, testID, children }: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();

  return (
    <div className={classes.reminderContainer}>
      {children}

      <div className={classes.timeContainer}>
        <RequiredFieldLabel id="timePicker" label={t("time")} />

        <TimePicker
          time={event.time}
          onChange={onTimeChange}
          testID={testID}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  reminderContainer: {
    display: "flex",
    flexDirection: "column",
  },
  eventTitle: {
    margin: `${theme.metrics.margin.normal} 0`,
    fontWeight: "bold",
    fontSize: theme.fonts.h5.size,
  },
  timeText: {
    fontSize: theme.fonts.defaultSize,
    color: theme.colors.text.secondary,
  },
  timeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

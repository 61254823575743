import React from "react";
import MeasureFormData from "../domain/MeasureFormData";
import MeasureReminder from "./MeasureReminder";

interface Props {
  events: MeasureFormData[];
  onChange: (newEvent: MeasureFormData) => void;
}

const MeasuresSection: React.FC<Props> = ({ events, onChange }) => (
  <div>
    {events.map((measureEvent) => (
      <MeasureReminder event={measureEvent} key={measureEvent.id} onChange={onChange} />
    ))}
  </div>
);

export default MeasuresSection;

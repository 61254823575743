import { S3 } from "@aws-sdk/client-s3";
import { v4 as uuid } from "uuid";
import { DocumentClientType } from "./DocumentClientType";
import { ConsultationDocument } from "../domain/ConsultationDocument";
import { ConsultationDocumentDto } from "./dto/ConsultationDocumentDto";
import envConfig from "../../main/config/envConfig";

export class S3DocumentClient implements DocumentClientType {
  public readonly s3: S3;
  constructor() {
    this.s3 = new S3({
      region: envConfig.cognitoRegion,
      credentials: {
        accessKeyId: envConfig.documentBucketAccessKey,
        secretAccessKey: envConfig.documentBucketAccessKeySecret,
      },
    });
  }

  public async upload(document: ConsultationDocument): Promise<ConsultationDocumentDto> {
    const temporaryPrefix = "private/temp";
    const body = await this.extractBody(document);
    const key = `${temporaryPrefix}/${uuid()}/${document.name}`;
    const bucket = envConfig.documentBucketName;

    await this.s3.putObject({
      Bucket: bucket,
      Key: key,
      Body: body,
    });

    return { location: key, name: document.name };
  }

  private async extractBody(document: ConsultationDocument): Promise<Buffer> {
    const BYTE_OFFSET = 0;
    return await document.readFile().then((result) => Buffer.from(result, BYTE_OFFSET));
  }

}

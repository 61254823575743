import {
  useMemo, useCallback, useState,
} from "react";
import Consultation from "../../../domain/PreviousConsultation";
import useProfileRepository from "../../../../authentication/store/hooks/useProfileRepository";

export interface UseConsultationFilterResult {
  filterActive: boolean;
  filteredConsultations: Consultation[];
  toggleFilter: () => void;
}

const useConsultationFilter = (consultations: Consultation[]): UseConsultationFilterResult => {
  const { profile } = useProfileRepository();
  const [filterActive, setFilterActive] = useState(false);
  const toggleFilter = useCallback(() => {
    setFilterActive(!filterActive);
  }, [setFilterActive, filterActive]);

  const filteredConsultations = useMemo<Consultation[]>(() => {
    if (filterActive) {
      return consultations.filter((consultation: Consultation) =>
        profile && consultation.professionalIdentifier === profile.id
      );
    }
    return consultations;
  }, [consultations, filterActive, profile]);

  return {
    filteredConsultations,
    toggleFilter,
    filterActive,
  };
};

export default useConsultationFilter;

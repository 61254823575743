import React from "react";
import Measurement from "../domain/measurements/Measurement";
import TreatmentBloodGlucoseMeasurement from "./TreatmentBloodGlucoseMeasurement";
import BloodGlucoseMeasurement from "../domain/measurements/bloodGlucoseMeasurement/BloodGlucoseMeasurement";
import TreatmentCustomMeasurement from "./TreatmentCustomMeasurement";
import CustomMeasurement from "../domain/measurements/customMeasurement/CustomMeasurement";
import { MeasurementType } from "../../measurement/domain/MeasurementType";
import TreatmentMigraineMeasurement from "./TreatmentMigraineMeasurement";
import MigraineMeasurement from "../domain/measurements/migraineMeasurement/MigraineMeasurement";
import TreatmentMoodMeasurement from "./TreatmentMoodMeasurement";
import MoodMeasurement from "report/domain/measurements/moodMeasurement/MoodMeasurement";
import BloodPressureMeasurement from "../domain/measurements/bloodPressureMeasurement/BloodPressureMeasurement";
import TreatmentBloodPressureMeasurement from "./TreatmentBloodPressureMeasurement";

interface Props {
  measurement: Measurement;
  expanded: boolean;
  toggleExpanded: () => void;
}

export const TreatmentMeasurement = ({ measurement, expanded, toggleExpanded }: Props): JSX.Element | null => {
  switch (measurement.type) {
    case MeasurementType.BLOOD_GLUCOSE:
      return (
        <TreatmentBloodGlucoseMeasurement
          measurement={measurement as BloodGlucoseMeasurement}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
        />
      );
    case MeasurementType.MIGRAINE:
      return (
        <TreatmentMigraineMeasurement
          measurement={measurement as MigraineMeasurement}
          expanded={expanded}
          toggleExpanded={toggleExpanded} />
      );
    case MeasurementType.MOOD:
      return (
        <TreatmentMoodMeasurement
          measurement={measurement as MoodMeasurement}
          expanded={expanded}
          toggleExpanded={toggleExpanded} />
      );
    case MeasurementType.BLOOD_PRESSURE:
      return (
        <TreatmentBloodPressureMeasurement
          measurement={measurement as BloodPressureMeasurement}
          expanded={expanded}
          toggleExpanded={toggleExpanded} />
      );
    case MeasurementType.CUSTOM:
      return (
        <TreatmentCustomMeasurement
          measurement={measurement as CustomMeasurement}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
        />
      );
    default:
      return null;
  }
};

export default TreatmentMeasurement;

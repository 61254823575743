import { ConfirmSignUpError } from "../../../domain/ConfirmSignUpError";

export default class FailedToConfirmSignUpException extends Error {

  constructor(
    public readonly errorCode: ConfirmSignUpError,
  ) {
    super(`Failed to confirm sign up for user: ${errorCode}`);
  }
}

import React from "react";
import { Link, LinkProps } from "@material-ui/core";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme, { pxToRem } from "../theme/theme";

interface Props extends LinkProps {
  href: string;
}

export const FooterLink = ({ href, children, className }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Link className={classNames(classes.footerLink, className)} href={href}>
      {children}
    </Link>
  );
};

const useStyles = makeStyles(() => ({
  footerLink: {
    "textDecoration": "underline",
    "fontSize": pxToRem(15),
    "color": theme.colors.text.secondary,
    "&:hover": {
      color: theme.colors.text.emphasis,
    },
  },
}));

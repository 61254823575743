import DateTime from "../../../main/dateTime/DateTime";

export interface MeasureAttributes {
  id: string;
  actualTimestamp: DateTime;
}

abstract class Measure {
  public readonly actualTimestamp: DateTime;
  public readonly id: string;

  constructor({ id, actualTimestamp }: MeasureAttributes) {
    this.id = id;
    this.actualTimestamp = actualTimestamp;
  }
}

export default Measure;

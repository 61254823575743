enum PartnerCompany {
  IA = "IA",
  BENEVA = "BENEVA",
  MC_KESSON = "MC_KESSON",
  HUMANIS = "HUMANIS",
  PHARMASAVE_MFP = "PHARMASAVE_MFP",
  GROUP_BENEFITS = "GROUP_BENEFITS",
  PATIENT_ADVISORY_BOARD = "PATIENT_ADVISORY_BOARD",
  TEST_USERS_QC = "TEST_USERS_QC",
  FASKEN = "FASKEN",
  MEDESYNC = "MEDESYNC",
  INTACT = "INTACT",
  DENTITEK = "DENTITEK",
}

export default PartnerCompany;

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../../main/theme/theme";
import CustomProgramFormData from "../../domain/CustomProgramFormData";
import MeasuresForDayFactory from "../../domain/MeasuresForDayFactory";
import ScheduleSectionSummary from "../ScheduleSectionSummary";
import IconButton from "../../../main/components/buttons/IconButton";
import { ReactComponent as DeleteSvg } from "../../../main/assets/Delete.svg";
import { ReactComponent as EditSvg } from "../../../main/assets/Edit.svg";

const useStyles = makeStyles(() => ({
  bloodGlucoseTargetRangeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sectionTitle: {
    margin: `${theme.metrics.margin.medium} 0`,
  },
}));

interface Props {
  isReadOnly: boolean;
  customProgram: CustomProgramFormData;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const CustomProgramSummary: React.FunctionComponent<Props> = ({
  isReadOnly,
  customProgram,
  onEditClick,
  onDeleteClick,
}) => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();

  const scheduleMeasures = MeasuresForDayFactory.create(customProgram);

  return (
    <>
      <div className={classes.bloodGlucoseTargetRangeContainer}>
        <Typography component="label" variant="h4" color="primary" className={classes.sectionTitle}>
          {t("bloodGlucoseTargetRange")}
        </Typography>

        {
          !isReadOnly
          && <div>
            <IconButton
              title={t("editCustomProgram")}
              onClick={onEditClick}
              type="primary"
            >
              <EditSvg width={20} height={20} />
            </IconButton>

            <IconButton
              title={t("deleteCustomProgram")}
              onClick={onDeleteClick}
              type="danger"
            >
              <DeleteSvg width={16} height={16} />
            </IconButton>
          </div>
        }
      </div>

      <ScheduleSectionSummary scheduleMeasures={scheduleMeasures} />
    </>
  );
};

export default CustomProgramSummary;

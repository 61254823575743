import React from "react";
import {
  Typography, Button, makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ChangePasswordFormData from "../domain/ChangePasswordFormData";
import ChangePasswordFormErrors from "../domain/ChangePasswordFormErrors";
import PasswordField from "../../components/PasswordField";
import AuthenticationFormErrors from "../../components/AuthenticationFormErrors";
import AuthenticationForm from "../../components/AuthenticationForm";
import theme from "../../../main/theme/theme";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: theme.metrics.margin.small,
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    alignSelf: "flex-start",
  },
  input: {
    margin: `${theme.metrics.margin.small} 0`,
  },
}));

interface Props {
  changePasswordFormData: ChangePasswordFormData;
  errors: ChangePasswordFormErrors;
  onChange: (changePasswordFormData: any) => void;
  onSubmit: () => void;
}

export const ChangePasswordForm: React.FunctionComponent<Props> = ({
  changePasswordFormData,
  onChange,
  onSubmit,
  errors,
}) => {
  const { t } = useTranslation("profile");
  const classes = useStyles();

  const change = (field: string) => (newValue: string): void => {
    onChange(new ChangePasswordFormData({ ...changePasswordFormData, [field]: newValue }));
  };

  const submit = (e: React.ChangeEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <div className={classes.container}>
      <AuthenticationForm onSubmit={submit}>
        <Typography component="h2" variant="h5" color="textPrimary" className={classes.title}>
          {t("changePassword")}
        </Typography>

        <PasswordField
          id="currentPassword"
          name="currentPassword"
          label={t("currentPassword")}
          password={changePasswordFormData.currentPassword}
          className={classes.input}
          onChange={change("currentPassword")}
          error={errors.currentPassword.length > 0 ? t(`errors.${errors.currentPassword[0]}`) : ""}
        />

        <PasswordField
          id="newPassword"
          name="newPassword"
          label={t("newPassword")}
          password={changePasswordFormData.newPassword}
          className={classes.input}
          onChange={change("newPassword")}
          error={errors.newPassword.length > 0 ? t(`errors.${errors.newPassword[0]}`) : ""}
        />

        <AuthenticationFormErrors errors={errors.body.map((error) => t(`errors.${error}`))} />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={changePasswordFormData.isEmpty()}
        >
          {t("save")}
        </Button>
      </AuthenticationForm>
    </div>
  );
};

export default ChangePasswordForm;

import PendingTreatmentPlan, { createEmpty as createEmptyTreatmentPlan } from "./PendingTreatmentPlan";
import {
  ConsultationDocumentsFormData,
  createEmptyConsultationDocuments,
} from "../../document/domain/ConsultationDocumentsFormData";

interface CarePlanStepFormFields {
  instructions: string;
  pendingTreatmentPlan: PendingTreatmentPlan;
  consultationDocuments: ConsultationDocumentsFormData;
}

export default class CarePlanStepFormData {
  public readonly instructions: string;
  public readonly pendingTreatmentPlan: PendingTreatmentPlan;
  public readonly consultationDocuments: ConsultationDocumentsFormData;

  constructor({ instructions, pendingTreatmentPlan, consultationDocuments }: CarePlanStepFormFields) {
    this.instructions = instructions;
    this.pendingTreatmentPlan = pendingTreatmentPlan;
    this.consultationDocuments = consultationDocuments;
  }

  public static empty(): CarePlanStepFormData {
    return new CarePlanStepFormData({
      instructions: "",
      pendingTreatmentPlan: createEmptyTreatmentPlan(),
      consultationDocuments: createEmptyConsultationDocuments(),
    });
  }

  public withInstructions(instructions: string): CarePlanStepFormData {
    return new CarePlanStepFormData({ ...this, instructions });
  }

  public updatePendingTreatmentPlan(pendingTreatmentPlan: Partial<PendingTreatmentPlan>): CarePlanStepFormData {
    return new CarePlanStepFormData({
      ...this,
      pendingTreatmentPlan: {
        ...this.pendingTreatmentPlan,
        ...pendingTreatmentPlan,
      },
    });
  }

  public updateConsultationDocuments(consultationDocuments: ConsultationDocumentsFormData): CarePlanStepFormData {
    return new CarePlanStepFormData({
      ...this,
      consultationDocuments,
    });
  }

  public isFilled(): boolean {
    return this.instructions !== "";
  }
}

import Measure, { MeasureAttributes } from "../Measure";

interface CustomMeasureAttributes extends MeasureAttributes {
  value: string;
}

class CustomMeasure extends Measure {

  public readonly value: string;

  constructor({ id, value, actualTimestamp }: CustomMeasureAttributes) {
    super({ id, actualTimestamp });
    this.value = value;
  }

}

export default CustomMeasure;

import { makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Weekday from "../domain/Weekday";
import theme from "../../main/theme/theme";
import RequiredFieldLabel from "../../main/components/RequiredFieldLabel";
import DayToggleButton from "./DayToggleButton";

const useStyles = makeStyles(() => ({
  subtitle: {
    margin: `${theme.metrics.margin.normal} 0 0 0`,
  },
  weekdaysContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: `${theme.metrics.margin.normal} 0`,
  },
}));

interface Props {
  formData: Weekday[];
  onChange: (weekdays: Weekday[]) => void;
}

const WeeklyFrequencySection: React.FC<Props> = ({ formData, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation(["medication", "weekdays"]);

  const isLastWeekday = (weekday: Weekday): boolean =>
    formData.length === 1 && formData.includes(weekday);

  const updateWeekdays = (day: Weekday, checked: boolean): void => {
    if (isLastWeekday(day)) {
      return;
    }

    const updatedFormData = checked ? [...formData, day] : formData.filter((d) => d !== day);
    onChange(updatedFormData);
  };

  return (
    <>
      <div className={classes.subtitle}>
        <RequiredFieldLabel bold label={t("every")} />
      </div>

      <div className={classes.weekdaysContainer}>
        {Object.keys(Weekday).map((day: string): JSX.Element => {
          const isWeekdaySelected = formData.includes(day as Weekday);

          return (
            <DayToggleButton
              key={day}
              label={t(day)[0]}
              ariaLabel={t(day)}
              onToggle={(newValue: boolean): void => updateWeekdays(day as Weekday, newValue)}
              value={isWeekdaySelected}
            />
          );
        })}
      </div>
    </>
  );
};

export default WeeklyFrequencySection;

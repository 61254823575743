enum Reasons {
  ALZHEIMER_DEMENTIA = "ALZHEIMER_DEMENTIA",
  ARTHRITIS = "ARTHRITIS",
  ASTHMA = "ASTHMA",
  ATTENTION_HYPERACTIVITY_DEFICIT = "ATTENTION_HYPERACTIVITY_DEFICIT",
  CANCER = "CANCER",
  CHOLESTEROL = "CHOLESTEROL",
  COPD_RESPIRATORY_PROBLEMS = "COPD_RESPIRATORY_PROBLEMS",
  DEPRESSION_ANXIETY_MOOD_DISORDERS = "DEPRESSION_ANXIETY_MOOD_DISORDERS",
  DIABETES = "DIABETES",
  EPILEPSY_SEIZURES = "EPILEPSY_SEIZURES",
  GERD_GASTRO_ESOPHAGEAL_REFLUX = "GERD_GASTRO_ESOPHAGEAL_REFLUX",
  HEART_DISEASE_CARDIOVASCULAR = "HEART_DISEASE_CARDIOVASCULAR",
  HYPERTENSION_BLOOD_PRESSURE = "HYPERTENSION_BLOOD_PRESSURE",
  OSTEOPOROSIS = "OSTEOPOROSIS",
  THYROID = "THYROID",
  ALLERGY = "ALLERGY",
  ALS = "ALS",
  AUTISM = "AUTISM",
  BRONCHITIS = "BRONCHITIS",
  CEREBRAL_PALSY = "CEREBRAL_PALSY",
  CONDITION_RELATED_TO_FEMALE = "CONDITION_RELATED_TO_FEMALE",
  CONDITION_RELATED_TO_MALE = "CONDITION_RELATED_TO_MALE",
  CROHNS_AND_COLITIS = "CROHNS_AND_COLITIS",
  CVA_CEREBROVASCULAR_ACCIDENT = "CVA_CEREBROVASCULAR_ACCIDENT",
  CYSTIC_FIBROSIS = "CYSTIC_FIBROSIS",
  DIGESTIVE_SYSTEM = "DIGESTIVE_SYSTEM",
  EYES = "EYES",
  HEART_FAILURE = "HEART_FAILURE",
  HEMOPHILIA = "HEMOPHILIA",
  HEPATITIS = "HEPATITIS",
  HIV_AIDS = "HIV_AIDS",
  HUNTINGTON = "HUNTINGTON",
  INFECTION_EAR_URINARY = "INFECTION_EAR_URINARY",
  INFLUENZA = "INFLUENZA",
  INJURY = "INJURY",
  KIDNEY_DISEASE = "KIDNEY_DISEASE",
  LEUKEMIA = "LEUKEMIA",
  LIVER = "LIVER",
  LYME = "LYME",
  MENOPAUSE_SYMPTOMS = "MENOPAUSE_SYMPTOMS",
  MULTIPLE_SCLEROSIS = "MULTIPLE_SCLEROSIS",
  MUSCULAR_DYSTROPHY = "MUSCULAR_DYSTROPHY",
  NAIL_FUNGUS = "NAIL_FUNGUS",
  OBESITY_AND_BODY_WEIGHT = "OBESITY_AND_BODY_WEIGHT",
  PAIN_BACK_OR_NECK = "PAIN_BACK_OR_NECK",
  PAIN_CHRONIC_MALAISE_FATIGUE = "PAIN_CHRONIC_MALAISE_FATIGUE",
  PARKINSON = "PARKINSON",
  PREGNANCY = "PREGNANCY",
  SCHIZOPHRENIA = "SCHIZOPHRENIA",
  SEXUALLY_TRANSMITTED_DISEASES = "SEXUALLY_TRANSMITTED_DISEASES",
  SINUSITIS = "SINUSITIS",
  SKIN = "SKIN",
  SORE_THROAT = "SORE_THROAT",
  SUBSTANCE_ABUSE = "SUBSTANCE_ABUSE",
}

export default Reasons;

import React from "react";
import ReactHtmlParser from "react-html-parser";

interface Props {
  text: string;
}

export const InstructionHtmlContent = ({ text }: Props): JSX.Element => (
  <>
    {ReactHtmlParser(text)}
  </>
);


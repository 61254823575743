import "chartjs-adapter-luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  makeStyles, MenuItem, Select, FormControl,
} from "@material-ui/core";
import ChartDateRange from "../domain/ChartDateRange";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  select: {
    margin: theme.metrics.margin.small,
  },
}));

interface Props {
  value: ChartDateRange;
  onChartDateRangeChange: (value: ChartDateRange) => void;
}

export const ChartDateRangeSelect = ({ value, onChartDateRangeChange }: Props): JSX.Element => {
  const { t } = useTranslation("report");
  const classes = useStyles();

  return (
    <FormControl size="small">
      <Select
        labelId="chartDateRange"
        variant="outlined"
        value={value}
        className={classes.select}
        onChange={(event): void => onChartDateRangeChange(parseInt(event.target.value as string))}
      >
        {(Object.keys(ChartDateRange)
          .filter((key) => !isNaN(Number(key)))
          .map((chartDateRange) => (
            <MenuItem value={chartDateRange} key={chartDateRange}>
              {t(`chartDateRange.${chartDateRange}`)}
            </MenuItem>
          )))}
      </Select>
    </FormControl>

  );
};


/* eslint-disable */
const privacyPolicy =
  `<0>MEDHELPER FOR PROFESSIONALS PRIVACY STATEMENT</0>
Effective Date: July 21, 2022

At MedHelper Inc. (“MedHelper”), your privacy is important to us. The purpose of this privacy statement is to let you know how we collect, use and disclose Personal Information, and to inform you of your rights with respect to such Personal Information. It is effective as of the Effective Date.

<0>ABOUT MEDHELPER</0>
MedHelper for Professionals is an online portal where Doctors and other healthcare professionals can view patient reported data (the “Service”).
Our Services are not intended for use by children and should only be accessed by individuals who are at least 18 years old and are using the Services for business purposes.

<0>WHAT INFORMATION WE COLLECT</0>
We collect information about you as reasonably necessary for the following activities:

Using our Services

We collect the following information when you use our Services:

Account information:

Your contact and profile information including your name, company, email address, office phone number, office address and profession; your preferences such as language, time zone; and image (if you choose to provide this).

Billing and other payment information, including payment method details, such as credit card number.

The Services you have acquired from us, including the type of plan and transaction information related to the Services.

Content:

Logs, usage, and support data:

Log data, which may include your IP address, the address of the web page you visited before using the Services, your browser type and settings, your device information (such as make, model, and OS), the date and time when you used the Services, information about your browser configuration, language preferences, unique identifiers, and cookies.

Usage data and analytics, which may include the frequency of login, and the different types of activity undertaken by users.

General Location information, such as IP address and the region in which you are located when you are logging in and using the Services.

Customer support questions, issues, and general feedback that you choose to provide.

<0>DISCLOSURE OF PERSONAL INFORMATION</0>
MedHelper may share Personal Information with people within the company who have a “need to know” the information for business or legal reasons, for example, in order to carry out an administrative function, such as processing an invoice or supporting questions that you have submitted to MedHelper. We may share Personal Information with third parties, including:
government and regulatory authorities, for example to respond to a legal request or comply with a legal obligation, in which case we will make reasonable efforts to give the relevant individual notice of the disclosure, provided we are able to identify the individual and are lawfully able to do so;
for the purposes of seeking legal or other professional advice;

suppliers of IT services and third service providers engaged by MedHelper as further detailed in this Privacy Statement and in the event that we sell, buy or merge any business or assets, including to the prospective seller or buyer of such business or assets and their respective professional advisers.

We may also share anonymous or de-identified information with other third parties in connection with the purposes outlined in this Privacy Statement.

<0>RIGHT OF ACCESS</0>
Pursuant to applicable law, you may have certain rights in relation to your Personal Information, including a right of access. We may require additional information to confirm your identity, which will only be used for that purpose.

<0>DATA LOCATION</0>
MedHelper uses Amazon Web Services to store and manage Customer Information from Canada.

<0>INFORMATION COLLECTED VIA OUR WEBSITE</0>
When you visit our website, https://mfp.medhelper.com, we may use cookies, pixel tags, analytics tools, and other similar technologies to help provide and improve our services to you, and as detailed below:

Cookies: Cookies are pieces of information stored directly on the computer that you are using. Cookies allow us to collect information such as browser type, time spent on the website, pages visited, language preferences, and other web traffic data. We use the information for security purposes, to facilitate online navigation, to display information more effectively, to personalize your experience while using the website, and to otherwise analyze user activity. We can recognize your computer to assist your use of the website.

We also gather statistical information about the usage of the website and application in order to continually improve their design and functionality, understand how they are used, and assist us with resolving questions regarding the website and application. Cookies may further allow us to select which of our advertisements, features or offers are most likely to appeal to you and to display them to you. We may also use cookies in online advertising, features or offers to see how you interact with such advertisements, features or offers and we may use cookies or other files to understand your use of other websites.

If you do not want information to be collected through the use of cookies when using our website or application, there is a simple procedure in most browsers that allows you to automatically decline cookies or gives you the choice of declining or accepting the transfer to your computer of a particular cookie (or cookies) from a particular site. You may also wish to refer to http://www.allaboutcookies.org/manage-cookies/index.html. However, if you do not accept these cookies, you may experience some inconvenience in your use of the website or application. For example, we may not be able to recognize your computer, and you may need to log in every time you visit the website or application.

Pixel tags and other similar technologies: Pixel tags (also known as web beacons and clear GIFs) may be used in connection with our website to, among other things, track the actions of users of the website and other means of communication with you (including e-mail recipients), measure the success of our marketing campaigns, and compile statistics about usage of the website and response rates.

Analytics tools: We use website and application analytics services provided by third parties that use cookies, JavaScript and other similar technologies to collect information about website or application use, perform data enrichment and to report patterns or trends. The third parties that provide us with these services may also collect information about your use of third-party websites. These analytics providers may store this analytics data in the United States and other locations. Below is the list of analytics tools we use so that you may review their terms of service, some of which include instructions on how you may opt out of certain third party information collection practices. We will update this list as we change our analytics tools.

<0>THIRD PARTY SOFTWARE USED BY MEDHELPER ON THE WEB</0>

Analytics, Advertising, Tracking and Social

Facebook

Google Tools
(incl. but not limited to Google Analytics, Google Tag Manager, Google AdWords, Google Signals)

LinkedIn

Twitter

Customer Relationship Management, Support and Sales Enhancement
Zendesk

Audio & Video Platforms with Analytics
YouTube

Hosting Providers
AWS

Online Chat Tools and Communication Platforms
Slack

<0>SAFEGUARDING PERSONAL INFORMATION</0>
We are required by law to safeguard the Personal Information in our custody or control. We use industry standard measures to protect Personal Information against loss or theft, as well as unauthorized access, disclosure, copying, use, or modification. We protect Personal Information regardless of the format in which it is held.

Our methods of protection include: (a) physical measures, such as restricted physical access to the systems delivering our Service; (b) organizational measures, including employee training and limiting access on a “need-to-know” basis; and, (c) technological measures, including the use of passwords and encryption.

We use service providers, including our data hosting provider, to facilitate providing the Service. We use contractual means to make sure that our service providers only deal with Personal Information on our behalf to provide the Service and not for any other purposes. We also undertake diligence to satisfy ourselves that our service providers will implement adequate safeguards to protect Personal Information.

If we have reason to believe that there has been a breach of security safeguards that has resulted in the inappropriate loss or disclosure of Personal Information, we will take reasonable measures to notify the affected Customers, as applicable, promptly and with sufficient detail to enable them to evaluate the breach and understand the likely consequences.

<0>REVISIONS TO THIS PRIVACY STATEMENT</0>
MedHelper may update this Privacy Statement from time to time. If it is updated, the effective date of the revision will be shown at the top of the Privacy Statement. In the event of a significant revision, Customers may receive notification by email or through the Service itself. All Personal Information collected after that revision date will be subject to the revised Privacy Statement.

<0>ANONYMOUS INFORMATION</0>
MedHelper may use de-identified and/or aggregate information derived from Personal Information, for any purposes, including:

Analytics to understand how our Customers and, their authorized users make use of the Service and our website and to make targeted offerings of certain Services to our Customers and authorized users;

Information used to determine how to make improvements to the Service and to develop new features, capabilities and ways of presenting data; and

For commercial purposes, either for MedHelper or for others.

We will take industry standard steps so that this de-identified and/or aggregate information cannot be connected to any particular individual.

If you have any questions, concerns or feedback, please email <2>privacy@medhelper.com</2>; or send a letter to:

MedHelper Inc.

6500 Trans-Canada Hwy 4th Floor, Pointe-Claire, QC

Canada H9R 0A5

If we are unable to resolve your concerns, you also have the right to contact your local data protection authority.
`;

export default privacyPolicy;

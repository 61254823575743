
import ProposedScheduleAssembler from "./ProposedScheduleAssembler";
import { ProposedSchedulableReminderDto } from "../dtos/ProposedSchedulableReminderDto";
import ProposedReminderDto from "../dtos/ProposedReminderDto";
import ReminderFormData from "reminders/domain/ReminderFormData";
import DetailFormData from "reminders/domain/DetailFormData";
import ProposedReminderEventAssembler from "./events/ProposedReminderEventAssembler";

const toDto = (formData: ReminderFormData): ProposedSchedulableReminderDto => ({
  reminder: assembleReminderToDto(formData.details),
  schedule: ProposedScheduleAssembler.toDto(formData.schedule, ProposedReminderEventAssembler),
  instructions: formData.instructions.text,
});

const assembleReminderToDto = (reminderDetails: DetailFormData): ProposedReminderDto => ({
  name: reminderDetails.name,
});

export const ProposedSchedulableReminderAssembler = {
  toDto,
};

import PatientAssembler from "./PatientAssembler";
import ReminderResults from "report/domain/reminders/ReminderResults";
import DateTime from "main/dateTime/DateTime";
import NoteAssembler from "./NoteAssembler";
import ReminderResultsResponse from "./responses/ReminderResultsResponse";

export default class ReminderResultsAssembler {
  public static fromResponse(response: ReminderResultsResponse): ReminderResults {
    return new ReminderResults(
      PatientAssembler.fromResponse(response.patientProfile),
      response.results.map(({ expectedTimestamp, actualTimestamp }) => ({
        actualTimestamp: DateTime.fromISO(actualTimestamp),
        expectedTimestamp: DateTime.fromISO(expectedTimestamp),
      })),
      response.reminderName,
      NoteAssembler.fromResultsResponse(response.notes)
    );
  }
}

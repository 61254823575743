import MeasurementResults from "../domain/measurements/bloodGlucoseMeasurement/MeasurementResults";
import PatientAssembler from "./PatientAssembler";
import MeasurementResultsResponse from "./responses/MeasurementResultsResponse";
import MeasureAssembler from "./MeasurementAssembler";
import { MeasurementType } from "../../measurement/domain/MeasurementType";
import NoteAssembler from "./NoteAssembler";

export default class MeasurementResultsAssembler {
  public static fromResponse(response: MeasurementResultsResponse): MeasurementResults {
    return new MeasurementResults(
      PatientAssembler.fromResponse(response.patientProfile),
      response.results.map(MeasureAssembler.fromResponse),
      response.measurementName,
      MeasurementType[response.measurementType as keyof typeof MeasurementType],
      NoteAssembler.fromResultsResponse(response.notes),
    );
  }
}

import { Chip, withStyles } from "@material-ui/core";
import ProfessionalConsultationType from "connections/domain/ProfessionnalConsultationType";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import NavigationPath from "router/NavigationPath";
import analyticsClient, { AnalyticCategory, AnalyticEvent } from "../../main/services/analytics";

const BoldChip = withStyles(() => ({
  outlined: {
    fontWeight: "bold",
  },
}))(Chip);

const InviteSentChip = withStyles((theme) => ({
  outlined: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.dark,
  },
}))(BoldChip);

interface Props {
  consultationId: string;
  type: ProfessionalConsultationType;
}

const ConnectionHistoryStatus: React.FunctionComponent<Props> = ({ type, consultationId }) => {
  const { t } = useTranslation("connections");

  const showConsultationSummary = (): void => {
    analyticsClient.logEvent(AnalyticCategory.CONNECTION_HISTORY, AnalyticEvent.VIEW_CAREPLAN_SENT);
    window.open(generatePath(NavigationPath.CONSULTATION_SUMMARY, { consultationId }));
  };

  if (type === ProfessionalConsultationType.INVITE) {
    return <InviteSentChip label={t("status.type.carePlanSent")} variant="outlined" onClick={showConsultationSummary} />;
  } else if (type === ProfessionalConsultationType.REVIEW) {
    return <BoldChip label={t("status.type.carePlanViewed")} variant="outlined" color="primary" />;
  }

  return null;
};

export default ConnectionHistoryStatus;

import React from "react";
import { useTranslation } from "react-i18next";
import TableBlankSlate from "../../../main/components/TableBlankSlate";
import ExpandableTableWithHeader from "../../../main/components/ExpandableTableWithHeader";
import Note from "../../domain/note/Note";
import NotesTableRow from "./NotesTableRow";
import NotesReport from "../../domain/note/NotesReport";
import NoteStatus from "../../domain/note/NoteStatus";
import NotesReportError from "./NotesReportError";

export interface NotesHeaderProps {
  title: string;
}

interface Props {
  notes: NotesReport;
  CustomHeader: (props: NotesHeaderProps) => JSX.Element;
}

const INITIAL_NUMBER_OF_RESULTS = 2;

export const NotesTable: React.FunctionComponent<Props> = ({
  notes,
  CustomHeader,
}) => {
  const { t } = useTranslation("report");
  const { results, status } = notes;
  const numberOfEntriesRemaining = notes.results.length - INITIAL_NUMBER_OF_RESULTS;
  const title = t("notes", { count: results.length });

  if (status === NoteStatus.FAILURE) {
    return (
      <ExpandableTableWithHeader
        initialDisplayedRows={INITIAL_NUMBER_OF_RESULTS}
        numberOfEntriesRemaining={numberOfEntriesRemaining}
        headerComponent={<CustomHeader title={title} />}
      >
        <NotesReportError />
      </ExpandableTableWithHeader>
    );
  }

  return (
    <ExpandableTableWithHeader
      initialDisplayedRows={INITIAL_NUMBER_OF_RESULTS}
      numberOfEntriesRemaining={numberOfEntriesRemaining}
      headerComponent={<CustomHeader title={title} />}
    >
      {results.length > 0 ?
        results.map((note: Note) => (
          <NotesTableRow
            key={note.identifier}
            note={note}
          />
        ))
        :
        <TableBlankSlate />
      }
    </ExpandableTableWithHeader>
  );
};

export default NotesTable;

import Consultation from "report/domain/PreviousConsultation";
import ConsultationResponse from "./responses/ConsultationResponse";
import DateTime from "../../main/dateTime/DateTime";
import HealthProfession from "../../authentication/profile/domain/HealthProfession";
import PhoneNumber from "../../main/domain/PhoneNumber";
import PreviousConsultation from "../domain/PreviousConsultation";

export default class ConsultationAssembler {
  public static fromResponse(consultationResponse: ConsultationResponse[]): Consultation[] {
    return consultationResponse.map((previousConsultationResponse) => new PreviousConsultation({
      identifier: previousConsultationResponse.identifier,
      professionalIdentifier: previousConsultationResponse.professionalIdentifier,
      date: DateTime.fromISO(previousConsultationResponse.date),
      officeNumber: PhoneNumber.from(
        previousConsultationResponse.officeNumber.value,
        previousConsultationResponse.officeNumber.extension
      ),
      professionalName: previousConsultationResponse.professionalName,
      profession: HealthProfession[previousConsultationResponse.profession as keyof typeof HealthProfession],
    }));
  }
}

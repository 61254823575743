import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  blankSlate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    height: "100px",
  },
  blankSlateLabel: {
    fontWeight: "bold",
    color: theme.colors.error,
  },
  heading: {
    margin: 20,
  },
}));

interface Props {
  regimen: string;
}

const TreatmentRegimenError = ({ regimen }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.heading}>
      <div className={classes.blankSlate}>
        <Typography component="h3" variant="h3" className={classes.blankSlateLabel}>
          {regimen}
        </Typography>
      </div>
    </div>

  );
};

export default TreatmentRegimenError;

import React from "react";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateTime from "../../dateTime/DateTime";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateUtils from "@date-io/luxon";
import i18next from "i18next";
import Luxon from "luxon";

interface Props {
  time: DateTime;
  onChange?: (newTime: DateTime) => void;
  testID?: string;
  labelledBy?: string;
};
const MedHelperTimePicker: React.FunctionComponent<Props> = ({ time, labelledBy, onChange, testID }) => {

  const change = (date: MaterialUiPickersDate): void => {
    if (!!date) {
      const newDateTime = DateTime.now().setTime(date.hour, date.minute);
      onChange && onChange(newDateTime);
    }
  };
  const isFormatEN = i18next.language.includes("en");

  return (
    <MuiPickersUtilsProvider libInstance={Luxon} utils={DateUtils}>
      <TimePicker
        id="time"
        required
        ampm={isFormatEN}
        value={time.getDate()}
        inputProps={{
          "aria-labelledby": labelledBy,
          "data-testid": testID,
        }}
        onChange={change}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MedHelperTimePicker;

import { Divider, makeStyles } from "@material-ui/core";
import React, { useRef } from "react";

import { ReactComponent as ReminderIcon } from "reminders/assets/alarm.svg";
import CarePlanSection from "../CarePlanSection";
import { useTranslation } from "react-i18next";
import ReminderFormData from "reminders/domain/ReminderFormData";
import { ReminderFormModal, ReminderModalRef } from "reminders/components/ReminderFormModal";
import ReminderSummarySection from "./ReminderSummarySection";

interface Props {
  disabled?: boolean;
  reminders: ReminderFormData[];
  onRemindersChange?: (customActivities: ReminderFormData[]) => void;
  onDeleteClick?: (onConfirm: () => void) => void;
}

export const RemindersSection = ({
  disabled,
  reminders = [],
  onRemindersChange,
  onDeleteClick,
}: Props): JSX.Element => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();
  const reminderModalRef = useRef<ReminderModalRef | null>(null);
  const isReadOnly = !Boolean(onRemindersChange);

  const addReminder = (reminder: ReminderFormData): void => {
    const updatedReminders = [...reminders, reminder];
    onRemindersChange?.(updatedReminders);
    reminderModalRef.current?.close();
  };

  const deleteReminder = (reminderToDelete: ReminderFormData): void => {
    const updatedReminders = reminders
      .filter((reminder) => reminder.id !== reminderToDelete.id);
      onRemindersChange?.(updatedReminders);
  };

  const onDeleteReminder = (reminder: ReminderFormData): void => {
    onDeleteClick?.(() => deleteReminder(reminder));
  };

  const editReminder = (reminderEdited: ReminderFormData): void => {
    const updatedReminders = reminders.map(
      (reminder: ReminderFormData) => reminder.id === reminderEdited.id
        ? reminderEdited : reminder
    );

    onRemindersChange?.(updatedReminders);
    reminderModalRef.current?.close();
  };

  const onClickEdit = (reminder: ReminderFormData): void => {
    reminderModalRef.current?.show(reminder);
  };

  const onClickAdd = (): void => reminderModalRef.current?.show();

  return (
    <CarePlanSection
      Icon={ReminderIcon}
      title={t("section.title.reminder")}
      text={t("section.subText.reminder")}
      disabled={disabled}
      onClick={isReadOnly ? undefined : onClickAdd}
    >
      <ReminderFormModal
        ref={reminderModalRef}
        onReminderAdd={addReminder}
        onEditReminder={editReminder}
      />

      <ul className={classes.reminders}>
        {reminders.map((reminder, index: number) => (
          <li
            key={`${reminder.id}`}
            aria-label={reminder.details.name}
          >
            <ReminderSummarySection
              isReadOnly={isReadOnly}
              onEditClick={onClickEdit}
              onDeleteClick={onDeleteReminder}
              reminder={reminder} />

            {index !== reminders.length - 1 &&
              <Divider light />
            }
          </li>

        ))}
      </ul>
    </CarePlanSection>
  );
};

const useStyles = makeStyles(() => ({
  reminders: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
}));

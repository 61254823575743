import DeletionModal, { DeletionModalRef } from "./DeletionModal";
import {
  FormGroup,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useRef } from "react";

import CarePlanStepFormData from "../domain/CarePlanStepFormData";
import { ConsultationDocumentSection } from "../../document/components/ConsultationDocumentSection";
import { ConsultationDocumentsFormData } from "../../document/domain/ConsultationDocumentsFormData";
import { CustomActivitiesSection } from "./CustomActivitiesSection";
import CustomActivityFormData from "../../customActivities/domain/CustomActivityFormData";
import CustomProgramFormData from "../../prescribe/domain/CustomProgramFormData";
import { CustomProgramSummaries } from "./customPrograms/CustomProgramSummaries";
import { CustomProgramsSection } from "./CustomProgramsSection";
import { InstructionsField } from "./InstructionsField";
import { MeasurementDetail } from "../../measurement/domain/MeasurementDetail";
import { MeasurementFormData } from "../../measurement/domain/MeasurementFormData";
import { MeasurementsSection } from "./MeasurementsSection";
import MedicationSummary from "../../medication/domain/MedicationSummary";
import { MedicationsSection } from "./MedicationsSection";
import Profile from "../../authentication/profile/domain/Profile";
import { ProximCustomProgramModalRef } from "./customPrograms/ProximCustomProgramModal";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";
import { RemindersSection } from "./reminder/RemindersSection";
import ReminderFormData from "reminders/domain/ReminderFormData";

interface Props {
  carePlan: CarePlanStepFormData;
  profile: Profile | undefined;
  onCarePlanChange: (newCarePlan: CarePlanStepFormData) => void;
  onMedicationsChange: (medications: MedicationSummary[]) => void;
  onChangeCustomActivities: (customActivties: CustomActivityFormData[]) => void;
  onCustomProgramsChange: (customProgram: CustomProgramFormData[]) => void;
  onMeasurementsChange: (measurements: MeasurementFormData<MeasurementDetail>[]) => void;
  onConsultationDocumentsChange: (consultationDocuemnts: ConsultationDocumentsFormData) => void;
  onRemindersChange: (reminders: ReminderFormData[]) => void;
  disabled?: boolean;
}

export const CarePlanForm: React.FunctionComponent<Props> = ({
  carePlan,
  onCarePlanChange,
  profile,
  onCustomProgramsChange,
  onMedicationsChange,
  onMeasurementsChange,
  onConsultationDocumentsChange,
  onRemindersChange,
  disabled,
  onChangeCustomActivities,
}) => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();
  const {
    pendingTreatmentPlan: {
      measurements,
      pendingCustomPrograms,
      medications,
      customActivities,
      reminders,
    },
    consultationDocuments,
    instructions,
  } = carePlan;

  const deletionModalRef = useRef<DeletionModalRef | null>(null);
  const proximCustomProgramModalRef = useRef<ProximCustomProgramModalRef | null>(null);

  const changeInstructions = useCallback((instruction: string) => {
    onCarePlanChange(carePlan.withInstructions(instruction));
  }, [carePlan, onCarePlanChange]);

  const deleteCustomProgram = (customProgramToRemove: CustomProgramFormData): void => {
    const updatedCustomPrograms = pendingCustomPrograms.list.filter(
      (customProgram: CustomProgramFormData) => customProgram.id !== customProgramToRemove.id
    );

    onCustomProgramsChange(updatedCustomPrograms);
  };

  const deleteConfirmation = (onConfirm: () => void): void => {
    deletionModalRef.current?.open(onConfirm);
  };

  return (
    <section aria-labelledby="carePlanTitle" style={{ flex: 1 }}>
      <Typography id="carePlanTitle" component="label" variant="h3" className={classes.sectionTitle}>
        {t("createCarePlan")}
      </Typography>

      <FormGroup row={false} style={{ paddingTop: theme.metrics.margin.medium }}>
        <InstructionsField
          placeholder={t("placeholderNotes")}
          initialHtmlText={instructions}
          onChange={changeInstructions}
          disabled={disabled}
        />
      </FormGroup>

      <CustomProgramsSection
        profile={profile}
        pendingCustomPrograms={pendingCustomPrograms.list}
        onCustomProgramsChange={onCustomProgramsChange}
        proximCustomProgramModalRef={proximCustomProgramModalRef}
        disabled={disabled}
      />

      <ConsultationDocumentSection
        disabled={disabled}
        consultationDocuments={consultationDocuments}
        onConsultationDocumentsChange={onConsultationDocumentsChange}
        onDeleteClick={deleteConfirmation}
      />

      <MeasurementsSection
        disabled={disabled}
        measurements={measurements.list}
        onMeasurementsChange={onMeasurementsChange}
        onDeleteClick={deleteConfirmation}
      />

      <RemindersSection
        reminders={reminders.list}
        onRemindersChange={onRemindersChange}
        onDeleteClick={deleteConfirmation}
        disabled={disabled}
      />

      <CustomActivitiesSection
        customActivities={customActivities.list}
        onCustomActivitiesChange={onChangeCustomActivities}
        disabled={disabled}
        onDeleteClick={deleteConfirmation}
      />

      <MedicationsSection
        medications={medications.list}
        onMedicationsChange={onMedicationsChange}
        onMedicationDelete={deleteConfirmation}
        disabled={disabled}
      />

      <CustomProgramSummaries
        customPrograms={pendingCustomPrograms.list}
        onCustomProgramChange={(customProgram): void => proximCustomProgramModalRef.current?.open(customProgram)}
        onCustomProgramDelete={(customProgram): void => deleteConfirmation(() => deleteCustomProgram(customProgram))}
      />

      <DeletionModal ref={deletionModalRef} />
    </section>
  );
};

const useStyles = makeStyles(() => ({
  sectionTitle: {
    margin: `${theme.metrics.margin.normal} 0`,
    fontWeight: "bold",
  },
}));

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  CircularProgress, Grid, Theme,
} from "@material-ui/core";
import { useParams } from "react-router";
import theme from "../main/theme/theme";
import ReportHeader from "./components/ReportHeader";
import ReportFooter from "./components/ReportFooter";
import useMeasurementResults from "./hooks/useMeasurementResults";
import PatientInfo from "./components/PatientInfo";
import BloodGlucoseMeasureResultsTable from "./components/BloodGlucoseMeasureResultsTable";
import BloodGlucoseMeasure from "./domain/measurements/bloodGlucoseMeasurement/BloodGlucoseMeasure";
import CustomMeasure from "./domain/measurements/customMeasurement/CustomMeasure";
import { CustomMeasureResultsTable } from "./components/CustomMeasureResultsTable";
import { MeasurementType } from "../measurement/domain/MeasurementType";
import { MigraineMeasureResultsTable } from "./components/MigraineMeasureResultsTable";
import MigraineMeasure from "./domain/measurements/migraineMeasurement/MigraineMeasure";
import { MoodMeasureResultsTable } from "./components/MoodMeasureResultsTable";
import MoodMeasure from "./domain/measurements/moodMeasurement/MoodMeasure";
import { BloodPressureMeasureResultsTable } from "./components/BloodPressureMeasureResultsTable";
import BloodPressureMeasure from "./domain/measurements/bloodPressureMeasurement/BloodPressureMeasure";
import { useTranslation } from "react-i18next";

const REPORT_MAX_WIDTH = 960;

const MeasurementResultsPage: React.FunctionComponent = () => {
  const { t } = useTranslation("report");
  const classes = useStyles();
  const { code } = useParams();
  const { reportCode } = useParams();

  const { results } = useMeasurementResults({ measurementReportCode: reportCode, code });

  const isBloodGlucoseMeasures = results?.type === MeasurementType.BLOOD_GLUCOSE;
  const isMigraineMeasures = results?.type === MeasurementType.MIGRAINE;
  const isMoodMeasures = results?.type === MeasurementType.MOOD;
  const isBloodPressureMeasures = results?.type === MeasurementType.BLOOD_PRESSURE;
  const isCustomMeasures = results?.type === MeasurementType.CUSTOM;

  if (results === null) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  const name = t([
    `noteMeasurement.${results.name}`,
    results.name,
  ]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div>
          <ReportHeader />

          <Grid container className={classes.row}>
            <Grid item sm={12} md={6}>
              <PatientInfo patient={results?.patient} />
            </Grid>
          </Grid>

          {
            !!isBloodGlucoseMeasures
            && <BloodGlucoseMeasureResultsTable
              measures={results?.results as BloodGlucoseMeasure[]}
              notes={results.notes}
            />
          }
          {
            !!isMigraineMeasures
            && <MigraineMeasureResultsTable
              measures={results?.results as MigraineMeasure[]}
              notes={results.notes}
              name={name}
            />
          }
          {
            !!isMoodMeasures
            && <MoodMeasureResultsTable
              measures={results?.results as MoodMeasure[]}
              notes={results.notes}
              name={name}
            />
          }
          {
            !!isBloodPressureMeasures
            && <BloodPressureMeasureResultsTable
              measures={results?.results as BloodPressureMeasure[]}
              notes={results.notes}
              name={name}
            />
          }
          {
            !!isCustomMeasures
            && <CustomMeasureResultsTable
              measures={results?.results as CustomMeasure[]}
              notes={results.notes}
              name={name}
            />
          }
        </div>

        <ReportFooter />
      </div>
    </div>
  );
};

export default MeasurementResultsPage;

const useStyles = makeStyles((muiTheme: Theme) => ({
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: `${theme.metrics.margin.medium} ${theme.metrics.margin.huge}`,
    [muiTheme.breakpoints.down("sm")]: {
      padding: theme.metrics.margin.medium,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: REPORT_MAX_WIDTH,
    width: "100%",
    minHeight: "100vh",
  },
  loadingContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  row: {
    margin: `${theme.metrics.margin.medium} 0`,
  },
  notes: {
    margin: `${theme.metrics.margin.huge} 0`,
  },
}));

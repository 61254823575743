import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { Modal } from "../../../main/modal/Modal";
import DrugLeaflet from "../../domain/DrugLeaflet";
import DrugLabels from "../../domain/DrugLabels";
import Medication from "../../domain/medications/Medication";
import theme from "../../../main/theme/theme";
import DrugSheetContent from "./components/DrugSheetContent";
import DrugSheetHeader from "./components/DrugSheetHeader";

const useStyle = makeStyles(() => ({
  modal: {
    padding: `${theme.metrics.margin.normal} 0`,
  },
  header: {
    padding: `0 ${theme.metrics.margin.normal}`,
  },
}));

interface Props {
  isOpen: boolean;
  medication: Medication | null;
  leafletLoading: boolean;
  labelsLoading: boolean;
  leaflet: DrugLeaflet | null;
  drugLabels: DrugLabels | null;
  onClose: () => void;
  className?: string;
}

export const DrugSheetModal: React.FunctionComponent<Props> = ({
  isOpen,
  medication,
  leaflet,
  leafletLoading,
  labelsLoading,
  onClose,
  className,
  drugLabels,
}) => {
  const classes = useStyle();

  return (
    <Modal className={classNames(className, classes.modal)} isOpen={isOpen} onClose={onClose}>
      {!!medication && <DrugSheetHeader className={classes.header} onClose={onClose} medication={medication} />}

      <DrugSheetContent
        leaflet={leaflet}
        leafletLoading={leafletLoading}
        drugLabels={drugLabels}
        labelsLoading={labelsLoading}
      />
    </Modal>
  );
};

export default DrugSheetModal;

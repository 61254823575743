import { useEffect, RefObject } from "react";

const useOutsideClick = (ref: RefObject<HTMLElement>, onOutsideClick: () => void, dependency?: any): void => {
  useEffect(() => {
    const handleClickOutside = (event: any): void => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick, dependency]);
};

export default useOutsideClick;

import React from "react";
import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../main/theme/theme";
import { ReactComponent as AlertIcon } from "../../main/assets/AlertIcon.svg";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "1.5rem",
  },
  content: {
    maxWidth: pxToRem(400),
    textAlign: "center",
  },
  alertIcon: {
    maxWidth: pxToRem(60),
    maxHeight: pxToRem(60),
    marginBottom: "1rem",
  },
}));

interface Props {
  className?: string;
}

export const WarningMessage: React.FunctionComponent<Props> = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={[classes.wrapper, className].join(" ")}>
      <div className={classes.content}>
        <AlertIcon className={classes.alertIcon} />

        <div>
          {children}
        </div>
      </div>
    </div>
  );
};
export default WarningMessage;

import { MeasurementDetail } from "./MeasurementDetail";
import { MeasurementType } from "./MeasurementType";

export class CustomMeasurementDetail implements MeasurementDetail {
  private _name: string;
  public readonly type: MeasurementType;

  constructor(name = "") {
    this.type = MeasurementType.CUSTOM;
    this._name = name;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  public canSubmitMeasurement(): boolean {
    return this.name !== "";
  }
}

import DateTime from "main/dateTime/DateTime";
import SupportedLanguages from "main/domain/SupportedLanguages";
import { SUPPORTED_LANGUAGES } from "main/hooks/useLanguageSelection";
import PrescribeFormData from "prescribe/domain/PrescribeFormData";
import TemporaryPatientResponse from "../responses/TemporaryPatientResponse";

class PrescribeFormDataAssembler {
  public fromResponse(patient: TemporaryPatientResponse, formData: PrescribeFormData): PrescribeFormData {
    return formData
      .withEmail(patient.email || "")
      .withFirstName(patient.firstName || "")
      .withLastName(patient.lastName || "")
      .withGender(patient.gender ?? undefined)
      .withAllergies(patient.allergies || "")
      .withBirthDate(DateTime.fromISO(patient.birthDate || ""))
      .withLanguage(this.getValidLanguage(formData, patient.language?.toLowerCase()));
  }

  private getValidLanguage(formData: PrescribeFormData, language?: string): SupportedLanguages {
    if (SUPPORTED_LANGUAGES.includes(language as SupportedLanguages)) {
      return language as SupportedLanguages;
    }

    return formData.language;
  }
}

export default new PrescribeFormDataAssembler();

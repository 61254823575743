import PrescribeFormData from "../../domain/PrescribeFormData";
import InvitationPatientProfileDto from "../dtos/InvitationPatientProfileDto";

export default class PatientProfileAssembler {
  public static toDto(prescribeFormData: PrescribeFormData): InvitationPatientProfileDto {
    const { firstName, lastName, gender, birthDate, allergies } = prescribeFormData;

    return {
      firstName,
      lastName,
      gender: gender?.valueOf(),
      birthDate: birthDate?.toISODate(),
      allergies,
    };
  }
}

import Client from "../../main/services/ApiClient";
import InvalidPatientCodeException from "../domain/exceptions/InvalidPatientCodeException";
import PatientReport from "../domain/PatientReport";
import PatientReportResponse from "./responses/PatientReportResponse";
import PatientReportAssembler from "./PatientReportAssembler";

export const fetchPatientReport = async (code: string, timezone: string): Promise<PatientReport> => {
  try {
    const response = await new Client({
      endpoint: "/doctors/report",
      queryParams: { code, timezone },
    })
      .get<PatientReportResponse>();
    return PatientReportAssembler.fromResponse(response);
  } catch (e) {
    if (e.status === 404) {
      throw new InvalidPatientCodeException();
    }

    throw e;
  }
};

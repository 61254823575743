import DateTime from "../../main/dateTime/DateTime";
import AdherenceStatus from "./AdherenceStatus";

class DailyAdherence {

  public readonly status: AdherenceStatus;
  public readonly date: DateTime;

  constructor({ status, date }: { status: AdherenceStatus; date: DateTime }) {
    this.status = status;
    this.date = date;
  }
}

export default DailyAdherence;

import i18next from "i18next";
import SessionFormData from "../../prescribe/domain/SessionFormData";
import ScheduleFormData from "./ScheduleFormData";
import Frequency from "./Frequency";
import { createRRule, recurrenceToText } from "report/service/RecurrenceRuleService";
import { RRule } from "rrule";
import SupportedLanguages from "main/domain/SupportedLanguages";
import DoseFormData from "medication/domain/DoseFormData";
import { DateTime as LuxonDateTime } from "luxon";

class ScheduleSummaryFactory {
  public create(scheduleFormData: ScheduleFormData<SessionFormData>): string {
    if (scheduleFormData.frequency === Frequency.PUNCTUAL) {
      if (scheduleFormData.events.length === 1) {
        return scheduleFormData.duration.startDate.formatLocale(LuxonDateTime.DATE_FULL) + " " + this.getEventTime(scheduleFormData.events[0]);
      }

      return i18next.t(`frequency:frequencies:${scheduleFormData.frequency}`);
    }

    const recurrence = createRRule(
      scheduleFormData.frequency,
      scheduleFormData.interval || 1,
      scheduleFormData.duration.startDate,
      scheduleFormData.duration.endDate,
      scheduleFormData.weekdays,
      scheduleFormData.monthDays,
    );

    return this.getDescription(scheduleFormData.events, recurrence);
  }

  private getDescription(events: SessionFormData[], recurrence: RRule): string {
    const formattedRecurrence = recurrenceToText(recurrence, i18next.language as SupportedLanguages);
    const eventsTime = events.map(this.getEventTime.bind(this)).join(` ${i18next.t("schedule:and")} `);

    return `${formattedRecurrence} ${eventsTime}`.trim();
  }

  private getDailyInfo(event: SessionFormData): string {
    if (event instanceof DoseFormData) {
      return ` (${i18next.t("schedule:dose", { count: event.quantity })})`;
    }

    return "";
  }

  private getEventTime(event: SessionFormData): string {
    const alarmDescription = !!event.alarm
      ? " (" + i18next.t("schedule:alarmDescription", { interval: event.alarm.intervalInMinutes }) + ")"
      : "";

    return i18next.t("schedule:at")
    + " " + event.time.formatLocale(LuxonDateTime.TIME_SIMPLE)
    + this.getDailyInfo(event)
    + alarmDescription;
  }
}

export default new ScheduleSummaryFactory();

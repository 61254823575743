export default class SearchDrug {
  public id: string;
  public name: string;
  public type: string | undefined;
  public dosage: string | undefined;

  constructor(
    id: string,
    name: string,
    type: string | undefined,
    dosage: string | undefined,
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.dosage = dosage;
  }
}

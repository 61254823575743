import PatientAssembler from "./PatientAssembler";
import DateTime from "main/dateTime/DateTime";
import MedicationResultsResponse from "./responses/MedicationResultsResponse";
import MedicationResults from "report/domain/medications/MedicationResults";
import MedicationResult from "report/domain/medications/MedicationResult";
import NoteAssembler from "./NoteAssembler";

export default class MedicationResultsAssembler {
  public static fromResponse(response: MedicationResultsResponse): MedicationResults {
    return new MedicationResults(
      PatientAssembler.fromResponse(response.patientProfile),
      response.results.map(({ expectedTimestamp, actualTimestamp, amount }) => new MedicationResult({
        actualTimestamp: DateTime.fromISO(actualTimestamp),
        expectedTimestamp: DateTime.fromISO(expectedTimestamp),
        amount,
      })),
      response.name,
      response.dosage,
      NoteAssembler.fromResultsResponse(response.notes),
    );
  }
}

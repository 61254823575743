import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../theme/theme";
import LanguageSelector from "../components/LanguageSelector";
import ProfileMenu from "../components/profileMenu/ProfileMenu";
import Profile from "../../../authentication/profile/domain/Profile";
import useSessionService from "../../services/useSessionService";
import useProfileRepository from "../../../authentication/store/hooks/useProfileRepository";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: `${theme.metrics.margin.normal} ${theme.metrics.margin.huge}`,
  },
  profileMenu: {
    marginLeft: theme.metrics.margin.medium,
  },
}));

interface Props {
  onLogout?: () => void;
}

const Header: React.FunctionComponent<Props> = ({ onLogout }) => {
  const classes = useStyles();
  const { profile } = useProfileRepository();
  const { clearSession } = useSessionService();

  const logout = async (): Promise<void> => {
    await clearSession();
    onLogout?.();
  };

  return (
    <div className={classes.header}>
      <LanguageSelector />

      <div className={classes.profileMenu}>
        <ProfileMenu
          onLogout={logout}
          profile={profile as Profile}
        />
      </div>
    </div>
  );
};

export default Header;

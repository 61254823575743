import { makeStyles } from "@material-ui/core";
import theme from "../../main/theme/theme";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  viewResultsButton: {
    "border": "none",
    "background": `linear-gradient(${theme.colors.primary.light},${theme.colors.primary.normal})`,
    "color": "white",
    "padding": theme.colors.button.primary.padding,
    "boxShadow": theme.colors.button.primary.boxShadow,
    "borderRadius": theme.colors.button.primary.borderRadius,
    "&:hover": {
      cursor: "pointer",
    },
    "&:active, &:focus": {
      boxShadow: `0 0 3pt 2pt ${theme.colors.primary.normal}`,
      outline: "none",
    },
  },
}));

interface Props {
  onClick: () => void;
}

export const ViewResultsButton = ({ onClick }: Props): JSX.Element => {
  const { t } = useTranslation("report");
  const styles = useStyles();
  return (
    <button
      className={styles.viewResultsButton}
      type="button"
      onClick={onClick}
    >
      {t("viewResults")}
    </button>
  );
};

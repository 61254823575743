import { ReactComponent as Paperclip } from "../assets/Paperclip.svg";
import React from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
}

export const ValidConsultationDocumentSummary = ({ name }: Props): JSX.Element => {
  const { t } = useTranslation("consultationDocument");
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paperclip
        title={t("file")}
        fill="black"
        width={20}
        height={20}
      />
      <Typography variant="h4" className={classes.name}>
        {name}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    padding: `0 ${theme.metrics.margin.small}`,
  },
}));

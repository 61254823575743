import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import CustomProgramOwner from "../../prescribe/domain/CustomProgramOwner";
import BloodGlucoseMeasurement from "../domain/measurements/bloodGlucoseMeasurement/BloodGlucoseMeasurement";
import BloodGlucoseIcon from "./BloodGlucoseIcon";
import CustomProgramPastAdherence from "./adherence/CustomProgramPastAdherence";
import PastAdherence from "./adherence/PastAdherence";
import { MeasurementTreatmentRow } from "./MeasurementTreatmentRow";
import { MeasurementType } from "../../measurement/domain/MeasurementType";

interface Props {
  measurement: BloodGlucoseMeasurement;
  expanded: boolean;
  toggleExpanded: () => void;
}

const TreatmentBloodGlucoseMeasurement: React.FunctionComponent<Props> = ({
  measurement,
  expanded,
  toggleExpanded,
}) => {
  const { t } = useTranslation("report");
  const { code } = useParams<{ code: string }>();

  const reportCode = measurement.reportCode.code;

  const hasCustomProgram = !!measurement.customProgram;

  return (
    <MeasurementTreatmentRow
      title={t("measurement")}
      subtitle={measurement.displayName()}
      schedule={!!hasCustomProgram ? t(`scheduleMeasurementType.${CustomProgramOwner.PROXIM}`) : t("asNeeded")}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      picture={<BloodGlucoseIcon />}
      code={code}
      reportCode={reportCode}
      type={MeasurementType.BLOOD_GLUCOSE}
    >
      <Grid container>
        <Grid item xs={12}>
          {
            !!hasCustomProgram ?
              <CustomProgramPastAdherence
                adherences={measurement.adherence}
                duration={measurement.schedule!!.duration}
              /> :
              <PastAdherence
                adherence={measurement.adherence}
                type="measurement"
              />
          }
        </Grid>
      </Grid>
    </MeasurementTreatmentRow>
  );

};

export default TreatmentBloodGlucoseMeasurement;

import DateTime from "../../main/dateTime/DateTime";

enum Weekday {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

export const weekdays = [
  undefined, // moment's isoWeekday begins at 1
  Weekday.MONDAY,
  Weekday.TUESDAY,
  Weekday.WEDNESDAY,
  Weekday.THURSDAY,
  Weekday.FRIDAY,
  Weekday.SATURDAY,
  Weekday.SUNDAY,
];

const getWeekDay = (day: number): Weekday => {
  const weekday = weekdays[day];

  if (!weekday) {
    throw new Error("No weekday found for the date. Should not happens");
  }

  return weekday;
};

export const getDayOfTheWeek = (date: DateTime): Weekday => getWeekDay(date.getDayOfWeek());

export const getCurrentWeekday = (): Weekday => {
  const now = DateTime.now();
  return getDayOfTheWeek(now);
};

export const RRuleWeekDayToWeekDay = (day: number): Weekday => getWeekDay(day + 1);

export default Weekday;

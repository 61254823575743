import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { makeStyles } from "@material-ui/core";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import useOutsideClick from "../../home/hooks/useOutsideClick";
import theme, { pxToRem } from "../theme/theme";
import CloseModalButton from "./CloseModalButton";
import classNames from "classnames";

const ANIMATION_DURATION = 200;
const DELAY = 50;

const useStyles = makeStyles(() => ({
  modal: {
    position: "absolute",
    backgroundColor: theme.colors.surface,
    top: "50%",
    left: "50%",
    maxHeight: "90vh",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "1rem",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    transform: "translate(-50%,-50%)",
    padding: "1rem",
    zIndex: 1,
    minWidth: pxToRem(300),
    maxWidth: pxToRem(940),
    display: "flex",
    flexDirection: "column",
  },
  overlay: {
    position: "fixed",
    overflow: "hidden",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.darkOverlay,
  },
  content: {
    position: "relative",
    flex: 1,
  },
  fade: {
    opacity: 0,
    transition: `opacity ease-in-out ${ANIMATION_DURATION}ms`,
  },
  willOpen: {
    opacity: 1,
  },
  willClose: {
    opacity: 0,
  },
  closeButton: {
    alignSelf: "flex-end",
    marginBottom: theme.metrics.margin.normal,
  },
}));

interface Props {
  isOpen: boolean;
  closable?: boolean;
  disableOutsideClick?: boolean;
  onClose: () => void;
  className?: string;
  labelledBy?: string;
}

export const Modal: React.FunctionComponent<Props> = ({
  isOpen,
  onClose,
  className,
  closable,
  children,
  disableOutsideClick,
  labelledBy,
}) => {
  const modalRef = React.createRef<HTMLDivElement>();
  const [open, setOpen] = useState(isOpen);
  const [willClose, setWillClose] = useState(false);
  const [willOpen, setWillOpen] = useState(false);
  const classes = useStyles();
  useOutsideClick(modalRef, () => {
    if (!disableOutsideClick) {
      onClose();
    }
  });

  useEffect(() => {
    let timer: number;
    if (isOpen) {
      setOpen(true);
      timer = setTimeout(() => setWillOpen(true), DELAY);
      setWillClose(false);

      if (modalRef && modalRef.current) {
        disableBodyScroll(modalRef.current);
      }
    } else {
      setWillOpen(false);
      setWillClose(true);
      timer = setTimeout(() => setOpen(false), ANIMATION_DURATION);

      if (modalRef && modalRef.current) {
        enableBodyScroll(modalRef.current);
      }
    }

    return (): void => {
      clearTimeout(timer);
    };
  }, [isOpen, modalRef]);

  const transitionClass = willOpen ?
    classes.willOpen :
    (willClose ? classes.willClose : "");

  if (!open) {
    return null;
  }

  const modalRoot = document.getElementById("modal-root");
  if (!modalRoot) {
    return null;
  }

  return createPortal(
    <div className={[classes.fade, classes.overlay, transitionClass].join(" ")}>
      <div
        ref={modalRef}
        className={classNames([classes.fade, classes.modal, transitionClass, className])}
        role="dialog"
        aria-labelledby={labelledBy}
      >
        {!!closable && <CloseModalButton className={classes.closeButton} onClose={onClose} />
        }

        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import theme, { pxToRem } from "../../../../main/theme/theme";
import Percentage from "../../../../main/domain/Percentage";
import Adherences from "../../../domain/Adherences";
import { AdherenceTypes } from "../../../domain/AdherenceTypes";
import AdherenceWheel from "./AdherenceWheel";

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 600,
  },
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.background,
    justifyContent: "space-around",
    padding: pxToRem(15),
    paddingBottom: pxToRem(30),
  },
  centerText: {
    display: "inline-block",
  },
  wrapper: {
    textAlign: "center",
  },
}));
const WHEEL_SIZE = 80;

interface Props {
  adherences: Adherences;
}

const AdherenceWheels: React.FunctionComponent<Props> = ({ adherences }) => {
  const classes = useStyles();
  const { t } = useTranslation("report");

  const canFollowTreatmentAdherence = adherences.adherences.map((adherence) => (adherence.value instanceof Percentage));
  const monthlyProgress = adherences.adherences.find((iterator) => iterator.type === AdherenceTypes.MONTHLY)!.value;
  const trimestrialProgress = adherences.adherences.find((iterator) =>
    iterator.type === AdherenceTypes.TRIMESTRIAL)!.value;

  return (
    <div className={classes.container}>
      <AdherenceWheel
        progress={monthlyProgress}
        size={WHEEL_SIZE}
        numberOfDays={30}
      />

      <div className={classes.centerText}>
        <Trans
          t={t}
          i18nKey={canFollowTreatmentAdherence ? "dailyAdherence" : "events"}
          components={[
            <Typography
              key="1"
              className={classes.bold}
              color="textPrimary"
              component="span"
              variant="h5"
              display="block"
            />,
          ]}
        />
      </div>

      <AdherenceWheel
        progress={trimestrialProgress}
        size={WHEEL_SIZE}
        numberOfDays={90}
      />
    </div>
  );
};

export default AdherenceWheels;

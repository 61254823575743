import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Collapse from "@material-ui/core/Collapse";
import theme from "../theme/theme";
import ExpandButton from "./ExpandButton";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    display: "block",
    width: "100%",
  },
  table: {
    width: "100%",
  },
  expandButtonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderTop: `${theme.metrics.border.tiny} ${theme.colors.divider}`,
  },
}));

interface Props {
  numberOfEntriesRemaining: number;
  initialDisplayedRows: number;
}

const ExpandableTable: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    initialDisplayedRows,
    numberOfEntriesRemaining: numberOfEntries,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const rows = React.Children.toArray(children);
  const amountOfRows = rows.length;
  const initialEntries = rows.slice(0, initialDisplayedRows);
  const expandableEntries = rows.slice(initialDisplayedRows, amountOfRows);

  const expandable = rows.length > initialDisplayedRows;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.table}>
          {initialEntries}

          <Collapse in={expanded}>
            {expandableEntries}
          </Collapse>
        </div>
      </div>

      {!!expandable &&
        <div className={classes.expandButtonContainer}>
          <ExpandButton
            numberOfEntries={numberOfEntries}
            onClick={(): void => setExpanded((isExpanded: boolean) => !isExpanded)}
            expanded={expanded}
          />
        </div>
      }
    </div>
  );
};

export default ExpandableTable;

import envConfig from "../config/envConfig";
import AuthenticationService from "../../authentication/services/authentication";
import CrashReportingService from "./CrashReportingService";

type Body = Record<string, unknown> | Blob;
interface Headers { [key: string]: string }

interface Params {
  baseUrl?: string;
  endpoint: string;
  token?: string;
  body?: Body;
  queryParams?: { [key: string]: string };
  headers?: Headers;
  withFile?: boolean;
  sendSessionToken?: boolean;
}

type Method = "GET" | "POST" | "PUT" | "DELETE" | "HEAD" | "PATCH";

class Client {

  private readonly baseUrl: string;
  private readonly endpoint: string;
  private readonly body?: Body;
  private readonly queryParams: { [key: string]: string };
  private readonly headers: { [key: string]: string };
  private readonly withFile: boolean;
  private readonly sendSessionToken: boolean;

  constructor({
    baseUrl,
    endpoint,
    body,
    queryParams = {},
    headers = {},
    withFile = false,
    sendSessionToken = true,
  }: Params) {
    this.baseUrl = baseUrl || envConfig.apiBaseUrl;
    this.endpoint = endpoint;
    this.body = body;
    this.queryParams = queryParams || {};
    this.headers = headers;
    this.withFile = withFile;
    this.sendSessionToken = sendSessionToken;
  }

  public async get<T = any>(): Promise<T> {
    return await this.request<T>("GET");
  }

  public async post<T = any>(): Promise<T> {
    return await this.request<T>("POST");
  }

  public async put<T = any>(): Promise<T> {
    return await this.request<T>("PUT");
  }

  public async delete<T = any>(): Promise<T> {
    return await this.request<T>("DELETE");
  }

  public async patch<T = any>(): Promise<T> {
    return await this.request<T>("PATCH");
  }

  public async head<T = any>(): Promise<T> {
    return await this.request<T>("HEAD");
  }

  public async request<T>(method: Method): Promise<T> {
    const endpoint = this.getEndpoint();
    const headers = await this.requestHeaders();
    const response = await this.sendRequest(method, endpoint, headers);
    return await this.handleResponse(response, method);
  }

  private getEndpoint(): string {
    const parameters = Object.keys(this.queryParams)
      .filter((key) => this.queryParams[key])
      .map((key) => [key, this.queryParams[key]].map(encodeURIComponent).join("="))
      .join("&");

    return parameters.length === 0 ?
      `${this.baseUrl}${this.endpoint}` :
      `${this.baseUrl}${this.endpoint}?${parameters}`;
  }

  private async requestHeaders(): Promise<Headers> {
    const headers = {
      Accept: "application/json",
      ...this.headers,
    };

    const bodyHeader: Headers = this.body && !this.withFile ? { "Content-Type": "application/json" } : {};

    let tokenHeader: Headers = {};
    if (this.sendSessionToken && envConfig.useAuthenticationHeader) {
      const token = await AuthenticationService.getUserToken();
      tokenHeader = { "X-Medhelper-User": `${token}` };
    }

    return { ...headers, ...tokenHeader, ...bodyHeader };
  }

  private sendRequest(method: Method, endpoint: string, headers: Headers): Promise<Response> {
    const options = { method, headers };
    const bodyOption = this.body ? { body: this.parseBody() } : {};

    return fetch(endpoint, { ...options, ...bodyOption });
  }

  private parseBody(): any {
    return this.withFile ? this.body : JSON.stringify(this.body);
  }

  private async handleResponse(response: Response, method: Method): Promise<any> {
    const json = await this.parseContent(response, method);

    if (!response.ok) {
      const error = {
        content: {
          status: response.status,
          code: json.code,
          message: json.message,
        },
      };

      // TODO: We should log the endpoint once the code is not passed in the URL directly
      CrashReportingService.logError(error);
      throw error.content;
    }

    return json;
  }

  private async parseContent(response: Response, method: Method): Promise<any> {
    try {
      if (this.withFile) {
        return await response.text();
      }

      if (method === "HEAD") {
        return response.headers;
      }

      return await response.json();
    } catch (e) {
      return {};
    }
  }

}

export default Client;

import {
  CustomActivityFormModal,
  CustomActivityModalRef,
} from "../../customActivities/components/CustomActivityFormModal";
import { Divider, makeStyles } from "@material-ui/core";
import React, { useRef } from "react";

import { ReactComponent as ActivityIcon } from "report/assets/activity.svg";
import CarePlanSection from "./CarePlanSection";
import CustomActivitiesSummarySection from "./CustomActivitiesSummarySection";
import CustomActivityFormData from "../../customActivities/domain/CustomActivityFormData";
import { useTranslation } from "react-i18next";

interface Props {
  disabled?: boolean;
  customActivities: CustomActivityFormData[];
  onCustomActivitiesChange?: (customActivities: CustomActivityFormData[]) => void;
  onDeleteClick?: (onConfirm: () => void) => void;
}

export const CustomActivitiesSection = ({
  disabled,
  customActivities = [],
  onCustomActivitiesChange,
  onDeleteClick,
}: Props): JSX.Element => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();
  const customActivityModalRef = useRef<CustomActivityModalRef | null>(null);
  const isReadOnly = !Boolean(onCustomActivitiesChange);

  const addCustomActivity = (customActivity: CustomActivityFormData): void => {
    const updatedOtherActivities = [...customActivities, customActivity];
    onCustomActivitiesChange?.(updatedOtherActivities);
    customActivityModalRef.current?.close();
  };

  const deleteCustomActivity = (customActivityToDelete: CustomActivityFormData): void => {
    const updatedActivities = customActivities
      .filter((customActivity) => customActivity.id !== customActivityToDelete.id);
    onCustomActivitiesChange?.(updatedActivities);
  };

  const onDeleteCustomActivity = (customActivity: CustomActivityFormData): void => {
    onDeleteClick?.(() => deleteCustomActivity(customActivity));
  };

  const editCustomActivity = (customActivityEdited: CustomActivityFormData): void => {
    const updatedCustomActivities = customActivities.map(
      (customActivity: CustomActivityFormData) => customActivity.id === customActivityEdited.id
        ? customActivityEdited : customActivity
    );

    onCustomActivitiesChange?.(updatedCustomActivities);
    customActivityModalRef.current?.close();
  };

  const onClickEdit = (customActivity: CustomActivityFormData): void => {
    customActivityModalRef.current?.show(customActivity);
  };

  const onClickAdd = (): void => customActivityModalRef.current?.show();

  return (
    <CarePlanSection
      Icon={ActivityIcon}
      title={t("section.title.activity")}
      text={t("section.subText.activity")}
      tooltipText={t("tooltip.customActivity")}
      disabled={disabled}
      onClick={isReadOnly ? undefined : onClickAdd}
    >
      <CustomActivityFormModal
        ref={customActivityModalRef}
        onCustomActivityAdd={addCustomActivity}
        onEditCustomActivity={editCustomActivity}
      />

      <ul className={classes.customActivities}>
        {customActivities.map((customActivity, index: number) => (
          <li
            key={`${customActivity.id}`}
            aria-label={customActivity.details.name}
          >
            <CustomActivitiesSummarySection
              isReadOnly={isReadOnly}
              onEditClick={onClickEdit}
              onDeleteClick={onDeleteCustomActivity}
              customActivity={customActivity} />

            {index !== customActivities.length - 1 &&
              <Divider light />
            }
          </li>

        ))}
      </ul>
    </CarePlanSection>
  );
};

const useStyles = makeStyles(() => ({
  customActivities: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
}));

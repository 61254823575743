import React from "react";
import {
  Button, makeStyles, Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AuthenticationIcon from "../../components/AuthenticationIcon";
import theme from "../../../main/theme/theme";
import AuthenticationPageWrapper from "../../components/AuthenticationPageWrapper";
import AuthenticationTextField from "../../components/AuthenticationTextField";
import AuthenticationForm from "../../components/AuthenticationForm";
import { AuthenticationFormErrorsWithLink } from "../../components/AuthenticationFormErrorsWithLink";
import { AuthenticationErrorWithLink } from "../../domain/AuthenticationErrorWithLink";

const useStyles = makeStyles(() => ({
  formRow: {
    marginTop: theme.metrics.margin.normal,
    marginBottom: theme.metrics.margin.medium,
  },
  link: {
    textDecoration: "underline",
    marginLeft: theme.metrics.margin.tiny,
  },
  authenticationIcon: {
    marginBottom: theme.metrics.margin.small,
  },
  resetPasswordText: {
    marginTop: theme.metrics.margin.normal,
  },
  sendButton: {
    width: "initial",
    marginRight: theme.metrics.margin.tiny,
  },
}));

interface Props {
  email: string;
  errors: AuthenticationErrorWithLink[];
  onSubmit: () => void;
  onEmailChange: (email: string) => void;
}

export const RequestPasswordResetForm: React.FunctionComponent<Props> = ({
  email,
  errors,
  onSubmit,
  onEmailChange,
}) => {
  const { t } = useTranslation("authentication");
  const classes = useStyles();
  const onSubmitForm = (event: React.ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <AuthenticationPageWrapper>
      <AuthenticationIcon className={classes.authenticationIcon} />

      <Typography component="h2" variant="h3">
        {t("resetPassword")}
      </Typography>

      <Typography component="h2" variant="h5" color="textPrimary" className={classes.resetPasswordText}>
        {t("resetPasswordInstructions")}
      </Typography>

      <AuthenticationForm onSubmit={onSubmitForm}>
        <AuthenticationTextField
          className={classes.formRow}
          autoFocus
          id="email"
          label={t("email")}
          name="email"
          value={email.toLowerCase()}
          onChange={onEmailChange}
        />

        <AuthenticationFormErrorsWithLink errors={errors} />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!email}
          className={classes.sendButton}
        >
          {t("send")}
        </Button>
      </AuthenticationForm>
    </AuthenticationPageWrapper>
  );
};

export default RequestPasswordResetForm;

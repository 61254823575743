import PendingRegimenFormData from "./PendingRegimenFormData";

class PendingSchedulableMeasurementFormData implements PendingRegimenFormData {
  public name: string;
  constructor(name: string) {
    this.name = name;
  }
}
export default PendingSchedulableMeasurementFormData;


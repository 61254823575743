import Reasons from "../Reasons";
import Adherences from "../Adherences";
import DrugTypes from "./DrugTypes";
import MedicationSchedule from "./MedicationSchedule";
import RegimenReportCode from "../RegimenReportCode";
import SupportedLanguages from "main/domain/SupportedLanguages";
import i18next from "i18next";

interface MedicationAttributes {
  name: string;
  dosage: string | undefined;
  type: DrugTypes | undefined;
  purpose: Reasons | undefined;
  din: string | undefined;
  prescribingDoctor: string | undefined;
  schedule: MedicationSchedule | undefined;
  adherence: Adherences;
  reportCode: RegimenReportCode;
}

export default class Medication {
  public name: string;
  public dosage: string | undefined;
  public type: DrugTypes | undefined;
  public purpose: Reasons | undefined;
  public din: string | undefined;
  public prescribingDoctor: string | undefined;
  public schedule: MedicationSchedule | undefined;
  public adherence: Adherences;
  public reportCode: RegimenReportCode;

  constructor(plain: MedicationAttributes) {
    this.name = plain.name;
    this.dosage = plain.dosage;
    this.type = plain.type;
    this.purpose = plain.purpose;
    this.din = plain.din;
    this.prescribingDoctor = plain.prescribingDoctor;
    this.schedule = plain.schedule;
    this.adherence = plain.adherence;
    this.reportCode = plain.reportCode;
  }

  public getFormattedSchedule(language: SupportedLanguages): string {
    if (this.schedule) {
      return this.schedule.toText(language);
    }

    return i18next.t("report:asNeeded");
  }

  public toString(): string {
    return JSON.stringify(this);
  }
}

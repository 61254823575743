import Client from "../../main/services/ApiClient";
import HistoricalInvitationResponse from "./response/HistoricalInvitationResponse";

class ConsultationSummaryApi {
  public async fetchConsultationSummary(consultationId: string): Promise<HistoricalInvitationResponse> {
    return await new Client({
      endpoint: `/doctors/invite/consultation/${consultationId}`,
    })
      .get<HistoricalInvitationResponse>();
  }
}

export default new ConsultationSummaryApi();

import ChangePasswordError from "./ChangePasswordError";

interface ChangePasswordFormErrorsFields {
  currentPassword?: ChangePasswordError[];
  newPassword?: ChangePasswordError[];
  body?: ChangePasswordError[];
}

export default class ChangePasswordFormErrors {
  public readonly currentPassword: ChangePasswordError[];
  public readonly newPassword: ChangePasswordError[];
  public readonly body: ChangePasswordError[];

  constructor({ currentPassword, newPassword, body = [] }: ChangePasswordFormErrorsFields) {
    this.currentPassword = currentPassword || [];
    this.newPassword = newPassword || [];
    this.body = body;
  }

  public static empty(): ChangePasswordFormErrors {
    return new ChangePasswordFormErrors({
      body: [],
    });
  }

  public isEmpty(): boolean {
    return this.currentPassword.length === 0 &&
      this.newPassword.length === 0 &&
      this.body.length === 0;
  }
}

import React, { FC } from "react";
import { MeasurementType } from "measurement/domain/MeasurementType";
import { makeStyles, Typography } from "@material-ui/core";
import { MeasurementIcon } from "../MeasurementIcon";
import { useTranslation } from "react-i18next";
import theme from "main/theme/theme";

interface Props {
  measurementType: MeasurementType;
  onSelect: (type: MeasurementType) => void;
}

export const MeasurementTypeCard: FC<Props> = ({ measurementType, onSelect }): JSX.Element => {
  const { t } = useTranslation("measurement");
  const styles = useStyles();

  return (
    <button className={styles.card} onClick={(): void => onSelect(measurementType)}>
      <div className={styles.icon}>
        <MeasurementIcon measurementType={measurementType} />
      </div>

      <Typography variant="h4">
        {t("measurementType." + measurementType)}
      </Typography>
    </button>
  );
};

const useStyles = makeStyles(() => ({
  card: {
    "backgroundColor": theme.colors.button.secondary.background,
    "border": "1px solid white",
    "padding": `${theme.metrics.margin.normal} ${theme.metrics.margin.normal}`,
    "display": "flex",
    "boxShadow": "rgba(0, 0, 0, 0.4) 0 1px 3px",
    "alignItems": "center",
    "gap": theme.metrics.margin.medium,
    "cursor": "pointer",

    "&:hover": {
      borderColor: theme.colors.primary.normal,
    },
  },
  icon: {
    display: "flex",
    width: "30px",
    alignSelf: "center",
    justifyContent: "center",
  },
}));

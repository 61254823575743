import { useEffect } from "react";

const useWindowResize = (callback: (size: { width: number; height: number }) => void): void => {
  useEffect(() => {
    const handleResize = (): void => {
      callback({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  });
};

export default useWindowResize;

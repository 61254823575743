import React, { PropsWithChildren } from "react";
import {
  makeStyles, TableHead, TableRow,
} from "@material-ui/core";
import theme, { convertHexToRGBA } from "../../main/theme/theme";

const useStyles = makeStyles((muiTheme) => ({
  header: {
    "& th": {
      color: muiTheme.palette.primary.contrastText,
      backgroundColor: convertHexToRGBA(theme.colors.table.header, 20),
    },
  },
}));

export const ReportResultsTableHead = ({ children }: PropsWithChildren<any>): JSX.Element => {
  const styles = useStyles();
  return (
    <TableHead>
      <TableRow classes={{ root: styles.header }}>
        {children}
      </TableRow>
    </TableHead>
  );
};

import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router";
import PrivateRoute from "authentication/components/PrivateRoute";
import Head from "main/components/Head";
import ViewReportPage from "report/ViewReportPage";
import MeasurementResultsPage from "report/MeasurementResultsPage";
import ConsultationSummary from "consultationSummary/ConsultationSummary";
import ConnectionHistory from "connections/ConnectionHistory";
import PrescribePage from "prescribe/PrescribePage";
import ProfilePage from "authentication/profile/pages/ProfilePage";
import NavigationPath from "./NavigationPath";
import ActivityResultsPage from "report/ActivityResultsPage";
import MedicationResultsPage from "report/MedicationResultsPage";
import ReminderResultsPage from "report/ReminderResultsPage";

interface Props {
  PageMenuWrapper: React.FunctionComponent;
  authenticated: boolean;
}

const PrivateRoutes = ({
  PageMenuWrapper,
  authenticated,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PrivateRoute authenticated={authenticated}>
      <Route exact path={NavigationPath.PATIENT_REPORT}>
        <Head title={t("titles:report")} />
        <ViewReportPage />
      </Route>

      <Route exact path={NavigationPath.PATIENT_REPORT_MEASUREMENTS}>
        <Head title={t("titles:report")} />
        <MeasurementResultsPage />
      </Route>

      <Route exact path={NavigationPath.PATIENT_REPORT_ACTIVITIES}>
        <Head title={t("titles:report")} />
        <ActivityResultsPage />
      </Route>

      <Route exact path={NavigationPath.PATIENT_REPORT_REMINDERS}>
        <Head title={t("titles:report")} />
        <ReminderResultsPage />
      </Route>

      <Route exact path={NavigationPath.PATIENT_REPORT_MEDICATIONS}>
        <Head title={t("titles:report")} />
        <MedicationResultsPage />
      </Route>

      <Route exact path={NavigationPath.CONSULTATION_SUMMARY}>
        <Head title={t("titles:report")} />
        <ConsultationSummary />
      </Route>

      <Route exact path={NavigationPath.CONNECTION_HISTORY}>
        <Head title={t("titles:connectionHistory")} />
        <PageMenuWrapper>
          <ConnectionHistory />
        </PageMenuWrapper>
      </Route>

      <Route exact path={NavigationPath.PRESCRIBE}>
        <Head title={t("titles:inviteAPatient")} />
        <PageMenuWrapper>
          <PrescribePage />
        </PageMenuWrapper>
      </Route>

      <Route exact path={NavigationPath.MY_ACCOUNT}>
        <Head title={t("titles:profile")} />
        <PageMenuWrapper>
          <ProfilePage />
        </PageMenuWrapper>
      </Route>

      {children}
    </PrivateRoute>
  );
};

export default PrivateRoutes;

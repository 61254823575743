import React from "react";
import DateTime from "../../main/dateTime/DateTime";
import SessionFormData from "../domain/SessionFormData";
import { SessionInput } from "./SessionInput";
import AlarmSection from "../../schedule/components/AlarmSection";
import { ReminderForm } from "./ReminderForm";
import { useTranslation } from "react-i18next";

interface Props {
  session: SessionFormData;
  index: number;
  onChange: (event: SessionFormData) => void;
  canDelete: boolean;
  onDelete: (event: SessionFormData) => void;
}

export const ReminderSessionRenderer = ({
  session,
  onChange,
  index,
  canDelete,
  onDelete,
}: Props): JSX.Element => {
  const { t } = useTranslation("customActivity");

  const onTimeChange = (newValue: DateTime): void => {
    const updatedEvent = new SessionFormData({ ...session, time: newValue });
    onChange(updatedEvent);
  };

  return (
    <ReminderForm
      session={session}
      index={index}
      label={t("reminder")}
      canDelete={canDelete}
      onDelete={onDelete}
      ariaLabel="session-input"
    >
      <SessionInput
        event={session}
        onTimeChange={onTimeChange}
      />

      <AlarmSection
        id={session.id}
        alarm={session.alarm}
        onChange={(alarm): void => onChange(new SessionFormData({ ...session, alarm }))}
      />
    </ReminderForm>
  );
};

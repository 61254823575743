import { useTranslation } from "react-i18next";
import {
  makeStyles, Typography, Divider, TableBody, TableRow, TableCell,
} from "@material-ui/core";
import theme, { convertHexToRGBA } from "main/theme/theme";
import React from "react";
import { ReportResultsTable } from "./ReportResultsTable";
import { ReportResultsTableHead } from "./ReportResultsTableHead";
import { ReportResultsTableHeaderTitle } from "./ReportResultsTableHeaderTitle";
import { weekdays } from "schedule/domain/Weekday";
import { DateTime as LuxonDateTime } from "luxon";
import NotesReport from "report/domain/note/NotesReport";
import NotesTableResults from "./notes/NotesTableResults";
import ReminderResult from "report/domain/reminders/ReminderResult";

interface Props {
  reminders: ReminderResult[] | null;
  notes: NotesReport;
  name: string;
}

export const ReminderResultsTable: React.FunctionComponent<Props> = ({ reminders, notes, name }) => {
  const styles = useStyles();
  const { t } = useTranslation("report");
  const sortedReminders = reminders?.slice().sort(
    (a, b) => a.expectedTimestamp.isBefore(b.expectedTimestamp)
      ? 1 : -1
  );

  return (
    <>
      <Typography component="h3" variant="h3" color="textPrimary" className={styles.title}>
        {name}
      </Typography>

      <Divider className={styles.divider} />

      <NotesTableResults notes={notes} />

      <ReportResultsTable>
        <ReportResultsTableHead>
          <TableCell />

          <ReportResultsTableHeaderTitle title={t("dateOnly")} />

          <ReportResultsTableHeaderTitle title={t("status")} />

          <ReportResultsTableHeaderTitle title={t("scheduleTime")} />

          <ReportResultsTableHeaderTitle title={t("completedTime")} />
        </ReportResultsTableHead>

        <TableBody>
          {sortedReminders?.map((reminder) => (
            <TableRow key={reminder.expectedTimestamp.toISO()} classes={{ root: styles.row }}>
              <TableCell>
                <Typography>{t(`weekdays:${weekdays[reminder.expectedTimestamp.getDayOfWeek()]}`)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{reminder.expectedTimestamp.formatLocale(LuxonDateTime.DATE_SHORT)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{t("completed")}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{reminder.expectedTimestamp.formatLocale(LuxonDateTime.TIME_SIMPLE)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{reminder.actualTimestamp.formatLocale(LuxonDateTime.TIME_SIMPLE)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ReportResultsTable>
    </>
  );
};

const useStyles = makeStyles(() => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: convertHexToRGBA(theme.colors.table.row, 100),
    },
  },
  title: {
    fontWeight: "bold",
    margin: `${theme.metrics.margin.small} 0`,
  },
  divider: {
    marginBottom: theme.metrics.margin.large,
  },
}));

import useProfileRepository from "authentication/store/hooks/useProfileRepository";
import useUserRepository from "authentication/store/hooks/useUserRepository";
import { isClientConnectContext } from "main/selectors/AppContextSelector";
import React from "react";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import NavigationPath from "router/NavigationPath";

interface Props {
  authenticated: boolean;
}

const PrivateRoute: React.FunctionComponent<Props> = ({
  authenticated,
  children,
}) => {
  const { user } = useUserRepository();
  const { profile } = useProfileRepository();
  const { pathname } = useLocation();

  const isClientConnectProfileIncomplete = isClientConnectContext() && !user?.agreementDate;

  if (authenticated && (!profile || isClientConnectProfileIncomplete)) {
    return <Redirect to={NavigationPath.COMPLETE_PROFILE} />;
  }

  if (!authenticated) {
    return <Redirect to={{ pathname: NavigationPath.AUTHENTICATION, state: { from: pathname } }} />;
  }

  return <>{children}</>;
};

export default PrivateRoute;

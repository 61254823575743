import { StateAction } from "./GlobalProvider";
import { AppState } from "./GlobalStore";

const globalStateReducer = (state: AppState, action: StateAction): AppState => {
  if (action.reducer) {
    return action.reducer(state);
  }
  return state;
};

export default globalStateReducer;

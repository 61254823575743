import DateTime from "../../main/dateTime/DateTime";
import Note from "../domain/note/Note";
import NoteRegimenType from "../domain/note/NoteRegimenType";
import NotesReport from "../domain/note/NotesReport";
import NoteStatus from "../domain/note/NoteStatus";
import NotesResponse from "./responses/NotesResponse";
import ResultsNotesResponse from "./responses/ResultsNotesResponse";
import { v4 as uuid } from "uuid";

class NoteAssembler {
  public static fromResponse(responses: NotesResponse): NotesReport {
    const notes = responses.results.map((response) =>
      new Note({
        identifier: response.identifier,
        text: response.text,
        regimenName: response.regimenName,
        createdAt: DateTime.fromISO(response.createdAt),
        regimenType: NoteRegimenType[response.regimenType as keyof typeof NoteRegimenType],
      })
    );

    return {
      results: notes,
      status: NoteStatus[responses.status as NoteStatus],
    };
  }

  public static fromResultsResponse(resultNotes: ResultsNotesResponse[]): NotesReport {
    const notes = resultNotes.map((response) =>
      new Note({
        identifier: uuid(),
        text: response.noteValue,
        createdAt: DateTime.fromISO(response.noteDate),
      })
    );

    return {
      results: notes,
      status: NoteStatus.OK,
    };
  }
}

export default NoteAssembler;

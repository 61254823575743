import { Language } from "rrule/dist/esm/nlp/i18n";
import { TokenTranslations } from "./types";

export const dates: Language = {
  dayNames: [
    "dimanche",
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
  ],
  monthNames: [
    "janvier",
    "février",
    "mars",
    "avril",
    "may",
    "juin",
    "juillet",
    "aout",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ],
  // `tokens` are only needed for `RRule.fromText`
  tokens: {},
};

export const tokens: TokenTranslations = {
  "every": "chaque",
  "until": "jusqu'au",
  "day": "jour",
  "days": "jours",
  "week": "semaine",
  "weeks": "semaines",
  "on": "le",
  "month": "mois",
  "months": "mois",
  "and": "et",
  "on the": "le",
  "(~ approximately)": "(~ approximativement)",
  "at": "à",
  "for": "pour",
  "hour": "heure",
  "hours": "heures",
  "in": "dans",
  "last": "dernier",
  "minutes": "minutes",
  "or": "ou",
  "st": "",
  "nd": "",
  "rd": "",
  "th": "",
  "the": "le",
  "time": "fois",
  "times": "fois",
  "weekday": "jour de la semaine",
  "weekdays": "jours de la semaine",
  "year": "année",
  "years": "années",
};

import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useUserProfile } from "authentication/hooks/useUserProfile";
import { Auth } from "aws-amplify";
import { getSessionContext } from "main/selectors/AppContextSelector";
import theme from "main/theme/theme";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAppExceptionRepository from "store/hooks/useAppExceptionRepository";
import ClientConnectError from "../domain/ClientConnectError";
import { LocalSessionContext } from "../domain/SessionContext";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    fontSize: theme.fonts.h2.size,
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
  },
}));

const ClientConnectAuthenticationContainer: React.FunctionComponent = () => {
  const styles = useStyles();
  const { addException } = useAppExceptionRepository();
  const { t } = useTranslation("authentication");
  const { initUserProfile } = useUserProfile();

  const signIn = useCallback(async () => {
    try {
      const { professionalIdentifier, token, organizationName } = getSessionContext() as LocalSessionContext;
      const challenge = await Auth.signIn(professionalIdentifier);

      if (challenge.challengeName === "CUSTOM_CHALLENGE") {
        await Auth.sendCustomChallengeAnswer(challenge, token);

        await initUserProfile(organizationName);
      }
    } catch (e) {
      addException(ClientConnectError.FailedToSignIn);
    }
  }, [addException, initUserProfile]);

  useEffect(() => {
    signIn();
  }, [signIn]);

  return (
    <div className={styles.loadingContainer}>
      {t("authenticating")}
      <CircularProgress />
    </div>
  );
};

export default ClientConnectAuthenticationContainer;

import DateTime from "../../main/dateTime/DateTime";
import SupportedLanguages from "../../main/domain/SupportedLanguages";
import Gender from "../../report/domain/Gender";
import CarePlanStepFormData from "./CarePlanStepFormData";

export interface PrescribeFormFields {
  email: string;
  firstName: string | undefined;
  lastName: string | undefined;
  birthDate: DateTime | undefined;
  gender: Gender | undefined;
  carePlan: CarePlanStepFormData;
  language: SupportedLanguages;
  allergies: string | undefined;
}

export default class PrescribeFormData {

  public readonly language: SupportedLanguages;
  public readonly email: string;
  public readonly firstName: string | undefined;
  public readonly lastName: string | undefined;
  public readonly birthDate: DateTime | undefined;
  public readonly allergies: string | undefined;
  public readonly gender: Gender | undefined;
  public readonly carePlan: CarePlanStepFormData;

  constructor({
    language,
    email,
    firstName,
    lastName,
    birthDate,
    allergies,
    gender,
    carePlan,
  }: PrescribeFormFields) {
    this.language = language;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.birthDate = birthDate;
    this.allergies = allergies;
    this.gender = gender;
    this.carePlan = carePlan;
  }

  public static empty(): PrescribeFormData {
    return new PrescribeFormData({
      language: "en",
      email: "",
      firstName: "",
      lastName: "",
      birthDate: undefined,
      allergies: "",
      gender: undefined,
      carePlan: CarePlanStepFormData.empty(),
    });
  }

  public withLanguage(language: SupportedLanguages): PrescribeFormData {
    return new PrescribeFormData({ ...this, language });
  }

  public withEmail(email: string): PrescribeFormData {
    return new PrescribeFormData({ ...this, email });
  }

  public withFirstName(firstName: string): PrescribeFormData {
    return new PrescribeFormData({ ...this, firstName });
  }

  public withLastName(lastName: string): PrescribeFormData {
    return new PrescribeFormData({ ...this, lastName });
  }

  public withBirthDate(birthDate: DateTime | undefined): PrescribeFormData {
    return new PrescribeFormData({ ...this, birthDate });
  }

  public withGender(gender: Gender | undefined): PrescribeFormData {
    return new PrescribeFormData({ ...this, gender });
  }

  public withCarePlan(carePlan: CarePlanStepFormData): PrescribeFormData {
    return new PrescribeFormData({ ...this, carePlan });
  }

  public withAllergies(allergies: string): PrescribeFormData {
    return new PrescribeFormData({ ...this, allergies });
  }

  public isFilled(): boolean {
    return !!this.email;
  }
}

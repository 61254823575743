import PendingTreatmentPlan from "prescribe/domain/PendingTreatmentPlan";
import React from "react";
import { Typography } from "@material-ui/core";
import { MedicationsSection } from "prescribe/components/MedicationsSection";
import { CustomActivitiesSection } from "prescribe/components/CustomActivitiesSection";
import { MeasurementsSection } from "prescribe/components/MeasurementsSection";
import { CustomProgramSummaries } from "prescribe/components/customPrograms/CustomProgramSummaries";
import { useTranslation } from "react-i18next";
import { RemindersSection } from "prescribe/components/reminder/RemindersSection";

interface Props {
  treatmentPlan?: PendingTreatmentPlan | null;
  titleClassname: string;
}

export const CarePlanSummary = ({ treatmentPlan, titleClassname }: Props): JSX.Element => {
  const { t } = useTranslation("consultationSummary");
  const hasMedication = !!treatmentPlan?.medications.list.length;
  const hasMeasurement = !!treatmentPlan?.measurements.list.length;
  const hasCustomActivities = !!treatmentPlan?.customActivities.list.length;
  const hasReminders = !!treatmentPlan?.reminders.list.length;
  const hasCustomProgram = !!treatmentPlan?.pendingCustomPrograms.list.length;
  const showCarePlan = hasMedication || hasMeasurement || hasCustomActivities || hasReminders || hasCustomProgram;

  return (
    <>
      <Typography component="label" variant="h3" className={titleClassname}>
        {t("carePlan")}
      </Typography>

      {
        treatmentPlan && showCarePlan
          ? <>
            {
              !!hasMedication
              && <MedicationsSection medications={treatmentPlan.medications.list} />
            }

            {
              !!hasCustomActivities
              && <CustomActivitiesSection customActivities={treatmentPlan.customActivities.list} />
            }

            {
              !!hasMeasurement
              && <MeasurementsSection measurements={treatmentPlan.measurements.list} />
            }

            {
              !!hasReminders
              && <RemindersSection reminders={treatmentPlan.reminders.list} />
            }

            <CustomProgramSummaries customPrograms={treatmentPlan.pendingCustomPrograms.list} />
          </>
          : <Typography component="div" variant="h5">{t("noCarePlan")}</Typography>
      }
    </>
  );

};

import PrescribeFormData from "../../domain/PrescribeFormData";
import InvitationDto from "../dtos/InvitationDto";
import PatientProfileAssembler from "./PatientProfileAssembler";
import PendingTreatmentPlanAssembler from "./PendingTreatmentPlanAssembler";
import { ConsultationDocumentDto } from "../../../document/api/dto/ConsultationDocumentDto";

class InvitationAssembler {
  public static toDto(
    prescribeFormData: PrescribeFormData,
    uploadedDocuments: ConsultationDocumentDto[]
  ): InvitationDto {
    const { email, language, carePlan } = prescribeFormData;
    return {
      email,
      language,
      instructions: carePlan.instructions,
      documents: uploadedDocuments,
      profile: PatientProfileAssembler.toDto(prescribeFormData),
      pendingTreatmentPlan: PendingTreatmentPlanAssembler.toDto(carePlan.pendingTreatmentPlan),
    };
  }
}

export default InvitationAssembler;

import Patient from "./Patient";
import Treatment from "./Treatment";
import Consultation from "./PreviousConsultation";
import NotesReport from "./note/NotesReport";

export default class PatientReport {
  public patient: Patient;
  public treatment: Treatment;
  public previousConsultations: Consultation[];
  public notes: NotesReport;

  constructor(
    patient: Patient,
    treatment: Treatment,
    previousConsultations: Consultation[],
    notes: NotesReport,
  ) {
    this.patient = patient;
    this.treatment = treatment;
    this.previousConsultations = previousConsultations;
    this.notes = notes;
  }
}

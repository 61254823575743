import React, { useState } from "react";
import { AuthenticatorChildrenProps, AuthenticatorOverridingViewProps } from "aws-amplify-react";
import RequestPasswordResetForm from "../resetPassword/containers/RequestPasswordResetForm";
import useRequestPasswordResetForm from "../resetPassword/hooks/useRequestPasswordResetForm";
import { useTranslation } from "react-i18next";

type Props = AuthenticatorOverridingViewProps;

type InjectedProps = Props & AuthenticatorChildrenProps;

const AmplifiedForgotPassword = (props: Props): JSX.Element | null => {
  const { onStateChange, authState } = props as InjectedProps;
  const { t } = useTranslation("authentication");

  const [email, setEmail] = useState<string>("");
  const goToResetPassword = (): void => {
    onStateChange("resetPassword", { email: email.toLowerCase(), title: t("resetPassword") });
    setEmail("");
  };
  const { requestPasswordReset, errors } = useRequestPasswordResetForm({ onSuccess: goToResetPassword });
  const submit = (): void => requestPasswordReset(email.toLowerCase());

  if (authState !== "forgotPassword") {
    return null;
  }

  return (
    <RequestPasswordResetForm
      email={email.toLowerCase()}
      errors={errors}
      onSubmit={submit}
      onEmailChange={setEmail}
    />
  );
};

export default AmplifiedForgotPassword;

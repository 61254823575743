import React from "react";
import Percentage from "../../../../main/domain/Percentage";
import PercentWheel from "./PercentWheel";
import CountWheel from "./CountWheel";

interface Props {
  progress: number | Percentage;
  size: number;
  numberOfDays: number;
}

export const AdherenceWheel: React.FunctionComponent<Props> = ({ progress, size, numberOfDays }) => {

  if (progress instanceof Percentage) {
    return (
      <PercentWheel
        progress={progress}
        size={size}
        numberOfDays={numberOfDays}
      />
    );
  } else {
    return (
      <CountWheel
        count={progress}
        size={size}
        numberOfDays={numberOfDays}
      />
    );
  }
};

export default AdherenceWheel;

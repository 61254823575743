enum AuthenticationError {
  UsernameExistsException = "UsernameExistsException",
  InvalidEmail = "InvalidEmail",
  EmptyEmail = "EmptyEmail",
  InvalidPassword = "InvalidPassword",
  EmptyPassword = "EmptyPassword",
  InvalidPasswordException = "InvalidPasswordException",
  InvalidPasswordConfirmed = "InvalidPasswordConfirmed",
  PasswordResetRequiredException = "PasswordResetRequiredException",
  AuthenticationDefault = "AuthenticationDefault",
  NetworkError = "NetworkError",
  NotAuthorizedException = "NotAuthorizedException",
  UserNotFoundException = "UserNotFoundException",
  UserNotConfirmedException = "UserNotConfirmedException",
  UserNotFoundExceptionResetPassword = "UserNotFoundExceptionResetPassword",
  CodeMismatchException = "CodeMismatchException",
  InvalidParameterException = "InvalidParameterException",
  InvalidParameterExceptionResetPassword = "InvalidParameterExceptionResetPassword",
  RequireNewPassword = "RequireNewPassword",
  RequireResetPassword = "RequireResetPassword",
  UserNotFoundExceptionExpiredPassword = "UserNotFoundExceptionExpiredPassword",
  UserIsDisabledException = "UserIsDisabledException",
  LoginAttemptsUserDisabled = "LoginAttemptsUserDisabled"
}

export default AuthenticationError;

import DrugPicture from "../domain/DrugPicture";
import Client from "../../main/services/ApiClient";
import DrugPictureAssembler from "./DrugPictureAssembler";

export interface DrugPictureResponse {
  jpegBase64: string;
}

class DrugPictureApi {

  public static async fetchPicture(din: string, maxWidth: number): Promise<DrugPicture> {
    const response = await new Client({
      endpoint: `/doctors/drugs/${din}/picture`,
      queryParams: { maxWidth: maxWidth.toString() },
    })
      .get<DrugPictureResponse>();

    return DrugPictureAssembler.assembleToInternal(response);
  }
}

export default DrugPictureApi;

import Adherences from "../Adherences";
import MeasurementSchedule from "./MeasurementSchedule";
import { MeasurementType } from "../../../measurement/domain/MeasurementType";
import RegimenReportCode from "../RegimenReportCode";
import i18next from "i18next";
import SupportedLanguages from "main/domain/SupportedLanguages";

export interface MeasurementAttributes {
  name: string;
  schedule: MeasurementSchedule | null;
  adherence: Adherences;
  type: MeasurementType;
  reportCode: RegimenReportCode;
}

export default abstract class Measurement {
  public name: string;
  public schedule: MeasurementSchedule | null;
  public adherence: Adherences;
  public type: MeasurementType;
  public reportCode: RegimenReportCode;

  protected constructor({ name, schedule, adherence, type, reportCode }: MeasurementAttributes) {
    this.name = name;
    this.schedule = schedule;
    this.adherence = adherence;
    this.type = type;
    this.reportCode = reportCode;
  }

  public abstract displayName(): string;

  public getFormattedSchedule(language: SupportedLanguages): string {
    if (this.schedule) {
      return this.schedule.toText(language);
    }

    return i18next.t("report:asNeeded");
  }

  public toString(): string {
    return JSON.stringify(this);
  }
}


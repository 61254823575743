import React, { createContext, useReducer } from "react";
import globalStateReducer from "./GlobalStateReducer";
import { AppState } from "./GlobalStore";

export interface StateAction {
  reducer: (state: AppState) => AppState;
}

export type DispatchAction = (action: StateAction) => void;

export interface GlobalContext {state: AppState; dispatch: DispatchAction}

export const GlobalStateContext = createContext<GlobalContext>({} as any);

const GlobalStateProvider: React.FunctionComponent<{store: AppState}> = ({ store, children }) => {
  const [state, dispatch] = useReducer(
    globalStateReducer,
    store
  );

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;

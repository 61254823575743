import { ConsultationDocumentErrors } from "./ConsultationDocumentErrors";

const consultationDocument = {
  en: {
    consultationDocument: {
      sectionTitle: "Share information",
      sectionSubText: "Attach a document ",
      errors: {
        [ConsultationDocumentErrors.InvalidExtension]: "Invalid file format",
        [ConsultationDocumentErrors.InvalidSize]: "Invalid file size",
        [ConsultationDocumentErrors.InvalidNameLength]: "Invalid filename size.",
      },
      deleteDocument: "Delete file",
      uploadFiles: "Upload files",
      file: "File",
      attachFileErrors: "There was an error with the following files",
      error: "error",
      acceptedFormat: "Accepted file formats : {{formats}}",
      acceptedSize: "Max file size : {{maxFileSize}} MB",
      deleteErrors: "Delete errors",
    },
  },
  fr: {
    consultationDocument: {
      sectionTitle: "Partagez de l'information",
      sectionSubText: "En joignant un document",
      errors: {
        [ConsultationDocumentErrors.InvalidExtension]: "Format de fichier invalide",
        [ConsultationDocumentErrors.InvalidSize]: "Taille de fichier invalide",
        [ConsultationDocumentErrors.InvalidNameLength]: "Longueur invalide du nom de fichier.",
      },
      deleteDocument: "Supprimer fichier",
      uploadFiles: "Téléverser fichiers",
      file: "Fichier",
      attachFileErrors: "Une erreur s'est produite avec les fichiers suivants",
      error: "erreur",
      acceptedFormat: "Formats de fichier acceptés : {{formats}}",
      acceptedSize: "Taille maximale des fichiers : {{maxFileSize}} MB",
      deleteErrors: "Supprimer les erreurs",
    },

  },
};

export default consultationDocument;

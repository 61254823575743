/* eslint-disable */
const privacyPolicy =
  `<0>DÉCLARATION DE CONFIDENTIALITÉ DE MEDHELPER FOR PROFESSIONALS</0>
Date d’entrée en vigueur : 18 juillet 2022

Chez MedHelper Inc. (« MedHelper » ou « MédAide »), votre vie privée est importante pour nous. L’objectif de cette déclaration de confidentialité est de vous informer de la manière dont nous collectons, utilisons et divulguons les informations personnelles, et de vous informer de vos droits concernant ces informations personnelles. Elle prend effet à la date d’entrée en vigueur.

<0>À PROPOS DE MEDHELPER</0>
MedHelper for Professionals ou MédAide pour les professionnels est un portail en ligne où les médecins et autres professionnels de la santé peuvent consulter les données déclarées par les patients (le « Service »).
Nos services ne sont pas destinés à être utilisés par des enfants et ne doivent être accessibles qu’aux personnes âgées d’au moins 18 ans qui utilisent les services à des fins professionnelles.

<0>QUELLES SONT LES INFORMATIONS QUE NOUS RECUEILLONS</0>
Nous recueillons les informations vous concernant dans la mesure où elles sont raisonnablement nécessaires pour les activités suivantes :

Utilisation de nos services

Nous recueillons les informations suivantes lorsque vous utilisez nos services :

Informations sur le compte :

Vos coordonnées et informations de profil, y compris votre nom, votre compagnie, votre adresse électronique, votre numéro de téléphone au bureau, votre adresse professionnelle et votre profession ; vos préférences telles que la langue, le fuseau horaire et l’image (si vous choisissez de les fournir).

Informations de facturation et autres informations de paiement, y compris les détails des méthodes de paiement, comme le numéro de carte de crédit.

Les services que vous avez acquis auprès de nous, y compris le type de plan et les informations sur les transactions liées aux services.

Le contenu :

Les journaux, l’utilisation et les données d’assistance :

Les données de connexion, qui peuvent inclure votre adresse IP, l’adresse de la page web que vous avez visitée avant d’utiliser les Services, le type et les paramètres de votre navigateur, les informations relatives à votre appareil (telles que la marque, le modèle et le système d’exploitation), la date et l’heure auxquelles vous avez utilisé les Services, les informations relatives à la configuration de votre navigateur, les préférences linguistiques, les identifiants uniques et les cookies.

Les données d’utilisation et les analyses, qui peuvent inclure la fréquence de connexion et les différents types d’activités entreprises par les utilisateurs.

Des informations générales de localisation, telles que l’adresse IP et la région dans laquelle vous vous trouvez lorsque vous vous connectez et utilisez les services.

Les questions, les problèmes et les commentaires généraux que vous choisissez de fournir au service clientèle.

<0>LA DIVULGATION D'INFORMATIONS PERSONNELLES</0>
MedHelper peut partager des informations personnelles avec des personnes au sein de l’entreprise qui ont un « besoin de connaître » ces informations pour des raisons commerciales ou juridiques, par exemple, afin d’exercer une fonction administrative, comme le traitement d’une facture ou des questions justificatives que vous avez soumises à MedHelper. Nous pouvons partager les informations personnelles avec des tiers, y compris :
les autorités gouvernementales et réglementaires, par exemple pour répondre à une demande légale ou se conformer à une obligation légale, auquel cas nous ferons des efforts raisonnables pour informer la personne concernée de la divulgation, à condition que nous puissions l’identifier et que nous soyons légalement en mesure de le faire ;
dans le but de demander un avis juridique ou un autre avis professionnel ;

aux fournisseurs de services informatiques et aux tiers fournisseurs de services engagés par MedHelper, comme indiqué dans la présente déclaration de confidentialité, et dans le cas où nous vendrions, achèterions ou fusionnerions une entreprise ou des actifs, y compris au vendeur ou à l’acheteur potentiel de cette entreprise ou de ces actifs et à leurs conseillers professionnels respectifs.
Nous pouvons également partager des informations anonymes ou dépersonnalisées avec d’autres tiers dans le cadre des objectifs décrits dans la présente déclaration de confidentialité.

<0>DROIT D'ACCÈS</0>
Conformément au droit applicable, vous pouvez avoir certains droits en ce qui concerne vos informations personnelles, y compris un droit d’accès. Nous pouvons avoir besoin d’informations supplémentaires pour confirmer votre identité, qui ne seront utilisées qu’à cette fin.

<0>EMPLACEMENT DES DONNÉES</0>
MedHelper utilise Amazon Web Services pour stocker et gérer les informations sur les clients du Canada.

<0>LES INFORMATIONS RECUEILLIES VIA NOTRE SITE WEB</0>
Lorsque vous visitez notre site web, https://mfp.medhelper.com, nous pouvons utiliser des cookies, des pixels invisibles, des outils d’analyse et d’autres technologies similaires pour vous aider à fournir et à améliorer nos services, comme indiqué ci-dessous :
Cookies : Les cookies sont des éléments d’information stockés directement sur l’ordinateur que vous utilisez. Les cookies nous permettent de collecter des informations telles que le type de navigateur, le temps passé sur le site web, les pages visitées, les préférences linguistiques et d’autres données sur le trafic web. Nous utilisons ces informations à des fins de sécurité, pour faciliter la navigation en ligne, pour afficher les informations plus efficacement, pour personnaliser votre expérience lors de l’utilisation du site web et pour analyser l’activité des utilisateurs. Nous pouvons reconnaître votre ordinateur pour vous aider à utiliser le site web.
Nous recueillons également des informations statistiques sur l’utilisation du site web et de l’application afin d’améliorer continuellement leur conception et leur fonctionnalité, de comprendre comment ils sont utilisés et de nous aider à résoudre les questions concernant le site web et l’application. Les cookies peuvent en outre nous permettre de sélectionner les publicités, les caractéristiques ou les offres les plus susceptibles de vous intéresser et de les afficher. Nous pouvons également utiliser des cookies dans des publicités, des fonctionnalités ou des offres en ligne pour voir comment vous interagissez avec ces publicités, fonctionnalités ou offres et nous pouvons utiliser des cookies ou d’autres fichiers pour comprendre votre utilisation d’autres sites web.
Si vous ne souhaitez pas que des informations soient collectées par l’utilisation de cookies lors de l’utilisation de notre site web ou de notre application, il existe une procédure simple dans la plupart des navigateurs qui vous permet de refuser automatiquement les cookies ou vous donne le choix de refuser ou d’accepter le transfert sur votre ordinateur d’un (ou plusieurs) cookie(s) particulier(s) provenant d’un site particulier. Vous pouvez également consulter le site http://www.allaboutcookies.org/manage-cookies/index.html. Toutefois, si vous n’acceptez pas ces cookies, vous risquez de subir des désagréments lors de votre utilisation du site ou de l’application. Par exemple, il se peut que nous ne puissions pas reconnaître votre ordinateur et que vous deviez vous connecter chaque fois que vous visitez le site ou l’application.
Pixel tags et autres technologies similaires : Les pixels invisibles (également appelés balises web et GIF transparents) peuvent être utilisés en relation avec notre site web pour, entre autres, suivre les actions des utilisateurs du site web et d’autres moyens de communication avec vous (y compris les destinataires de courriels), mesurer le succès de nos campagnes de marketing et compiler des statistiques sur l’utilisation du site web et les taux de réponse.

Outils d’analyse : Nous utilisons des services d’analyse de sites web et d’applications fournis par des tiers qui utilisent des cookies, JavaScript et autres technologies similaires pour collecter des informations sur l’utilisation du site web ou de l’application, effectuer un enrichissement des données et signaler des modèles ou des tendances. Les tiers qui nous fournissent ces services peuvent également collecter des informations sur votre utilisation des sites web tiers. Ces fournisseurs d’analyses peuvent stocker ces données d’analyse aux États-Unis et dans d’autres endroits. Vous trouverez ci-dessous la liste des outils d’analyse que nous utilisons afin que vous puissiez prendre connaissance de leurs conditions de service, dont certaines comprennent des instructions sur la manière dont vous pouvez refuser certaines pratiques de collecte d’informations par des tiers. Nous mettrons cette liste à jour au fur et à mesure que nous modifierons nos outils d’analyse.

<0>LES LOGICIELS TIERS UTILISÉS PAR MEDHELPER SUR LE WEB</0>

Analytique, publicité, suivi et social
Facebook
Outils Google
(y compris mais non limité à Google Analytics, Google Tag Manager, Google AdWords, Google Signals)

LinkedIn
Twitter

Gestion de la relation client, soutien et amélioration des ventes
Zendesk

Plateformes audio et vidéo avec analyse
YouTube

Fournisseurs d'hébergement
AWS

Outils de chat en ligne et plates-formes de communication
Slack

<0>LA PROTECTION DES INFORMATIONS PERSONNELLES</0>

Nous sommes tenus par la loi de protéger les informations personnelles dont nous avons la garde ou le contrôle. Nous utilisons les mesures standard du secteur pour protéger les informations personnelles contre la perte ou le vol, ainsi que contre l’accès, la divulgation, la copie, l’utilisation ou la modification non autorisés. Nous protégeons les informations personnelles quel que soit le format dans lequel elles sont détenues.
Nos méthodes de protection comprennent : (a) des mesures physiques, telles qu’un accès physique restreint aux systèmes fournissant notre service ; (b) des mesures organisationnelles, y compris la formation des employés et la limitation de l’accès sur la base du « besoin de savoir » ; et, (c) des mesures technologiques, y compris l’utilisation de mots de passe et de cryptage.
Nous faisons appel à des fournisseurs de services, y compris notre fournisseur d’hébergement de données, pour faciliter la fourniture du service. Nous utilisons des moyens contractuels pour nous assurer que nos fournisseurs de services ne traitent les informations personnelles qu’en notre nom pour fournir le service et non à d’autres fins. Nous nous engageons également à nous assurer que nos fournisseurs de services mettront en place des mesures de protection adéquates pour protéger les informations personnelles.
Si nous avons des raisons de croire qu’il y a eu une violation des mesures de sécurité qui a entraîné la perte ou la divulgation inappropriée d’informations personnelles, nous prendrons des mesures raisonnables pour informer les clients concernés, le cas échéant, rapidement et avec suffisamment de détails pour leur permettre d’évaluer la violation et de comprendre les conséquences probables.

<0>RÉVISIONS DE LA PRÉSENTE DÉCLARATION DE CONFIDENTIALITÉ</0>

MedHelper peut mettre à jour la présente déclaration de confidentialité de temps à autre. Si elle est mise à jour, la date d’entrée en vigueur de la révision sera indiquée en haut de la déclaration de confidentialité. En cas de révision importante, les clients peuvent recevoir une notification par courrier électronique ou par le biais du service lui-même. Toutes les informations personnelles collectées après cette date de révision seront soumises à la déclaration de confidentialité révisée.

<0>DES INFORMATIONS ANONYMES</0>

MedHelper peut utiliser des informations désidentifiées et/ou agrégées dérivées des informations personnelles, à toutes fins, y compris :
L’analyse pour comprendre comment nos clients et leurs utilisateurs autorisés utilisent le service et notre site Internet et pour faire des offres ciblées de certains services à nos clients et utilisateurs autorisés ;
Les informations utilisées pour déterminer comment apporter des améliorations au service et développer de nouvelles fonctionnalités, capacités et façons de présenter les données ; et

À des fins commerciales, soit pour MedHelper, soit pour d’autres.

Nous prendrons des mesures conformes aux normes du secteur afin que ces informations dépersonnalisées et/ou agrégées ne puissent être reliées à une personne en particulier.

Si vous avez des questions, des préoccupations ou des commentaires, veuillez envoyer un courriel à <2>privacy@medhelper.com</2> ; ou une lettre à

MedHelper Inc.

6500, autoroute Transcanadienne, 4e étage, Pointe-Claire, QC

Canada H9R 0A5

Si nous ne sommes pas en mesure de répondre à vos préoccupations, vous avez également le droit de contacter votre autorité locale de protection des données.
`;

export default privacyPolicy;

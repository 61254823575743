import { Divider, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import MedicationFormData from "../../medication/domain/MedicationFormData";
import SearchDrug from "../../drugs/domain/SearchDrug";
import theme from "../../main/theme/theme";
import DurationSection from "../../prescribe/components/DurationSection";
import InventorySection from "../../prescribe/components/InventorySection";
import ReminderSection from "../../schedule/components/ReminderSection";
import { createEmptyDoseFormData } from "../domain/DoseFormData";
import { useScheduleForm } from "../hooks/useScheduleForm";
import DetailsSection from "./DetailsSection";
import SearchDrugCard from "./SearchDrugCard";
import { DoseInput } from "./DoseInput";
import { RegimenForm } from "../../prescribe/components/RegimenForm";
import DevFlag from "../../main/utilities/DevFlag";
import DevFlagDisplay from "../../main/components/DevFlagDisplay";
import { useDuration } from "../../prescribe/hooks/useDuration";
import { UseMedicationFormResult } from "medication/hooks/useMedicationForm";

interface Props {
  formData: MedicationFormData;
  onSubmit: (medication: MedicationFormData, drug: SearchDrug) => void;
  onChange: UseMedicationFormResult["onChange"];
  drug?: SearchDrug;
  submitLabel: string;
}

export const MedicationFormDetails = ({
  drug,
  formData,
  onChange,
  onSubmit,
  submitLabel,
}: Props): JSX.Element | null => {
  const classes = useStyles();
  const { t } = useTranslation("medication");

  const {
    toggle,
    active,
    addNewEvent,
    updateEvent,
    deleteEvent,
    changeFrequency,
    changeInterval,
    changeWeekdays,
    changeMonthDays,
  } = useScheduleForm(
    formData.schedule,
    (field, value) => onChange("schedule", field, value),
    createEmptyDoseFormData,
  );

  const { onStartDateChange, onEndDateChange } = useDuration(
    formData.schedule,
    (field, value) => onChange("schedule", field, value),
  );

  if (!drug) {
    return null;
  }

  return (
    <RegimenForm
      onSubmit={(): void => onSubmit(formData, drug)}
      onChangeInstruction={(instructions): void => {
        onChange("instructions", "text", instructions);
      }}
      instructions={formData.instructions}
      submitLabel={submitLabel}
    >
      <SearchDrugCard className={classes.card} drug={drug} />

      <DetailsSection
        details={formData.details}
        onChangeFormData={(field, value): void => onChange("details", field, value)}
      />

      <Divider light />

      <DurationSection
        isEndDateEditable
        dateContainerStyle={classes.durationSection}
        durationFormData={formData.schedule.duration}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />

      <Divider light />

      <DevFlagDisplay flag={DevFlag.DISPLAY_INVENTORY}>
        <InventorySection
          inventory={formData.inventory}
          onChangeFormData={(field, value): void => onChange("inventory", field, value)}
        />
      </DevFlagDisplay>

      <Divider light />

      <ReminderSection
        formData={formData.schedule}
        renderEvent={(dose, index): JSX.Element => (
          <DoseInput
            dose={dose}
            onChange={updateEvent}
            index={index}
            canDelete={formData.schedule.events.length > 1}
            onDelete={deleteEvent}
          />
        )}
        active={active}
        onToggle={toggle}
        onAddSession={addNewEvent}
        addEventLabel={t("prescribe:addDose")}
        onChangeFrequency={changeFrequency}
        onChangeInterval={changeInterval}
        onChangeMonthDays={changeMonthDays}
        onChangeWeekdays={changeWeekdays}
      />

      <Divider light />
    </RegimenForm>
  );
};

const useStyles = makeStyles(() => ({
  card: {
    paddingBottom: theme.metrics.margin.normal,
  },
  durationSection: {
    padding: "0",
  },
}));

enum CognitoAttributes {
  FIRST_NAME = "given_name",
  LAST_NAME = "family_name",
  OFFICE_ADDRESS = "custom:officeAddress",
  OFFICE_NUMBER = "custom:officeNumber",
  PROFESSION = "custom:profession",
  EMAIL = "email",
  LATEST_PASSWORD_CHANGE = "custom:latestPasswordChange",
  PARTNER_COMPANY = "custom:partnerCompany",
  AGREEMENT_DATE = "custom:agreementDate",
}

export default CognitoAttributes;

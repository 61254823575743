import React from "react";
import { makeStyles } from "@material-ui/core";
import SideMenu, { MenuItem } from "sideMenu/SideMenu";
import Header from "main/header/container/Header";
import { Footer } from "../Footer";
import { useTranslation } from "react-i18next";
import NavigationPath from "router/NavigationPath";

const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const ClientConnectMenuWrapper: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation("sideMenu");

  const sideMenuItems: MenuItem[] = [
    {
      to: NavigationPath.PRESCRIBE,
      text: t("inviteAPatient"),
      iconName: "prescribe",
    },
    {
      to: NavigationPath.CONNECTION_HISTORY,
      text: t("connectionHistory"),
      iconName: "report-log",
    }];

  return (
    <>
      <SideMenu menuItems={sideMenuItems} />
      <div className={classes.content}>
        <Header onLogout={(): void => window.close()} />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default ClientConnectMenuWrapper;

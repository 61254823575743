import React from "react";
import { Route, Switch } from "react-router-dom";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useUserRepository from "../authentication/store/hooks/useUserRepository";
import useAppLoadingRepository from "../store/hooks/useAppLoadingRepository";
import Head from "../main/components/Head";
import ClientConnectMenuWrapper from "main/components/menuWrapper/ClientConnectMenuWrapper";
import NavigationPath from "./NavigationPath";
import CompleteClientConnectProfilePage from "authentication/profile/pages/CompleteClientConnectProfilePage";
import ClientConnectAuthenticationPage from "authentication/clientConnect/pages/ClientConnectAuthenticationPage";
import PrivateRoutes from "./PrivateRoutes";

const useStyles = makeStyles(() => ({
  activityIndicator: {
    width: "100%",
  },
}));

const Router: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { authenticated } = useUserRepository();
  const { loading } = useAppLoadingRepository();

  if (loading) {
    return (
      <LinearProgress
        className={classes.activityIndicator}
        color="primary"
      />
    );
  }

  return (
    <Switch>
      <Route exact path={NavigationPath.AUTHENTICATION}>
        <ClientConnectAuthenticationPage authenticated={authenticated} />
      </Route>

      <Route exact path={NavigationPath.COMPLETE_PROFILE}>
        <Head title={t("titles:completeProfile")} />
        <CompleteClientConnectProfilePage />
      </Route>

      <PrivateRoutes
        PageMenuWrapper={ClientConnectMenuWrapper}
        authenticated={authenticated}
      />

    </Switch>
  );
};

export default Router;

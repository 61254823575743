import MeasureFormData from "prescribe/domain/MeasureFormData";
import { EventAssembler } from "./EventAssembler";
import ProposedEventDto from "../../dtos/ProposedEventDto";
import ScheduleFormData from "../../../../schedule/domain/ScheduleFormData";

class ProposedBloodGlucoseEventAssembler extends EventAssembler<MeasureFormData> {
  public toDto(measureFormData: MeasureFormData, _: ScheduleFormData<MeasureFormData>): ProposedEventDto {

    return {
      id: measureFormData.id,
      frequency: measureFormData.frequency.toString(),
      amount: measureFormData.amount,
      interval: measureFormData.interval,
      creationDate: measureFormData.creationDate.formatToUtc(),
      expectedTimestamp: measureFormData.time.formatToLocalDateTime(),
      weekdays: [],
      monthDays: [],
      description: this.getDescription(measureFormData),
    };
  }

  // TODO: Validate the utility of this logic as the assembler should not contain domain logics. The same logic
  // is present in the MeasureFormData but localised
  protected getDescription(measureFormData: MeasureFormData): string | undefined {
    return !!measureFormData.meal ?
      `${measureFormData.timeOfDay} ${measureFormData.meal && measureFormData.meal}` :
      `${measureFormData.timeOfDay}`;
  }

}

export default new ProposedBloodGlucoseEventAssembler();

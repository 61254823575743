import AuthenticationError from "../../domain/AuthenticationError";
import { AuthenticationErrorWithLink } from "../../domain/AuthenticationErrorWithLink";

export class UserSignUpFormErrors {

  public readonly email: AuthenticationError[];
  public readonly password: AuthenticationError[];
  public readonly body: AuthenticationErrorWithLink[];

  constructor({ email, password, body }:
  { email?: AuthenticationError[]; password?: AuthenticationError[]; body?: AuthenticationErrorWithLink[] } = {}) {
    this.email = email || [];
    this.password = password || [];
    this.body = body || [];
  }

  public static empty(): UserSignUpFormErrors {
    return new UserSignUpFormErrors({ email: [], password: [], body: [] });
  }

  public isEmpty(): boolean {
    return this.email.length > 0 || this.password.length > 0 || this.body.length > 0;
  }

  public add(error: AuthenticationError, field?: "email" | "password"): UserSignUpFormErrors {
    if (field === "email") {
      return this.addEmailError(error);
    }

    if (field === "password") {
      return this.addPasswordError(error);
    }

    return new UserSignUpFormErrors({ ...this, body: [...this.body, { error }] });
  }

  private addEmailError(error: AuthenticationError): UserSignUpFormErrors {
    return new UserSignUpFormErrors({ ...this, email: [...this.email, error] });
  }

  private addPasswordError(error: AuthenticationError): UserSignUpFormErrors {
    return new UserSignUpFormErrors({ ...this, password: [...this.password, error] });
  }
}

import React from "react";
import Consultation from "report/domain/PreviousConsultation";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TableFilter from "../../../main/types/TableFilter";
import { PreviousConsultationsTable } from "./PreviousConsultationsTable";
import useConsultationFilter from "./hooks/useConsultationFilter";

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface Props {
  previousConsultations: Consultation[];
}

const PreviousConsultations: React.FunctionComponent<Props> = ({ previousConsultations }) => {
  const classes = useStyles();
  const { filteredConsultations, toggleFilter, filterActive } = useConsultationFilter(previousConsultations);
  const { t } = useTranslation("report");

  const onlyMyConsultationsFilter: TableFilter = {
    title: t("onlyMyConsultations"),
    enabled: filterActive,
    onChange: toggleFilter,
  };

  return (
    <div className={classes.tableContainer}>
      <PreviousConsultationsTable
        previousConsultations={filteredConsultations}
        filters={[onlyMyConsultationsFilter]}
      />
    </div>
  );
};
export default PreviousConsultations;

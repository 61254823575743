import { Auth } from "aws-amplify";
import { ConfirmSignUpError } from "../../domain/ConfirmSignUpError";
import AuthenticationError from "../../domain/AuthenticationError";
import UserSignUpFormData from "../../signup/domain/UserSignUpFormData";
import FailedToSignUpException from "../../signup/domain/exceptions/FailedToSignUpException";
import LogInFormData from "../../login/domain/LogInFormData";
import FailedToLogInException from "../../login/domain/exceptions/FailedToLogInException";
import { UnauthorizedUserException } from "../../domain/exceptions/UnauthorizedUserException";
import DateTime from "../../../main/dateTime/DateTime";
import { AmplifyConfirmSignUpError } from "../exceptions/AmplifyConfirmSignUpError";
import FailedToConfirmSignUpException from "../../confirmSignUp/domain/exceptions/FailedToConfirmSignUpException";
import User from "../../../main/domain/User";
import CognitoUserAttributes from "../../profile/services/profile/CognitoUserAttributes";
import CognitoAttributes from "../../profile/services/profile/CognitoAttributes";
import FailedToResetPasswordException from "../../resetPassword/domain/FailedToResetPasswordException";
import FailedToChangePasswordException from "../../resetPassword/domain/FailedToChangePasswordException";
import AuthenticationService, { PASSWORD_CHANGE_DAYS_THRESHOLD } from "./AuthenticationService";
import { FailedToCompleteNewPasswordException } from "../../passwordExpired/domain/FailedToCompleteNewPasswordException";
import { CognitoUser } from "amazon-cognito-identity-js";
import { LoginChallenge } from "../../domain/LoginChallenge";
import { AuthenticationErrorWithLinkFactory } from "../../domain/AuthenticationErrorWithLinkFactory";
import { FailedToLogoutException } from "../../logout/domain/exceptions/FailedToLogoutException";
import { FailedToResendConfirmationCodeException } from "../../resetPassword/domain/FailedToResendConfirmationCodeException";

class AmplifyAuthenticationService implements AuthenticationService {

  public confirmSignUp = async (email: string, code: string): Promise<void> => {
    try {
      await Auth.confirmSignUp(email, code);
    } catch (error) {
      const errorCode: ConfirmSignUpError =
        error.code === AmplifyConfirmSignUpError.CodeMismatch ?
          ConfirmSignUpError.InvalidCode :
          ConfirmSignUpError.DefaultError;
      throw new FailedToConfirmSignUpException(errorCode);
    }
  };

  public async signUp(signUp: UserSignUpFormData, agreementDate: DateTime): Promise<void> {
    try {
      await Auth.signUp({
        username: signUp.email,
        password: signUp.password,
        attributes: {
          [CognitoAttributes.AGREEMENT_DATE]: agreementDate.toISO(),
        },
      });
    } catch (error) {
      throw new FailedToSignUpException(AuthenticationErrorWithLinkFactory.createFrom(error));
    }
  }

  public async login(formData: LogInFormData, onRequireNewPassword: (user: CognitoUser) => void): Promise<void> {
    let user;
    try {
      user = await Auth.signIn({ username: formData.email, password: formData.password });
    } catch (error) {
      throw new FailedToLogInException(AuthenticationErrorWithLinkFactory.createFrom(error));
    }

    if (user.challengeName === LoginChallenge.NEW_PASSWORD_REQUIRED) {
      onRequireNewPassword(user);
      throw new FailedToLogInException(
        AuthenticationErrorWithLinkFactory.createFrom({ code: AuthenticationError.RequireNewPassword })
      );
    }
  }

  public logout = async (): Promise<void> => {
    try {
      await Auth.signOut();
    } catch (error) {
      throw new FailedToLogoutException(AuthenticationErrorWithLinkFactory.createFrom(error));
    }
  };

  public resendConfirmationCode = async (email: string): Promise<void> => {
    try {
      await Auth.resendSignUp(email);
    } catch (error) {
      throw new FailedToResendConfirmationCodeException(AuthenticationErrorWithLinkFactory.createFrom(error));
    }
  };

  public requestPasswordReset = async (email: string): Promise<void> => {
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      throw new FailedToResetPasswordException(AuthenticationErrorWithLinkFactory.createFrom(error));
    }
  };

  public completeNewPassword = async (user: CognitoUser, newPassword: string): Promise<void> => {
    try {
      await Auth.completeNewPassword(user, newPassword);
      await Auth.updateUserAttributes(user, {
        [CognitoAttributes.LATEST_PASSWORD_CHANGE]: DateTime.now().getDate(),
      });
    } catch (error) {
      throw new FailedToCompleteNewPasswordException(AuthenticationErrorWithLinkFactory.createFrom(error));
    }
  };

  public resetPassword = async (email: string, code: string, password: string): Promise<void> => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
    } catch (error) {
      throw new FailedToResetPasswordException(AuthenticationErrorWithLinkFactory.createFrom(error));
    }
  };

  public changePassword = async (currentPassword: string, newPassword: string): Promise<void> => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(cognitoUser, currentPassword, newPassword);
      await Auth.updateUserAttributes(cognitoUser, {
        [CognitoAttributes.LATEST_PASSWORD_CHANGE]: DateTime.now().getDate(),
      });
    } catch (error) {
      throw new FailedToChangePasswordException(AuthenticationErrorWithLinkFactory.createFrom(error));
    }
  };

  public getUser = async (): Promise<User> => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);
      const cognitoAttributes = new CognitoUserAttributes(attributes);

      const id = cognitoAttributes.getAttribute("sub") as string;
      const email = cognitoAttributes.getAttribute(CognitoAttributes.EMAIL) as string;
      const latestPasswordChange = cognitoAttributes.getAttribute(CognitoAttributes.LATEST_PASSWORD_CHANGE) as string;
      const agreementDate = cognitoAttributes.getAttribute(CognitoAttributes.AGREEMENT_DATE);

      return new User({ id, email, latestPasswordChange, agreementDate });
    } catch (e) {
      await this.logout();
      throw new UnauthorizedUserException();
    }
  };

  public getUserToken: () => Promise<string> = async () => {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (e) {
      throw new UnauthorizedUserException();
    }
  };

  public isRequiredToChangePassword(latestPasswordChange: string): boolean {
    const currentDateTime = DateTime.now().getDate().getTime();
    const lastPasswordChangeDateTime = DateTime.fromISO(latestPasswordChange).getDate().getTime();
    const millisToDaysConversion = 1000 * 3600 * 24;
    return Math.floor(currentDateTime - lastPasswordChangeDateTime) / millisToDaysConversion >=
        PASSWORD_CHANGE_DAYS_THRESHOLD;
  }
}

export default new AmplifyAuthenticationService();

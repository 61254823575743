import { useCallback } from "react";
import * as AppExceptionActions from "../AppExceptionActions";
import { getAppExceptions } from "../selectors/AppExceptionSelector";
import useGlobalState from "./useGlobalState";

export interface UseAppExceptionRepositoryResult {
  addException: (error: string) => void;
  clearExceptions: () => void;
  errors: string[];
}

const useAppExceptionRepository = (): UseAppExceptionRepositoryResult => {
  const { dispatch, state } = useGlobalState();
  const errors = getAppExceptions(state);

  const addException = useCallback((error: string): void =>
    AppExceptionActions.addException(dispatch, error), [dispatch]);

  const clearExceptions = useCallback((): void =>
    AppExceptionActions.clearExceptions(dispatch), [dispatch]);

  return {
    addException,
    clearExceptions,
    errors,
  };
};

export default useAppExceptionRepository;

import React, { SVGProps, FC } from "react";
import { makeStyles } from "@material-ui/core";
import TreatmentRowPicture from "./TreatmentRowPicture";
import theme from "main/theme/theme";

const useStyles = makeStyles(() => ({
  icon: {
    width: "auto",
    height: "80%",

    color: theme.colors.primary.normal,
    fill: "currentColor",
  },
}));

interface Props {
  Icon: FC<SVGProps<SVGSVGElement>>;
}

export const TreatmentRowIcon = ({ Icon }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <TreatmentRowPicture>
      <Icon className={classes.icon} />
    </TreatmentRowPicture>
  );
};

export default TreatmentRowIcon;


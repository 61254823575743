import Typography from "@material-ui/core/Typography";
import AuthenticationIcon from "authentication/components/AuthenticationIcon";
import TermsAndPrivacy from "authentication/components/TermsAndPrivacy";
import AlertCard from "main/components/AlertCard";
import DateTime from "main/dateTime/DateTime";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import useProfileForm from "../hooks/useProfileForm";
import { useCompleteProfileStyles } from "../styles/CompleteProfileStyle";
import ProfileForm from "./ProfileForm";
import theme from "../../../main/theme/theme";

const CompleteClientConnectProfileForm: React.FunctionComponent = () => {
  const classes = useCompleteProfileStyles();
  const { t } = useTranslation("profile");
  const { profileFormData, errors, onChange, onSubmitWithAgreement } = useProfileForm();
  const [agreementDate, setAgreementDate] = useState<DateTime>();

  if (!agreementDate) {
    return (
      <div>
        <TermsAndPrivacy onAccept={(): void => setAgreementDate(DateTime.now())} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <AlertCard className={classes.alertCard}>
        <Trans
          t={t}
          i18nKey="clientConnectMessage"
          components={[
            <Typography
              key="0"
              component="span"
              variant="h5"
              display="block"
              className={classes.boldMessage}
            />,
            <Typography
              key="1"
              component="span"
              variant="h5"
              display="block"
            />,
            <Typography
              style={{ color: theme.colors.error }}
              key="2"
              component="p"
              variant="h5"
            />,
          ]}
        />
      </AlertCard>

      <AuthenticationIcon className={classes.createProfileIcon} />

      <Typography component="h2" variant="h4" className={classes.createProfileText}>
        {t("completeProfile")}
      </Typography>

      <ProfileForm
        profileFormData={profileFormData}
        errors={errors}
        onChange={onChange}
        onSubmit={(): Promise<void> => onSubmitWithAgreement(agreementDate)}
      />
    </div>
  );
};

export default CompleteClientConnectProfileForm;

import { MeasurementDetail } from "./MeasurementDetail";
import SessionFormData from "../../prescribe/domain/SessionFormData";
import ScheduleFormData, {
  assembleScheduleFormData,
  createEmpty as createEmptySchedule,
} from "../../schedule/domain/ScheduleFormData";
import { IdentifierGenerator } from "../../main/services/IdentifierGenerator";
import InstructionFormData from "main/domain/InstructionFormData";
import { MeasurementTreatmentPlanResponse } from "consultationSummary/api/response/MeasurementTreatmentPlanResponse";
import { ProposedSchedulableRegimenResponse } from "consultationSummary/api/response/ProposedSchedulableRegimenResponse";
import { ProposedMeasurementResponse } from "consultationSummary/api/response/ProposedMeasurementResponse";
import { MeasurementType } from "./MeasurementType";
import { CustomMeasurementDetail } from "./CustomMeasurementDetail";
import { BaseMeasurementDetail } from "./BaseMeasurementDetail";

export interface MeasurementFormData<T extends MeasurementDetail> {
  localId: string;
  details: { measurementDetail: T };
  schedule: ScheduleFormData<SessionFormData>;
  instructions: InstructionFormData;
}

export const initializeMeasurementFormData = <T extends MeasurementDetail>(
  detail: T,
  instructions: string
): MeasurementFormData<T> => ({
  localId: IdentifierGenerator.generate(),
  details: { measurementDetail: detail },
  schedule: createEmptySchedule<SessionFormData>(),
  instructions: { text: instructions },
});

export const assembleFromHistoricalMeasurement = (
  measurement: ProposedSchedulableRegimenResponse<MeasurementTreatmentPlanResponse>
): MeasurementFormData<MeasurementDetail> => ({
  localId: measurement.id,
  details: { measurementDetail: assembleMeasurementDetail(measurement.regimen.measurement) },
  instructions: { text: measurement.instructions },
  schedule: assembleScheduleFormData(measurement, SessionFormData.assembleFromProposedEvents),
});

const assembleMeasurementDetail = ({ type, name }: ProposedMeasurementResponse): MeasurementDetail => {
  if (type === MeasurementType.CUSTOM) {
    return new CustomMeasurementDetail(name);
  }

  return new BaseMeasurementDetail(type);
};

import { useCallback, useState } from "react";
import DrugLeaflet from "../domain/DrugLeaflet";
import Medication from "../domain/medications/Medication";
import DrugLabels from "../domain/DrugLabels";
import useDrugFinderService from "./useDrugFinderService";
import useDrugLabelsService from "./useDrugLabelsService";

interface UseDrugSheetModalResult {
  drugSheetMedication: Medication|null;
  leaflet: DrugLeaflet|null;
  drugLabels: DrugLabels|null;
  leafletLoading: boolean;
  labelsLoading: boolean;
  isDrugSheetOpen: boolean;
  closeDrugSheet: () => void;
  openDrugSheet: (medication: Medication) => void;
}

export const useDrugSheetModal = (language: string): UseDrugSheetModalResult => {
  const { leafletLoading, getLeaflet } = useDrugFinderService(language);
  const { labelsLoading, getLabels } = useDrugLabelsService(language);
  const [modalOpen, setModal] = useState(false);
  const [drugSheetMedication, setDrugSheetMedication] = useState<Medication | null>(null);
  const [leaflet, setLeaflet] = useState<DrugLeaflet | null>(null);
  const [drugLabels, setDrugLabels] = useState<DrugLabels | null>(null);

  const getDrugSheet = useCallback(async (din: string): Promise<void> => {
    const foundLeaflet = await getLeaflet(din);

    setLeaflet(foundLeaflet);
  }, [getLeaflet]);

  const getDrugLabels = useCallback(async (din: string): Promise<void> => {
    const foundLabels = await getLabels(din);

    setDrugLabels(foundLabels);
  }, [getLabels]);

  const resetModalContent = useCallback(() => {
    setDrugSheetMedication(null);
    setLeaflet(null);
    setDrugLabels(null);
  }, [setDrugLabels, setLeaflet, setDrugSheetMedication]);

  const setModalContent = useCallback((medication: Medication) => {
    setDrugSheetMedication(medication);
    if (medication.din) {
      getDrugSheet(medication.din);
      getDrugLabels(medication.din);
    }
  }, [getDrugSheet, getDrugLabels, setDrugSheetMedication]);

  const openDrugSheet = (medication: Medication): void => {
    resetModalContent();

    setModal(true);
    setModalContent(medication);
  };

  const closeDrugSheet = (): void => {
    setModal(false);
  };

  return {
    isDrugSheetOpen: modalOpen,
    openDrugSheet,
    closeDrugSheet,
    drugSheetMedication,
    leaflet,
    drugLabels,
    leafletLoading,
    labelsLoading,
  };
};

export default useDrugSheetModal;

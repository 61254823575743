import DevFlag from "../utilities/DevFlag";

const toBool = (text: string): boolean => !!text && Boolean(JSON.parse(text));

const environmentName = process.env.REACT_APP_ENVIRONMENT as string;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL as string;
const cognitoRegion = process.env.REACT_APP_COGNITO_REGION as string;
const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID as string;
const cognitoAppClientId = process.env.REACT_APP_COGNITO_APP_CLIENT_ID as string;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN as string;
const contactEmail = process.env.REACT_APP_CONTACT_EMAIL as string;
const documentBucketName = process.env.REACT_APP_S3_DOCUMENT_BUCKET_NAME as string;
const documentBucketAccessKey = process.env.REACT_APP_S3_ACCESS_KEY_ID as string;
const documentBucketAccessKeySecret = process.env.REACT_APP_S3_ACCESS_KEY_SECRET_ID as string;
const useAuthenticationHeader = toBool(process.env.REACT_APP_USE_AUTHENTICATION_HEADER as string);
const appVersion = process.env.REACT_APP_VERSION as string;
const textEditorApiKey = process.env.REACT_APP_TEXT_EDITOR_API_KEY as string;

const isFlagDefined = (flag: DevFlag | undefined): flag is DevFlag => !!flag;

const listEnabledDevFlags =
  (environment: { [key: string]: string | undefined }): DevFlag[] => Object.keys(DevFlag)
    .map((devFlag: string) => {
      const storedFlag = environment[`REACT_APP_DEV_FLAG_${devFlag}`];
      if (!storedFlag) {
        throw new Error(`Could not find value of dev flag ${devFlag}`);
      }

      if (!toBool(storedFlag)) {
        return undefined;
      }

      return DevFlag[devFlag as keyof typeof DevFlag];
    })
    .filter(isFlagDefined as any) as unknown as DevFlag[];

const ALL_FLAGS_ENABLED = Object.values(DevFlag) as unknown as DevFlag[];

export default {
  environmentName,
  apiBaseUrl,
  cognitoRegion,
  cognitoUserPoolId,
  cognitoAppClientId,
  sentryDsn,
  contactEmail,
  documentBucketName,
  documentBucketAccessKey,
  documentBucketAccessKeySecret,
  useAuthenticationHeader,
  appVersion,
  textEditorApiKey,
  devFlags: process.env.NODE_ENV === "test" ? ALL_FLAGS_ENABLED : listEnabledDevFlags(process.env),
};

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../main/theme/theme";
import DateTime from "../../main/dateTime/DateTime";

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: "flex",
    padding: theme.metrics.margin.normal,
  },
  eventTimeContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface Props {
  event: string;
  time: string;
  day?: number;
  date?: DateTime;
}

const ScheduleEventRow: React.FunctionComponent<Props> = ({
  day,
  date,
  event,
  time,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("schedule");
  return (
    <div
      className={classes.rowContainer}
      style={{
        justifyContent: day ? "space-between" : "flex-end",
        backgroundColor: day ? theme.colors.primary.paleBlue : theme.colors.surface,
      }}>
      <Typography component="label" variant="h4">
        {
          day ? `${t("day")} ${day}` : undefined
        }
      </Typography>

      <Typography component="label" variant="h4">
        {date?.toISODate()}
      </Typography>

      <div className={classes.eventTimeContainer}>
        <Typography component="label" variant="h4">
          {event}
        </Typography>

        <Typography component="label" variant="h4">
          {time}
        </Typography>
      </div>
    </div>
  );
};

export default ScheduleEventRow;

import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
}

export const Head: React.FunctionComponent<Props> = ({ title }) => (
  <Helmet
    titleTemplate="MFP - %s"
    title={title}
  />
);

export default Head;

import DateTime from "main/dateTime/DateTime";

export interface MedicationAttributes {
  expectedTimestamp: DateTime;
  actualTimestamp: DateTime;
  amount: string;
}

export default class MedicationResult {
  public readonly actualTimestamp: DateTime;
  public readonly expectedTimestamp: DateTime;
  private _amount: string;

  constructor({ actualTimestamp, expectedTimestamp, amount }: MedicationAttributes) {
    this.expectedTimestamp = expectedTimestamp;
    this.actualTimestamp = actualTimestamp;
    this._amount = amount;
  }

  public get amount(): number {
    return parseFloat(this._amount);
  }
}

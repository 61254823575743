import React from "react";
import {
  Typography, makeStyles, Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../../main/theme/theme";
import AuthenticationPageWrapper from "../../components/AuthenticationPageWrapper";
import AuthenticationIcon from "../../components/AuthenticationIcon";
import PasswordField from "../../components/PasswordField";
import CodeFormValidator from "../../../main/domain/CodeFormValidator";
import CodeFormData from "../../../main/domain/CodeFormData";
import CodeInput from "../../../main/components/CodeInput";
import PasswordResetFormErrors from "../domain/PasswordResetFormErrors";
import PasswordResetFormData from "../domain/ResetPasswordFormData";
import AuthenticationForm from "../../components/AuthenticationForm";
import { AuthenticationFormErrorsWithLink } from "../../components/AuthenticationFormErrorsWithLink";

const useStyles = makeStyles(() => ({
  formRow: {
    marginBottom: theme.metrics.margin.medium,
  },
  sendButton: {
    marginRight: theme.metrics.margin.tiny,
  },
  icon: {
    marginBottom: theme.metrics.margin.small,
  },
  codeInput: {
    marginTop: theme.metrics.margin.small,
    marginBottom: theme.metrics.margin.medium,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  resetFormHeaderText: {
    marginBottom: theme.metrics.margin.small,
  },
}));

interface Props {
  form: PasswordResetFormData;
  errors: PasswordResetFormErrors;
  title: string;
  onSubmit: () => Promise<void>;
  onChange: (form: PasswordResetFormData) => void;
}

export const PasswordResetForm: React.FunctionComponent<Props> = ({
  form,
  errors,
  onSubmit,
  title,
  onChange,
}: Props) => {
  const { t } = useTranslation("authentication");
  const classes = useStyles();

  const onPasswordChange = (newPassword: string): void => onChange(new PasswordResetFormData({ ...form, newPassword }));
  const onCodeChange = (code: CodeFormData): void => onChange(new PasswordResetFormData({ ...form, code }));
  const onSubmitForm = async (event: React.ChangeEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    await onSubmit();
  };

  const isFilled = (): boolean => CodeFormValidator.isFull(form.code.codeCharacters) && !!form.newPassword;

  return (
    <AuthenticationPageWrapper>
      <AuthenticationIcon className={classes.icon} />

      <Typography component="h2" variant="h3" className={classes.resetFormHeaderText}>
        {title}
      </Typography>

      <Typography component="h2" variant="h5" color="textPrimary" className={classes.resetFormHeaderText}>
        {t("confirmNewPasswordInstructions")}
      </Typography>

      <AuthenticationForm onSubmit={onSubmitForm}>
        <div className={classes.codeInput}>
          <CodeInput
            size="small"
            code={form.code}
            onChange={onCodeChange}
          />
        </div>

        <PasswordField
          className={classes.formRow}
          password={form.newPassword}
          onChange={onPasswordChange}
          error={errors.password.length > 0 ? t(`authenticationErrors:${errors.password[0]}`) : ""}
          name="newPassword"
          label={t("newPassword")}
        />

        <AuthenticationFormErrorsWithLink errors={errors.body} />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isFilled()}
          className={classes.sendButton}
        >
          {t("confirm")}
        </Button>
      </AuthenticationForm>
    </AuthenticationPageWrapper>
  );
};

export default PasswordResetForm;

import { useEffect, useState } from "react";
import DateTime from "../../main/dateTime/DateTime";
import { fetchActivityResults } from "../api/ReportResultsApi";
import OtherActivityResults from "report/domain/otherActivity/OtherActivityResults";

export interface ActivityResultsResult {
  errors: boolean;
  results: OtherActivityResults | null;
}

export interface ActivityResultResultsProps {
  code: string;
  reportCode: string;
}

const useActivityResults = ({
  reportCode,
  code,
}: ActivityResultResultsProps): ActivityResultsResult => {
  const [results, setResults] = useState<OtherActivityResults | null>(null);
  const [errors, setErrors] = useState<boolean>(false);

  useEffect(() => {
    const fetchActivity = async (): Promise<void> => {
      setErrors(false);
      const timeZone = DateTime.getCurrentTimezone();
      try {
        const activityResults = await fetchActivityResults(reportCode, code, timeZone);
        setResults(activityResults);
      } catch (e) {
        setErrors(true);
      }
    };

    fetchActivity();
  }, [code, reportCode]);

  return { results, errors };

};

export default useActivityResults;

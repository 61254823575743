import { PasswordExpiredFormData } from "./PasswordExpiredFormData";
import { PasswordExpiredFormErrors } from "./PasswordExpiredFormErrors";
import isPasswordValid from "../../services/validators/passwordValidator";
import AuthenticationError from "../../domain/AuthenticationError";
import { AuthenticationErrorWithLinkFactory } from "../../domain/AuthenticationErrorWithLinkFactory";

export class PasswordExpiredFormValidator {

  public static validate(form: PasswordExpiredFormData): PasswordExpiredFormErrors {
    let errors = PasswordExpiredFormErrors.empty();

    if (!form.user) {
      errors = new PasswordExpiredFormErrors({
        ...errors,
        body: [...errors.body,
          AuthenticationErrorWithLinkFactory.createFrom({
            code: AuthenticationError.UserNotFoundExceptionExpiredPassword,
          }),
        ],
      });
    }

    if (!isPasswordValid(form.newPassword)) {
      errors = new PasswordExpiredFormErrors({
        ...errors,
        password: [...errors.password, AuthenticationError.InvalidPassword],
      });
    }

    return errors;
  }
}

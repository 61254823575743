import { MeasurementType } from "measurement/domain/MeasurementType";

const measurement = {
  en: {
    measurement: {
      addMeasurement: "Add a measurement",
      measurementName: "Measurement Name",
      name: "Name",
      add: "Add",
      save: "Save",
      addReminder: "Add a reminder",
      editMeasurement: "Edit measurement",
      deleteMeasurement: "Delete measurement",
      customMeasurement: "Custom measurement",
      measurementType: {
        [MeasurementType.BLOOD_GLUCOSE]: "Blood Glucose",
        [MeasurementType.BLOOD_PRESSURE]: "Blood Pressure",
        [MeasurementType.MIGRAINE]: "Migraine",
        [MeasurementType.MOOD]: "Mood",
        [MeasurementType.CUSTOM]: "Custom Measurement",
      },
      instruction: {
        [MeasurementType.MIGRAINE]: "Record the severity of your migraine using the following scale:" +
          "<ul><li><b>Mild episode</b> = lingering pain</li>" +
          "<li><b>Severe episode</b> = inability to perform daily activities, absence from work</li>" +
          "<li><b>Moderate episode</b> = in pain but still functional</li></ul>" +
          "Record the following information, by adding a note:" +
          "<ul><li>Identify <b>triggers</b> (food, activity, menstrual cycle, etc.)" +
          "<li>Identify associated <b>symptoms</b> (nausea, vomiting, auras, etc.)</li>" +
          "<li>Record <b>treatments</b> used (e.g. Triptans or other medications) and note whether the episode was controlled or not.</li></ul>",
        [MeasurementType.BLOOD_PRESSURE]: "Take your blood pressure twice a day, at different times following the below instructions:" +
          "<ul><li>Rest in a seated position 5-minutes before taking your measurement.</li>" +
          "<li>Do not smoke or consume caffeine 60 minutes before taking your measurement.</li>" +
          "<li>Do not exercise 30 minutes before taking your measurement.</li>" +
          "<li>Ensure your arm is well-supported and raised to the height of your heart.</li></ul>",
        [MeasurementType.MOOD]: "<ul><li>Choose what best represents your overall mood for the day.</li>" +
        "<li>Don't just rely on your mood at the time of answering - it may not represent the whole day.</li>" +
        "<li>If there were significant mood changes during the day, intense emotions or particular events, write them down in the \"notes\" section.</li></ul>",
      },
    },
  },
  fr: {
    measurement: {
      addMeasurement: "Ajouter une mesure",
      measurementName: "Nom de la mesure",
      name: "Nom",
      add: "Ajouter",
      save: "Sauvegarder",
      addReminder: "Ajouter un rappel",
      editMeasurement: "Modifier la mesure",
      deleteMeasurement: "Supprimer la mesure",
      customMeasurement: "Mesure personnalisée",
      measurementType: {
        [MeasurementType.BLOOD_GLUCOSE]: "Glycémie",
        [MeasurementType.BLOOD_PRESSURE]: "Tension artérielle",
        [MeasurementType.MIGRAINE]: "Migraine",
        [MeasurementType.MOOD]: "Humeur",
        [MeasurementType.CUSTOM]: "Mesure personnalisée",
      },
      instruction: {
        [MeasurementType.MIGRAINE]: "Enregistrer l’intensité de votre mal de tête selon l'échelle suivante :" +
          "<ul><li><b>Crise légère</b> = fond de mal de tête</li>" +
          "<li><b>Crise modérée</b> = vous êtes tout de même fonctionnel</li>" +
          "<li><b>Crise sévère</b> = incapacité d’accomplir vos activités quotidiennes, absence au travail</li></ul>" +
          "Enregistrer les informations suivantes en ajoutant une note :" +
          "<ul><li>Identifier les <b>déclencheurs</b> (aliment, activité, cycle menstruel, etc.)" +
          "<li>Identifier les <b>symptômes</b> associés (nausées, vomissements, auras, etc.)</li>" +
          "<li>Inscrire les <b>traitements</b> de crise utilisés (exemple : Triptans) et noter si la crise a été contrôlée ou non.</li></ul>",
        [MeasurementType.BLOOD_PRESSURE]: "Prendre votre mesure de tension artérielle 2 fois par jour, à différents moments, en vous assurant de respecter les consignes suivantes:" +
        "<ul><li>Assurez-vous d’avoir observé une période de repos assis de 5 minutes avant la prise de la mesure.</li>" +
        "<li>Ne pas avoir fumé ou pris de la caféine dans les 60 minutes avant la prise de mesure.</li>" +
        "<li>Ne pas avoir fait d’exercice physique dans les 30 minutes avant la prise de mesure.</li>" +
        "<li>Assurez-vous d’avoir votre bras bien supporté et élevé à la hauteur de votre coeur.</li></ul>",
        [MeasurementType.MOOD]: "<ul><li>Choisissez ce qui représente le mieux votre humeur générale de la journée.</li>" +
        "<li>Ne vous fiez pas seulement à votre humeur au moment de répondre, elle ne représente peut-être pas l’ensemble de la journée.</li>" +
        "<li>S’il y a eu des changements d’humeur importants dans la journée, des émotions intenses ou des événements particuliers, inscrivez-les dans la section « notes ».</li></ul>",
      },
    },
  },
};

export default measurement;

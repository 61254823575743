import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LanguageSelector from "../../main/header/components/LanguageSelector";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: `${theme.metrics.margin.medium} ${theme.metrics.margin.huge}`,
    paddingBottom: 0,
    flexShrink: 0,
  },
}));

const AuthenticationHeader: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <LanguageSelector />
    </div>
  );
};

export default AuthenticationHeader;

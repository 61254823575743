import React from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme, { pxToRem } from "../../main/theme/theme";
import FontIcon, { FontIconName } from "../../main/FontIcon";

interface Props {
  to: string;
  text: string;
  iconName: FontIconName;
}

const useStyles = makeStyles(() => ({
  navLink: {
    "display": "block",
    "textDecoration": "none",
    "padding": `${pxToRem(18)} 
    ${theme.metrics.margin.small} 
    ${pxToRem(18)}
    ${theme.metrics.margin.medium}`,
    "& .side-menu-text, & .icon": {
      color: "white",
      verticalAlign: "middle",
      fontWeight: 600,
    },
    "&:hover .side-menu-text, &:hover .icon": {
      color: theme.colors.primary.normal,
    },
    "&.active": {
      "backgroundColor": theme.colors.surface,
      "& .side-menu-text, & .icon": {
        color: theme.colors.text.title,
        fontWeight: 700,
      },
      "&:hover .side-menu-text, &:hover .icon": {
        color: theme.colors.primary.light,
      },
    },
  },
  iconWrapper: {
    display: "inline-block",
    textAlign: "center",
    width: pxToRem(30),
    marginRight: theme.metrics.margin.small,
  },
}));

export const SideMenuItem: React.FunctionComponent<Props> = ({ to, text, iconName }: Props) => {
  const styles = useStyles();

  return (
    <NavLink to={to} activeClassName="active" className={styles.navLink}>
      <span className={styles.iconWrapper}>
        <FontIcon
          name={iconName}
          variant="h2"
          color="textPrimary"
        />
      </span>

      <Typography
        component="span"
        className="side-menu-text"
        variant="h4"
        color="textPrimary"
      >
        {text.toUpperCase()}
      </Typography>
    </NavLink>
  );
};

export default SideMenuItem;

import {
  Divider, makeStyles, TableBody, TableCell, TableRow, Typography,
} from "@material-ui/core";
import theme, { convertHexToRGBA } from "../../main/theme/theme";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReportResultsTable } from "./ReportResultsTable";
import { ReportResultsTableHead } from "./ReportResultsTableHead";
import { ReportResultsTableHeaderTitle } from "./ReportResultsTableHeaderTitle";
import { weekdays } from "../../schedule/domain/Weekday";
import MigraineMeasure from "../domain/measurements/migraineMeasurement/MigraineMeasure";
import { MigraineChart } from "./charts/MigraineChart";
import { DateTime as LuxonDateTime } from "luxon";
import { ChartHeaderField } from "./ChartHeaderField";
import ChartDateRange from "../domain/ChartDateRange";
import DateTime from "../../main/dateTime/DateTime";
import NotesReport from "report/domain/note/NotesReport";
import NotesTableResults from "./notes/NotesTableResults";

const useStyles = makeStyles(() => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: convertHexToRGBA(theme.colors.table.row, 100),
    },
  },
}));

interface Props {
  measures: MigraineMeasure[] | null;
  notes: NotesReport;
  testID?: string;
  name: string;
}
export const MigraineMeasureResultsTable: React.FunctionComponent<Props> = ({ measures, notes, name }) => {
  const styles = useStyles();
  const { t } = useTranslation("report");
  const [chartDateRange, setDateRange] = React.useState<ChartDateRange>(ChartDateRange.LAST30DAYS);
  const sortedDescendingMeasures = measures?.slice().sort(
    (a, b) => a.actualTimestamp.isBefore(b.actualTimestamp)
      ? 1 : -1
  );
  const dateRangeDate = DateTime.now().addDays(-chartDateRange.valueOf());
  const sortedDateRangeMeasures = measures?.slice().sort(
    (a) => a.actualTimestamp.isAfter(dateRangeDate)
      ? 1 : -1
  );
  const [chartData, setChartData] = React.useState<MigraineMeasure[]>(sortedDateRangeMeasures || []);

  const changeChartDateRange = (dateRange: ChartDateRange): void => {
    setDateRange(dateRange);
    setChartData(sortedDateRangeMeasures || []);
  };

  return (
    <>
      <ChartHeaderField name={name} value={chartDateRange} onChartDateRangeChange={changeChartDateRange} />
      <Divider />
      <MigraineChart dateRange={chartDateRange} measures={chartData || []} />

      <NotesTableResults notes={notes} />

      <ReportResultsTable>
        <ReportResultsTableHead>
          <TableCell />

          <ReportResultsTableHeaderTitle title={t("dateOnly")} />

          <ReportResultsTableHeaderTitle title={t("timeOnly")} />

          <ReportResultsTableHeaderTitle title={t("value")} />
        </ReportResultsTableHead>

        <TableBody>
          {sortedDescendingMeasures?.map((measure) => (
            <TableRow key={measure.id} classes={{ root: styles.row }}>
              <TableCell>
                <Typography>{t(`weekdays:${weekdays[measure.actualTimestamp.getDayOfWeek()]}`)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{measure.actualTimestamp.formatLocale(LuxonDateTime.DATE_SHORT)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{measure.actualTimestamp.formatLocale(LuxonDateTime.TIME_SIMPLE)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{t(`severity.${measure.severity}`)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ReportResultsTable>
    </>
  );
};

import React, { useState } from "react";
import {
  TextField, TextFieldProps, Theme,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  showPasswordIconButton: {
    "paddingRight": 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    "fill": theme.palette.secondary.dark,
    "&:hover": {
      fill: theme.palette.secondary.contrastText,
    },
  },
}));

interface Props {
  onChange: (newPassword: string) => void;
  error?: string;
  password: string;
  label: string;
  id?: string;
}

type ComponentProps = Omit<TextFieldProps, "error" | "onChange" | "helperText"> & Props;

const PasswordField: React.FunctionComponent<ComponentProps> = ({
  onChange,
  error,
  password,
  label,
  id,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    onChange(event.target.value);

  const onToggleDisplayPassword = (): void =>
    setShowPassword(!showPassword);

  return (
    <TextField
      {...rest}
      id={id ?? "password"}
      variant="outlined"
      fullWidth
      label={label}
      type={showPassword ? "text" : "password"}
      autoComplete="current-password"
      value={password}
      onChange={onPasswordChange}
      InputProps={{
        endAdornment: <DisplayToggle display={showPassword} onToggle={onToggleDisplayPassword} />,
      }}
      error={!!error}
      helperText={error}
    />
  );
};

interface DisplayToggleProps {
  onToggle: () => void;
  display: boolean;
}

const DisplayToggle: React.FunctionComponent<DisplayToggleProps> = ({ onToggle, display }) => {
  const classes = useStyles();

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onToggle}
        className={classes.showPasswordIconButton}
      >
        {display ?
          <Visibility className={classes.icon} fontSize="large" /> :
          <VisibilityOff className={classes.icon} fontSize="large" />
        }
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordField;

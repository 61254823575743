import i18next from "i18next";
import CustomProgram from "../../CustomProgram";
import Measurement, { MeasurementAttributes } from "../Measurement";
import { MeasurementType } from "measurement/domain/MeasurementType";

interface BloodGlucoseMeasurementAttributes extends Omit<MeasurementAttributes, "type"> {
  customProgram: CustomProgram | null;
}

export default class BloodGlucoseMeasurement extends Measurement {

  public customProgram: CustomProgram | null;

  constructor({ name, schedule, customProgram, adherence, reportCode }: BloodGlucoseMeasurementAttributes) {
    super({ name, schedule, adherence, type: MeasurementType.BLOOD_GLUCOSE, reportCode });
    this.customProgram = customProgram;
  }

  public displayName(): string {
    return i18next.t(`report:measurementType.${this.name}`);
  }
}

import React, { ChangeEvent } from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PrescribeError from "../domain/PrescribeError";
import { PatientProfileField } from "./PatientProfileField";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textField: {
    margin: 0,
  },
}));

interface Props {
  name: string;
  value?: string;
  errors?: PrescribeError[];
  onChange?: (value: string) => void;
  id: string;
  multiline?: boolean;
  maxLength?: number;
  maxRows?: number;
}

export const PatientProfileTextField: React.FunctionComponent<Props> = (
  { name, value, errors, id, onChange, maxLength, maxRows, multiline }) => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();
  const change = (event: ChangeEvent<HTMLInputElement>): void => onChange?.(event.target.value);

  return (
    <PatientProfileField name={name} htmlFor={id}>
      <TextField
        className={classes.textField}
        id={id}
        variant="outlined"
        name={id}
        fullWidth
        multiline={multiline}
        maxRows={maxRows}
        value={value}
        onChange={change}
        error={!!errors && errors.length > 0}
        helperText={!!errors && errors.length > 0 ? t(`errors.${errors[0]}`) : null}
        disabled={!onChange}
        inputProps={{ maxLength }}
      />
    </PatientProfileField>
  );
};

export default PatientProfileTextField;

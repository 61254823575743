import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import theme from "../theme/theme";

const useStyle = makeStyles(() => ({
  wrapper: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  overlay: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.colors.paleOverlay,
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

interface Props {
  visible: boolean;
}

const OverlayLoading: React.FunctionComponent<Props> = ({ visible }) => {
  const classes = useStyle();
  if (!visible) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.overlay} />
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default OverlayLoading;

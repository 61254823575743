import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";

import { ArrowForwardIos } from "@material-ui/icons";
import { InstructionHtmlContent } from "./InstructionHtmlContent";
import React from "react";
import theme from "main/theme/theme";
import { useTranslation } from "react-i18next";
import { carePlanTheme } from "./CarePlanSection";

interface Props {
  instructions: string;
}

export const InstructionsAccordion = ({ instructions }: Props): JSX.Element => {
  const { t } = useTranslation("prescribe");
  const styles = useStyle();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary>
        <Typography component="span" variant="h5">
          {t("instructions")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.instructions}>
          <InstructionHtmlContent text={instructions} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyle = makeStyles({
  instructions: {
    "& p": {
      margin: 0,
    },
  },
});

const Accordion = withStyles({
  root: {
    "border": "0",
    "boxShadow": "none",

    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "marginBottom": theme.metrics.margin.normal,
    "marginLeft": carePlanTheme.spacerWidthInstruction + "px",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginLeft: carePlanTheme.spacerWidthInstruction + "px",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    "flexDirection": "row-reverse",
    "borderBottom": "0",
    "minHeight": "auto",
    "padding": 0,
    "&$expanded": {
      minHeight: "auto",
    },
  },
  expandIcon: {
    "padding": 0,
    "marginRight": 0,
    "color": theme.colors.button.icon.color,
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  content: {
    "margin": theme.metrics.margin.small,
    "&$expanded": {
      margin: theme.metrics.margin.small,
    },
  },
  expanded: {},
})((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIos fontSize="small" />} {...props} />
));

const AccordionDetails = withStyles(() => ({
  root: {
    padding: `${theme.metrics.margin.tiny} 0 ${theme.metrics.margin.small} ${theme.metrics.margin.large} `,
  },
}))(MuiAccordionDetails);

import ChangePasswordError from "../domain/ChangePasswordError";
import HealthProfession from "../domain/HealthProfession";
import PartnerCompany from "../domain/PartnerCompany";
import ProfileError from "../domain/ProfileError";

const profile = {
  en: {
    profile: {
      congratulationsMessage:
        "<0>Congratulations</0> <0>Your account is setup!</0> " +
        "<1>Please complete your profile</1>",
      clientConnectMessage:
        "<0>Please validate your profile information.</0>" +
        "<1>This step is only required the first time you access MedHelper.</1> <2>Please note that this information is visible to the patient in the MedHelper mobile application.</2>",
      createProfile: "Create your Profile",
      completeProfile: "Complete your Profile",
      firstName: "First Name",
      lastName: "Last Name",
      officePhoneNumber: "Office Phone Number",
      extension: "Extension (Optional)",
      profession: "Profession",
      selectProfession: "Select a Profession",
      save: "UPDATE",
      emailAddress: "Email Address",
      officeAddressOptional: "Office Address (Optional)",
      myAccount: "My Account",
      profileInformation: "Profile Information",
      profileUpdated: "Your profile has been updated.",
      currentPassword: "Current Password",
      newPassword: "New Password",
      company: "Company",
      changePassword: "Change Password",
      passwordChanged: "Your password has been updated.",
      professions: {
        [HealthProfession.OTHER]: "Other",
        [HealthProfession.DENTIST]: "Dentist",
        [HealthProfession.DISABILITY_CASE_MANAGEMENT]: "Disability Case Management",
        [HealthProfession.MEDECINE]: "Medecine",
        [HealthProfession.PHARMACY]: "Pharmacy",
        [HealthProfession.PHYSICAL_THERAPIES]: "Physical Therapies",
        [HealthProfession.PSYCHOLOGY]: "Psychology",
        [HealthProfession.NURSE_ADVISOR]: "Nurse advisor",
      },
      partnerCompany: {
        [PartnerCompany.IA]: "Industrial Alliance",
        [PartnerCompany.BENEVA]: "Beneva",
        [PartnerCompany.MC_KESSON]: "McKesson",
        [PartnerCompany.HUMANIS]: "Humanis",
        [PartnerCompany.PHARMASAVE_MFP]: "Pharmasave",
        [PartnerCompany.GROUP_BENEFITS]: "Group Benefits",
        [PartnerCompany.PATIENT_ADVISORY_BOARD]: "Patient Advisory Board",
        [PartnerCompany.TEST_USERS_QC]: "Test Users Quebec",
        [PartnerCompany.FASKEN]: "Fasken",
        [PartnerCompany.MEDESYNC]: "Medesync",
        [PartnerCompany.INTACT]: "Intact",
        [PartnerCompany.DENTITEK]: "Dentitek",
      },
      errors: {
        [ProfileError.DefaultError]:
          "Something went wrong with the server. Please try later.",
        [ProfileError.EmptyProfession]: "Please specify a profession.",
        [ProfileError.EmptyOfficeNumber]: "Please specify an office number.",
        [ProfileError.EmptyFirstName]: "Please specify your first name.",
        [ProfileError.EmptyLastName]: "Please specify your last name.",
        [ChangePasswordError.CurrentPasswordEmpty]:
          "Please specify your current password.",
        [ChangePasswordError.InvalidNewPassword]:
          "New password must be at least 8 characters in length. " +
          "It must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.",
        [ChangePasswordError.DefaultError]:
          "We were unable to change your password.",
      },
    },
  },
  fr: {
    profile: {
      congratulationsMessage:
        "<0>Félicitations</0> <0>Votre compte est configuré!</0> " +
        "<1>Veuillez compléter votre profil</1>",
      clientConnectMessage:
        "<0>Veuillez valider vos informations.</0>" +
        "<1>Cette étape est requise la première fois que vous accédez à MedHelper.</1> <2>Veuillez noter que ces informations sont visibles par le patient dans l'App mobile MedHelper.</2>",
      createProfile: "Créez votre profil",
      completeProfile: "Complétez votre profil",
      firstName: "Prénom",
      lastName: "Nom",
      officePhoneNumber: "Téléphone au bureau",
      extension: "Extension (optionnel)",
      profession: "Profession",
      save: "ENREGISTRER",
      selectProfession: "Choisir une profession",
      emailAddress: "Adresse courriel",
      officeAddressOptional: "Adresse du bureau (Optionnel)",
      myAccount: "Mon compte",
      profileInformation: "Profil",
      profileUpdated: "Votre profil a été mis à jour.",
      currentPassword: "Mot de passe courant",
      newPassword: "Nouveau mot de passe",
      company: "Compagnie",
      changePassword: "Modifier votre mot de passe",
      passwordChanged: "Votre mot de passe a été mis à jour.",
      professions: {
        [HealthProfession.OTHER]: "Autre",
        [HealthProfession.DENTIST]: "Dentiste",
        [HealthProfession.DISABILITY_CASE_MANAGEMENT]: "Gestion des invalidités",
        [HealthProfession.MEDECINE]: "Médecine",
        [HealthProfession.PHARMACY]: "Pharmacie",
        [HealthProfession.PHYSICAL_THERAPIES]: "Thérapies physiques",
        [HealthProfession.PSYCHOLOGY]: "Psychologie",
        [HealthProfession.NURSE_ADVISOR]: "Infirmier(ère)-conseil",
      },
      partnerCompany: {
        [PartnerCompany.IA]: "Industrielle Alliance",
        [PartnerCompany.BENEVA]: "Beneva",
        [PartnerCompany.MC_KESSON]: "McKesson",
        [PartnerCompany.HUMANIS]: "Humanis",
        [PartnerCompany.PHARMASAVE_MFP]: "Pharmasave",
        [PartnerCompany.GROUP_BENEFITS]: "Groupe Benefits",
        [PartnerCompany.PATIENT_ADVISORY_BOARD]:
          "Conseil Consultatif des Patients",
        [PartnerCompany.TEST_USERS_QC]: "Test Utilisateurs Québec",
        [PartnerCompany.FASKEN]: "Fasken",
        [PartnerCompany.MEDESYNC]: "Medesync",
        [PartnerCompany.INTACT]: "Intact",
        [PartnerCompany.DENTITEK]: "Dentitek",
      },
      errors: {
        [ProfileError.DefaultError]:
          "Quelque chose ne va pas avec le serveur. " +
          "Veuillez réessayer plus tard.",
        [ProfileError.EmptyProfession]: "Veuillez entrer votre profession.",
        [ProfileError.EmptyOfficeNumber]:
          "Veuillez entrer le numéro de téléphone de votre bureau.",
        [ProfileError.EmptyFirstName]: "Veuillez entrer votre prénom.",
        [ProfileError.EmptyLastName]: "Veuillez entrer votre nom.",
        [ChangePasswordError.CurrentPasswordEmpty]:
          "Veuillez entrer votre mot de passe actuel.",
        [ChangePasswordError.InvalidNewPassword]:
          "Le mot de passe doit contenir au moins 8 caractères, " +
          "des lettres majuscules, des lettres minuscules, des nombres et des caractères spéciaux.",
        [ChangePasswordError.DefaultError]:
          "Nous sommes incapables de modifier votre mot de passe.",
      },
    },
  },
};

export default profile;

interface DetailFormData {
  name: string;
}

export const createEmpty = (): DetailFormData => ({
  name: "",
});

export const canSubmitReminder = (detail: DetailFormData): boolean => detail.name !== "";

export default DetailFormData;

import React, { useState } from "react";
import { AuthenticatorChildrenProps, AuthenticatorOverridingViewProps } from "aws-amplify-react";
import SignUpForm from "../signup/containers/SignUpForm";
import { useSignUpForm } from "../signup/hooks/useSignUpForm";
import DateTime from "../../main/dateTime/DateTime";
import TermsAndPrivacy from "../components/TermsAndPrivacy";
import UserSignUpFormData from "../signup/domain/UserSignUpFormData";

type Props = AuthenticatorOverridingViewProps;

type InjectedProps = Props & AuthenticatorChildrenProps;

export const AmplifiedSignUpForm: React.FunctionComponent<Props> = (props: Props) => {
  const { onStateChange, authState } = props as InjectedProps;
  const [agreementDate, setAgreementDate] = useState<DateTime | undefined>(undefined);

  const onSignInRequested = (): void => onStateChange("signIn", {});
  const onSignUpCompleted =
    (signUp: UserSignUpFormData): void =>
      onStateChange("confirmSignUp", { email: signUp.email, password: signUp.password });

  const { onSubmit, onChange, errors, userSignUp } = useSignUpForm({ onSuccess: onSignUpCompleted });

  if (authState !== "signUp") {
    return null;
  }

  if (!agreementDate) {
    return (
      <TermsAndPrivacy
        onAccept={(): void => setAgreementDate(DateTime.now())}
        onCancel={onSignInRequested}
      />
    );
  }

  return (
    <SignUpForm
      onSubmit={(): Promise<void> => onSubmit(agreementDate)}
      onChange={onChange}
      errors={errors}
      signUp={userSignUp}
      onSignInRequested={onSignInRequested}
    />
  );
};

export default AmplifiedSignUpForm;

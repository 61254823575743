import CodeFormErrors from "./CodeFormErrors";
import CodeFormData from "./CodeFormData";

class CodeFormValidator {
  public validate = (formData: CodeFormData): string[] => {
    const errors = [];
    if (!this.isFull(formData.codeCharacters)) {
      errors.push(CodeFormErrors.EMPTY_CHARACTER);
    }

    if (!this.isAlphaNumeric(formData.codeCharacters)) {
      errors.push(CodeFormErrors.INVALID_CHARACTER);
    }

    return errors;
  };

  public isFull = (codeCharacters: string[]): boolean => !codeCharacters.some((character) => !character.trim());

  private isAlphaNumeric = (codeCharacters: string[]): boolean =>
    codeCharacters.every((character) => !character || character.match(/[A-Z]|[0-9]/g));
}

export default new CodeFormValidator();

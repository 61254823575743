import React from "react";
import RequiredFieldLabel from "../../../main/components/RequiredFieldLabel";
import { makeStyles, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme, { pxToRem } from "../../../main/theme/theme";
import useReminderForm from "../../hooks/useReminderForm";
import { useScheduleForm } from "../../../medication/hooks/useScheduleForm";
import SessionFormData, { createEmpty as createEmptySession } from "../../../prescribe/domain/SessionFormData";
import { RegimenForm } from "../../../prescribe/components/RegimenForm";
import { useDuration } from "../../../prescribe/hooks/useDuration";
import ReminderFormData from "reminders/domain/ReminderFormData";
import AlarmSection from "schedule/components/AlarmSection";
import DateTime from "main/dateTime/DateTime";
import DurationDatePicker from "prescribe/components/DurationDatePicker";
import TimePicker from "main/components/inputs/TimePicker";

interface Props {
  onSubmit: (formData: ReminderFormData) => void;
  submitLabel: string;
  reminder?: ReminderFormData;
}

export const ReminderForm = ({ onSubmit, submitLabel, reminder }: Props): JSX.Element => {
  const { t } = useTranslation("reminder");
  const classes = useStyles();
  const { formData, onChange, canSubmit } = useReminderForm(reminder);

  const {
    updateEvent,
  } = useScheduleForm(
    formData.schedule,
    (field, value) => onChange("schedule")(field)(value),
    createEmptySession,
  );

  const session = formData.schedule.events[0];

  const { onAllDateChange } = useDuration(
    formData.schedule,
    (field, value) => onChange("schedule")(field)(value),
  );

  const onTimeChange = (newValue: DateTime): void => {
    const updatedEvent = new SessionFormData({ ...session, time: newValue });
    updateEvent(updatedEvent);
  };

  return (
    <RegimenForm
      instructions={formData.instructions}
      onChangeInstruction={(instructions): void => onChange("instructions")("text")(instructions)}
      submitLabel={submitLabel}
      onSubmit={(): void => onSubmit(formData)}
      canSubmit={canSubmit}
    >
      <RequiredFieldLabel
        id="nameLabel"
        bold
        label={t("reminderName")}
        className={classes.textFieldTitle}
      />

      <TextField
        inputProps={{
          "aria-labelledby": "nameLabel",
        }}
        placeholder={t("name")}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onChange("details")("name")(event.target.value)}
        variant="outlined"
        required
        fullWidth
        value={formData.details.name}
      />

      <div className={classes.dateContainer}>
        <RequiredFieldLabel id="startDatePicker" bold label={t("date")} className={classes.textFieldTitle} />

        <div className={classes.date}>
          <DurationDatePicker
            ariaLabelledBy="startDatePicker"
            date={formData.schedule.duration.startDate}
            onChange={onAllDateChange}
            required
            minValue={DateTime.now().toISODate()}
          />

          <TimePicker
            time={session.time}
            onChange={onTimeChange}
          />
        </div>
      </div>

      <AlarmSection
        id={session.id}
        alarm={session.alarm}
        onChange={(alarm): void => updateEvent(new SessionFormData({ ...session, alarm }))}
      />
    </RegimenForm>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: `0 ${theme.metrics.margin.normal} ${theme.metrics.margin.normal}`,
    width: pxToRem(500),
    overflowY: "auto",
    maxHeight: "70vh",
    overflowX: "hidden",
    ...theme.scrollbar,
  },
  textFieldTitle: {
    marginBottom: theme.metrics.margin.small,
  },
  dateContainer: {
    marginTop: theme.metrics.margin.normal,
  },
  date: {
    display: "flex",
    gap: theme.metrics.margin.normal,
    flex: 1,
  },
  addButton: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.metrics.margin.normal,
  },
}));

import ProximCustomProgramModal, { ProximCustomProgramModalRef } from "./customPrograms/ProximCustomProgramModal";
import React, { MutableRefObject } from "react";

import AddCustomProgramButton from "./AddCustomProgramButton";
import CarePlanSection from "./CarePlanSection";
import CustomProgramFormData from "../domain/CustomProgramFormData";
import PartnerCompany from "../../authentication/profile/domain/PartnerCompany";
import Profile from "../../authentication/profile/domain/Profile";
import { useTranslation } from "react-i18next";

interface Props {
  profile: Profile | undefined;
  pendingCustomPrograms: CustomProgramFormData[];
  onCustomProgramsChange: (customProgram: CustomProgramFormData[]) => void;
  proximCustomProgramModalRef: MutableRefObject<ProximCustomProgramModalRef | null>;
  disabled?: boolean;
}

export const CustomProgramsSection = ({
  profile,
  pendingCustomPrograms,
  onCustomProgramsChange,
  proximCustomProgramModalRef,
  disabled,
}: Props): JSX.Element => {
  const { t } = useTranslation("prescribe");

  const addCustomProgram = (newCustomProgram: CustomProgramFormData): void => {
    const updatedCustomPrograms = [...pendingCustomPrograms, newCustomProgram];

    onCustomProgramsChange(updatedCustomPrograms);
  };

  const updateCustomProgram = (updatedCustomProgram: CustomProgramFormData): void => {
    const updatedCustomPrograms = pendingCustomPrograms.map(
      (customProgram: CustomProgramFormData) =>
        (customProgram.id === updatedCustomProgram.id)
          ? updatedCustomProgram
          : customProgram
    );

    onCustomProgramsChange(updatedCustomPrograms);
  };

  return (
    <>
      {profile?.partnerCompany === PartnerCompany.MC_KESSON &&
        <CarePlanSection title={t("customProgram")} text="">
          <ProximCustomProgramModal
            ref={proximCustomProgramModalRef}
            onAddCustomProgram={addCustomProgram}
            onUpdateCustomProgram={updateCustomProgram}
          />

          <AddCustomProgramButton
            text={t("proximBloodGlucoseMonitoringProgram")}
            onClick={(): void => proximCustomProgramModalRef.current?.open()}
            disabled={disabled}
          />

        </CarePlanSection>
      }
    </>
  );
};

import Adherences from "../Adherences";
import OtherActivitySchedule from "./OtherActivitySchedule";
import RegimenReportCode from "../RegimenReportCode";
import SupportedLanguages from "main/domain/SupportedLanguages";
import i18next from "i18next";

interface OtherActivityAttributes {
  name: string;
  schedule: OtherActivitySchedule | undefined;
  adherence: Adherences;
  reportCode: RegimenReportCode;
}

export default class OtherActivity {
  public name: string;
  public schedule: OtherActivitySchedule | undefined;
  public adherence: Adherences;
  public reportCode: RegimenReportCode;

  constructor(plain: OtherActivityAttributes) {
    this.name = plain.name;
    this.schedule = plain.schedule;
    this.adherence = plain.adherence;
    this.reportCode = plain.reportCode;
  }

  public getFormattedSchedule(language: SupportedLanguages): string {
    if (this.schedule) {
      return this.schedule.toText(language);
    }

    return i18next.t("report:asNeeded");
  }

  public toString(): string {
    return JSON.stringify(this);
  }
}

import Percentage from "../../main/domain/Percentage";
import { AdherenceTypes } from "./AdherenceTypes";

interface AdherenceAttributes {
  type: AdherenceTypes;
  value: Percentage | number;
}

export default class Adherence {
  public type: AdherenceTypes;
  public value: Percentage | number;

  constructor({ value, type }: AdherenceAttributes) {
    this.value = value;
    this.type = type;
  }
}

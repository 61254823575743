import Patient from "../Patient";
import OtherActivityResult from "./OtherActivityResult";
import NotesReport from "../note/NotesReport";

export default class OtherActivityResults {
  public patient: Patient;
  public results: OtherActivityResult[];
  public activityName: string;
  public notes: NotesReport;

  constructor(
    patient: Patient,
    results: OtherActivityResult[],
    activityName: string,
    notes: NotesReport,
  ) {
    this.patient = patient;
    this.results = results;
    this.activityName = activityName;
    this.notes = notes;
  }
}

import React from "react";
import useLanguageSelection from "../../main/hooks/useLanguageSelection";
import { ReactComponent as TextEn } from "../assets/MedhelperForProfessionals_en.svg";
import { ReactComponent as TextFr } from "../assets/MedhelperForProfessionals_fr.svg";

type SVGComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string | undefined}>;

export const MedhelperSubText: SVGComponent = (props) => {
  const { currentLanguage } = useLanguageSelection();

  let Component;
  switch (currentLanguage) {
    case "fr":
      Component = TextFr;
      break;
    case "en":
      Component = TextEn;
      break;
    default:
      Component = TextEn;
      break;
  }

  return <Component {...props} />;
};

export default MedhelperSubText;

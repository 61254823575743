import ScheduleFormData, { createEmpty as createEmptySchedule, assembleScheduleFormData } from "../../schedule/domain/ScheduleFormData";
import DetailFormData, { createEmpty as createEmptyDetail } from "./DetailFormData";
import { v4 as uuid } from "uuid";
import InventoryFormData, { createEmpty as createEmptyInventory } from "./InventoryFormData";
import DoseFormData from "./DoseFormData";
import InstructionFormData, { createEmpty as createEmptyInstructions } from "main/domain/InstructionFormData";
import { MedicationTreatmentPlanResponse } from "consultationSummary/api/response/MedicationTreatmentPlanResponse";
import { ProposedSchedulableRegimenResponse } from "consultationSummary/api/response/ProposedSchedulableRegimenResponse";

export default interface MedicationFormData {
  id: string;
  details: DetailFormData;
  inventory: InventoryFormData;
  schedule: ScheduleFormData<DoseFormData>;
  instructions: InstructionFormData;
}

export const createEmpty = (): MedicationFormData => ({
  id: uuid(),
  details: createEmptyDetail(),
  inventory: createEmptyInventory(),
  schedule: createEmptySchedule<DoseFormData>(),
  instructions: createEmptyInstructions(),
});

export const fromHistoricalMedication = (
  medication: ProposedSchedulableRegimenResponse<MedicationTreatmentPlanResponse>
): MedicationFormData => ({
  id: medication.regimen.medication.id,
  details: { displayName: medication.regimen.medication.medicationAlias || "" },
  inventory: { quantity: medication.regimen.medication.inventory.refillsDefaultQuantity },
  instructions: { text: medication.instructions },
  schedule: assembleScheduleFormData(medication, DoseFormData.assembleFromProposedEvents),
});

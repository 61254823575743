import DrugLeaflet from "../domain/DrugLeaflet";
import DrugLeafletResponse from "./responses/DrugLeafletResponse";

class DrugAssembler {
  public static fromResponse(response: DrugLeafletResponse): DrugLeaflet {
    return new DrugLeaflet({
      description: response.description,
      additionalInformation: response.extraInformation.additionalInformation,
      generalInformation: response.extraInformation.generalInformation,
      sideEffects: response.extraInformation.sideEffects,
      storageInformation: response.extraInformation.storageInformation,
      instructions: response.extraInformation.instructions,
    });
  }
}

export default DrugAssembler;

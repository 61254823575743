import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import BloodPressureMeasure from "report/domain/measurements/bloodPressureMeasurement/BloodPressureMeasure";

interface Props {
  measures: BloodPressureMeasure[];
}

const handleDecimals = (value: number): number => +value.toFixed(2) || 0;

const BloodPressureMeasureAverage = ({ measures }: Props): JSX.Element => {
  const { t } = useTranslation("report");

  const total = measures.reduce((previousValue, currentValue) => ({
    systolic: previousValue.systolic + currentValue.systolic,
    diastolic: previousValue.diastolic + currentValue.diastolic,
    pulse: previousValue.pulse + currentValue.pulse,
  }), { systolic: 0, diastolic: 0, pulse: 0 });

  const average = {
    systolic: handleDecimals(total.systolic / measures.length),
    diastolic: handleDecimals(total.diastolic / measures.length),
    pulse: handleDecimals(total.pulse / measures.length),
  };

  if (measures.length === 0) {
    return <></>;
  }

  return (
    <ul style={{ float: "right", listStyle: "none" }}>
      <li><Typography>{t("average.systolic") + ": " + average.systolic}</Typography></li>
      <li><Typography>{t("average.diastolic") + ": " + average.diastolic}</Typography></li>
      <li><Typography>{t("average.pulse") + ": " + average.pulse}</Typography></li>
    </ul>
  );

};

export default BloodPressureMeasureAverage;

import React from "react";
import {
  makeStyles, Typography, Divider,
} from "@material-ui/core";
import classNames from "classnames";
import theme from "../theme/theme";
import CloseModalButton from "./CloseModalButton";

interface Props {
  title?: string;
  onClose: () => void;
  className?: string;
  id?: string;
}

export const ModalHeaderCarePlan: React.FunctionComponent<Props> = ({ id, title, onClose, children, className }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classNames(classes.header, className)}>
        {
          children
          || <Typography id={id} variant="h4" component="h4" className={classes.title}>
            {title}
          </Typography>
        }
        <CloseModalButton onClose={onClose} className={classes.closeButton} />
      </div>
      <Divider />
    </>
  );
};

export default ModalHeaderCarePlan;

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "0 " + theme.metrics.margin.large,
    margin: theme.metrics.margin.medium,
  },
  closeButton: {
    position: "absolute",
    right: theme.metrics.margin.normal,
    padding: "1px",
  },
  title: {
    fontWeight: "bold",
  },
}));

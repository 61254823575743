import React from "react";
import { makeStyles } from "@material-ui/core";

interface Props {
  onSubmit: (event: React.ChangeEvent<HTMLFormElement>) => void;
}

const useStyles = makeStyles(() => ({
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const AuthenticationForm: React.FunctionComponent<Props> = ({ onSubmit, children }) => {
  const classes = useStyles();

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default AuthenticationForm;

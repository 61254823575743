import React from "react";
import {
  TextField, makeStyles, TextFieldProps,
} from "@material-ui/core";
import theme from "../../main/theme/theme";

interface Props {
  error?: string;
  onChange: (value: string) => void;
}

const useStyles = makeStyles(() => ({
  textField: {
    margin: `${theme.metrics.margin.small} 0`,
  },
}));

export const AuthenticationTextField: React.FunctionComponent<Props & Omit<TextFieldProps, "onChange" | "error">> = ({
  className,
  onChange,
  error,
  ...rest
}) => {
  const classes = useStyles();

  const change = (event: React.ChangeEvent<HTMLInputElement>): void =>
    onChange(event.target.value);

  return (
    <TextField
      className={[classes.textField, className].join(" ")}
      variant="outlined"
      fullWidth
      {...rest}
      onChange={change}
      error={!!error}
      helperText={error}
    />
  );
};
export default AuthenticationTextField;

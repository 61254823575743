import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandableTable from "./ExpandableTable";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

interface Props {
  numberOfEntriesRemaining: number;
  initialDisplayedRows: number;
  headerComponent: JSX.Element;
}

const ExpandableTableWithHeader: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    initialDisplayedRows,
    numberOfEntriesRemaining: numberOfEntries,
    headerComponent,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>

      {headerComponent}

      <ExpandableTable
        children={children}
        initialDisplayedRows={initialDisplayedRows}
        numberOfEntriesRemaining={numberOfEntries}
      />
    </div>
  );
};

export default ExpandableTableWithHeader;

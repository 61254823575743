import AuthenticationError from "../../domain/AuthenticationError";
import { AuthenticationErrorWithLink } from "../../domain/AuthenticationErrorWithLink";

class PasswordResetFormErrors {

  public readonly password: AuthenticationError[];
  public readonly body: AuthenticationErrorWithLink[];

  constructor({ password, body }: { password: AuthenticationError[]; body: AuthenticationErrorWithLink[] }) {
    this.password = password;
    this.body = body;
  }

  public static empty(): PasswordResetFormErrors {
    return new PasswordResetFormErrors({ password: [], body: [] });
  }

  public isEmpty(): boolean {
    return this.password.length === 0 && this.body.length === 0;
  }
}

export default PasswordResetFormErrors;

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  textContainer: {
    whiteSpace: "pre-wrap",
  },
}));

const TermsAndPrivacyContent: React.FunctionComponent = () => {
  const { t } = useTranslation("authentication");
  const classes = useStyles();

  return (
    <>
      <div className={classes.textContainer}>
        <Trans
          t={t}
          i18nKey="termsOfService"
          components={[
            <strong key="0" />,
            <Link key="1" href={t("termsOfServiceLink")} />,
          ]}
        />
      </div>

      <br />

      <div className={classes.textContainer}>
        <Trans
          t={t}
          i18nKey="privacyPolicy"
          components={[
            <strong key="0" />,
            <Link key="1" href="http://www.allaboutcookies.org/manage-cookies/index.html" />,
            <Link key="2" href="mailto:privacy@medhelper.com" />,
          ]}
        />
      </div>
    </>
  );
};

export default TermsAndPrivacyContent;

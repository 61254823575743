import {
  Divider, makeStyles, TableBody, TableCell, TableRow, Typography,
} from "@material-ui/core";
import theme, { convertHexToRGBA } from "../../main/theme/theme";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReportResultsTable } from "./ReportResultsTable";
import { ReportResultsTableHead } from "./ReportResultsTableHead";
import { ReportResultsTableHeaderTitle } from "./ReportResultsTableHeaderTitle";
import { weekdays } from "../../schedule/domain/Weekday";
import { DateTime as LuxonDateTime } from "luxon";
import BloodPressureMeasure from "../domain/measurements/bloodPressureMeasurement/BloodPressureMeasure";
import { BloodPressureChart } from "./charts/BloodPressureChart";
import ChartDateRange from "../domain/ChartDateRange";
import DateTime from "../../main/dateTime/DateTime";
import { ChartHeaderField } from "./ChartHeaderField";
import BloodPressureMeasureAverage from "./BloodPressureMeasureAverage";
import NotesReport from "report/domain/note/NotesReport";
import NotesTableResults from "./notes/NotesTableResults";

const useStyles = makeStyles(() => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: convertHexToRGBA(theme.colors.table.row, 100),
    },
  },
}));

export interface Props {
  measures: BloodPressureMeasure[] | null;
  notes: NotesReport;
  testID?: string;
  name: string;
}

export const BloodPressureMeasureResultsTable: React.FunctionComponent<Props> = ({ measures, notes, name }) => {
  const styles = useStyles();
  const { t } = useTranslation("report");
  const [chartDateRange, setDateRange] = React.useState<ChartDateRange>(ChartDateRange.LAST30DAYS);

  const filteredMeasures = measures?.filter(
    (measure) => measure.actualTimestamp.isAfter(DateTime.now().addDays(-chartDateRange))
  ) || [];

  const sortedDescendingMeasures = measures?.slice().sort(
    (a, b) => a.actualTimestamp.isBefore(b.actualTimestamp) ? 1 : -1
  ) || [];

  return (
    <>
      <ChartHeaderField name={name} value={chartDateRange} onChartDateRangeChange={setDateRange} />

      <Divider />

      <BloodPressureMeasureAverage measures={filteredMeasures} />
      <BloodPressureChart dateRange={chartDateRange} measures={filteredMeasures} />

      <NotesTableResults notes={notes} />

      <ReportResultsTable>
        <ReportResultsTableHead>
          <TableCell />

          <ReportResultsTableHeaderTitle title={t("dateOnly")} />

          <ReportResultsTableHeaderTitle title={t("timeOnly")} />

          <ReportResultsTableHeaderTitle title={t("bloodPressure.systolic")} />

          <ReportResultsTableHeaderTitle title={t("bloodPressure.diastolic")} />

          <ReportResultsTableHeaderTitle title={t("bloodPressure.pulse")} />
        </ReportResultsTableHead>

        <TableBody>
          {sortedDescendingMeasures.map((measure) => (
            <TableRow key={measure.id} classes={{ root: styles.row }}>
              <TableCell>
                <Typography>{t(`weekdays:${weekdays[measure.actualTimestamp.getDayOfWeek()]}`)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{measure.actualTimestamp.formatLocale(LuxonDateTime.DATE_SHORT)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{measure.actualTimestamp.formatLocale(LuxonDateTime.TIME_SIMPLE)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{measure.systolic}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{measure.diastolic}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{measure.pulse}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ReportResultsTable>
    </>
  );
};

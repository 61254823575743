import { MeasurementDetail } from "../domain/MeasurementDetail";
import { MeasurementFormData } from "../domain/MeasurementFormData";
import { useFormData } from "@nexapp/nexapp-react-forms";
import { CustomMeasurementDetail } from "measurement/domain/CustomMeasurementDetail";

interface UserMeasurementFormResult<T extends MeasurementDetail> {
  formData: MeasurementFormData<T>;
  onChange: <F extends keyof MeasurementFormData<T>>(
    section: F
  ) => (field: keyof MeasurementFormData<T>[F]) => (value: MeasurementFormData<T>[F][typeof field]) => void;
  updateName: (name: string) => void;
  canSubmit: boolean;
  canChangeName: boolean;
}

export const useMeasurementForm =
  <T extends MeasurementDetail>(defaultMeasurementForm: MeasurementFormData<T>): UserMeasurementFormResult<T> => {
    const { formData, onChange } = useFormData({
      initialData: defaultMeasurementForm,
    });

    const updateName = (value: string): void => {
      formData.details.measurementDetail.name = value;
      onChange("details")("measurementDetail")(formData.details.measurementDetail);
    };

    return {
      formData,
      onChange,
      canSubmit: formData.details.measurementDetail.canSubmitMeasurement(),
      canChangeName: formData.details.measurementDetail instanceof CustomMeasurementDetail,
      updateName,
    };
  };

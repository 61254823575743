import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DrugLeaflet, { DrugLeafletAttributes } from "../../../domain/DrugLeaflet";
import theme from "../../../../main/theme/theme";
import DrugSheetTitle from "./DrugSheetTitle";

const useStyles = makeStyles(() => ({
  leafletSection: {
    marginBottom: theme.metrics.margin.small,
  },
  container: {
    "& $leafletSection:last-child": {
      marginBottom: 0,
    },
  },
}));

interface Props {
  leaflet: DrugLeaflet | null;
}

export const DrugSheetLeaflet: React.FunctionComponent<Props> = ({ leaflet }) => {
  const classes = useStyles();
  const { t } = useTranslation("report");

  if (!leaflet) {
    return null;
  }

  return (
    <div className={classes.container}>
      {leaflet.getOrderedLeafletAttributes().map((data: keyof DrugLeafletAttributes, count) => !!leaflet[data] &&
        <div className={classes.leafletSection} key={count}>
          <div>
            <div>
              <DrugSheetTitle>
                {t(`medicationDetailSubtitles.${data}`)}
              </DrugSheetTitle>
            </div>

            {(leaflet[data] as string).split("\n").map((item: string, index: number) => (
              <Typography component="span" variant="h4" key={index}>
                {item}

                <br />
              </Typography>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DrugSheetLeaflet;

import { useTranslation } from "react-i18next";
import SupportedLanguages from "../domain/SupportedLanguages";

export const I18N_LANGUAGE_KEY = "lang";

export const SUPPORTED_LANGUAGES = ["en", "fr"] as const;

export interface UseLanguageSelectionResponse {
  changeLanguage: (language: SupportedLanguages) => void;
  currentLanguage: SupportedLanguages;
  supportedLanguages: readonly SupportedLanguages[];
}

const useLanguageSelection = (): UseLanguageSelectionResponse => {
  const { i18n } = useTranslation();

  return {
    changeLanguage: (language: SupportedLanguages): void => {
      i18n.changeLanguage(language);
      localStorage.setItem(I18N_LANGUAGE_KEY, language);
    },
    currentLanguage: i18n.language.substr(0, 2).toLowerCase() as SupportedLanguages,
    supportedLanguages: SUPPORTED_LANGUAGES,
  };
};

export default useLanguageSelection;

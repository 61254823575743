import React from "react";
import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../../../main/theme/theme";

const useStyles = makeStyles(() => ({
  container: {
    height: pxToRem(20),
    width: pxToRem(20),
    padding: pxToRem(2),
    backgroundColor: "white",
    borderRadius: "50%",
  },
  pill: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#707070",
    fontWeight: 600,
  },
}));

interface Props {
  className?: string;
}

export const PercentIndicator: React.FunctionComponent<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={[classes.container, className].join(" ")}>
      <div className={classes.pill}>
        %
      </div>
    </div>
  );
};

export default PercentIndicator;

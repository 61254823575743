import NotesTable from "./NotesTable";
import NotesTableResultsHeader from "./NotesTableResultsHeader";
import NotesReport from "report/domain/note/NotesReport";
import React from "react";

interface Props {
  notes: NotesReport;
}

const NotesTableResults = ({ notes }: Props): JSX.Element =>
  <NotesTable notes={notes} CustomHeader={NotesTableResultsHeader} />;

export default NotesTableResults;

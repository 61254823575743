import React from "react";
import {
  Button, makeStyles, Typography,
} from "@material-ui/core";
import PlusIcon from "@material-ui/icons/Add";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  text: {
    cursor: "pointer",
  },
  button: {
    textTransform: "none",
    alignItems: "center",
    marginLeft: theme.metrics.margin.normal,
  },
  container: {
    margin: `${theme.metrics.margin.medium} 0`,
  },
}));

interface Props {
  text: string;
  onClick: (isOpen: boolean) => void;
  disabled?: boolean;
}

const AddCustomProgramButton: React.FunctionComponent<Props> = ({
  onClick,
  text,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        data-testid="customProgramButton"
        disabled={disabled}
        onClick={(): void => onClick(true)}
        className={classes.button}
        startIcon={<PlusIcon color={disabled ? undefined : "primary"} />}
      >
        <Typography component="label" variant="h4" className={classes.text}>
          {text}
        </Typography>
      </Button>
    </div>
  );
};

export default AddCustomProgramButton;

import CustomProgramOwner from "./CustomProgramOwner";
import SchedulableRegimenFormData, { assembleSchedulableRegimenFormData } from "./SchedulableRegimenFormData";
import { ProposedCustomProgramResponse } from "consultationSummary/api/response/ProposedCustomProgramResponse";

export default interface CustomProgramFormData {
  schedulableRegimen: SchedulableRegimenFormData;
  id: string;
  owner: CustomProgramOwner;
};

export const assembleFromHistoricalCustomProgram = (
  historicalCustomProgram: ProposedCustomProgramResponse
): CustomProgramFormData => ({
  id: historicalCustomProgram.customProgramId,
  owner: CustomProgramOwner.PROXIM,
  schedulableRegimen: assembleSchedulableRegimenFormData(historicalCustomProgram.schedulableRegimen),
});


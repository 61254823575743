import React from "react";
import { Theme, makeStyles } from "@material-ui/core";
import theme from "../../main/theme/theme";

const useStyles = makeStyles((muiTheme: Theme) => ({
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: `0 ${theme.metrics.margin.huge}`,
    [muiTheme.breakpoints.down("sm")]: {
      paddingTop: theme.metrics.margin.large,
    },
  },
}));

export const AuthenticationPageWrapper: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      {children}
    </div>
  );
};

export default AuthenticationPageWrapper;

import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import CodeFormValidator from "../../main/domain/CodeFormValidator";
import AlertCard from "../../main/components/AlertCard";
import theme from "../../main/theme/theme";
import CodeInput from "../../main/components/CodeInput";
import CodeFormData from "../../main/domain/CodeFormData";
import AuthenticationIcon from "./AuthenticationIcon";

const useStyles = makeStyles((muiTheme: Theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.metrics.margin.huge,
    [muiTheme.breakpoints.down("xs")]: {
      padding: theme.metrics.margin.medium,
    },
  },
  codeInput: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  alertCard: {
    width: "100%",
    padding: theme.metrics.margin.medium,
  },
  sentToMessage: {
    fontWeight: "bold",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.metrics.margin.huge,
    [muiTheme.breakpoints.down("sm")]: {
      marginTop: theme.metrics.margin.medium,
    },
  },
  title: {
    marginTop: theme.metrics.margin.normal,
    marginBottom: theme.metrics.margin.medium,
  },
  buttonsContainer: {
    marginTop: theme.metrics.margin.medium,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [muiTheme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  confirmButton: {
    marginRight: theme.metrics.margin.tiny,
  },
  resendButton: {
    marginLeft: theme.metrics.margin.tiny,
    whiteSpace: "nowrap",
    [muiTheme.breakpoints.down("md")]: {
      marginTop: theme.metrics.margin.small,
    },
  },
  error: {
    textAlign: "center",
    marginTop: theme.metrics.margin.medium,
  },
  codeSentMessage: {
    marginTop: theme.metrics.margin.medium,
    color: theme.colors.text.success,
    textAlign: "center",
  },
}));

interface Props {
  emailSentTo: string;
  error: string | undefined;
  message: string | undefined;
  onConfirmSignUp: (email: string, code: CodeFormData) => void;
  onResendCode: () => void;
}

const ConfirmSignUpForm: React.FunctionComponent<Props> = ({
  emailSentTo,
  onConfirmSignUp,
  onResendCode,
  error,
  message,
}) => {
  const [code, setCode] = useState<CodeFormData>(CodeFormData.withLength(6));
  const classes = useStyles();
  const { t } = useTranslation("authentication");

  return (
    <div className={classes.content}>
      <AlertCard className={classes.alertCard}>
        <Typography component="span" variant="h4" display="block" className={classes.sentToMessage}>
          {t("codeSentTo")}
        </Typography>

        <Typography component="span" variant="h4" display="block">
          {emailSentTo}
        </Typography>

        <Typography component="span" variant="h4" display="block">
          {t("validForTwentyFourHours")}
        </Typography>
      </AlertCard>

      <div className={classes.form}>
        <AuthenticationIcon name="code" />

        <Typography component="h2" variant="h3" className={classes.title}>
          {t("enterConfirmationCode")}
        </Typography>

        <div className={classes.codeInput}>
          <CodeInput
            size="small"
            code={code}
            onChange={setCode}
          />
        </div>

        {!!error &&
          <Typography component="span" variant="h5" color="error" className={classes.error}>
            {error}
          </Typography>
        }

        {!!message &&
          <Typography component="span" variant="h5" className={classes.codeSentMessage}>
            {message}
          </Typography>
        }

        <div className={classes.buttonsContainer}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!CodeFormValidator.isFull(code.codeCharacters)}
            className={classes.confirmButton}
            onClick={(): void => onConfirmSignUp(emailSentTo, code)}
          >
            {t("confirm")}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.resendButton}
            onClick={onResendCode}
          >
            {t("resendCode")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmSignUpForm;

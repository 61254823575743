import termsOfServiceEn from "../assets/termsofservice/en";
import termsOfServiceFr from "../assets/termsofservice/fr";
import privacyPolicyEn from "../assets/privacypolicy/en";
import privacyPolicyFr from "../assets/privacypolicy/fr";
import { ConfirmSignUpError } from "../domain/ConfirmSignUpError";

const authentication = {
  en: {
    authentication: {
      authenticating: "Authenticating",
      email: "Email Address",
      password: "Password",
      forgotPassword: "Forgot password?",
      signUpMessage: "Don’t have an account?",
      signUp: "Sign Up",
      signUpAction: "SIGN UP",
      loginAction: "LOG IN",
      logInMessage: "Already a Member?",
      logInMessageAction: "LOG IN",
      tryAtNoCharge: "Try For 6 Months At No Charge!",
      noCreditCardRequired: "NO CREDIT CARD REQUIRED",
      login: "Log in",
      logout: "Logout",
      termsOfService: termsOfServiceEn,
      termsOfServiceLink: "https://medhelper.com/policies-medhelper-for-professionals/",
      privacyPolicy: privacyPolicyEn,
      accept: "ACCEPT",
      cancel: "CANCEL",
      termsOfServicePlusPrivacyPolicy: "Terms of Service + Privacy Policy",
      codeSentTo: "Your Code Was Sent To:",
      validForTwentyFourHours: "(It will be valid for 24 Hrs)",
      enterConfirmationCode: "Enter Your Confirmation Code",
      confirm: "CONFIRM",
      resendCode: "RESEND CODE",
      codeSent: "A new code has been sent to your email.",
      errors: {
        [ConfirmSignUpError.InvalidCode]: "The code is not valid.",
        [ConfirmSignUpError.DefaultError]: "Something went wrong with the server. Please try again later.",
      },
      resetPassword: "Reset my password",
      resetPasswordInstructions: "Enter the email address used to access MedHelper. " +
        "We will email you a code that will allow you to validate the creation of a new password.",
      send: "Send",
      confirmNewPasswordInstructions: "Enter the verification code and your new password.",
      newPassword: "New password",
      passwordExpired: "Password expired",
      passwordExpiredInstructions: "Your password has expired. Please enter a new password.",
    },
  },
  fr: {
    authentication: {
      authenticating: "Authentification",
      email: "Courriel",
      password: "Mot de passe",
      forgotPassword: "Mot de passe oublié?",
      signUpMessage: "Vous n’avez pas de compte?",
      signUp: "Créer un compte",
      signUpAction: "S'INSCRIRE",
      loginAction: "SE CONNECTER",
      logInMessage: "Vous avez un compte?",
      logInMessageAction: "CONNECTEZ-VOUS",
      tryAtNoCharge: "Essayez pendant 6 mois sans frais",
      noCreditCardRequired: "PAS DE CARTE DE CREDIT NÉCESSAIRE",
      login: "S'identifier",
      logout: "Déconnexion",
      termsOfService: termsOfServiceFr,
      termsOfServiceLink: "https://medhelper.com/fr/politiques-medaide-pour-les-professionnels/",
      privacyPolicy: privacyPolicyFr,
      accept: "ACCEPTER",
      cancel: "ANNULER",
      termsOfServicePlusPrivacyPolicy: "Conditions d'utilisation + Déclaration de confidentialité",
      codeSentTo: "Votre code a été envoyé à:",
      validForTwentyFourHours: "(Il sera valide pendant 24 heures)",
      enterConfirmationCode: "Entrer le code de confirmation",
      confirm: "CONFIRMER",
      resendCode: "ENVOYER UN NOUVEAU CODE",
      codeSent: "Un nouveau code a été envoyé à votre courriel.",
      errors: {
        [ConfirmSignUpError.InvalidCode]: "Ce code est invalide.",
        [ConfirmSignUpError.DefaultError]: "Quelque chose ne va pas avec le serveur. Veuillez réessayer plus tard.",
      },
      resetPassword: "Mot de passe oublié",
      resetPasswordInstructions: "Saisissez l'adresse courriel utilisée pour accéder à MedHelper. " +
        "Nous vous enverrons par courriel un code qui vous permettra de valider la création d'un nouveau mot de passe.",
      send: "Envoyer",
      confirmNewPasswordInstructions: "Saisissez le code de vérification ainsi que votre nouveau mot de passe.",
      newPassword: "Nouveau mot de passe",
      passwordExpired: "Mot de passe expiré",
      passwordExpiredInstructions: "Votre mot de passe a expiré. Veuillez entrer un nouveau mot de passe.",
    },
  },
};

export default authentication;

import React, { useState } from "react";
import { generatePath } from "react-router";
import NavigationPath from "router/NavigationPath";
import CodeFormData from "../../main/domain/CodeFormData";
import { usePatientCodeForm } from "../hooks/usePatientCodeForm";
import CodeForm from "./CodeForm";

export const CodeFormContainer: React.FunctionComponent = () => {
  const [code, setCode] = useState<CodeFormData>(CodeFormData.withLength(4));
  const onSuccess = (): void => {
    window.open(generatePath(NavigationPath.PATIENT_REPORT, { code: code.describe() }));
  };
  const { submit, errors } = usePatientCodeForm({ onSuccess });

  return (
    <CodeForm
      code={code}
      onChange={setCode}
      errors={errors}
      onSubmit={async (): Promise<void> => await submit(code)}
    />
  );
};

export default CodeFormContainer;

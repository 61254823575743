import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  blankSlate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    height: "100px",
  },
  blankSlateLabel: {
    fontWeight: "lighter",
  },
}));

export const TableBlankSlate: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={classes.blankSlate}>
      <Typography component="h3" variant="h3" className={classes.blankSlateLabel}>
        {t("noItem").toUpperCase()}
      </Typography>
    </div>

  );
};

export default TableBlankSlate;

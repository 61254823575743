import { Button, makeStyles } from "@material-ui/core";
import theme from "../../main/theme/theme";
import React, { PropsWithChildren } from "react";
import { InstructionsField } from "./InstructionsField";
import InstructionFormData from "main/domain/InstructionFormData";
import { useTranslation } from "react-i18next";

interface Props {
  onSubmit: any;
  onChangeInstruction: (value: string) => void;
  submitLabel: string;
  canSubmit?: boolean;
  instructions?: InstructionFormData;
}

export const RegimenForm = ({
  onSubmit,
  onChangeInstruction,
  instructions,
  submitLabel,
  children,
  canSubmit = true,
}: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();

  return (
    <>
      {children}

      <div className={classes.instructions}>
        <InstructionsField
          title={t("instructions")}
          initialHtmlText={instructions?.text || ""}
          onChange={onChangeInstruction}
        />
      </div>

      <div className={classes.addButton}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!canSubmit}
        >
          {submitLabel}
        </Button>
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  instructions: {
    "marginTop": theme.metrics.margin.normal,
    "& label": {
      fontWeight: "bold",
    },
  },
  addButton: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.metrics.margin.normal,
  },
}));

import HealthProfession from "./HealthProfession";
import PartnerCompany from "./PartnerCompany";
import PhoneNumber from "../../../main/domain/PhoneNumber";

export default class Profile {
  public id: string;
  public firstName: string;
  public lastName: string;
  public officeAddress?: string;
  public officeNumber: PhoneNumber;
  public profession: HealthProfession;
  public partnerCompany?: PartnerCompany;

  constructor(
    id: string,
    firstName: string,
    lastName: string,
    officeAddress: string | undefined,
    officeNumber: PhoneNumber,
    profession: HealthProfession,
    partnerCompany: PartnerCompany | undefined,
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.officeAddress = officeAddress;
    this.officeNumber = officeNumber;
    this.profession = profession;
    this.partnerCompany = partnerCompany;
  }

  public isMedesync(): boolean {
    return this.partnerCompany === PartnerCompany.MEDESYNC;
  }
}

import React from "react";
import MuiSnackbar from "@material-ui/core/Snackbar";
import { SnackbarContent } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../theme/theme";
import CloseModalButton from "../modal/CloseModalButton";

export type SnackbarType = "success" | "error";

const useStyles = makeStyles(() => ({
  content: {
    minWidth: "initial",
    display: "flex",
    justifyContent: "center",
    padding: `${theme.metrics.margin.tiny} ${theme.metrics.margin.large}`,
  },
  success: {
    backgroundColor: theme.colors.text.success,
  },
  error: {
    backgroundColor: theme.colors.error,
  },
  closeButton: {
    "color": theme.colors.text.contrast,
    "&:hover": {
      color: theme.colors.text.emphasis,
    },
    "&:focus, &:active": {
      outline: "none",
    },
  },
  message: {
    fontSize: theme.fonts.h4.size,
    flex: 1,
  },
}));

interface Props {
  visible: boolean;
  onClose: () => void;
  type: SnackbarType;
  withCloseButton?: boolean;
}

const SNACKBAR_DISPLAY_DURATION_MS = 5000;

const Snackbar: React.FunctionComponent<Props> = ({ children, visible, onClose, type, withCloseButton }) => {
  const classes = useStyles();

  return (
    <MuiSnackbar
      open={visible}
      autoHideDuration={SNACKBAR_DISPLAY_DURATION_MS}
      onClose={onClose}
    >
      <SnackbarContent
        message={children}
        className={[classes.content, classes[type]].join(" ")}
        classes={{ message: classes.message }}
        action={!!withCloseButton && <CloseModalButton className={classes.closeButton} onClose={onClose} />}
      />
    </MuiSnackbar>
  );
};

export default Snackbar;

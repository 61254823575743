import ScheduleFormData, { createEmpty as createEmptySchedule, assembleScheduleFormData } from "../../schedule/domain/ScheduleFormData";
import { v4 as uuid } from "uuid";
import SessionFormData from "../../prescribe/domain/SessionFormData";
import DetailFormData, { createEmpty as createEmptyDetail } from "./DetailFormData";
import InstructionFormData, { createEmpty as createEmptyInstructions } from "main/domain/InstructionFormData";
import { ProposedSchedulableRegimenResponse } from "consultationSummary/api/response/ProposedSchedulableRegimenResponse";
import { CustomActivityTreatmentPlanResponse } from "consultationSummary/api/response/CustomActivityTreatmentPlanResponse";

export default interface CustomActivityFormData {
  id: string;
  details: DetailFormData;
  schedule: ScheduleFormData<SessionFormData>;
  instructions: InstructionFormData;
}

export const createEmpty = (): CustomActivityFormData => ({
  id: uuid(),
  details: createEmptyDetail(),
  schedule: createEmptySchedule<SessionFormData>(),
  instructions: createEmptyInstructions(),
});

export const assembleFromHistoricalActivity = (
  activity: ProposedSchedulableRegimenResponse<CustomActivityTreatmentPlanResponse>
): CustomActivityFormData => ({
  id: activity.id,
  details: { name: activity.regimen.customActivity.name },
  instructions: { text: activity.instructions },
  schedule: assembleScheduleFormData(activity, SessionFormData.assembleFromProposedEvents),
});

import React from "react";
import { Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FooterLink } from "../../main/components/FooterLink";
import { AppVersion } from "../../main/components/AppVersion";
import classNames from "classnames";

interface Props {
  className?: string;
}

export const AuthenticationFooter = ({ className }: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("authentication");

  return (
    <div className={classNames(classes.footer, className)}>
      <FooterLink href={t("userGuideLink")}>
        {t("userGuide")}
      </FooterLink>

      <div>
        <Trans
          t={t}
          i18nKey="poweredByMedHelper"
          components={[
            <Typography key="0" component="span" variant="h6" color="textSecondary" />,
            <FooterLink key="1" href={t("MedHelperWebsite")} />,
          ]}
        />
      </div>

      <AppVersion />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import theme from "../../main/theme/theme";

const useStyles = makeStyles((muiTheme: Theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    maxWidth: "250px",
    height: "auto",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    [muiTheme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  endSessionLink: {
    display: "flex",
    alignItems: "center",
  },
  endSessionIcon: {
    marginLeft: theme.metrics.margin.tiny,
  },
  historyLink: {
    marginRight: theme.metrics.margin.large,
    display: "flex",
    alignItems: "center",
  },
}));

interface Props {
  onEndSession?: () => void;
}

const ReportHeader: React.FunctionComponent<Props> = ({ onEndSession }) => {
  const classes = useStyles();
  const { t } = useTranslation("report");

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <img className={classes.logo} src={t("MedHelperLogo")} alt="Logo" />
      </Grid>

      <Grid item xs={12} sm={8} className={classes.buttonsContainer}>
        {!!onEndSession &&
          <Link onClick={onEndSession} variant="h4" color="textPrimary" className={classes.endSessionLink}>
            {t("endSession")}
            <CloseIcon className={classes.endSessionIcon} />
          </Link>
        }
      </Grid>
    </Grid>
  );
};

export default ReportHeader;

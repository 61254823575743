import PrescribeFormData from "prescribe/domain/PrescribeFormData";
import { DispatchAction } from "store/GlobalProvider";
import { AppState } from "store/GlobalStore";

export const storePrescribeFormData = (dispatch: DispatchAction, carePlan: PrescribeFormData): void => {
  dispatch({
    reducer: (state: AppState): AppState =>
      ({ ...state, prescribeForm: carePlan }),
  });
};

export const clearPrescribeFormData = (dispatch: DispatchAction): void => {
  dispatch({
    reducer: (state: AppState): AppState =>
      ({ ...state, prescribeForm: null }),
  });
};

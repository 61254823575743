import { useEffect, useState } from "react";
import DateTime from "../../main/dateTime/DateTime";
import { fetchMeasurementResults } from "../api/ReportResultsApi";
import MeasurementResults from "../domain/measurements/bloodGlucoseMeasurement/MeasurementResults";

export interface MeasurementResultsResult {
  errors: boolean;
  results: MeasurementResults | null;
}

export interface MeasurementResultsProps {
  code: string;
  measurementReportCode: string;
}

const useMeasurementResults = ({
  measurementReportCode,
  code,
}: MeasurementResultsProps): MeasurementResultsResult => {
  const [results, setResults] = useState<MeasurementResults | null>(null);
  const [errors, setErrors] = useState<boolean>(false);

  useEffect(() => {
    const fetchMeasurement = async (): Promise<void> => {
      setErrors(false);
      const timeZone = DateTime.getCurrentTimezone();
      try {
        const measurementResults = await fetchMeasurementResults(measurementReportCode, code, timeZone);
        setResults(measurementResults);
      } catch (e) {
        setErrors(true);
      }
    };

    fetchMeasurement();
  }, [code, measurementReportCode]);

  return { results, errors };

};

export default useMeasurementResults;

import React, {
  useCallback, useRef, useState,
} from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import ShowMoreText from "react-show-more-text";
import theme from "../../../main/theme/theme";
import ExpandableTableRow from "../../../main/components/ExpandableTableRow";
import Note from "../../domain/note/Note";
import useWindowResize from "../../../main/hooks/useWindowResize";

const useStyles = makeStyles(() => ({
  content: {
    flexDirection: "row",
    padding: `${theme.metrics.margin.normal} 0`,
  },
  boldText: {
    fontWeight: "bold",
  },
  emphasis: {
    fontWeight: 350,
  },
  showMore: {
    "marginLeft": 10,
    "color": theme.colors.primary.normal,
    "textDecoration": "none",
    "fontWeight": 450,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

interface Props {
  note: Note;
}

const NotesTableRow: React.FunctionComponent<Props> = ({ note }) => {
  const { t } = useTranslation("report");
  const [lineWidth, setLineWidth] = useState(0);
  const typographyRef = useRef<any>(null);
  const classes = useStyles();
  const calculateLineWidth = useCallback(() => {
    if (typographyRef?.current?.parentNode) {
      setLineWidth(typographyRef.current.parentNode.getBoundingClientRect().width - 40);
    }
  }, [typographyRef]);
  const setRef = useCallback((node) => {
    typographyRef.current = node;
    calculateLineWidth();
  }, [calculateLineWidth]);

  const title = t([
    `noteMeasurement.${note.regimenName}`,
    note.regimenName || "",
  ]);

  useWindowResize(calculateLineWidth);

  return (
    <ExpandableTableRow className={classes.content}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography component="strong" variant="h5" color="textPrimary" className={classes.boldText}>
                {
                  [note.createdAt.format(t("dateTime.date")), title]
                    .filter(Boolean)
                    .join(" - ")
                }
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Typography
              ref={setRef}
              component="span"
              variant="h5"
              color="textPrimary"
              className={classes.emphasis}
            >
              <ShowMoreText
                width={lineWidth}
                lines={1}
                anchorClass={classes.showMore}
                more={t("more").toUpperCase()}
                less={t("less").toUpperCase()}
              >
                {note.text}
              </ShowMoreText>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ExpandableTableRow>
  );
};

export default NotesTableRow;

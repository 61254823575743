import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import useDrugSheetModal from "../hooks/useDrugSheetModal";
import useLanguageSelection from "../../main/hooks/useLanguageSelection";
import theme from "../../main/theme/theme";
import PatientReport from "../domain/PatientReport";
import DrugSheetModal from "./drugSheetModal/DrugSheetModal";
import PreviousConsultations from "./previousConsultations/PreviousConsultations";
import PatientInfo from "./PatientInfo";
import TreatmentOverview from "./TreatmentOverview";
import Notes from "./notes/Notes";

const useStyles = makeStyles(() => ({
  report: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  row: {
    margin: `${theme.metrics.margin.medium} 0`,
  },
  treatment: {
    marginTop: theme.metrics.margin.large,
  },
}));

interface Props {
  report: PatientReport;
}

export const PatientTreatmentReport: React.FunctionComponent<Props> = ({ report }) => {
  const classes = useStyles();
  const { currentLanguage } = useLanguageSelection();
  const {
    isDrugSheetOpen,
    leaflet,
    leafletLoading,
    labelsLoading,
    drugLabels,
    drugSheetMedication,
    closeDrugSheet,
    openDrugSheet,
  } = useDrugSheetModal(currentLanguage);

  return (
    <div className={classes.report}>
      <DrugSheetModal
        medication={drugSheetMedication}
        leaflet={leaflet}
        drugLabels={drugLabels}
        leafletLoading={leafletLoading}
        labelsLoading={labelsLoading}
        isOpen={isDrugSheetOpen}
        onClose={closeDrugSheet}
      />

      <Grid container className={classes.row}>
        <Grid item sm={12} md={6}>
          <PatientInfo patient={report.patient} />
        </Grid>
      </Grid>

      <Grid container className={[classes.row, classes.treatment].join(" ")}>
        <Grid item xs={12}>
          <TreatmentOverview
            treatment={report.treatment}
            openDrugSheet={openDrugSheet}
          />
        </Grid>

        <Grid item xs={12} className={classes.row}>
          <Notes
            notes={report.notes}
          />
        </Grid>

        <Grid item xs={12} className={classes.row}>
          <PreviousConsultations
            previousConsultations={report.previousConsultations}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default PatientTreatmentReport;

enum DrugTypes {
  CAPLET = "CAPLET",
  CAPSULE = "CAPSULE",
  TABLET = "TABLET",
  SPRAY = "SPRAY",
  APPLICATION = "APPLICATION",
  DROP = "DROP",
  INHALE = "INHALE",
  POWDER = "POWDER",
  INJECTION = "INJECTION",
  LIQUID = "LIQUID",
}

export default DrugTypes;

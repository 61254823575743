import { Optional } from "main/utilities/TypeUtilities";
import PhoneNumberAssembler from "../../../../main/services/PhoneNumberAssembler";
import NewProfile from "../../domain/NewProfile";
import CognitoAttributes from "./CognitoAttributes";

type CognitoAttributesFiltered = Omit<{
  [key in CognitoAttributes]: string
}, CognitoAttributes.EMAIL | CognitoAttributes.LATEST_PASSWORD_CHANGE>;

type CognitoAttributesToUpdate = Optional<CognitoAttributesFiltered, CognitoAttributes.AGREEMENT_DATE>;

export default class AmplifyProfileAssembler {
  public static assembleToExternal(profile: NewProfile): CognitoAttributesToUpdate {
    const attributesToUpdate: CognitoAttributesToUpdate = {
      [CognitoAttributes.FIRST_NAME]: profile.firstName,
      [CognitoAttributes.LAST_NAME]: profile.lastName,
      [CognitoAttributes.OFFICE_ADDRESS]: profile.officeAddress || "",
      [CognitoAttributes.OFFICE_NUMBER]: PhoneNumberAssembler.assembleToExternal(profile.officeNumber),
      [CognitoAttributes.PROFESSION]: profile.profession,
      [CognitoAttributes.PARTNER_COMPANY]: (profile.partnerCompany && profile.partnerCompany.toString()) || "",
    };

    if (profile.agreementDate) {
      attributesToUpdate[CognitoAttributes.AGREEMENT_DATE] = profile.agreementDate;
    }

    return attributesToUpdate;
  }
}

import Client from "../../main/services/ApiClient";
import MeasurementResults from "../domain/measurements/bloodGlucoseMeasurement/MeasurementResults";
import MeasurementResultsResponse from "./responses/MeasurementResultsResponse";
import MeasurementResultsAssembler from "./MeasurementResultsAssembler";
import ActivityResultsResponse from "./responses/ActivityResultsResponse";
import ActivityResultsAssembler from "./ActivityResultsAssembler";
import OtherActivityResults from "report/domain/otherActivity/OtherActivityResults";
import MedicationResultsResponse from "./responses/MedicationResultsResponse";
import MedicationResultsAssembler from "./MedicationResultsAssembler";
import MedicationResults from "report/domain/medications/MedicationResults";
import ReminderResultsResponse from "./responses/ReminderResultsResponse";
import ReminderResultsAssembler from "./ReminderResultsAssembler";
import ReminderResults from "report/domain/reminders/ReminderResults";

export const fetchMeasurementResults = async (
  measurementReportCode: string,
  code: string,
  timezone: string
): Promise<MeasurementResults> => {
  const response = await new Client({
    endpoint: `/doctors/patients/${code}/measurements/${measurementReportCode}`,
    queryParams: { timezone },
  })
    .get<MeasurementResultsResponse>();

  return MeasurementResultsAssembler.fromResponse(response);
};

export const fetchActivityResults = async (
  reportCode: string,
  code: string,
  timezone: string
): Promise<OtherActivityResults> => {
  const response = await new Client({
    endpoint: `/doctors/patients/${code}/activities/${reportCode}`,
    queryParams: { timezone },
  })
    .get<ActivityResultsResponse>();

  return ActivityResultsAssembler.fromResponse(response);
};

export const fetchMedicationResults = async (
  reportCode: string,
  code: string,
  timezone: string
): Promise<MedicationResults> => {
  const response = await new Client({
    endpoint: `/doctors/patients/${code}/medications/${reportCode}`,
    queryParams: { timezone },
  })
    .get<MedicationResultsResponse>();

  return MedicationResultsAssembler.fromResponse(response);
};

export const fetchReminderResults = async (
  reportCode: string,
  code: string,
  timezone: string
): Promise<ReminderResults> => {
  const response = await new Client({
    endpoint: `/doctors/patients/${code}/reminders/${reportCode}`,
    queryParams: { timezone },
  })
    .get<ReminderResultsResponse>();

  return ReminderResultsAssembler.fromResponse(response);
};

import { useCallback, useState } from "react";
import Treatment from "../domain/Treatment";
import Medication from "../domain/medications/Medication";
import OtherActivity from "../domain/otherActivity/OtherActivity";
import Measurement from "../domain/measurements/Measurement";
import Reminder from "report/domain/reminders/Reminder";

interface ExpandableMedication {
  medication: Medication;
  expanded: boolean;
}

interface ExpandableActivity {
  activity: OtherActivity;
  expanded: boolean;
}

interface ExpandableMeasurement {
  measurement: Measurement;
  expanded: boolean;
}

interface ExpandableReminder {
  reminder: Reminder;
  expanded: boolean;
}

interface ExpandableTreatments {
  medications: ExpandableMedication[];
  activities: ExpandableActivity[];
  measurements: ExpandableMeasurement[];
  reminders: ExpandableReminder[];
}

export interface ExpandableTreatmentResult {
  toggleExpandAll: () => void;
  toggleExpandMedication: (medication: Medication) => void;
  toggleExpandActivity: (activity: OtherActivity) => void;
  toggleExpandMeasurement: (measurement: Measurement) => void;
  toggleExpandReminder: (reminder: Reminder) => void;
  expandableTreatment: ExpandableTreatments;
  allExpanded: boolean;
}

interface ExpandableTreatmentProps {
  treatment: Treatment;
}

export const useExpandableTreatment = ({ treatment }: ExpandableTreatmentProps): ExpandableTreatmentResult => {
  const defaultExpandableTreatment: ExpandableTreatments = {
    medications: treatment.getMedications().map((medication) => ({ medication, expanded: false })),
    activities: treatment.getActivities().map((activity) => ({ activity, expanded: false })),
    measurements: treatment.getMeasurements().map((measurement) => ({ measurement, expanded: false })),
    reminders: treatment.getReminders().map((reminder) => ({ reminder, expanded: false })),
  };

  const [expandableTreatment, onChangeExpandableTreatment] = useState(defaultExpandableTreatment);
  const [allExpanded, onChangeAllExpanded] = useState(false);

  const toggleExpandAll = useCallback(() => {
    const expanded = !allExpanded;

    const medications: ExpandableMedication[] = expandableTreatment.medications
      .map((medication) => ({ medication: medication.medication, expanded }));

    const activities: ExpandableActivity[] = expandableTreatment.activities
      .map((activity) => ({ activity: activity.activity, expanded }));

    const measurements: ExpandableMeasurement[] = expandableTreatment.measurements
      .map((schedulableMeasurement) => ({ measurement: schedulableMeasurement.measurement, expanded }));

    const reminders: ExpandableReminder[] = expandableTreatment.reminders
      .map((schedulableReminder) => ({ reminder: schedulableReminder.reminder, expanded }));

    onChangeExpandableTreatment({ medications, activities, measurements, reminders });
    onChangeAllExpanded(expanded);
  }, [expandableTreatment, allExpanded]);

  const toggleExpandMedication = useCallback((medication: Medication) => {
    const medications = expandableTreatment.medications.map((expandableMed) => {
      if (medication === expandableMed.medication) {
        return { ...expandableMed, expanded: !expandableMed.expanded };
      }
      return expandableMed;
    });

    onChangeExpandableTreatment((previousState) => ({ ...previousState, medications }));
  }, [expandableTreatment]);

  const toggleExpandActivity = useCallback((activity: OtherActivity) => {
    const activities = expandableTreatment.activities.map((expandableActivity) => {
      if (activity === expandableActivity.activity) {
        return { ...expandableActivity, expanded: !expandableActivity.expanded };
      }
      return expandableActivity;
    });

    onChangeExpandableTreatment((previousState) => ({ ...previousState, activities }));
  }, [expandableTreatment]);

  const toggleExpandMeasurement = useCallback((measurement: Measurement) => {
    const measurements = expandableTreatment.measurements.map((expandableMesurement) => {
      if (measurement === expandableMesurement.measurement) {
        return { ...expandableMesurement, expanded: !expandableMesurement.expanded };
      }
      return expandableMesurement;
    });

    onChangeExpandableTreatment((previousState) => ({ ...previousState, measurements }));
  }, [expandableTreatment]);

  const toggleExpandReminder = useCallback((reminder: Reminder) => {
    const reminders = expandableTreatment.reminders.map((expandableReminder) => {
      if (reminder === expandableReminder.reminder) {
        return { ...expandableReminder, expanded: !expandableReminder.expanded };
      }
      return expandableReminder;
    });

    onChangeExpandableTreatment((previousState) => ({ ...previousState, reminders }));
  }, [expandableTreatment]);

  return {
    toggleExpandAll,
    toggleExpandMedication,
    toggleExpandActivity,
    toggleExpandMeasurement,
    toggleExpandReminder,
    expandableTreatment,
    allExpanded,
  };
};

import React from "react";
import {
  Redirect, Route, Switch,
} from "react-router-dom";
import PageMenuWrapper from "main/components/menuWrapper/PageMenuWrapper";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import HomePage from "../home/HomePage";
import AuthenticationPage from "../authentication/pages/AuthenticationPage";
import useUserRepository from "../authentication/store/hooks/useUserRepository";
import CompleteProfilePage from "../authentication/profile/pages/CompleteProfilePage";
import useAppLoadingRepository from "../store/hooks/useAppLoadingRepository";
import Head from "../main/components/Head";
import NavigationPath from "./NavigationPath";
import PrivateRoutes from "./PrivateRoutes";

const useStyles = makeStyles(() => ({
  activityIndicator: {
    width: "100%",
  },
}));

const Router: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { authenticated } = useUserRepository();
  const { loading } = useAppLoadingRepository();

  if (loading) {
    return (
      <LinearProgress
        className={classes.activityIndicator}
        color="primary"
      />
    );
  }

  return (
    <Switch>
      <Redirect exact from="/" to={NavigationPath.CONNECT_WITH_PATIENT} />

      <Route exact path={NavigationPath.AUTHENTICATION}>
        <AuthenticationPage authenticated={authenticated} />
      </Route>

      <Route exact path={NavigationPath.COMPLETE_PROFILE}>
        <Head title={t("titles:completeProfile")} />
        <CompleteProfilePage />
      </Route>

      <PrivateRoutes
        PageMenuWrapper={PageMenuWrapper}
        authenticated={authenticated}
      >
        <Route exact path={NavigationPath.CONNECT_WITH_PATIENT}>
          <Head title={t("titles:home")} />
          <PageMenuWrapper>
            <HomePage />
          </PageMenuWrapper>
        </Route>
      </PrivateRoutes>
    </Switch>
  );
};

export default Router;

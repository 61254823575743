import ScheduleFormData, { createEmpty as createEmptySchedule, assembleScheduleFormData } from "../../schedule/domain/ScheduleFormData";
import { v4 as uuid } from "uuid";
import SessionFormData, { createEmpty as createEmptySession } from "../../prescribe/domain/SessionFormData";
import DetailFormData, { createEmpty as createEmptyDetail } from "./DetailFormData";
import InstructionFormData, { createEmpty as createEmptyInstructions } from "main/domain/InstructionFormData";
import { ProposedSchedulableRegimenResponse } from "consultationSummary/api/response/ProposedSchedulableRegimenResponse";
import { ReminderTreatmentPlanResponse } from "consultationSummary/api/response/ReminderTreatmentPlanResponse";
import ScheduleType from "schedule/domain/ScheduleType";
import DateTime from "main/dateTime/DateTime";

export default interface ReminderFormData {
  id: string;
  details: DetailFormData;
  schedule: ScheduleFormData<SessionFormData>;
  instructions: InstructionFormData;
}

export const createEmpty = (): ReminderFormData => ({
  id: uuid(),
  details: createEmptyDetail(),
  schedule: createEmptySchedule<SessionFormData>({
    type: ScheduleType.REGULAR,
    events: [createEmptySession()],
    duration: { startDate: DateTime.now(), endDate: DateTime.now() },
  }),
  instructions: createEmptyInstructions(),
});

export const assembleFromHistoricalReminder = (
  reminder: ProposedSchedulableRegimenResponse<ReminderTreatmentPlanResponse>
): ReminderFormData => ({
  id: reminder.id,
  details: { name: reminder.regimen.reminder.name },
  instructions: { text: reminder.instructions },
  schedule: assembleScheduleFormData(reminder, SessionFormData.assembleFromProposedEvents),
});

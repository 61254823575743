import React from "react";
import { MeasurementFormData } from "../../domain/MeasurementFormData";
import RequiredFieldLabel from "../../../main/components/RequiredFieldLabel";
import {
  Divider, makeStyles, TextField, Typography,
} from "@material-ui/core";
import ReminderSection from "../../../schedule/components/ReminderSection";
import { useTranslation } from "react-i18next";
import theme from "../../../main/theme/theme";
import { RegimenForm } from "../../../prescribe/components/RegimenForm";
import { useDuration } from "../../../prescribe/hooks/useDuration";
import DurationSection from "../../../prescribe/components/DurationSection";
import { useMeasurementForm } from "measurement/hooks/useMeasurementForm";
import { MeasurementDetail } from "measurement/domain/MeasurementDetail";
import { MeasurementIcon } from "../MeasurementIcon";
import { useScheduleForm } from "medication/hooks/useScheduleForm";
import { createEmpty as createEmptySession } from "prescribe/domain/SessionFormData";
import { ReminderSessionRenderer } from "prescribe/components/ReminderSessionRenderer";

interface Props {
  onSubmit: (formData: MeasurementFormData<MeasurementDetail>) => void;
  submitLabel: string;
  measurement: MeasurementFormData<MeasurementDetail>;
}

export const MeasurementForm = ({ onSubmit, submitLabel, measurement }: Props): JSX.Element => {
  const { t } = useTranslation("measurement");
  const classes = useStyles();
  const {
    formData,
    canSubmit,
    canChangeName,
    onChange,
    updateName,
  } = useMeasurementForm(measurement);

  const {
    toggle,
    active,
    addNewEvent,
    updateEvent,
    deleteEvent,
    changeFrequency,
    changeInterval,
    changeWeekdays,
    changeMonthDays,
  } = useScheduleForm(
    formData.schedule,
    (field, value) => onChange("schedule")(field)(value),
    createEmptySession,
  );

  const { onStartDateChange, onEndDateChange } = useDuration(
    formData.schedule,
    (field, value) => onChange("schedule")(field)(value),
  );

  return (
    <RegimenForm
      instructions={formData.instructions}
      onChangeInstruction={(instructions): void => onChange("instructions")("text")(instructions)}
      submitLabel={submitLabel}
      canSubmit={canSubmit}
      onSubmit={(): void => onSubmit(formData)}
    >
      {
        canChangeName
          ? <>
            <RequiredFieldLabel
              id="nameLabel"
              bold
              label={t("measurementName")}
              className={classes.textFieldLabel}
            />

            <TextField
              inputProps={{
                "aria-labelledby": "nameLabel",
              }}
              placeholder={t("name")}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => updateName(event.target.value)}
              variant="outlined"
              required
              fullWidth
              value={formData.details.measurementDetail.name}
            />

            <Divider light />
          </>
          : <div className={classes.measureTitleSection}>
            <Typography variant="h2" className={classes.measureTitle}>
              {formData.details.measurementDetail.name}
            </Typography>
            <MeasurementIcon
              measurementType={formData.details.measurementDetail.type}
              iconClassName={classes.measureIcon}
            />
          </div>
      }

      <DurationSection
        isEndDateEditable
        dateContainerStyle={classes.durationSection}
        durationFormData={formData.schedule.duration}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />

      <ReminderSection
        formData={formData.schedule}
        renderEvent={(session, index): JSX.Element => (
          <ReminderSessionRenderer
            session={session}
            onChange={updateEvent}
            index={index}
            canDelete={formData.schedule.events.length > 1}
            onDelete={deleteEvent}
          />
        )}
        active={active}
        onToggle={toggle}
        onAddSession={addNewEvent}
        addEventLabel={t("addReminder")}
        onChangeFrequency={changeFrequency}
        onChangeInterval={changeInterval}
        onChangeWeekdays={changeWeekdays}
        onChangeMonthDays={changeMonthDays}
      />
    </RegimenForm>
  );
};

const useStyles = makeStyles(() => ({
  textFieldLabel: {
    marginBottom: theme.metrics.margin.small,
  },
  measureTitleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.metrics.margin.small,
    marginBottom: theme.metrics.margin.small,
  },
  measureTitle: {
    fontWeight: "bold",
  },
  measureIcon: {
    height: 23,
  },
  durationSection: {
    padding: "0",
  },
}));

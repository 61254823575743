import DateTime from "../../main/dateTime/DateTime";
import ScheduleFormData from "../../schedule/domain/ScheduleFormData";
import SessionFormData from "../domain/SessionFormData";

export interface UseDurationResult {
  onAllDateChange: (newDate: string) => void;
  onStartDateChange: (newDate: string) => void;
  onEndDateChange: (newDate: string) => void;
}

type OnChange<M extends SessionFormData> = <T extends keyof ScheduleFormData<M>>(
  field: T,
  value: ScheduleFormData<M>[T]
) => void;

export const useDuration = <T extends SessionFormData>(
  schedule: ScheduleFormData<T>,
  onChange: OnChange<T>
): UseDurationResult => {
  const onStartDateChange = (newDate: string): void => {
    const date = DateTime.fromISO(newDate);

    if (schedule.duration.endDate !== null && schedule.duration.endDate.isBefore(date)) {
      onAllDateChange(newDate);
    } else {
      onChange("duration", { ...schedule.duration, startDate: date });
    }
  };

  const onEndDateChange = (newDate: string): void => {
    const date = DateTime.fromISO(newDate);
    onChange("duration", { ...schedule.duration, endDate: date });
  };

  const onAllDateChange = (newDate: string): void => {
    const date = DateTime.fromISO(newDate);
    onChange("duration", { startDate: date, endDate: date });
  };

  return {
    onAllDateChange,
    onStartDateChange,
    onEndDateChange,
  };
};

import React, { ChangeEvent, RefObject } from "react";

interface Props{
  inputRef: RefObject<HTMLInputElement>;
  onValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  hidden?: boolean;
  multiple?: boolean;
  extensions?: string[];
  title?: string;
  disabled?: boolean;
}

export const FileInput = ({
  inputRef,
  onValueChange,
  hidden,
  multiple,
  extensions,
  title,
  disabled,
}: Props): JSX.Element => (
  <input
    type="file"
    ref={inputRef}
    hidden={hidden}
    title={title}
    onChange={onValueChange}
    multiple={multiple}
    disabled={disabled}
    accept={extensions?.join(",")}
  />
);

import isEmailValid from "../../services/validators/emailValidator";
import isPasswordValid from "../../services/validators/passwordValidator";
import AuthenticationError from "../../domain/AuthenticationError";
import UserSignUpFormData from "./UserSignUpFormData";
import { UserSignUpFormErrors } from "./UserSignUpFormErrors";

class UserSignUpValidator {

  public validate(signUp: UserSignUpFormData): UserSignUpFormErrors {
    let errors = UserSignUpFormErrors.empty();

    if (!isEmailValid(signUp.email)) {
      errors = errors.add(AuthenticationError.InvalidEmail, "email");
    }

    if (!isPasswordValid(signUp.password)) {
      errors = errors.add(AuthenticationError.InvalidPassword, "password");
    }

    return errors;
  }
}

export default new UserSignUpValidator();

import { Typography, makeStyles } from "@material-ui/core";

import { MeasurementDetail } from "../domain/MeasurementDetail";
import { MeasurementFormData } from "../domain/MeasurementFormData";
import React from "react";
import { TreatmentPlanRegimenSummary } from "../../prescribe/components/TreatmentPlanRegimenSummary";
import { carePlanTheme } from "prescribe/components/CarePlanSection";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";

interface Props {
  isReadOnly: boolean;
  measurement: MeasurementFormData<MeasurementDetail>;
  onEditClick: (measurement: MeasurementFormData<MeasurementDetail>) => void;
  onDeleteClick: (measurement: MeasurementFormData<MeasurementDetail>) => void;
}

export const MeasurementSummary = ({ isReadOnly, measurement, onEditClick, onDeleteClick }: Props): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation("measurement");

  return (
    <TreatmentPlanRegimenSummary
      isReadOnly={isReadOnly}
      instructions={measurement.instructions}
      schedule={measurement.schedule}
      onEditClick={(): void => onEditClick(measurement)}
      onDeleteClick={(): void => onDeleteClick(measurement)}
      editTitle={t("editMeasurement")}
      deleteTitle={t("deleteMeasurement")}
    >
      <div className={classes.spacer} />

      <div className={classes.detail}>
        <Typography
          component="h4"
          variant="h4"
          className={classes.name}
        >
          {measurement.details.measurementDetail.name}
        </Typography>
      </div>
    </TreatmentPlanRegimenSummary>
  );
};

const useStyle = makeStyles(() => ({
  detail: {
    display: "flex",
    alignItems: "center",
    flex: 3,
  },
  name: {
    flex: 2,
    fontWeight: "bold",
    color: theme.colors.text.primary,
    wordBreak: "break-word",
  },
  spacer: {
    width: carePlanTheme.spacerWidth + "px",
  },
  icon: {
    height: 30,
    width: 30,
  },
}));

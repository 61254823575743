import DailyAdherence from "./DailyAdherence";

class DailyAdherenceCalendar {

  private readonly days: DailyAdherence[];

  constructor({ days }: { days: DailyAdherence[] }) {
    this.days = days;
  }

  public makeGroupsOf(numberOfDays: number): DailyAdherence[][] {
    const numberOfGroups = Math.ceil(this.days.length / numberOfDays);
    const groups = [];
    for (let i = 0; i < numberOfGroups; i++) {
      const start = i * numberOfDays;
      groups.push(this.days.slice(start, start + numberOfDays));
    }

    return groups;
  }
}

export default DailyAdherenceCalendar;

import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { pxToRem, convertHexToRGBA } from "../../main/theme/theme";
import Images from "../../main/assets/Images";

const NavMenu = styled.nav`
  flex: 1;
  background-image: url(${Images.SideMenuBottom});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  & > a {
    border-top: ${pxToRem(1)} solid ${({ theme }): string => convertHexToRGBA(theme.colors.primary.light, 35)};
  }

  & a:last-child {
    border-bottom: ${pxToRem(1)} solid ${({ theme }): string => convertHexToRGBA(theme.colors.primary.light, 35)};
  }
`;

export const SideMenuItems: React.FunctionComponent<PropsWithChildren<any>> = ({ children }) => (
  <NavMenu>
    {children}
  </NavMenu>
);

export default SideMenuItems;

import {
  Collapse, IconButton, makeStyles, Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MinusIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import PlusIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import React from "react";
import Grid from "@material-ui/core/Grid";
import theme from "../../main/theme/theme";
import { ViewResultsButton } from "./ViewResultsButton";

const useStyles = makeStyles(() => ({
  rowContainer: {
    alignItems: "center",
  },
  rowSection: {
    padding: theme.metrics.margin.normal,
  },
  treatmentAttribute: {
    fontWeight: "bold",
  },
  emphasis: {
    fontWeight: 350,
  },
  value: {
    marginLeft: theme.metrics.margin.tiny,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    padding: 0,
  },
  content: {
    display: "flex",
  },
  container: {
    "width": "100%",
    "borderBottom": `${theme.metrics.border.tiny} ${theme.colors.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  picture: {
    alignSelf: "stretch",
    display: "flex",
    justifyContent: "flex-end",
  },
  viewResults: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface Props {
  title: string;
  subtitle: string;
  purpose?: string;
  schedule: string;
  expanded: boolean;
  toggleExpanded: () => void;
  onViewResult: () => void;
  picture: React.ReactElement;
}

export const TreatmentRow: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  purpose,
  schedule,
  expanded,
  toggleExpanded,
  onViewResult,
  picture,
  children,
}) => {

  const classes = useStyles();
  const { t } = useTranslation("report");

  return (
    <div className={classes.container}>
      <div className={classes.row}>

        <Grid container className={classes.rowContainer}>
          <Grid item xs={4} className={[classes.row].join(" ")}>
            <div className={classes.buttonContainer}>
              <IconButton
                color="primary"
                onClick={toggleExpanded}
                classes={{
                  root: classes.button,
                }}
              >
                {expanded ? <MinusIcon /> : <PlusIcon />}
              </IconButton>
            </div>

            <div className={classes.rowSection}>
              <Typography component="h4" variant="h4" className={classes.treatmentAttribute}>
                {title}
              </Typography>

              <Typography
                component="span"
                variant="h5"
                className={classes.emphasis}
              >
                {subtitle}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={2} className={classes.picture}>
            {picture}
          </Grid>

          <Grid item xs={4} className={classes.rowSection}>
            {!!purpose &&
              <div className={classes.row}>
                <Typography component="span" variant="h5" className={classes.treatmentAttribute}>
                  {t("purpose")}:
                </Typography>

                <Typography
                  component="span"
                  variant="h5"
                  className={[classes.emphasis, classes.value].join(" ")}
                >
                  {purpose}
                </Typography>
              </div>
            }

            <div className={classes.row}>
              <Typography component="span" variant="h5" className={classes.treatmentAttribute}>
                {t("schedule")}:
              </Typography>

              <Typography
                component="span"
                variant="h5"
                className={[classes.emphasis, classes.value].join(" ")}
              >
                {schedule}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={2} className={classes.viewResults}>
            <ViewResultsButton onClick={onViewResult} />
          </Grid>
        </Grid>
      </div>

      <div>
        <Collapse in={expanded}>
          <div className={classes.content}>
            {children}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

import AdherenceStatus from "../domain/AdherenceStatus";

const COLORS = {
  [AdherenceStatus.NOT_APPLICABLE]: "#c5c5c5",
  [AdherenceStatus.ONGOING]: "#c5c5c5",
  [AdherenceStatus.FAILED]: "#d93731",
  [AdherenceStatus.COMPLETED]: "#56c612",
};

const getAdherenceStatusColor = (status: AdherenceStatus): string => COLORS[status];

export default getAdherenceStatusColor;

import {
  Divider, makeStyles, TableBody, TableCell, TableRow, Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import theme, { convertHexToRGBA } from "../../main/theme/theme";
import BloodGlucoseMeasure from "../domain/measurements/bloodGlucoseMeasurement/BloodGlucoseMeasure";
import TimeOfDay from "../domain/measurements/bloodGlucoseMeasurement/TimeOfDay";
import { ReportResultsTable } from "./ReportResultsTable";
import { ReportResultsTableHead } from "./ReportResultsTableHead";
import { ReportResultsTableHeaderTitle } from "./ReportResultsTableHeaderTitle";
import { DateTime as LuxonDateTime } from "luxon";
import NotesReport from "report/domain/note/NotesReport";
import NotesTableResults from "./notes/NotesTableResults";

interface Props {
  measures: BloodGlucoseMeasure[] | null;
  notes: NotesReport;
  testID?: string;
}

const BloodGlucoseMeasureResultsTable: React.FunctionComponent<Props> = ({ measures, notes }) => {
  const styles = useStyles();
  const { t } = useTranslation("report");
  const uponWakingMeasures = measures!.filter((measure) => (measure.measureTime === TimeOfDay.UPON_WAKING));
  const beforeMealMeasures = measures!.filter((measure) => (measure.measureTime === TimeOfDay.BEFORE_MEAL));
  const afterMealMeasures = measures!.filter((measure) => (measure.measureTime === TimeOfDay.AFTER_MEAL));
  const bedtimeMeasures = measures!.filter((measure) => (measure.measureTime === TimeOfDay.AT_BEDTIME));
  const sortedMeasures = [uponWakingMeasures, beforeMealMeasures, afterMealMeasures, bedtimeMeasures].flat();

  return (
    <>
      <Typography component="span" variant="h5" color="textPrimary" style={{ fontWeight: "bold" }}>
        {t("bloodGlucoseResults")}
      </Typography>

      <Divider className={styles.divider} />

      <NotesTableResults notes={notes} />

      <ReportResultsTable>
        <ReportResultsTableHead>
          <TableCell />

          <ReportResultsTableHeaderTitle title={t("dateAndTime")} />

          <ReportResultsTableHeaderTitle title={t("value")} />

          <ReportResultsTableHeaderTitle title={t("targetRange")} />
        </ReportResultsTableHead>

        <TableBody>
          {sortedMeasures.map((measure, index) => (
            <TableRow key={measure.id} classes={{ root: styles.row }}>
              <TableCell>
                {
                  index > 0 ? sortedMeasures[index - 1].measureTime !== measure.measureTime ?
                    <Typography>{t(`measureTime.${measure.measureTime}`)}</Typography> : null :
                    <Typography>{t(`measureTime.${measure.measureTime}`)}</Typography>
                }
              </TableCell>

              <TableCell>
                <Typography>{measure.actualTimestamp.formatLocale(LuxonDateTime.DATETIME_SHORT)}</Typography>
              </TableCell>

              <TableCell>
                <Typography>{`${measure.value} mmol/L`}</Typography>
              </TableCell>

              <TableCell />
            </TableRow>
          ))}
        </TableBody>
      </ReportResultsTable>
    </>
  );
};

const useStyles = makeStyles(() => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: convertHexToRGBA(theme.colors.table.row, 100),
    },
  },
  divider: {
    marginBottom: theme.metrics.margin.large,
  },
}));

export default BloodGlucoseMeasureResultsTable;

import React from "react";
import {
  Button, makeStyles, Typography,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { Modal } from "../../main/modal/Modal";
import PrescribeFormData from "../domain/PrescribeFormData";
import theme from "../../main/theme/theme";
import ModalHeader from "../../main/modal/ModalHeader";
import OverlayLoading from "../../main/components/OverlayLoading";
import { InstructionHtmlContent } from "./InstructionHtmlContent";
import { isStandaloneContext } from "main/selectors/AppContextSelector";
import useProfileRepository from "authentication/store/hooks/useProfileRepository";

const useStyles = makeStyles(() => ({
  noInstructionsMessage: {
    fontStyle: "italic",
  },
  actions: {
    width: "100%",
    justifyContent: "space-around",
    display: "flex",
    margin: `${theme.metrics.margin.large} 0 ${theme.metrics.margin.medium}`,
  },
}));

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  form: PrescribeFormData;
  loading?: boolean;
}

export const ConfirmationModal: React.FunctionComponent<Props> = ({
  className,
  isOpen,
  onClose,
  onConfirm,
  form,
  loading = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("prescribe");
  const { profile } = useProfileRepository();

  const showWithInstruction = form.carePlan.isFilled() && isStandaloneContext();

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose} disableOutsideClick={loading}>
      <ModalHeader
        title={t("prescriptionConfirmation")}
        onClose={onClose}
      />

      <Typography variant="h4" paragraph>
        <Trans
          t={t}
          i18nKey={showWithInstruction ? "wouldYouLikeToSend" : "wouldYouLikeToSendNoInstructions"}
          values={{ email: form.email }}
        />
      </Typography>

      {
        !!showWithInstruction
        && <InstructionHtmlContent text={form.carePlan.instructions} />
      }

      {
        Boolean(profile?.isMedesync())
        && <Typography variant="h4" paragraph>
          <Trans t={t} i18nKey="medesyncConfirmation" />
        </Typography>
      }

      <div className={classes.actions}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={onClose}
        >
          {t("modify")}
        </Button>

        <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onConfirm}
        >
          {t("send")}
        </Button>
      </div>

      <OverlayLoading visible={loading} />
    </Modal>
  );
};

export default ConfirmationModal;

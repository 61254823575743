import {
  makeStyles, Typography, FormControlLabel, Checkbox,
} from "@material-ui/core";
import theme, { pxToRem } from "main/theme/theme";
import React from "react";
import TableFilter from "main/types/TableFilter";

interface Props {
  title: string;
  filters?: TableFilter[];
}

export const PreviousConsultationsTableHeader = ({ title, filters = [] }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <Typography component="h2" variant="h3" color="textPrimary" className={classes.title}>
        {title}
      </Typography>

      {filters.map((filter: TableFilter, index: number) => (
        <FormControlLabel
          key={index}
          className={[classes.checkboxContainer, filter.enabled && "active"].join(" ")}
          control={
            <Checkbox
              classes={{ root: classes.checkbox }}
              style={{ color: filter.enabled ? "white" : theme.colors.text.secondary }}
              checked={filter.enabled}
              onChange={filter.onChange}
            />
          }
          label={
            <Typography
              component="span"
              variant="h5"
            >
              {filter.title}
            </Typography>
          }
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: theme.metrics.margin.small,
    marginBottom: theme.metrics.margin.small,
    borderBottom: `${theme.metrics.border.tiny} black`,
  },
  checkboxContainer: {
    "border": `${theme.metrics.border.tiny} ${theme.colors.text.secondary}`,
    "borderRadius": pxToRem(5),
    "padding": `${theme.metrics.margin.tiny} ${theme.metrics.margin.small}`,
    "&.active": {
      backgroundColor: theme.colors.primary.light,
      color: "white",
    },
  },
  checkbox: {
    "padding": 0,
    "color": "transparent",
    "margin-right": `${theme.metrics.margin.tiny}`,
  },
}));

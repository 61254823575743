import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
}));

interface Props {
  loading: boolean;
}

export const DrugSheetLoading: React.FunctionComponent<Props> = ({ loading }) => {
  const classes = useStyles();
  if (!loading) {
    return null;
  }

  return (
    <div className={classes.loadingContainer}>
      <CircularProgress />
    </div>
  );
};

const isEqual = (prevProps: Props, nextProps: Props): boolean =>
  prevProps.loading === nextProps.loading;

export default React.memo(DrugSheetLoading, isEqual);

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import theme from "../theme/theme";

const useStyles = makeStyles(() => ({
  viewAllText: {
    fontWeight: "bold",
    marginRight: theme.metrics.margin.tiny,
  },
}));

interface Props {
  numberOfEntries: number;
  onClick: () => void;
  expanded: boolean;
}

const ExpandButton: React.FunctionComponent<Props> = ({ numberOfEntries, onClick, expanded }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Button
        onClick={onClick}
        endIcon={expanded ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
      >
        {expanded ?
          <Typography key="0" component="span" variant="h4" className={classes.viewAllText}>
            {t("collapse")}
          </Typography>
          :
          <Trans
            t={t}
            i18nKey="collapseShowAll"
            components={[
              <Typography key="0" component="span" variant="h4" className={classes.viewAllText} />,
              <Typography key="1" component="span" variant="h4" />,
            ]}
            values={{ numberOfEntries }}
          />
        }
      </Button>
    </div>
  );
};

export default ExpandButton;

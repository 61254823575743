import DrugLabels from "../domain/DrugLabels";
import DrugLabelsResponse from "./responses/DrugLabelsResponse";
import DrugLabelAssembler from "./DrugLabelAssembler";

class DrugLabelsAssembler {
  public static fromResponse(plain: DrugLabelsResponse): DrugLabels {
    return new DrugLabels({
      auxiliary: plain.auxiliary.map((labelResponse) => DrugLabelAssembler.formResponse(labelResponse)),
      mandatory: plain.mandatory.map((labelResponse) => DrugLabelAssembler.formResponse(labelResponse)),
    });
  }
}

export default DrugLabelsAssembler;

import { useState, useCallback } from "react";
import DrugLeaflet from "../domain/DrugLeaflet";
import { fetchDrugSheet } from "../api/DrugSheetApi";

export interface UseDrugFinderServiceResult {
  leafletLoading: boolean;
  getLeaflet: (din: string) => Promise<DrugLeaflet | null>;
}

const useDrugFinderService = (language: string): UseDrugFinderServiceResult => {
  const [cache, setLeaflet] = useState<{ drugId: string; leaflet: DrugLeaflet }[]>([]);
  const [leafletLoading, setLoading] = useState(true);

  const fromCache = useCallback((din: string): DrugLeaflet | undefined =>
    cache.find((drugLeaflet) => drugLeaflet.drugId === din)?.leaflet
  , [cache]);

  const getLeaflet = async (dinToFetch: string): Promise<DrugLeaflet | null> => {
    const leafletFromCache = fromCache(dinToFetch);
    if (!!leafletFromCache) {
      return leafletFromCache;
    }

    try {
      setLoading(true);
      const drugLeaflet = await fetchDrugSheet(dinToFetch, language);
      if (drugLeaflet) {
        setLeaflet([...cache, { drugId: dinToFetch, leaflet: drugLeaflet }]);
      }
      setLoading(false);
      return drugLeaflet;
    } catch {
      setLoading(false);
      return null;
    }
  };

  return {
    leafletLoading,
    getLeaflet,
  };
};

export default useDrugFinderService;

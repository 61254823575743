const translations = {
  en: {
    titles: {
      home: "Home",
      authentication: "Authentication",
      report: "Report",
      profile: "Profile",
      connectionHistory: "Connection History",
      inviteAPatient: "Invite a patient",
      completeProfile: "Complete Profile",
    },
  },
  fr: {
    titles: {
      home: "Accueil",
      authentication: "Authentification",
      report: "Rapport",
      profile: "Profil",
      connectionHistory: "Historique des connexions",
      inviteAPatient: "Inviter un patient",
      completeProfile: "Compléter votre profil",
    },
  },
};

export default translations;

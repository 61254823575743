import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CodeFormContainer from "./components/CodeFormContainer";

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export const HomePage: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CodeFormContainer />
    </div>
  );
};

export default HomePage;

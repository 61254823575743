import Frequency from "../domain/Frequency";

const schedule = {
  en: {
    schedule: {
      ofTheMonth: "{{ monthDays }} of the month",
      repeatEvery: "Repeat every",
      setAReminder: "Set a reminder",
      setAnAlarm: "Set an alarm",
      minutes: "Minutes",
      alarmIcon: "Alarm icon",
      clockIcon: "Clock icon",
      alarmDescription: "Alarm - Every {{ interval }} minutes",
      event: "Event",
      selectFrequency: "Select a frequency",
      startDate: "Start Date",
      endDate: "End Date",
      day: "Day",
      frequency: {
        [Frequency.DAILY]: "Daily",
        [Frequency.WEEKLY]: "Weekly",
        [Frequency.MONTHLY]: "Monthly",
      },
      dose: "{{count}} dose",
      dose_plural: "{{count}} doses",
      at: "at",
      and: "and",
    },
  },
  fr: {
    schedule: {
      ofTheMonth: "{{ monthDays }} du mois",
      repeatEvery: "Répéter tous les/toutes les ",
      setAReminder: "Définir un rappel",
      setAnAlarm: "Ajouter une alarme",
      minutes: "Minutes",
      alarmIcon: "Icône d'alarme",
      clockIcon: "Icône d'horloge",
      alarmDescription: "Alarme - Toutes les {{ interval }} minutes",
      event: "Évènement",
      selectFrequency: "Sélectionner une fréquence",
      startDate: "Date de début",
      endDate: "Date de fin",
      frequency: {
        [Frequency.DAILY]: "Jour(s)",
        [Frequency.WEEKLY]: "Semaine(s)",
        [Frequency.MONTHLY]: "Mois",
      },
      day: "Jour",
      dose: "{{count}} dose",
      dose_plural: "{{count}} doses",
      at: "à",
      and: "et",
    },
  },
};

export default schedule;

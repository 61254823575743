import React, {
  useEffect, useState, useRef, useCallback,
} from "react";

import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Editor } from "@tinymce/tinymce-react";
import { Typography } from "@material-ui/core";
import envConfig from "../../main/config/envConfig";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";

const defaultTheme = {
  "margin": `${theme.metrics.margin.normal} 0`,
  "& textarea": {
    resize: "vertical",
  },
  "& .tox-statusbar__branding": {
    display: "none !important",
  },
  "& .tox-statusbar__path-item": {
    display: "none !important",
  },
};

const textEditorStyles = makeStyles(() => ({
  textField: {
    "& .tox-tinymce": {
      borderRadius: 4,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "rgb(196, 196, 196)",
    },
    ...defaultTheme,

  },
}));

const focusTextEditorStyles = makeStyles(() => ({
  textField: {
    "& .tox-tinymce": {
      borderRadius: 4,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "#188ab9",
    },
    ...defaultTheme,
  },
}));

interface Props {
  title?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  initialHtmlText: string;
}

export const InstructionsField: React.FunctionComponent<Props> = ({
  title,
  placeholder,
  onChange,
  disabled,
  initialHtmlText,
}) => {
  const FIELD_MAX_CHARACTERS = 65535;
  const classes = textEditorStyles();
  const focusClasses = focusTextEditorStyles();

  const [instructions, setInstructions] = useState(initialHtmlText);
  const [isInitialized, setIsInitialized] = useState(false);
  const [textEditorStyle, setTextEditorStyle] = useState<ClassNameMap<"textField">>();
  const { t } = useTranslation("prescribe");
  const editorRef = useRef<any>();

  useEffect(() => {
    setTextEditorStyle(classes);
  }, [classes]);

  useEffect(() => {
    // Only happen when clearing the form
    if (initialHtmlText === "") {
      setInstructions("");
    }
  }, [initialHtmlText]);

  const onFocusIn = (_event: {}): void => {
    setTextEditorStyle(focusClasses);
  };

  const onFocusOut = (_event: {}, editor: any): void => {
    // Doing the onChange on focus out to prevent performance issue while typing
    onChange(editor.getContent());
    setTextEditorStyle(classes);
  };

  const changePlaceholder = useCallback((): void => {
    // Handle placholder changes manually since it only allow to be set at initialization
    if (editorRef?.current && !editorRef.current.getContent()) {
      editorRef.current.getBody().setAttribute("data-mce-placeholder", placeholder);
    }
  }, [placeholder]);

  const onHandleTextChange = (htmlText: string, editor: any): void => {
    const length = editor.getContent({ format: "text" }).length;

    if (length <= FIELD_MAX_CHARACTERS) {
      setInstructions(htmlText);
    }

    changePlaceholder();
  };

  useEffect(() => {
    changePlaceholder();
  }, [changePlaceholder]);

  return (
    <div style={{ width: "100%" }}>
      {
        !!title
        && <Typography component="label" variant="h4">
          {title}
        </Typography>
      }

      <div aria-label={disabled ? "textEditDisabled" : "textEditNotDisabled"} className={textEditorStyle?.textField}>
        <Editor
          value={instructions}
          onEditorChange={onHandleTextChange}
          disabled={!!disabled && isInitialized}
          apiKey={envConfig.textEditorApiKey}
          onFocusOut={onFocusOut}
          onFocusIn={onFocusIn}
          // Must keep track of initialization because the placeholder do not display if we disable the editor too soon
          onPostRender={(): void => setIsInitialized(true)}
          onInit={(_event, editor): void => {
            editorRef.current = editor;
          }}
          init={{
            readonly: true,
            height: 250,
            placeholder: placeholder || t("enterInstructionsHere"),
            menubar: false,
            statusbar: true,
            paste_as_text: true,
            plugins: [
              "advlist", "autolink", "lists", "link", "image", "charmap", "preview",
              "anchor", "searchreplace", "visualblocks", "code", "fullscreen",
              "insertdatetime", "media", "table", "code", "help", "wordcount", "placeholder",
            ],
            content_style:
              "body: {  fontSize: 1px; box-sizing: border-box; font-family: 'Source Sans Pro',sans-serif; }",
            toolbar: "undo redo  | formatselect | bold italic underline | bullist",
          }}
        />
      </div>
    </div>
  );
};


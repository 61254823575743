import React from "react";
import { Typography } from "@material-ui/core";

interface Props {
  firstName: string;
  lastName: string;
}

export const UserName: React.FunctionComponent<Props> = ({ firstName, lastName }) => (
  <div>
    <div>
      <Typography component="span" variant="h3" color="textSecondary">{firstName}</Typography>
    </div>

    <div>
      <Typography component="span" variant="h3" color="textSecondary">{lastName}</Typography>
    </div>
  </div>
);

export default UserName;

import React from "react";
import { AuthenticatorChildrenProps, AuthenticatorOverridingViewProps } from "aws-amplify-react";
import { PasswordExpiredForm } from "../passwordExpired/components/PasswordExpiredForm";
import { CognitoUser } from "amazon-cognito-identity-js";

type Props = AuthenticatorOverridingViewProps;

interface CustomAuthenticatorProps {
  user: CognitoUser;
}

type InjectedProps = Props & AuthenticatorChildrenProps;

export const AmplifiedPasswordExpired = (props: Props): JSX.Element | null => {
  const { authState, authData } = props as InjectedProps;
  const user = (authData as CustomAuthenticatorProps)?.user;

  if (authState !== "requireNewPassword") {
    return null;
  }

  return (
    <PasswordExpiredForm
      user={user}
    />
  );
};

import { AuthenticationErrorWithLink } from "../../domain/AuthenticationErrorWithLink";

export class FailedToCompleteNewPasswordException extends Error {

  constructor(
    public readonly authenticationErrorWithLink: AuthenticationErrorWithLink,
  ) {
    super(`Failed to complete new password for user: ${authenticationErrorWithLink.error}`);
    Object.setPrototypeOf(this, FailedToCompleteNewPasswordException.prototype);
  }
}

import AuthenticationError from "../../domain/AuthenticationError";
import { AuthenticationErrorWithLink } from "../../domain/AuthenticationErrorWithLink";

interface LoginFormErrorsFields {
  email?: AuthenticationError[];
  password?: AuthenticationError[];
  body?: AuthenticationErrorWithLink[];
}

class LogInFormErrors {

  public readonly email: AuthenticationError[];
  public readonly password: AuthenticationError[];
  public readonly body: AuthenticationErrorWithLink[];

  constructor({ email, password, body }: LoginFormErrorsFields = {}) {
    this.email = email || [];
    this.password = password || [];
    this.body = body || [];
  }

  public static empty(): LogInFormErrors {
    return new LogInFormErrors({ email: [], password: [], body: [] });
  }

  public isEmpty(): boolean {
    return this.email.length === 0 && this.password.length === 0 && this.body.length === 0;
  }

  public addBodyError(error: AuthenticationErrorWithLink): LogInFormErrors {
    return new LogInFormErrors({ ...this, body: [...this.body, error] });
  }

  public addEmailError(error: AuthenticationError): LogInFormErrors {
    return new LogInFormErrors({ ...this, email: [...this.email, error] });
  }

  public addPasswordError(error: AuthenticationError): LogInFormErrors {
    return new LogInFormErrors({ ...this, password: [...this.password, error] });
  }
}

export default LogInFormErrors;

import React, { useCallback } from "react";
import {
  Radio, FormControlLabel, Typography,
} from "@material-ui/core";

interface Props<T> {
  value: T;
  checked: boolean;
  label: string;
  onChange: (value: T) => void;
}

export const RadioButtonOption = <T extends any>({
  value,
  checked,
  label,
  onChange,
}: Props<T>): JSX.Element => {
  const changeValue = useCallback(
    () => onChange(value),
    [onChange, value]
  );

  return (
    <FormControlLabel
      control={
        <Radio
          checked={checked}
          color="primary"
          onChange={changeValue}
          disableRipple={false}
        />
      }
      label={<Typography variant="h4">{label}</Typography>}
    />
  );
};

export default RadioButtonOption;


import {
  Divider, FormGroup, makeStyles, TextField,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import theme, { pxToRem } from "../../main/theme/theme";
import RadioButtonOption from "../../prescribe/components/RadioButtonOption";
import { ReactComponent as SearchIcon } from "../assets/searchIcon.svg";
import useDrugSearch, { SearchCriteria, SearchCriteriaKeys } from "../../drugs/hooks/useDrugSearch";
import SearchDrug from "../../drugs/domain/SearchDrug";
import SearchDrugCard from "./SearchDrugCard";

const useStyles = makeStyles(() => ({
  searchInput: {
    "margin": `${theme.metrics.margin.normal} 0`,
    "& textarea": {
      resize: "vertical",
    },
  },
  searchIcon: {
    width: pxToRem(16),
    height: pxToRem(16),
  },
  drugResults: {
    overflowY: "auto",
    width: "100%",
    height: pxToRem(500),
    listStyleType: "none",
    paddingLeft: 0,
    margin: 0,
    ...theme.scrollbar,
  },
  card: {
    padding: `${theme.metrics.margin.normal} 0`,
    borderBottom: `${theme.metrics.border.tiny} ${theme.colors.divider}`,
  },
  clickable: {
    "&:hover": {
      backgroundColor: theme.colors.primary.paleBlue,
    },
    "&.active": {
      backgroundColor: theme.colors.primary.paleBlue,
    },
    "cursor": "pointer",
  },
}));

const INPUT_DEBOUNCE = 500;

interface Props {
  onSelectDrug: (drug: SearchDrug) => void;
}

const MedicationFormSearch: React.FC<Props> = ({ onSelectDrug }) => {
  const { t } = useTranslation("medication");
  const classes = useStyles();
  const [selectedCriteria, setSelectedCriteria] = useState<SearchCriteriaKeys>("name");
  const [displayedSearchText, setDisplayedSearchText] = useState<string>("");
  const { results, startNewSearch, reset, getNextPage } = useDrugSearch();

  const fetchMoreDrugs = debounce((text: string, criteria: SearchCriteriaKeys): void => {
    startNewSearch(criteria, text);
  }, INPUT_DEBOUNCE);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeText = useCallback(fetchMoreDrugs, []);

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDisplayedSearchText(event.target.value);

    handleChangeText(event.target.value, selectedCriteria);
  };

  const handleScroll = (event: any): void => {
    const bottom = Math.floor(event.target.scrollHeight - event.target.scrollTop) === event.target.clientHeight;
    if (bottom) {
      getNextPage();
    }
  };

  return (
    <>
      <FormGroup row>
        {SearchCriteria.map((option) => (
          <RadioButtonOption
            key={option}
            value={option}
            label={t(`medication_${option}`)}
            checked={selectedCriteria === option}
            onChange={(newValue: SearchCriteriaKeys): void => {
              setSelectedCriteria(newValue);
              setDisplayedSearchText("");
              reset();
            }}
          />
        ))}
      </FormGroup>

      <TextField
        id="search-input"
        value={displayedSearchText}
        label={t(`enter_${selectedCriteria}`)}
        fullWidth
        variant="outlined"
        className={classes.searchInput}
        InputProps={{
          endAdornment: <SearchIcon className={classes.searchIcon} />,
        }}
        onChange={onChangeText}
      />

      <Divider light />

      <ul
        onScroll={handleScroll}
        className={classes.drugResults}
      >
        {results.map((drug) => (
          <li
            onClick={(event: React.MouseEvent<HTMLLIElement>): void => {
              event.preventDefault();
              onSelectDrug(drug);
              setDisplayedSearchText("");
              reset();
            }}
            className={classes.clickable}
            aria-labelledby={drug.id}
            key={drug.id}
          >
            <SearchDrugCard
              drug={drug}
              className={classes.card}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default MedicationFormSearch;

import React, { useState } from "react";
import { AuthenticatorChildrenProps, AuthenticatorOverridingViewProps } from "aws-amplify-react";
import LogInForm from "../login/LogInForm";
import LogInFormData from "../login/domain/LogInFormData";
import useLoginForm from "../login/hooks/useLoginForm";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useTranslation } from "react-i18next";

type Props = AuthenticatorOverridingViewProps;

type InjectedProps = Props & AuthenticatorChildrenProps;

export const AmplifiedLoginForm: React.FunctionComponent<Props> = (props: Props) => {
  const { onStateChange, authState } = props as InjectedProps;
  const { t } = useTranslation("authentication");

  const goToForgotPassword = (): void => onStateChange("forgotPassword", {});
  const goToRequireNewPassword = (user: CognitoUser): void => onStateChange("requireNewPassword", { user });
  const goToSignUp = (): void => onStateChange("signUp", {});
  const resetForm = (): void => setLoginFormData(new LogInFormData({ email: logInFormData.email, password: "" }));
  const goToConfirmationSignUp =
    (email: string, password: string): void => onStateChange("confirmSignUp", { email, password });
  const goToResetPassword = (email: string): void => {
    onStateChange("resetPassword", { email, title: t("passwordExpired") });
  };

  const [logInFormData, setLoginFormData] = useState(LogInFormData.empty());
  const { onSubmit, errors } = useLoginForm({
    onConfirmationNeeded: goToConfirmationSignUp,
    onRequireNewPassword: goToRequireNewPassword,
    onRequireResetPassword: goToResetPassword,
    clearForm: resetForm,
  });

  const changeForm = (formData: LogInFormData): void => {
    setLoginFormData(formData);
  };

  if (authState !== "signIn") {
    return null;
  }

  return (
    <LogInForm
      onSubmit={(): Promise<void> => onSubmit(logInFormData)}
      onChange={changeForm}
      authenticating={false}
      errors={errors}
      logInFormData={logInFormData}
      goToSignUp={goToSignUp}
      goToForgotPassword={goToForgotPassword}
    />
  );
};

export default AmplifiedLoginForm;

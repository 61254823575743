import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DrugLabels from "../../../domain/DrugLabels";
import DrugLabel from "../../../domain/DrugLabel";
import theme, { pxToRem } from "../../../../main/theme/theme";
import DrugSheetTitle from "./DrugSheetTitle";

const useStyles = makeStyles(() => ({
  label: {
    border: `${pxToRem(1)} solid ${theme.colors.divider}`,
    padding: theme.metrics.margin.tiny,
  },
  labelContainer: {
    "& $label": {
      marginBottom: theme.metrics.margin.small,
    },
    "& $label:last-child": {
      marginBottom: 0,
    },
  },
}));

interface Props {
  className?: string;
  drugLabels: DrugLabels | null;
}

export const DrugSheetLabels: React.FunctionComponent<Props> = ({ drugLabels, className }) => {
  const classes = useStyles();
  const { t } = useTranslation("report");

  if (!drugLabels || drugLabels.isEmpty()) {
    return null;
  }

  return (
    <div className={className}>
      <DrugSheetTitle>
        {t("medicationDetailSubtitles.labels")}
      </DrugSheetTitle>

      <div className={classes.labelContainer}>
        {[drugLabels.mandatory, drugLabels.auxiliary].map((labelGroup) =>
          labelGroup.map((label: DrugLabel) => (
            <div key={label.priority} className={classes.label} style={{ backgroundColor: label.color }}>
              <Typography component="span" variant="h4">
                {label.text}
              </Typography>
            </div>
          )))}
      </div>
    </div>
  );
};

export default DrugSheetLabels;

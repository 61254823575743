import Patient from "../domain/Patient";
import Gender from "../domain/Gender";
import DateTime from "../../main/dateTime/DateTime";
import PatientResponse from "./responses/PatientResponse";

class PatientAssembler {
  public static fromResponse({
    firstName,
    lastName,
    birthdate,
    gender,
    picture,
    allergies,
    email,
  }: PatientResponse): Patient {
    return new Patient({
      firstName,
      lastName,
      birthdate: birthdate ? DateTime.fromISO(birthdate) : null,
      gender: gender ? Gender[gender as keyof typeof Gender] : null,
      picture,
      allergies,
      email,
    });
  }
}

export default PatientAssembler;

import PendingSchedulableMedicationDto from "../dtos/PendingSchedulableMedicationDto";
import ProposedScheduleAssembler from "./ProposedScheduleAssembler";
import PendingMedicationAssembler from "./PendingMedicationAssembler";
import MedicationSummary from "../../../medication/domain/MedicationSummary";
import ProposedMedicationEventAssembler from "./events/ProposedMedicationEventAssembler";

class PendingSchedulableMedicationAssembler {
  public toDto(medicationSummary: MedicationSummary): PendingSchedulableMedicationDto {
    return {
      id: medicationSummary.medication.id,
      medication: PendingMedicationAssembler.toDto(medicationSummary),
      schedule: ProposedScheduleAssembler.toDto(
        medicationSummary.medication.schedule,
        ProposedMedicationEventAssembler
      ),
      instructions: medicationSummary.medication.instructions?.text,
    };
  }
}

export default new PendingSchedulableMedicationAssembler();

import React from "react";
import theme from "../../../../main/theme/theme";
import Wheel from "./Wheel";

interface Props {
  count: number;
  size: number;
  numberOfDays: number;
}

const FULL = 100;
const EMPTY = 0;

export const CountWheel: React.FunctionComponent<Props> = ({ count, size, numberOfDays }) => (
  <Wheel
    id="count-wheel"
    progressValue={count > 0 ? FULL : EMPTY}
    progressText={count.toString()}
    size={size}
    numberOfDays={numberOfDays}
    startColor={theme.colors.progressWheel.start}
    middleColor={theme.colors.progressWheel.start}
    endColor={theme.colors.progressWheel.start}
  />
);

export default CountWheel;

import { useEffect, useState } from "react";
import ConnectionsApi from "../api/ConnectionsApi";
import Connection from "../domain/Connection";

export interface UseConnectionHistoryResult {
  loading: boolean;
  error: boolean;
  connections: Connection[];
}

const useConnectionHistory = (): UseConnectionHistoryResult => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connections, setConnections] = useState<Connection[]>([]);

  useEffect(() => {
    const fetchConnectionHistory = async (): Promise<void> => {
      setLoading(true);

      try {
        setConnections(await ConnectionsApi.fetchConnectionHistory());
      } catch {
        setError(true);
      }

      setLoading(false);
    };

    fetchConnectionHistory();
  }, []);

  return { connections, error, loading };
};

export default useConnectionHistory;

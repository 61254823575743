import React, { useMemo } from "react";
import isDevFlagEnabled from "../utilities/isDevFlagEnabled";
import DevFlag from "../utilities/DevFlag";

interface Props {
  flag: DevFlag | DevFlag[];
}

const DevFlagDisplay: React.FunctionComponent<Props> = ({ children, flag }) => {
  const displayChildren = useMemo(() => {
    if (flag instanceof Array) {
      return flag.every(isDevFlagEnabled);
    }

    return isDevFlagEnabled(flag);
  }, [flag]);

  if (!displayChildren) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

export default DevFlagDisplay;

import Client from "main/services/ApiClient";
import { SessionContext } from "../domain/SessionContext";
import SessionContextResponse from "./response/SessionContextResponse";

class ClientConnectApi {

  public static async consume(sessionToken: string): Promise<SessionContext> {
    return await new Client({
      endpoint: "/client-connect/consume",
      headers: { Authorization: sessionToken },
      sendSessionToken: false,
    }).post<SessionContextResponse>();
  }
}

export default ClientConnectApi;

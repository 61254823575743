import { useTranslation } from "react-i18next";
import React, { useImperativeHandle, useState } from "react";
import {
  Button, Divider, makeStyles,
} from "@material-ui/core";
import DateTime from "../../../main/dateTime/DateTime";
import CustomProgramFormData from "../../../prescribe/domain/CustomProgramFormData";
import useCustomProgramFormData from "../../hooks/useCustomProgramFormData";
import MeasureFormData from "../../domain/MeasureFormData";
import DurationSection from "../DurationSection";
import MeasuresSection from "../MeasuresSection";
import theme from "../../../main/theme/theme";
import CustomProgramModal from "./CustomProgramModal";

const useStyles = makeStyles(() => ({
  button: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.metrics.margin.normal,
  },
}));

interface Props {
  onAddCustomProgram?: (customProgram: CustomProgramFormData) => void;
  onUpdateCustomProgram?: (updatedCustomProgram: CustomProgramFormData) => void;
}

export interface ProximCustomProgramModalRef {
  open: (customProgram?: CustomProgramFormData) => void;
}

const ProximCustomProgramModal = React.forwardRef(({
  onAddCustomProgram,
  onUpdateCustomProgram,
}: Props, ref: React.Ref<ProximCustomProgramModalRef>) => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const {
    formData,
    updateEvent,
    updateDuration,
    initializeForm,
    rescheduleWithSelectedDates,
  } = useCustomProgramFormData();

  const open = (customProgram?: CustomProgramFormData): void => {
    initializeForm(customProgram);
    setIsEditing(!!customProgram);
    setIsOpen(true);
  };

  const closeModal = (): void => {
    initializeForm();
    setIsEditing(false);
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open,
  }));

  const onChangeEventTime = (event: MeasureFormData): void => {
    updateEvent(event);
  };

  const updateCustomProgram = (): void => {
    if (onUpdateCustomProgram) {
      onUpdateCustomProgram(formData);
      closeModal();
    }
  };

  const addCustomProgram = (): void => {
    if (onAddCustomProgram) {
      const updatedCustomProgram = rescheduleWithSelectedDates();
      onAddCustomProgram(updatedCustomProgram);
      closeModal();
    }
  };

  const adjustInvalidDuration = (): void => {
    const now = DateTime.now().formatWithTimeZone();
    const tomorrow = now.addDays(1);

    if (
      formData.schedulableRegimen.schedule.duration.startDate.isBefore(now) &&
      !formData.schedulableRegimen.schedule.duration.startDate.isSame(now, "day")
    ) {
      onDurationChange(tomorrow.toISO());
    }
  };

  const onDurationChange = (newDate: string): void => {
    const now = DateTime.now().formatWithTimeZone();
    const tomorrow = now.addDays(1);

    let date;
    if (newDate) {
      date = DateTime.fromISO(newDate);
    } else {
      date = tomorrow;
    }
    date = date.setTime(8, 0);

    updateDuration({
      startDate: date,
      endDate: date.addDays(7),
    });
  };

  return (
    <CustomProgramModal
      isOpen={isOpen}
      title={t("proximBloodGlucoseMonitoringProgramTitle")}
      onClose={closeModal}
    >
      <DurationSection
        data-testid="durationSectionCustomProgram"
        isEndDateEditable={false}
        durationFormData={formData.schedulableRegimen.schedule.duration}
        onBlur={adjustInvalidDuration}
        onStartDateChange={onDurationChange}
      />

      <Divider light />

      <MeasuresSection
        data-testid="measuresSectionCustomProgram"
        events={formData.schedulableRegimen.schedule.events}
        onChange={onChangeEventTime}
      />

      <div className={classes.button}>
        {
          isEditing ?
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={updateCustomProgram}
            >
              {t("update")}
            </Button>
            :
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={addCustomProgram}
            >
              {t("add")}
            </Button>
        }
      </div>
    </CustomProgramModal>
  );
});

export default ProximCustomProgramModal;

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../theme/theme";

const useStyles = makeStyles(() => ({
  content: {
    "display": "flex",
    "width": "100%",
    "padding": `${theme.metrics.margin.small} 0`,
    "borderBottom": `${theme.metrics.border.tiny} ${theme.colors.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

interface Props {
  className?: string;
}

const ExpandableTableRow: React.FunctionComponent<Props> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={[classes.content, className].join(" ")}>
      {children}
    </div>
  );
};

export default ExpandableTableRow;

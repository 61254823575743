import { recurrenceStringToText } from "report/service/RecurrenceRuleService";
import ScheduleEvent from "../ScheduleEvent";
import i18next from "i18next";
import SupportedLanguages from "main/domain/SupportedLanguages";
import { DateTime as LuxonDateTime } from "luxon";

interface MedicationScheduleAttributes {
  recurrenceRule: string;
  dailyDosesCount: number;
  dailyPillsCount: number;
  events: ScheduleEvent[];
}

export default class MedicationSchedule {
  public recurrenceRule: string;
  public dailyDosesCount: number;
  public dailyPillsCount: number;
  public events: ScheduleEvent[];

  constructor(plain: MedicationScheduleAttributes) {
    this.recurrenceRule = plain.recurrenceRule;
    this.dailyDosesCount = plain.dailyDosesCount;
    this.dailyPillsCount = plain.dailyPillsCount;
    this.events = plain.events;
  }

  public toText(language: SupportedLanguages): string {
    return recurrenceStringToText(this.recurrenceRule, language)
      + this.events.map(this.getEventTime).join(` ${i18next.t("schedule:and")} `);
  }

  private getEventTime(event: ScheduleEvent): string {
    const alarmDescription = !!event.alarmIntervalInMinutes
      ? " (" + i18next.t("schedule:alarmDescription", { interval: event.alarmIntervalInMinutes }) + ")"
      : "";

    return " " + i18next.t("schedule:at")
    + " " + event.eventDate.formatLocale(LuxonDateTime.TIME_SIMPLE)
    + ` (${i18next.t("schedule:dose", { count: event.amount })})`
    + alarmDescription;
  }
}

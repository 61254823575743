import Treatment, {
  TreatmentActivities, TreatmentMeasurements, TreatmentMedications, TreatmentReminders,
} from "../domain/Treatment";
import TreatmentResponse, {
  MeasurementsResponse, MedicationsResponse, OtherActivitiesResponse, RemindersResponse,
} from "./responses/TreatmentResponse";
import TreatmentMedicationAssembler from "./TreatmentMedicationAssembler";
import TreatmentOtherActivityAssembler from "./TreatmentOtherActivityAssembler";
import TreatmentMeasurementAssembler from "./TreatmentMeasurementAssembler";
import TreatmentRegimenStatus from "../domain/TreatmentRegimenStatus";
import TreatmentReminderAssembler from "./TreatmentReminderAssembler";

class TreatmentAssembler {
  public static fromResponse(response: TreatmentResponse): Treatment {
    return new Treatment(
      TreatmentAssembler.extractMedications(response.medications),
      TreatmentAssembler.extractOtherActivities(response.activities),
      TreatmentAssembler.extractMeasurements(response.measurements),
      TreatmentAssembler.extractReminders(response.reminders)
    );
  }

  private static extractMeasurements(response: MeasurementsResponse): TreatmentMeasurements {
    const results = response.results.map((measurement) => TreatmentMeasurementAssembler.toDomain(measurement));

    return {
      results,
      status: TreatmentRegimenStatus[response.status as keyof typeof TreatmentRegimenStatus],
    };
  }

  private static extractOtherActivities(response: OtherActivitiesResponse): TreatmentActivities {
    const results = response.results.map((otherActivity) => TreatmentOtherActivityAssembler.toDomain(otherActivity));

    return {
      results,
      status: TreatmentRegimenStatus[response.status as keyof typeof TreatmentRegimenStatus],
    };
  }

  private static extractMedications(response: MedicationsResponse): TreatmentMedications {
    const results = response.results.map((medication) => TreatmentMedicationAssembler.toDomain(medication));

    return {
      results,
      status: TreatmentRegimenStatus[response.status as keyof typeof TreatmentRegimenStatus],
    };
  }

  private static extractReminders({ status, results }: RemindersResponse): TreatmentReminders {
    return {
      results: results.map((reminder) => TreatmentReminderAssembler.toDomain(reminder)),
      status,
    };
  }
}

export default TreatmentAssembler;

import { useCallback, useState } from "react";
import PrescribeFormData from "../domain/PrescribeFormData";
import { PrescribeFormErrors } from "../domain/PrescribeFormErrors";
import PrescribeValidator from "../domain/PrescribeValidator";
import SupportedLanguages from "../../main/domain/SupportedLanguages";
import usePatientProfile from "./usePatientProfile";
import { clearPrescribeFormData, storePrescribeFormData } from "prescribe/store/PrescribeFormAction";
import useGlobalState from "store/hooks/useGlobalState";
import { getPrescribeFormData } from "prescribe/store/selectors/PrescribeFormSelector";
import { AppState } from "store/GlobalStore";
import CarePlanStepFormData from "prescribe/domain/CarePlanStepFormData";
import { isClientConnectContext } from "main/selectors/AppContextSelector";

export interface PrescribeResult {
  errors: PrescribeFormErrors;
  prescribeFormData: PrescribeFormData;
  onChange: (prescribeForm: PrescribeFormData) => void;
  clearForm: () => void;
  resetForm: () => void;
  canSubmit: boolean;
}

const createEmptyForm = (defaultLanguage: SupportedLanguages): PrescribeFormData => PrescribeFormData.empty()
  .withLanguage(defaultLanguage);

const initializeForm = (state: AppState, defaultLanguage: SupportedLanguages): PrescribeFormData =>
  getPrescribeFormData(state) || createEmptyForm(defaultLanguage);

export const usePrescribeForm = (defaultLanguage: SupportedLanguages): PrescribeResult => {
  const { dispatch, state } = useGlobalState();
  const [prescribeFormData, setPrescribeFormData] = useState(initializeForm(state, defaultLanguage));
  const [errors, setErrors] = useState<PrescribeFormErrors>(PrescribeValidator.validate(prescribeFormData));

  const onChange = useCallback((newPrescribeForm: PrescribeFormData): void => {
    setPrescribeFormData(newPrescribeForm);
    storePrescribeFormData(dispatch, newPrescribeForm);

    const validationErrors = PrescribeValidator.validate(newPrescribeForm);
    setErrors(validationErrors);
  }, [dispatch]);

  usePatientProfile({ setPrescribeFormData });

  const canSubmit = prescribeFormData.isFilled() && errors.isEmpty();

  const resetForm = useCallback(() => {
    clearPrescribeFormData(dispatch);
    setPrescribeFormData(createEmptyForm(defaultLanguage));
    setErrors(PrescribeFormErrors.empty());
  }, [defaultLanguage, dispatch]);

  const clearForm = useCallback(() => {
    clearPrescribeFormData(dispatch);

    const formData = isClientConnectContext()
      ? prescribeFormData.withCarePlan(CarePlanStepFormData.empty())
      : createEmptyForm(defaultLanguage);

    setPrescribeFormData(formData);
    setErrors(PrescribeFormErrors.empty());
  }, [defaultLanguage, dispatch, prescribeFormData]);

  return { errors, prescribeFormData, onChange, canSubmit, clearForm, resetForm };
};

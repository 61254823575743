import ProfileError from "./ProfileError";

interface ProfileFormErrorsFields {
  firstName?: ProfileError[];
  lastName?: ProfileError[];
  officeNumber?: ProfileError[];
  profession?: ProfileError[];
  body?: ProfileError[];
}

export class ProfileFormErrors {
  public readonly firstName: ProfileError[];
  public readonly lastName: ProfileError[];
  public readonly officeNumber: ProfileError[];
  public readonly profession: ProfileError[];
  public readonly body: ProfileError[];

  constructor({ firstName, lastName, officeNumber, profession, body = [] }: ProfileFormErrorsFields) {
    this.firstName = firstName || [];
    this.lastName = lastName || [];
    this.officeNumber = officeNumber || [];
    this.profession = profession || [];
    this.body = body;
  }

  public static empty(): ProfileFormErrors {
    return new ProfileFormErrors({
      body: [],
    });
  }

  public isEmpty(): boolean {
    return this.firstName.length === 0 &&
      this.lastName.length === 0 &&
      this.officeNumber.length === 0 &&
      this.profession.length === 0 &&
      this.body.length === 0;
  }
}

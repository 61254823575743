import Adherence from "../domain/Adherence";
import Percentage from "../../main/domain/Percentage";
import DailyAdherence from "../domain/DailyAdherence";
import DateTime from "../../main/dateTime/DateTime";
import AdherenceStatus from "../domain/AdherenceStatus";
import DailyAdherenceCalendar from "../domain/DailyAdherenceCalendar";
import Adherences from "../domain/Adherences";
import { AdherenceTypes } from "../domain/AdherenceTypes";
import {
  AdherenceResponse, AdherenceType, PatientTreatmentAdherenceResponse,
} from "./responses/TreatmentResponse";

export default class AdherencesAssembler {
  public static assembleToInternal(adherenceResponse: PatientTreatmentAdherenceResponse): Adherences {
    const assembledDays = adherenceResponse.calendar.days.map(this.assembleDailyAdherence);
    return new Adherences({
      adherences: adherenceResponse.adherences.map((it) => {
        const type = AdherenceTypes[it.duration as keyof typeof AdherenceTypes];
        return new Adherence({ type, value: this.fromType(it.adherence) });
      }),
      calendar: new DailyAdherenceCalendar({ days: assembledDays }),
    });
  }

  private static fromType(adherenceResponse: AdherenceResponse): Percentage | number {
    switch (adherenceResponse.type) {
      case AdherenceType.PERCENTAGE:
        return new Percentage(adherenceResponse.value);
      case AdherenceType.COUNT:
      default:
        return adherenceResponse.value || 0;
    }
  }

  private static assembleDailyAdherence({ date, status }: { date: string; status: string }): DailyAdherence {
    return new DailyAdherence({
      date: DateTime.fromISO(date),
      status: AdherenceStatus[status as keyof typeof AdherenceStatus],
    });
  }
}

import React, { useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DailyAdherence from "../../../domain/DailyAdherence";
import getAdherenceStatusColor from "../../../helpers/getAdherenceStatusColor";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  calendar: {
    width: "100%",
    height: "100%",
  },
}));

const CIRCLE_MARGIN = 3;
const CIRCLE_RADIUS = 0.7;
const BOX_SIZE = CIRCLE_MARGIN + CIRCLE_RADIUS;

interface Props {
  rowSize: number;
  columnSize: number;
  items: DailyAdherence[];
}

const AdherenceCalendar: React.FunctionComponent<Props> = ({ rowSize, columnSize, items }) => {
  const classes = useStyles();

  const lastBoxIndex = items.length - 1;
  const boxWidth = calculateX(lastBoxIndex, rowSize) + CIRCLE_RADIUS;
  const boxHeight = calculateY(lastBoxIndex, rowSize) + CIRCLE_RADIUS;
  const itemsToRender = useMemo(() => items.slice(0, rowSize * columnSize), [columnSize, rowSize, items]);

  return (
    <div className={classes.wrapper}>
      <svg viewBox={`0 0 ${boxWidth} ${boxHeight}`} className={classes.calendar}>
        {itemsToRender.map((dailyAdherence, index) => (
          <circle
            key={dailyAdherence.date.toISO()}
            cx={calculateX(index, rowSize)}
            cy={calculateY(index, rowSize)}
            r={CIRCLE_RADIUS}
            fill={getAdherenceStatusColor(dailyAdherence.status)}
          />
        ))}
      </svg>
    </div>
  );
};

const calculateX = (index: number, rowSize: number): number => {
  const boxStart = (index % rowSize) * (BOX_SIZE - 1);
  return boxStart + CIRCLE_RADIUS;
};

const calculateY = (index: number, rowSize: number): number => {
  const boxStart = Math.floor(index / rowSize) * (BOX_SIZE - 1);
  return boxStart + CIRCLE_RADIUS;
};

export default AdherenceCalendar;

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as ClockSvg } from "../assets/Clock.svg";
import ScheduleSummaryFactory from "../domain/ScheduleSummaryFactory";
import ScheduleFormData from "../domain/ScheduleFormData";
import SessionFormData from "../../prescribe/domain/SessionFormData";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

const ICON_SIZE = 20;

const useStyles = makeStyles(() => ({
  scheduleSection: {
    alignItems: "center",
  },
  svg: {
    color: "black",
    minWidth: ICON_SIZE,
    minHeight: ICON_SIZE,
  },
  descriptionSummary: {
    display: "flex",
    alignItems: "center",
  },
  eventDescription: {
    padding: `0 ${theme.metrics.margin.medium} 0 ${theme.metrics.margin.small}`,
  },
}));

interface Props {
  schedule: ScheduleFormData<SessionFormData>;
  className?: string;
}

const ScheduleSummarySection: React.FC<Props> = ({ schedule, className }) => {
  const classes = useStyles();
  const scheduleSummary = ScheduleSummaryFactory.create(schedule);
  const { t } = useTranslation("schedule");

  return (
    <section className={classnames(classes.scheduleSection, className)} data-testid={`scheduleSummary - ${schedule.localId}`}>
      <div className={classes.descriptionSummary}>
        <ClockSvg
          title={t("clockIcon")}
          className={classes.svg}
        />

        <Typography className={classes.eventDescription}>
          {scheduleSummary}
        </Typography>
      </div>
    </section>
  );
};

export default ScheduleSummarySection;

enum HealthProfession {
  MEDECINE = "MEDECINE",
  PHARMACY = "PHARMACY",
  PSYCHOLOGY = "PSYCHOLOGY",
  PHYSICAL_THERAPIES = "PHYSICAL_THERAPIES",
  DENTIST = "DENTIST",
  DISABILITY_CASE_MANAGEMENT = "DISABILITY_CASE_MANAGEMENT",
  NURSE_ADVISOR = "NURSE_ADVISOR",
  OTHER = "OTHER"
}

export default HealthProfession;

import PendingTreatmentPlan from "prescribe/domain/PendingTreatmentPlan";
import PendingTreatmentPlanDto from "../dtos/PendingTreatmentPlanDto";
import PendingCustomProgramAssembler from "./PendingCustomProgramAssembler";
import PendingSchedulableMedicationAssembler from "./PendingSchedulableMedicationAssembler";
import { ProposedSchedulableCustomActivityAssembler } from "./ProposedSchedulableCustomActivityAssembler";
import { ProposedSchedulableMeasurementAssembler } from "./ProposedSchedulableMeasurementAssembler";
import { ProfessionalProposedTreatmentPlanResponse } from "consultationSummary/api/response/ProfessionalProposedTreatmentPlanResponse";
import { assembleFromHistoricalActivity } from "customActivities/domain/CustomActivityFormData";
import { assembleFromHistoricalMeasurement } from "measurement/domain/MeasurementFormData";
import { assembleFromHistoricalMedication } from "medication/domain/MedicationSummary";
import { assembleFromHistoricalCustomProgram } from "prescribe/domain/CustomProgramFormData";
import { ProposedSchedulableReminderAssembler } from "./ProposedSchedulableReminderAssembler";
import { assembleFromHistoricalReminder } from "reminders/domain/ReminderFormData";

class PendingTreatmentPlanAssembler {
  public toDto(pendingTreatmentPlan: PendingTreatmentPlan): PendingTreatmentPlanDto {
    return {
      id: pendingTreatmentPlan.localId,
      pendingCustomPrograms: pendingTreatmentPlan.pendingCustomPrograms.list
        .map(PendingCustomProgramAssembler.toDto),
      pendingMedications: pendingTreatmentPlan.medications.list
        .map(PendingSchedulableMedicationAssembler.toDto),
      proposedMeasurements: pendingTreatmentPlan.measurements.list
        .map(ProposedSchedulableMeasurementAssembler.toDto),
      proposedCustomActivities: pendingTreatmentPlan.customActivities.list
        .map(ProposedSchedulableCustomActivityAssembler.toDto),
      proposedReminders: pendingTreatmentPlan.reminders.list
        .map(ProposedSchedulableReminderAssembler.toDto),
    };
  }

  public fromProposedTreatmentPlan(
    pendingTreatmentPlan: ProfessionalProposedTreatmentPlanResponse
  ): PendingTreatmentPlan {
    return {
      localId: pendingTreatmentPlan.id,
      customActivities: { list: pendingTreatmentPlan.customActivities.map(assembleFromHistoricalActivity) },
      medications: { list: pendingTreatmentPlan.medications.map(assembleFromHistoricalMedication) },
      measurements: { list: pendingTreatmentPlan.measurements.map(assembleFromHistoricalMeasurement) },
      pendingCustomPrograms: { list: pendingTreatmentPlan.customPrograms.map(assembleFromHistoricalCustomProgram) },
      reminders: { list: pendingTreatmentPlan.reminders.map(assembleFromHistoricalReminder) },
    };
  }
}

export default new PendingTreatmentPlanAssembler();

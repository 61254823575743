import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import envConfig from "../config/envConfig";
import theme from "../theme/theme";
import { FooterLink } from "./FooterLink";
import { AppVersion } from "./AppVersion";

export const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <FooterLink className={classes.footerItem} href={t("userGuideLink")}>
        {t("userGuide")}
      </FooterLink>

      <FooterLink className={classes.footerItem} href={t("helpLink")}>
        {t("help")}
      </FooterLink>

      <FooterLink className={classes.footerItem} href={`mailto:${envConfig.contactEmail}`}>
        {t("contactUs")}
      </FooterLink>

      <FooterLink className={classes.footerItem} href={t("policiesLink")}>
        {t("policies")}
      </FooterLink>

      <AppVersion className={classes.footerItem} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  footer: {
    display: "flex",
    flexDirection: "row",
    padding: `${theme.metrics.margin.large} ${theme.metrics.margin.huge}`,
  },
  footerItem: {
    "marginLeft": theme.metrics.margin.large,
    "&:first-child": {
      marginLeft: 0,
    },
  },
}));

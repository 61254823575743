import React from "react";
import { makeStyles } from "@material-ui/core";
import { MeasuresForDayFormData } from "../../prescribe/domain/MeasureFormData";
import ScheduleEventRow from "./ScheduleEventRow";

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  measuresForDayFormData: MeasuresForDayFormData;
  day?: number;
}

const DailyScheduleSection: React.FunctionComponent<Props> = ({ measuresForDayFormData, day }) => {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      {
        measuresForDayFormData.measures.map((measure, index) => {
          const isEvenRow = index % 2 === 0;
          return (
            <ScheduleEventRow
              key={measure.id}
              day={isEvenRow ? day : undefined}
              date={isEvenRow ? measuresForDayFormData.date : undefined}
              event={measure.displayMeasureEvent()}
              time={measure.time.getTime()}
            />
          );
        })
      }
    </div>
  );
};

export default DailyScheduleSection;

import merge from "deepmerge";
import home from "../../home/internationalization/home";
import authentication from "../../authentication/internationalization/authentication";
import sideMenu from "../../sideMenu/i18n/sideMenu";
import report from "../../report/internationalization/report";
import drugTypes from "../../report/internationalization/drugTypes";
import reasons from "../../report/internationalization/reasons";
import profile from "../../authentication/profile/internationalization/profile";
import connections from "../../connections/internationalization/connections";
import prescribe from "../../prescribe/internationalization/prescribe";
import Gender from "../../report/domain/Gender";
import medication from "../../medication/internationalization/medication";
import weekdays from "../../prescribe/internationalization/weekdays";
import frequency from "../../schedule/internationalization/frequency";
import exceptions from "./exceptions";
import titles from "./titles";
import schedule from "../../schedule/internationalization/schedule";
import measurement from "../../measurement/internationalization/measurement";
import consultationDocument from "../../document/internationalization/consultationDocument";
import customActivity from "../../customActivities/internationalization/customActivity";
import consultationSummary from "consultationSummary/internationalization/consultationSummary";
import reminder from "reminders/internationalization/reminder";

const translations = {
  en: {
    common: {
      language: "Language",
      contactUs: "Contact Us",
      userGuide: "User Guide",
      userGuideLink: "https://medhelper.com/wp-content/uploads/2021/11/MedHelper-for-Professionals-User-Guide.pdf",
      help: "Help",
      helpLink: "https://support.medhelper.ca/hc/en-us/categories/360004031114-Help-Center-Introducing-MedHelper-for-Professionals",
      policies: "Policies",
      policiesLink: "https://medhelper.com/policies-medhelper-for-professionals/",
      version: "Version",
      poweredByMedHelper: "<0>A solution powered by </0> <1>MedHelper Inc.</1>",
      MedHelperWebsite: "https://medhelper.com/",
      noItem: "No Item",
      clear: "Clear",
      collapseShowAll: "<0>SHOW ALL</0> <1>({{numberOfEntries}})</1>",
      phoneNumber: "{{value}}",
      phoneNumberWithExtension: "{{value}} ext. {{extension}}",
      dateTime: {
        full: "MM/dd/yyyy h:mm a",
        date: "MM/dd/yyyy",
        time: "h:mm a",
      },
      collapse: "Collapse",
      expand: "Expand",
      add: "Add",
      close: "Close",
      modify: "Modify",
      genders: {
        [Gender.MALE]: "Male",
        [Gender.FEMALE]: "Female",
        [Gender.INTERSEX]: "Intersex",
      },
      time: "Time",
      increment: "Increment",
      decrement: "Decrement",
      delete: "Delete",
      cancel: "Cancel",
      areYouSureYouWantToDeleteItem: "Are you sure you want to delete this item?",
    },
  },
  fr: {
    common: {
      language: "Langue",
      contactUs: "Nous joindre",
      userGuide: "Guide utilisateur",
      userGuideLink: "https://medhelper.com/wp-content/uploads/2021/11/MedAide-pour-professionnels-Guide-utilisateur.pdf",
      help: "Aide",
      helpLink: "https://support.medhelper.ca/hc/fr-ca/categories/360004031114-Centre-d-aide-Introduction-de-M%C3%A9dAide-pour-les-professionnels",
      policies: "Politiques",
      policiesLink: "https://medhelper.com/fr/politiques-medaide-pour-les-professionnels/",
      version: "Version",
      poweredByMedHelper: "<0>Une solution de </0> <1>MedHelper Inc.</1>",
      MedHelperWebsite: "https://medhelper.com/fr/",
      noItem: "Aucun élément",
      clear: "Effacer",
      collapseShowAll: "<0>AFFICHER TOUTES</0> <1>({{numberOfEntries}})</1>",
      phoneNumber: "{{value}}",
      phoneNumberWithExtension: "{{value}} ext. {{extension}}",
      dateTime: {
        full: "dd/MM/yyyy HH:mm",
        date: "dd/MM/yyyy",
        time: "HH:mm",
      },
      collapse: "Réduire",
      expand: "Agrandir",
      add: "Ajouter",
      close: "Fermer",
      modify: "Modifier",
      genders: {
        [Gender.MALE]: "Homme",
        [Gender.FEMALE]: "Femme",
        [Gender.INTERSEX]: "Intersexe",
      },
      time: "Temps",
      increment: "Incrémenter",
      decrement: "Décrémenter",
      delete: "Supprimer",
      cancel: "Annuler",
      areYouSureYouWantToDeleteItem: "Êtes-vous sûr de vouloir supprimer cet élément?",
    },
  },
};

export default merge.all([
  translations,
  home,
  authentication,
  sideMenu,
  report,
  profile,
  drugTypes,
  reasons,
  exceptions,
  titles,
  prescribe,
  connections,
  medication,
  customActivity,
  measurement,
  reminder,
  weekdays,
  frequency,
  schedule,
  consultationDocument,
  consultationSummary,
]);

import { Typography } from "@material-ui/core";

import DateTime from "../../main/dateTime/DateTime";
import Gender from "../../report/domain/Gender";
import { GenderField } from "./GenderField";
import LanguageField from "./LanguageField";
import PatientProfileDatePicker from "./PatientProfileDatePicker";
import PatientProfileTextField from "./PatientProfileTextField";
import PrescribeFormData from "../domain/PrescribeFormData";
import { PrescribeFormErrors } from "../domain/PrescribeFormErrors";
import React from "react";
import SupportedLanguages from "../../main/domain/SupportedLanguages";
import { isStandaloneContext } from "main/selectors/AppContextSelector";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";

const ALLERGIES_FIELD_MAX_CHARACTERS = 280;

const useStyles = makeStyles((themeMui) => ({
  wrapper: {
    flex: 1,
    paddingBottom: theme.metrics.margin.huge,
  },
  textField: {
    fontWeight: "bold",
  },
  columnGroup: {
    [themeMui.breakpoints.up("lg")]: {
      "display": "flex",
      "gap": "8%",
      "margin": `${theme.metrics.margin.normal} 0`,

      "& > *": {
        margin: 0,
      },
    },
  },
}));

interface Props {
  form: PrescribeFormData;
  onChange: (form: PrescribeFormData) => void;
  supportedLanguages: readonly SupportedLanguages[];
  errors: PrescribeFormErrors;
}

export const PatientProfileForm: React.FunctionComponent<Props> = ({ form, onChange, supportedLanguages, errors }) => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();

  const allowChange = isStandaloneContext();

  const changeAllergies = (allergies: string): void => {
    if (allergies.length > ALLERGIES_FIELD_MAX_CHARACTERS) {
      onChange(form.withAllergies(allergies.substr(0, ALLERGIES_FIELD_MAX_CHARACTERS)));
    } else {
      onChange(form.withAllergies(allergies));
    }
  };

  return (
    <div className={classes.wrapper}>
      <Typography component="label" variant="h3" className={classes.textField}>
        {t("patientContactInformation")}
      </Typography>

      <LanguageField
        options={supportedLanguages}
        value={form.language}
        onChange={(language: SupportedLanguages): void => onChange(form.withLanguage(language))}
      />

      <PatientProfileTextField
        name={t("emailAddress")}
        id="email"
        value={form.email}
        onChange={
          allowChange
            ? (email: string): void => onChange(form.withEmail(email))
            : undefined
        }
        errors={errors.email}
      />

      <div className={classes.columnGroup}>
        <PatientProfileTextField
          name={t("lastName")}
          id="lastName"
          value={form.lastName}
          onChange={
            allowChange
              ? (lastName: string): void => onChange(form.withLastName(lastName))
              : undefined
          }
        />

        <PatientProfileTextField
          name={t("firstName")}
          id="firstName"
          value={form.firstName}
          onChange={
            allowChange
              ? (firstName: string): void => onChange(form.withFirstName(firstName))
              : undefined
          }
        />
      </div>

      <div className={classes.columnGroup}>
        <PatientProfileDatePicker
          name={t("birthDate")}
          id="birthDate"
          onChange={
            allowChange
              ? (birthDate: string): void => onChange(form.withBirthDate(DateTime.fromISO(birthDate)))
              : undefined
          }
          errors={errors.birthDate}
          value={form.birthDate}
        />
        <GenderField
          value={form.gender}
          onChange={
            allowChange
              ? (gender: Gender): void => onChange(form.withGender(gender))
              : undefined
          } />
      </div>

      <PatientProfileTextField
        name={t("allergies")}
        id="allergies"
        value={form.allergies}
        onChange={
          allowChange
            ? changeAllergies
            : undefined
        }
        multiline
        maxLength={ALLERGIES_FIELD_MAX_CHARACTERS}
        maxRows={8}
      />

    </div>
  );
};

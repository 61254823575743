interface UserProps {
  id: string;
  email: string;
  latestPasswordChange: string;
  agreementDate?: string;
}

export default class User {
  public readonly id: string;
  public readonly email: string;
  public readonly latestPasswordChange: string;
  public readonly agreementDate?: string;

  constructor({ id, email, latestPasswordChange, agreementDate }: UserProps) {
    this.id = id;
    this.email = email;
    this.latestPasswordChange = latestPasswordChange;
    this.agreementDate = agreementDate;
  }
}

import React from "react";
import { useTranslation } from "react-i18next";
import TableBlankSlate from "../../../main/components/TableBlankSlate";
import ExpandableTableWithHeader from "../../../main/components/ExpandableTableWithHeader";
import Consultation from "../../domain/PreviousConsultation";
import TableFilter from "../../../main/types/TableFilter";
import PreviousConsultationTableRow from "./PreviousConsultationTableRow";
import { PreviousConsultationsTableHeader } from "./PreviousConsultationsTableHeader";

interface Props {
  previousConsultations: Consultation[];
  filters?: TableFilter[];
}

export const PreviousConsultationsTable: React.FunctionComponent<Props> = ({
  previousConsultations,
  filters= [],
}) => {
  const { t } = useTranslation("report");
  const numberOfEntriesRemaining = previousConsultations.length - 2;

  return (
    <ExpandableTableWithHeader
      initialDisplayedRows={2}
      numberOfEntriesRemaining={numberOfEntriesRemaining}
      headerComponent={
        <PreviousConsultationsTableHeader
          filters={filters}
          title={t("previousConsultations", { count: previousConsultations.length })}
        />
      }
    >
      {previousConsultations.length > 0 ?
        previousConsultations.map((previousConsultation: Consultation) => (
          <PreviousConsultationTableRow
            key={previousConsultation.identifier}
            previousConsultation={previousConsultation}
          />
        ))
        :
        <TableBlankSlate />
      }
    </ExpandableTableWithHeader>
  );
};

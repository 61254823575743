import React from "react";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as LockPinkIcon } from "../assets/LockPinkIcon.svg";
import { ReactComponent as ProfilePinkIcon } from "../assets/ProfilePinkIcon.svg";
import { ReactComponent as TermsPinkIcon } from "../assets/TermsPinkIcon.svg";
import { ReactComponent as CodePinkIcon } from "../assets/CodePinkIcon.svg";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  icon: {
    width: theme.metrics.headerIconSize,
    height: theme.metrics.headerIconSize,
  },
}));

interface Props {
  name?: "lock"| "profile" | "terms" | "code";
  className?: string;
}

export const AuthenticationIcon: React.FunctionComponent<Props> = ({ className, name }) => {
  const classes = useStyles();

  switch (name) {
    case "lock":
      return <LockPinkIcon className={[classes.icon, className].join(" ")} />;
    case "code":
      return <CodePinkIcon className={[classes.icon, className].join(" ")} />;
    case "terms":
      return <TermsPinkIcon className={[classes.icon, className].join(" ")} />;
    case "profile":
    default:
      return <ProfilePinkIcon className={[classes.icon, className].join(" ")} />;
  }
};

export default AuthenticationIcon;

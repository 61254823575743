import { PasswordExpiredFormData } from "../domain/PasswordExpiredFormData";
import {
  useCallback, useEffect, useState,
} from "react";
import AuthenticationService from "../../services/authentication";
import { PasswordExpiredFormErrors } from "../domain/PasswordExpiredFormErrors";
import { PasswordExpiredFormValidator } from "../domain/PasswordExpiredFormValidator";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useUserProfile } from "../../hooks/useUserProfile";
import { FailedToCompleteNewPasswordException } from "../domain/FailedToCompleteNewPasswordException";

interface UsePasswordExpiredResult {
  form: PasswordExpiredFormData;
  errors: PasswordExpiredFormErrors;
  submitPasswordExpired: () => void;
  onChange: (form: PasswordExpiredFormData) => void;
}

interface Props {
  user?: CognitoUser;
}

export const usePasswordExpired = ({ user }: Props): UsePasswordExpiredResult => {
  const { initUserProfile } = useUserProfile();
  const [form, setForm] = useState(PasswordExpiredFormData.from(user));
  const [errors, setErrors] = useState(PasswordExpiredFormErrors.empty());

  const submitPasswordExpired = useCallback(async () => {
    const validationErrors = PasswordExpiredFormValidator.validate(form);
    if (!validationErrors.isEmpty()) {
      setErrors(validationErrors);
      return;
    }

    if (!form.user) {
      return;
    }

    try {
      await AuthenticationService.completeNewPassword(form.user, form.newPassword);

      setErrors(PasswordExpiredFormErrors.empty());
      setForm(PasswordExpiredFormData.empty());

      await initUserProfile();
    } catch (error) {
      const authenticationErrorWithLink = (error as FailedToCompleteNewPasswordException).authenticationErrorWithLink;
      setErrors(new PasswordExpiredFormErrors({ password: [], body: [authenticationErrorWithLink] }));
    }
  }, [initUserProfile, form]);

  useEffect(() => {
    setForm((previousForm) => new PasswordExpiredFormData({ ...previousForm, user }));
  }, [user]);

  return {
    form,
    errors,
    submitPasswordExpired,
    onChange: setForm,
  };
};

import DrugTypes from "../domain/medications/DrugTypes";

const translations = {
  en: {
    drugTypes: {
      [DrugTypes.CAPLET]: "Caplets",
      [DrugTypes.TABLET]: "Tablets",
      [DrugTypes.SPRAY]: "Sprays",
      [DrugTypes.APPLICATION]: "Applications",
      [DrugTypes.DROP]: "Drops",
      [DrugTypes.INHALE]: "Puffs",
      [DrugTypes.POWDER]: "Powder",
      [DrugTypes.INJECTION]: "Injection",
      [DrugTypes.LIQUID]: "Liquid",
      [DrugTypes.CAPSULE]: "Capsule",
    },
  },
  fr: {
    drugTypes: {
      [DrugTypes.CAPLET]: "Caplets",
      [DrugTypes.TABLET]: "Comprimés",
      [DrugTypes.SPRAY]: "Pulvérisations",
      [DrugTypes.APPLICATION]: "Applications",
      [DrugTypes.DROP]: "Gouttes",
      [DrugTypes.INHALE]: "Bouffées",
      [DrugTypes.POWDER]: "Poudre",
      [DrugTypes.INJECTION]: "Injection",
      [DrugTypes.LIQUID]: "Liquide",
      [DrugTypes.CAPSULE]: "Capsule",
    },
  },
};

export default translations;

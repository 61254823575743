import { AuthenticationErrorWithLink } from "../../domain/AuthenticationErrorWithLink";

export default class FailedToResetPasswordException extends Error {

  constructor(
    public readonly authenticationErrorWithLink: AuthenticationErrorWithLink,
  ) {
    super(`Failed to reset password for user: ${authenticationErrorWithLink.error}`);
    Object.setPrototypeOf(this, FailedToResetPasswordException.prototype);
  }
}

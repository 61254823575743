import DoseFormData from "../../../../medication/domain/DoseFormData";
import { EventAssembler } from "./EventAssembler";
import ScheduleFormData from "../../../../schedule/domain/ScheduleFormData";
import ProposedEventDto from "../../dtos/ProposedEventDto";
import Frequency from "../../../../schedule/domain/Frequency";
import DateTime from "../../../../main/dateTime/DateTime";

class ProposedMedicationEventAssembler extends EventAssembler<DoseFormData> {
  public toDto(doseFormData: DoseFormData, schedule: ScheduleFormData<DoseFormData>): ProposedEventDto {
    const shouldIncludeWeekly = schedule.frequency === Frequency.WEEKLY;
    const shouldIncludeMonthly = schedule.frequency === Frequency.MONTHLY;

    return {
      id: doseFormData.id,
      frequency: schedule.frequency.toString(),
      amount: doseFormData.quantity,
      interval: schedule.interval,
      creationDate: DateTime.now().formatToUtc(),
      expectedTimestamp: doseFormData.time.formatToLocalDateTime(),
      weekdays: shouldIncludeWeekly ? schedule.weekdays : [],
      monthDays: shouldIncludeMonthly ? schedule.monthDays : [],
      alarmIntervalInMinutes: doseFormData.alarm?.intervalInMinutes,
    };
  }
}
export default new ProposedMedicationEventAssembler();

import Measurement from "./measurements/Measurement";
import Medication from "./medications/Medication";
import OtherActivity from "./otherActivity/OtherActivity";
import TreatmentRegimenStatus from "./TreatmentRegimenStatus";
import Reminder from "./reminders/Reminder";

export interface TreatmentMedications {
  status: TreatmentRegimenStatus;
  results: Medication[];
}

export interface TreatmentActivities {
  status: TreatmentRegimenStatus;
  results: OtherActivity[];
}

export interface TreatmentMeasurements {
  status: TreatmentRegimenStatus;
  results: Measurement[];
}

export interface TreatmentReminders {
  status: TreatmentRegimenStatus;
  results: Reminder[];
}

export default class Treatment {
  public medications: TreatmentMedications;
  public activities: TreatmentActivities;
  public measurements: TreatmentMeasurements;
  public reminders: TreatmentReminders;

  constructor(
    medications: TreatmentMedications,
    activities: TreatmentActivities,
    measurements: TreatmentMeasurements,
    reminders: TreatmentReminders,
  ) {
    this.medications = medications;
    this.activities = activities;
    this.measurements = measurements;
    this.reminders = reminders;
  }

  public getMedications(): Medication[] {
    return this.medications.results;
  }

  public getActivities(): OtherActivity[] {
    return this.activities.results;
  }

  public getMeasurements(): Measurement[] {
    return this.measurements.results;
  }

  public getReminders(): Reminder[] {
    return this.reminders.results;
  }

  public isEmpty(): boolean {
    return this.getMedications().length === 0
      && this.getActivities().length === 0
      && this.getMeasurements().length === 0
      && this.getReminders().length === 0;
  }
}

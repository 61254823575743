import React from "react";
import ProfileFormData from "../domain/ProfileFormData";
import { ProfileFormErrors } from "../domain/ProfileFormErrors";
import useAuthenticationRepo from "../../store/hooks/useUserRepository";
import User from "../../../main/domain/User";
import ProfileForm from "./ProfileForm";

interface Props {
  profileFormData: ProfileFormData;
  errors: ProfileFormErrors;
  onChange: (updatedProfileFormData: ProfileFormData) => void;
  canSubmit: boolean;
  onSubmit: () => void;
}

const EditProfileForm: React.FunctionComponent<Props> = ({
  profileFormData,
  onChange,
  onSubmit,
  errors,
  canSubmit,
}) => {
  const { user } = useAuthenticationRepo();

  return (
    <ProfileForm
      profileFormData={profileFormData}
      onChange={onChange}
      onSubmit={onSubmit}
      canSubmit={canSubmit}
      errors={errors}
      userEmail={(user as User).email}
      withOfficeAddress
    />
  );
};

export default EditProfileForm;

import {
  makeStyles, TableCell, Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  headerText: {
    fontWeight: 600,
  },
}));

interface Props {
  title: string;
}

export const ReportResultsTableHeaderTitle = ({ title }: Props): JSX.Element => {
  const styles = useStyles();
  return (
    <TableCell>
      <Typography className={styles.headerText} color="textPrimary">{title}</Typography>
    </TableCell>
  );
};

import Measurement, { MeasurementAttributes } from "../Measurement";
import { MeasurementType } from "measurement/domain/MeasurementType";
import i18next from "i18next";

export default class MoodMeasurement extends Measurement {

  constructor({ name, schedule, adherence, reportCode }: Omit<MeasurementAttributes, "type">) {
    super({ name, schedule, adherence, type: MeasurementType.MOOD, reportCode });
  }

  public displayName(): string {
    return i18next.t(`report:measurementType.${MeasurementType.MOOD}`);
  }
}

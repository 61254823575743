import { useEffect, useState } from "react";
import PatientReport from "../../report/domain/PatientReport";
import { fetchPatientReport } from "../../report/api/PatientCodeApi";
import DateTime from "../../main/dateTime/DateTime";

export interface PatientReportResult {
  report?: PatientReport | null;
}

const usePatientReport = (code: string): PatientReportResult => {
  const [report, setReport] = useState<PatientReport | undefined | null>();
  const timeZone = DateTime.getCurrentTimezone();

  useEffect(() => {
    const fetchReport = async (): Promise<void> => {
      try {
        const patientReport = await fetchPatientReport(code, timeZone);
        setReport(patientReport);
      } catch {
        setReport(null);
      }
    };

    fetchReport();
  }, [code, timeZone]);

  return { report };
};

export default usePatientReport;

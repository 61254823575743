import Frequency from "../domain/Frequency";

const frequency = {
  en: {
    frequency: {
      frequencies: {
        [Frequency.DAILY]: "Daily",
        [Frequency.WEEKLY]: "Weekly",
        [Frequency.MONTHLY]: "Monthly",
        [Frequency.PUNCTUAL]: "As Needed",
      },
    },
  },
  fr: {
    frequency: {
      frequencies: {
        [Frequency.DAILY]: "Quotidienne",
        [Frequency.WEEKLY]: "Hebdo",
        [Frequency.MONTHLY]: "Mensuelle",
        [Frequency.PUNCTUAL]: "Au besoin",
      },
    },
  },
};

export default frequency;

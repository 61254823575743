import ClientConnectApi from "authentication/clientConnect/api/ClientConnectApi";
import {
  getClientToken,
  isStandaloneContext,
} from "main/selectors/AppContextSelector";
import { SessionStorageKeys } from "main/domain/SessionStorageKeys";
import FailedToConsumeToken from "../domain/exceptions/FailedToConsumeToken";
import { LocalStorageKeys } from "main/domain/LocalStorageKeys";
import useLanguageSelection from "main/hooks/useLanguageSelection";

export interface UseClientConnectResult {
  consumeToken: () => Promise<void>;
  clearSessionToken: () => void;
}

export const useClientConnect = (): UseClientConnectResult => {
  const { changeLanguage } = useLanguageSelection();

  const consumeToken = async (): Promise<void> => {
    const token = getClientToken();

    if (isStandaloneContext() || !token) {
      return;
    }

    try {
      const { patientProfileKey, documents, ...rest } = await ClientConnectApi.consume(token);

      changeLanguage(rest.language);

      // Patient infos set in session storage to allow multiple tab open with different patient
      sessionStorage.setItem(SessionStorageKeys.CLIENT_CONNECT_PATIENT_ID, patientProfileKey);
      sessionStorage.setItem(SessionStorageKeys.CLIENT_CONNECT_PATIENT_DOCUMENTS, JSON.stringify(documents));

      localStorage.setItem(LocalStorageKeys.CLIENT_CONNECT_CONTEXT, JSON.stringify(rest));
      sessionStorage.removeItem(SessionStorageKeys.CLIENT_CONNECT_TOKEN);
    } catch (e) {
      throw new FailedToConsumeToken();
    }
  };

  const clearSessionToken = (): void => {
    sessionStorage.removeItem(SessionStorageKeys.CLIENT_CONNECT_TOKEN);
    sessionStorage.removeItem(SessionStorageKeys.CLIENT_CONNECT_PATIENT_ID);
    sessionStorage.removeItem(SessionStorageKeys.CLIENT_CONNECT_PATIENT_DOCUMENTS);
    localStorage.removeItem(LocalStorageKeys.CLIENT_CONNECT_CONTEXT);
  };

  return {
    consumeToken,
    clearSessionToken,
  };
};

import { useCallback } from "react";
import { isAppLoading } from "../selectors/AppLoadingSelector";
import { setLoading } from "../AppLoadingActions";
import useGlobalState from "./useGlobalState";

export interface UseAppLoadingRepositoryResult {
  startLoading: () => void;
  stopLoading: () => void;
  loading: boolean;
}

const useAppLoadingRepository = (): UseAppLoadingRepositoryResult => {
  const { dispatch, state } = useGlobalState();
  const loading = isAppLoading(state);

  const startLoadingAction = useCallback((): void =>
    setLoading(dispatch, true), [dispatch]);

  const stopLoadingAction = useCallback((): void =>
    setLoading(dispatch, false), [dispatch]);

  return {
    startLoading: startLoadingAction,
    stopLoading: stopLoadingAction,
    loading,
  };
};

export default useAppLoadingRepository;

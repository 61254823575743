import SupportedLanguages from "main/domain/SupportedLanguages";
import ScheduleEvent from "../ScheduleEvent";
import CustomProgramDuration from "./CustomProgramDuration";
import { recurrenceStringToText } from "report/service/RecurrenceRuleService";
import i18next from "i18next";
import { DateTime as LuxonDateTime } from "luxon";

interface MeasurementScheduleAttributes {
  recurrenceRule: string;
  duration: CustomProgramDuration;
  dailySessions: number;
  events: ScheduleEvent[];
}

export default class MeasurementSchedule {
  public recurrenceRule: string;
  public duration: CustomProgramDuration;
  public dailySessions: number;
  public events: ScheduleEvent[];

  constructor({ recurrenceRule, duration, dailySessions, events }: MeasurementScheduleAttributes) {
    this.recurrenceRule = recurrenceRule;
    this.duration = duration;
    this.dailySessions = dailySessions;
    this.events = events;
  }

  public toText(language: SupportedLanguages): string {
    return recurrenceStringToText(this.recurrenceRule, language)
      + this.events.map(this.getEventTime).join(` ${i18next.t("schedule:and")} `);
  }

  private getEventTime(event: ScheduleEvent): string {
    const alarmDescription = !!event.alarmIntervalInMinutes
      ? " (" + i18next.t("schedule:alarmDescription", { interval: event.alarmIntervalInMinutes }) + ")"
      : "";

    return " " + i18next.t("schedule:at")
    + " " + event.eventDate.formatLocale(LuxonDateTime.TIME_SIMPLE)
    + alarmDescription;
  }
}

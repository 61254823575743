import { AppState } from "./GlobalStore";
import { DispatchAction } from "./GlobalProvider";

export const addException = (dispatch: DispatchAction, error: string): void => {
  dispatch({
    reducer: (state: AppState): AppState =>
      ({ ...state, appExceptions: { errors: [...state.appExceptions.errors, error] } }),
  });
};
export const clearExceptions = (dispatch: DispatchAction): void => {
  dispatch({
    reducer: (state: AppState): AppState =>
      ({ ...state, appExceptions: { errors: [] } }),
  });
};

const home = {
  en: {
    connections: {
      date: "Date",
      time: "Time",
      name: "Patient's Name",
      email: "Patient's Email",
      age: "Patient's Age",
      gender: "Patient's Gender",
      status: {
        columnName: "Type",
        type: {
          carePlanSent: "Care Plan Sent",
          carePlanViewed: "Care Plan Viewed",
        },
      },
      error: "There seems to be a problem with the server. " +
        "Please try to refresh the page. Don't hesitate to contact us if the problem persists.",
    },
  },
  fr: {
    connections: {
      date: "Date",
      time: "Heure",
      name: "Nom du patient",
      email: "Courriel du patient",
      age: "Âge du patient",
      gender: "Genre du patient",
      status: {
        columnName: "Type",
        type: {
          carePlanSent: "Plan de soins envoyé",
          carePlanViewed: "Plan de soins consulté",
        },
      },
      error: "Il semble y avoir un problème avec le serveur. " +
        "Veuillez raffraichir la page. N'hésitez pas à nous informer si le problème persiste.",
    },
  },
};

export default home;

/* eslint-disable */
const termsOfService =
    `<0>MedHelper for Professionals Terms of Service</0>
Last modified: July 18, 2022

Please review these terms (the “Agreement”) carefully. By accessing or using the Service offered by MedHelper Inc. (“MedHelper”, “we” or “us”), you are confirming that you have read, understand and accept this Agreement. This Agreement can be updated from time to time as set out in Section 24 below. You are responsible for regularly reviewing the most current version of this Agreement, which is published at: <1>https://medhelper.com/policies-medhelper-for-professionals/</1>, though we will notify you of any changes that, in our sole discretion, materially impact this Agreement. Continued use of the Service after any such changes have been made will constitute your consent to such changes. When we change this Agreement, we will update the “Last Modified” date above.

This Agreement applies to the “Service”, MedHelper for Professionals which is an online portal where Doctors and other healthcare professionals can view patient reported data.

<0>By using our Service, you confirm that:</0>

    A. You will only use our Self-Serve Services for business and professional reasons;

    B. You are at least 18 years old or otherwise able to enter into a legally binding agreement;

    C. You accept and will comply with this Agreement;

    D. You are a certified Medical Professional and/or have the legal right to view the medication information about your Patient; and

    E. You are responsible for anyone that uses our Service through your account, such as your employees, consultants or contractors (“Authorized Users”).

<0>MedHelper for Professionals</0>

    1. Subject to the rest of this Agreement, we will allow you and your Authorized Users to access and use our Service in the way set out in the plan you subscribed to. You may not use or access the Service in any other way, such as using robots, spiders, crawlers and scraping technologies.

    2. Our Service evolves constantly. We may change our Service, such as by changing, adding or removing features at any time, for any reason. We may or may not provide notice of those changes to you. We will not be liable to you or any third party for the modification, price change, suspension or discontinuance of any part of our Services.

<0>Acceptable Use Rules</0>

    3. You and any Authorized Users using your account must comply with the Acceptable Use Rules and all laws. If we reasonably believe that you have breached the Acceptable Use Rules or any applicable law, we may, at any time and without notice, remove any of your Content and suspend, restrict or terminate your account or your access to the Service.

<0>Customer Content</0>

    4. You are solely responsible for Customer Content. We may, but do not have to, review, filter, block or remove Content, including Customer Content.

<0>Patient Content</0>

    5. You are using the Service to access personal and medical information about a Patient (“Patient Content”), you acknowledge and agree that:

        (A) the Service does not help you comply with any laws, rules, or regulations that may apply to your collection or use of Patient Content, which is solely your responsibility; and

        (B) we will not be liable to you or any third party for the Patient Content, and we make no warranty that any Patient Content obtained or displayed through the Service is accurate or complete.

<0>Account Information</0>

    6. You are responsible for the completeness, security, confidentiality and accuracy of the account information you provide to us. You will promptly notify us of any unauthorized access to or use of your log-in credentials or account.


<0>Confidentiality</0>

    7. If we share non-public information about MedHelper or our Service with you, you must keep it confidential and use reasonable security measures to prevent unauthorized disclosure of or access to that information.

<0>Intellectual Property</0>

    Ownership in the Service, and the software powering the Service, will at all times remain with MedHelper. MedHelper will retain all right, title, copyright, trade secrets, patents, trademarks, and other proprietary and intellectual property rights in the Service and the software powering the Service. You do not acquire any rights in the Service or the software powering the service, other than those specified in these Terms. You will not remove any copyright, patent, trademark, design right, trade secret or any other proprietary rights or legends from the Service.

<0>Payment Terms, Trials and Renewal</0>

    8. Fees and Trials. You must pay all fees for the Service you purchase, except for Services that we expressly offer free of charge.
    9. Auto-Renewal of the Service. The Service plans will renew automatically at the end of the term (annually), and we will automatically bill you on renewal unless you cancel our Service under Section 23 below.

    10. Taxes. You are responsible for paying all taxes on all fees that you pay to us. Local taxes may differ based on your payment method.

<0>Cancellation and Termination of the Service</0>

    11. You may cancel your Service plan at any time by contacting us at info@medhlper.com. Should you cancel your subscription to the Service, this Agreement will terminate at the end of the period for which you have committed to pay fees and you agree to cease using the Service. Any fees that you have committed to paying must still be paid. You are solely responsible for contacting MedHelper to cancel the Service or request deletion of Your account. MedHelper will delete Your Data within six months of you cancelling your subscription to the Service or, if earlier, upon receipt of a written request to delete Your Data.
    12. We may refuse to provide service (including the Service) to anyone for any reason at any time. We may also immediately suspend or terminate your access to our Service for any reason, at any time, without notifying you in advance. If we terminate this Agreement because you violated the Acceptable Use Rules or any other part of this Agreement, you will not receive a refund. If we terminate your access to the Service under this section for no reason, we will refund you for the Services you have not yet received.

<0>Disclaimer of Warranties and Limitation of Liability</0>

    13. We offer our Service “as is” and are not making any warranties, conditions, representations or guarantees of any kind, whether express, implied, statutory or otherwise, including all express or implied warranties of design, merchantability, fitness for a particular purpose, title, quality and non-infringement, that our Service will meet your requirements or that our Service will always be available, accessible, uninterrupted, timely, secure, accurate, complete or error-free. Use our Service at your own risk. You hereby release us and our affiliates and the directors, officers, employees, agents, licensors and service providers of MedHelper and our affiliates (the “MedHelper Parties”) from any claims, known or unknown, you have against them.

    14. We will not be liable for indirect, punitive, incidental, special, consequential or exemplary damages, or for loss of profits, goodwill or data or other intangible losses, that result from the use of, or inability to use, our Service or any other aspect of this Agreement. Under no circumstances will we be liable for any third parties, any connection to or transmission from the Internet, or for any damage, loss or injury resulting from hacking, tampering, theft or other unauthorized access to or use of our Service or your account or the information contained in your account.

    15. Our aggregate liability to you for all losses or damages arising out of this Agreement or your use of our Service, even if we have been advised of the possibility of such losses, will not exceed the greater of the fees we received from you for our Service that are subject to the claim during the three-month period immediately preceding the date on which the damages arose and CAD$100. The limitations of liability in this section also apply to the MedHelper Parties and to claims brought based on any cause of action, including breach of contract, tort (including negligence), statute or other legal or equitable theory. If there is a law that limits how the limitation of liability in this section applies to you, our liability will be limited to the fullest extent permitted by law.

    16. No advice or information, whether oral or written, obtained from us or elsewhere will create any warranty or condition not expressly stated in this Agreement.

    17. Our prices are set based on limitations of liability and the disclaimers of warranties in this section. You acknowledge that they will survive and apply even if found to have failed their essential purpose.

    18. If you are dissatisfied with our Service or believe that you’ve been harmed by your use of our Service or this Agreement, you may terminate your use of our Service (as set out above). That termination is your sole and exclusive remedy (and our sole and exclusive liability) under this Agreement.

<0>Claims and Disputes</0>

    19. These Terms will be governed by the laws of the Province of Quebec and the laws of Canada applicable in Quebec. Each party agrees that the courts of the Province of Quebec will have jurisdiction. The parties agree that the terms of the United Nations Convention of Contracts for the International Sale of Goods do not apply to these Terms.

    20. You will indemnify and hold harmless the MedHelper Parties from and against all losses, damages, penalties, liability and costs, including reasonable legal fees, of any kind or nature related to any claim against a MedHelper Party related to: (a) Patient Content; (b) your breach of this Agreement or any laws; or (c) your use of our Service.

    21. If you breach this Agreement or violate our rights or another person’s rights, we may ask a court to stop you; if we bring a claim against you, you will reimburse us for our reasonable legal fees for that claim.

    22. Any Dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. MedHelper and you each waive any right to a trial by jury.

<0>Miscellaneous</0>

    23. Export Compliance. You will not use or access our Service if you are located in any jurisdiction in which the provision of our Service is prohibited under Canadian, U.S. or other laws (a “Prohibited Jurisdiction”) and you will not provide access to our Service to any government, entity or individual located in any Prohibited Jurisdiction. You confirm that you are not named on any Canadian or U.S. government list of persons or entities prohibited from transaction with any Canadian or U.S. person; (b) you are not a national of, or a company registered in, any Prohibited Jurisdiction; (c) you will not allow Authorized Users to access or use our Service in violation of any Canadian, U.S. or other export embargoes, prohibitions or restrictions; and (d) you will comply with all laws regarding the transmission of data exported from the country in which you (or your Authorized Users) are located to Canada and the United States.

    24. Amendment. We may change any part of this Agreement (including any terms or documents incorporated by reference in this Agreement) at any time by posting the revised terms on the MedHelper website, and your use of the Service after the effective date of the updated Agreement or other terms constitutes your agreement to the updated Agreement or other terms, as applicable. It is important for you to review this Agreement before using our Service and from time to time. The updated Agreement will be effective as of the time of posting, or on such later date as may be specified in the updated Agreement, and will apply to your use of the Service from that point forward.

    25. Assignment. You may not assign your rights under this Agreement to anyone else. We may assign our rights to any other individual or entity.

    26. Severability. If a court finds part of this Agreement to be invalid, the rest will continue to apply with the minimum changes required to remove the invalid part.

    27. Force Majeure. We will not be liable to you or any third party for any failure to perform our obligations under this Agreement if that failure results from any cause beyond our control, such as the elements; fire; flood; severe weather; earthquake; vandalism; accidents; sabotage; power failure; denial of service attacks or similar attacks or other acts of hackers; Internet or network failures or interruptions; acts of God and the public enemy; acts of war; acts of terrorism; riots; civil or public disturbances; strikes, lockouts or labor disruptions; any laws, orders, rules, regulations, acts or restraints of any government or governmental body or authority, civil or military, including the orders and judgments of courts; or if another person changes their services or terms or no longer offers their services to you or MedHelper on terms we believe are reasonable.

    28. Remedies. If we fail to enforce this Agreement, we are not waiving our right to do so. Any remedies we have under this Agreement are not exclusive of any other remedies we may have under this Agreement, at law or otherwise.

    29. Notices to You. MedHelper may give notice to you by placing a banner notice on the MedHelper for Professionals platform or MedHelper’s website. We may also contact you or your Authorized Users through your MedHelper account or contact information you provide, such as email or telephone. If you fail to maintain accurate account information, such as contact information, you may not receive critical information about our Service or this Agreement.

    30. Notices to MedHelper. For any notice to MedHelper that you give under or regarding this Agreement, you must notify MedHelper by email to legal@medhelper.com with a duplicate copy sent via registered mail to the following address: MedHelper Inc., 6500 Trans-Canada Hwy 4th Floor, Pointe-Claire, Quebec, Canada H9R 0A5.

    31. Entire Agreement. This Agreement, including the other documents referred to as applicable to the Service in this Agreement, is the entire agreement between you and MedHelper for your use of our Service. Any prior understandings, statements or and agreements (oral or written) do not apply, including additional terms that you may present (such as terms in a unilateral notice from you to us or printed on a purchase order or any other document generated by you). This Agreement is binding on the parties and their permitted successors and assigns.
`;

export default termsOfService;

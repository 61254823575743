import CustomActivityFormData from "../../../customActivities/domain/CustomActivityFormData";
import DetailFormData from "../../../customActivities/domain/DetailFormData";
import { ProposedCustomActivityDto } from "../dtos/ProposedCustomActivityDto";
import { ProposedSchedulableCustomActivityDto } from "../dtos/ProposedSchedulableCustomActivityDto";
import ProposedSessionEventAssembler from "./events/ProposedSessionEventAssembler";
import ProposedScheduleAssembler from "./ProposedScheduleAssembler";

const toDto = (formData: CustomActivityFormData): ProposedSchedulableCustomActivityDto => ({
  customActivity: assembleCustomActivityToDto(formData.details),
  schedule: ProposedScheduleAssembler.toDto(formData.schedule, ProposedSessionEventAssembler),
  instructions: formData.instructions.text,
});

const assembleCustomActivityToDto = (customActivityDetails: DetailFormData): ProposedCustomActivityDto => ({
  name: customActivityDetails.name,
});

export const ProposedSchedulableCustomActivityAssembler = {
  toDto,
};

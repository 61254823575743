import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import theme from "../../main/theme/theme";
import TermsAndPrivacyContent from "./TermsAndPrivacyContent";
import AuthenticationIcon from "./AuthenticationIcon";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    height: "90%",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingLeft: theme.metrics.margin.huge,
    paddingRight: theme.metrics.margin.huge,
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.metrics.margin.normal,
  },
  headerIcon: {
    width: theme.metrics.headerIconSize,
    height: theme.metrics.headerIconSize,
    marginBottom: theme.metrics.margin.normal,
  },
  headerTitle: {
    textAlign: "center",
  },
  contentContainer: {
    padding: theme.metrics.margin.large,
    borderTop: theme.metrics.border.tiny,
    borderBottom: theme.metrics.border.tiny,
    borderTopColor: theme.colors.divider,
    borderBottomColor: theme.colors.divider,
    overflowY: "scroll",
    overflow: "hidden",
    height: "55vh",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: theme.metrics.margin.normal,
    paddingBottom: 0,
  },
  button: {
    width: "75%",
  },
  buttonWrapper: {
    display: "flex",
    width: "50%",
    margin: theme.metrics.margin.tiny,
    justifyContent: "center",
  },
}));

interface Props {
  onAccept: () => void;
  onCancel?: () => void;
}

const PX_OFFSET_DONE_READING = 100;

export const TermsAndPrivacy: React.FunctionComponent<Props> = ({ onAccept, onCancel }) => {
  const { t } = useTranslation("authentication");
  const classes = useStyles();
  const [canAccept, setCanAccept] = useState(false);
  const onScroll = (event: React.UIEvent<HTMLElement>): void => {
    const target = event.currentTarget;
    const isAtBottom = target.scrollHeight - target.scrollTop <= target.clientHeight + PX_OFFSET_DONE_READING;

    if (isAtBottom) {
      setCanAccept(true);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <AuthenticationIcon name="terms" className={classes.headerIcon} />

        <Typography component="h1" variant="h3" className={classes.headerTitle}>
          {t("termsOfServicePlusPrivacyPolicy")}
        </Typography>
      </div>

      <div className={classes.contentContainer} onScroll={onScroll}>
        <TermsAndPrivacyContent />
      </div>

      <div className={classes.buttonContainer}>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
            onClick={onAccept}
            disabled={!canAccept}
          >
            {t("accept")}
          </Button>
        </div>

        {
          !!onCancel &&
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="secondary"
                onClick={onCancel}
              >
                {t("cancel")}
              </Button>
            </div>
        }
      </div>
    </div>
  );
};

export default TermsAndPrivacy;

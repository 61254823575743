import PhoneNumber from "../domain/PhoneNumber";

export default class PhoneNumberAssembler {

  public static assembleToExternal(phoneNumber: PhoneNumber): string {
    return JSON.stringify({ value: phoneNumber.value, extension: phoneNumber.extension });
  }

  public static assembleToInternal(describedPhoneNumber: string): PhoneNumber {
    const parsedPhoneNumber = JSON.parse(describedPhoneNumber);

    return PhoneNumber.from(parsedPhoneNumber.value, parsedPhoneNumber.extension);
  }
}

import { useCallback } from "react";
import AuthenticationError from "../../domain/AuthenticationError";
import LogInFormData from "../domain/LogInFormData";
import FailedToLogInException from "../domain/exceptions/FailedToLogInException";
import AuthenticationService from "../../services/authentication";
import LogInFormErrors from "../domain/LogInFormErrors";
import LogInFormValidator from "../domain/LogInFormValidator";
import useUserRepository from "../../store/hooks/useUserRepository";
import useAppExceptionRepository from "../../../store/hooks/useAppExceptionRepository";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useUserProfile } from "../../hooks/useUserProfile";
import { AuthenticationErrorWithLinkFactory } from "../../domain/AuthenticationErrorWithLinkFactory";

export interface LogInFormResult {
  errors: LogInFormErrors;
  onSubmit: (logInFormData: LogInFormData) => Promise<void>;
}

export interface Props {
  onConfirmationNeeded?: (email: string, password: string) => void;
  onRequireNewPassword: (user: CognitoUser) => void;
  onRequireResetPassword?: (email: string) => void;
  clearForm?: () => void;
}

const useLoginForm = ({
  onConfirmationNeeded,
  onRequireNewPassword,
  onRequireResetPassword,
  clearForm,
}: Props): LogInFormResult => {
  const { initUserProfile } = useUserProfile();
  const { errors, setErrors } = useUserRepository();
  const { clearExceptions } = useAppExceptionRepository();

  const onSubmit = useCallback(async (logInFormData: LogInFormData): Promise<void> => {
    const validationErrors = LogInFormValidator.validate(logInFormData);
    if (!validationErrors.isEmpty()) {
      setErrors(validationErrors);
      return;
    }

    try {
      setErrors(LogInFormErrors.empty());
      clearExceptions();

      await AuthenticationService.login(logInFormData, onRequireNewPassword);
      clearForm && clearForm();

      await initUserProfile();

      const user = await AuthenticationService.getUser();

      if (AuthenticationService.isRequiredToChangePassword(user.latestPasswordChange)) {
        await AuthenticationService.requestPasswordReset(logInFormData.email);
        await AuthenticationService.logout();
        throw new FailedToLogInException(AuthenticationErrorWithLinkFactory.createFrom(
          { code: AuthenticationError.RequireResetPassword }
        ));
      }
    } catch (e) {
      if (e instanceof FailedToLogInException) {
        const authenticationError = e.authenticationErrorWithLink.error;
        if (authenticationError === AuthenticationError.RequireResetPassword) {
          onRequireResetPassword && onRequireResetPassword(logInFormData.email);
          return;
        }

        if (authenticationError === AuthenticationError.RequireNewPassword) {
          return;
        }

        if (authenticationError === AuthenticationError.UserNotConfirmedException) {
          onConfirmationNeeded && onConfirmationNeeded(logInFormData.email, logInFormData.password);
        }

        setErrors(LogInFormErrors.empty().addBodyError(e.authenticationErrorWithLink));
        return;
      }

      setErrors(LogInFormErrors.empty().addBodyError(
        AuthenticationErrorWithLinkFactory.createFrom( { code: AuthenticationError.AuthenticationDefault }),
      ));
    }
  },
  [
    setErrors,
    clearExceptions,
    onRequireNewPassword,
    initUserProfile,
    clearForm,
    onRequireResetPassword,
    onConfirmationNeeded,
  ]);

  return { onSubmit, errors };
};

export default useLoginForm;

import UserStore, { userInitialState } from "../authentication/store/UserStore";
import ProfileStore, { profileInitialState } from "../authentication/store/ProfileStore";
import AppExceptionStore, { appExceptionStoreInitialState } from "./AppExceptionStore";
import PrescribeFormStore from "prescribe/store/PrescribeFormStore";

export interface AppState {
  user: UserStore;
  profile: ProfileStore;
  prescribeForm: PrescribeFormStore;
  appExceptions: AppExceptionStore;
  appLoading: boolean;
}

export const createStore = (): AppState => ({
  user: userInitialState,
  profile: profileInitialState,
  prescribeForm: null,
  appExceptions: appExceptionStoreInitialState,
  appLoading: true,
});

import CouldNotAssembleProfileError from "./exceptions/CouldNotAssembleProfileError";
import HealthProfession from "./HealthProfession";
import NewProfile from "./NewProfile";
import PhoneNumber from "../../../main/domain/PhoneNumber";
import Profile from "./Profile";
import ProfileFormData from "./ProfileFormData";
import ProfileValidator from "./ProfileValidator";

export default class ProfileAssembler {

  public static fromFormData(profileFormData: ProfileFormData): NewProfile {
    const errors = ProfileValidator.validate(profileFormData);

    if (!errors.isEmpty()) {
      throw new CouldNotAssembleProfileError(errors);
    }

    const profession = profileFormData.profession as string;

    return new NewProfile(
      profileFormData.firstName,
      profileFormData.lastName,
      profileFormData.officeAddress,
      PhoneNumber.from(profileFormData.officeNumber, profileFormData.extension),
      HealthProfession[profession as keyof typeof HealthProfession],
      profileFormData.partnerCompany
    );
  }

  public static toFormData(profile: Profile): ProfileFormData {
    return new ProfileFormData({
      firstName: profile.firstName,
      lastName: profile.lastName,
      officeAddress: profile.officeAddress || "",
      officeNumber: profile.officeNumber.value,
      extension: profile.officeNumber.extension || "",
      profession: profile.profession,
      partnerCompany: profile.partnerCompany,
    });
  }
}

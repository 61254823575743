import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import theme, { pxToRem } from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  circleButton: {
    "border": "none",
    "background": theme.colors.disabled,
    "&:hover": {
      cursor: "pointer",
    },
    "&:active": {
      border: `1.5px solid ${theme.colors.primary.paleBlue}`,
    },
    "&.checked": {
      background: theme.colors.primary.normal,
    },
    "height": `${pxToRem(40)}`,
    "width": `${pxToRem(40)}`,
    "borderRadius": "50%",
  },
  dayText: {
    "textAlign": "center",
    "color": `${theme.colors.text.primary}`,
    "lineHeight": 1.8,
    "fontWeight": "bold",
    "fontSize": theme.fonts.h5.size,
    "&.checked": {
      color: theme.colors.text.contrast,
    },
  },
}));

interface Props {
  label: string;
  ariaLabel?: string;
  onToggle: (newValue: boolean) => void;
  value: boolean;
}

const DayToggleButton: React.FC<Props> = ({ label, onToggle, value, ariaLabel }) => {
  const classes = useStyles();
  return (
    <button
      onClick={(): void => onToggle(!value)}
      className={classNames(classes.circleButton, { checked: value })}
      role="checkbox"
      aria-checked={value}
      aria-label={ariaLabel || label}
    >
      <Typography
        className={classNames(classes.dayText, { checked: value })}
      >
        {label}
      </Typography>
    </button>
  );
};

export default DayToggleButton;

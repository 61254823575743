import { ProfileFormErrors } from "../ProfileFormErrors";

export default class CouldNotAssembleProfileError extends Error {

  constructor(
    public readonly errors: ProfileFormErrors,
  ) {
    super("Could not assemble profile");
  }
}

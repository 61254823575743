import GoogleAnalyticsClient from "./GoogleAnalyticsClient";

const analyticsClient = new GoogleAnalyticsClient();

export enum AnalyticEvent {
  SIGN_UP = "Sign_Up",
  ADD_CAREPLAN = "Add_CarePlan",
  SEND_CAREPLAN = "Send_CarePlan",
  ADD_DOCUMENT = "Add_Document",
  VIEW_CAREPLAN_SENT = "View_CarePlan_Sent",
  VIEW_REPORT = "View_Report",
  VIEW_RESULTS = "View_Results"
}

export enum AnalyticTreatment {
  MEDICATION = "Medication",
  ACTIVITY = "Custom_Activity",
  MEASUREMENT = "Measurement",
  REMINDER = "Reminder"
}

export enum AnalyticCategory {
  AUTHENTICATION = "Authentication",
  HOME = "Home",
  REPORT = "Report",
  PRESCRIBE = "Prescribe",
  CONNECTION_HISTORY = "Connection_History",
  CARE_PLAN = "Care Plan"
}

export enum AnalyticCustomProperty {
  PARTNER_COMPANY = "partnerCompany",
}

export enum AnalyticCustomPropertyValues {
  GENERAL_USER = "generalUser"
}

export default analyticsClient;

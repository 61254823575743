import CustomActivityFormData, { createEmpty as createEmptyFormData } from "../domain/CustomActivityFormData";
import { useFormData } from "@nexapp/nexapp-react-forms";
import { canSubmitCustomActivity } from "../domain/DetailFormData";

export interface UseCustomActivityFormResult {
  formData: CustomActivityFormData;
  onChange: <T extends keyof CustomActivityFormData>(
    section: T
  ) => (
    field: keyof CustomActivityFormData[T]
  ) => (value: CustomActivityFormData[T][typeof field]) => void;
  hasChanged: boolean;
  canSubmit: boolean;
}

export const useCustomActivityForm = (customActivity?: CustomActivityFormData): UseCustomActivityFormResult => {
  const { formData, onChange, hasChanged } = useFormData({
    initialData: customActivity || createEmptyFormData(),
  });

  return {
    formData,
    onChange,
    hasChanged,
    canSubmit: canSubmitCustomActivity(formData.details),
  };
};

export default useCustomActivityForm;

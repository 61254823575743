import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import theme from "../../../main/theme/theme";
import AlertCard from "../../../main/components/AlertCard";
import PasswordField from "../../components/PasswordField";
import AuthenticationIcon from "../../components/AuthenticationIcon";
import UserSignUpFormData from "../domain/UserSignUpFormData";
import { UserSignUpFormErrors } from "../domain/UserSignUpFormErrors";
import analyticsClient, { AnalyticCategory, AnalyticEvent } from "../../../main/services/analytics";
import { AuthenticationFormErrorsWithLink } from "../../components/AuthenticationFormErrorsWithLink";

const useStyles = makeStyles((muiTheme: Theme) => ({
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: `0 ${theme.metrics.margin.huge}`,
    [muiTheme.breakpoints.down("sm")]: {
      paddingTop: theme.metrics.margin.large,
    },
  },
  alertCard: {
    width: "100%",
    padding: theme.metrics.margin.medium,
  },
  tryAtNoChargeMessage: {
    fontWeight: "bold",
  },
  signUpIcon: {
    marginBottom: theme.metrics.margin.small,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.metrics.margin.large,
  },
  textField: {
    margin: `${theme.metrics.margin.normal} 0`,
  },
  logInMessage: {
    marginTop: theme.metrics.margin.large,
  },
  logInLink: {
    textDecoration: "underline",
    marginLeft: theme.metrics.margin.tiny,
  },
  errors: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.metrics.margin.normal,
  },
}));

interface Props {
  onChange: (signUp: UserSignUpFormData) => void;
  onSubmit: () => void;
  onSignInRequested: () => void;

  signUp: UserSignUpFormData;
  errors: UserSignUpFormErrors;
}

const SignUpForm: React.FunctionComponent<Props> =
  ({ onChange, onSubmit, onSignInRequested, signUp, errors }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation(["authentication", "authenticationErrors"]);

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
      onChange(signUp.withEmail(event.target.value.toLowerCase()));
    const onPasswordChange = (password: string): void => onChange(signUp.withPassword(password));

    const onSubmitForm = (event: React.ChangeEvent<HTMLFormElement>): void => {
      analyticsClient.logEvent(AnalyticCategory.AUTHENTICATION, AnalyticEvent.SIGN_UP);
      event.preventDefault();
      onSubmit();
    };

    return (
      <div className={classes.content}>
        <AlertCard className={classes.alertCard}>
          <Typography component="span" variant="h5" display="block" className={classes.tryAtNoChargeMessage}>
            {t("tryAtNoCharge")}
          </Typography>

          <Typography component="span" variant="h6" display="block">
            {t("noCreditCardRequired")}
          </Typography>
        </AlertCard>

        <form className={classes.form} onSubmit={onSubmitForm} noValidate aria-labelledby="sign-up-header">
          <AuthenticationIcon className={classes.signUpIcon} />

          <Typography component="h2" variant="h4" id="sign-up-header">
            {t("signUp")}
          </Typography>

          <TextField
            className={classes.textField}
            id="email"
            variant="outlined"
            fullWidth
            label={t("email")}
            name="email"
            autoComplete="email"
            autoFocus
            value={signUp.email}
            onChange={onEmailChange}
            error={errors.email.length > 0}
            helperText={errors.email.length > 0 && t(`authenticationErrors:${errors.email[0]}`)}
          />

          <PasswordField
            className={classes.textField}
            password={signUp.password}
            onChange={onPasswordChange}
            error={errors.password.length > 0 ? t(`authenticationErrors:${errors.password[0]}`) : ""}
            label={t("password")}
            name="password"
          />

          <AuthenticationFormErrorsWithLink errors={errors.body} />

          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            {t("signUpAction")}
          </Button>

          <div className={classes.logInMessage}>
            <Typography component="span" variant="h4" color="textSecondary">
              {t("logInMessage")}
            </Typography>

            <Link onClick={onSignInRequested}>
              <Typography className={classes.logInLink} component="span" variant="h4">
                {t("loginAction")}
              </Typography>
            </Link>
          </div>
        </form>
      </div>
    );
  };

export default SignUpForm;

import Adherences from "../Adherences";
import ReminderSchedule from "./ReminderSchedule";
import RegimenReportCode from "../RegimenReportCode";

interface ReminderAttributes {
  name: string;
  schedule: ReminderSchedule;
  adherence: Adherences;
  reportCode: RegimenReportCode;
}

export default class Reminder {
  public name: string;
  public schedule: ReminderSchedule;
  public adherence: Adherences;
  public reportCode: RegimenReportCode;

  constructor(plain: ReminderAttributes) {
    this.name = plain.name;
    this.schedule = plain.schedule;
    this.adherence = plain.adherence;
    this.reportCode = plain.reportCode;
  }

  public getFormattedSchedule(): string {
    return this.schedule.toText();
  }

  public toString(): string {
    return JSON.stringify(this);
  }
}

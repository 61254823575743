import Client from "../../main/services/ApiClient";
import DrugLeaflet from "../domain/DrugLeaflet";
import DrugLabels from "../domain/DrugLabels";
import DrugAssembler from "./DrugLeafletAssembler";
import DrugLeafletResponse from "./responses/DrugLeafletResponse";
import DrugLabelsAssembler from "./DrugLabelsAssembler";
import DrugLabelsResponse from "./responses/DrugLabelsResponse";

export const fetchDrugSheet = async (din: string, language: string): Promise<DrugLeaflet|null> => {
  try {
    const response = await new Client({
      endpoint: `/doctors/drugs/${din}/leaflet`,
      queryParams: { language },
    })
      .get<DrugLeafletResponse>();

    return DrugAssembler.fromResponse(response);
  } catch (e) {
    if (e.status === 404) {
      return null;
    }

    throw e;
  }
};

export const fetchDrugLabels = async (din: string, language: string): Promise<DrugLabels|null> => {
  try {
    const response = await new Client({
      endpoint: `/doctors/drugs/${din}/labels`,
      queryParams: { language },
    })
      .get<DrugLabelsResponse>();

    return DrugLabelsAssembler.fromResponse(response);
  } catch (e) {
    if (e.status === 404) {
      return null;
    }

    throw e;
  }
};

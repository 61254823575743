import Meal from "../../report/domain/measurements/bloodGlucoseMeasurement/Meal";
import PrescribeError from "../domain/PrescribeError";
import { Step } from "prescribe/PrescribePage";
import { StepRequirement } from "../components/StepListItem";
import TimeOfDay from "../../report/domain/measurements/bloodGlucoseMeasurement/TimeOfDay";

const prescribe = {
  en: {
    prescribe: {
      errors: {
        [PrescribeError.InvalidEmail]: "Please enter a valid email address",
        [PrescribeError.UnexpectedError]:
          "Something went wrong with the server. Please try again later.",
        [PrescribeError.InvalidBirthDate]: "Please enter a valid birth date",
      },
      step: {
        [Step.EMAIL]: "Profile",
        [Step.CARE_PLAN]: "Communication",
      },
      clear: "Clear",
      send: "Send",
      communicationLanguage: "Language",
      languages: {
        en: "English",
        fr: "French",
      },
      stepRequirements: {
        [StepRequirement.OPTIONAL]: "Optional",
        [StepRequirement.REQUIRED]: "Required",
      },
      createCarePlan: "Send to the patient via MedHelper",
      enterInstructionsHere: "Enter instructions here...",
      placeholderNotes: "Enter the notes to share with the patient.",
      prescriptionConfirmation: "Confirmation",
      wouldYouLikeToSend:
        "Would you like to send this Care Plan to <strong>{{email}}</strong> " +
        "to perform the below <nobr>activities:</nobr>",
      wouldYouLikeToSendNoInstructions:
        "Would you like to send this Care Plan to <strong>{{email}}</strong>?",
      medesyncConfirmation:
        "If you accessed MedHelper from Notes a copy of your Care Plan will " +
        "be added to your Note in Medesync.",
      instructionsSent: "Your instructions have been sent to {{email}}.",
      instructions: "Instructions",
      emailAddress: "Email address",
      lastName: "Last Name",
      firstName: "First Name",
      birthDate: "Date of Birth",
      allergies: "Allergies",
      gender: "Gender",
      selectGender: "Select gender",
      notSpecified: "Not specified",
      patientContactInformation: "Patient's Contact Information",
      customProgram: "Custom program",
      section: {
        title: {
          measurement: "Follow a health measure",
          activity: "Recommend an action",
          medication: "Monitor a medication",
          reminder: "Set-up a reminder",
        },
        subText: {
          measurement: "Request patient data",
          activity: "Suggest a custom activity",
          medication: "Choose from the RxVigilance database",
          reminder: "Schedule a one-time event",
        },
      },
      editCustomProgram: "Edit custom program",
      deleteCustomProgram: "Delete custom program",
      proximBloodGlucoseMonitoringProgram:
        "Proxim Blood Glucose Monitoring Program",
      instructionLabel: "instructionLabel",
      proximBloodGlucoseMonitoringProgramTitle:
        "Proxim Blood Glucose Monitoring Program",
      add: "Add",
      addDose: "+ Add a dose",
      update: "Update",
      back: "Back",
      timeOfDay: {
        [TimeOfDay.UPON_WAKING]: "Upon Waking",
        [TimeOfDay.BEFORE_MEAL]: "Before",
        [TimeOfDay.AFTER_MEAL]: "After",
        [TimeOfDay.AT_BEDTIME]: "At Bedtime",
      },
      meal: {
        [Meal.BREAKFAST]: "Breakfast",
        [Meal.LUNCH]: "Lunch",
        [Meal.DINNER]: "Dinner",
      },
      bloodGlucoseTargetRange: "Blood Glucose Target Range",
      tooltip: {
        medication: "The patient can see specific medications and record intake",
        customActivity: "Add any desired activity, the patient can record completion",
        measurement: "Request data from the patient, ex: blood pressure, mood diary, etc.",
      },
    },
  },
  fr: {
    prescribe: {
      errors: {
        [PrescribeError.InvalidEmail]:
          "Veuillez entrer une adresse de courriel valide",
        [PrescribeError.UnexpectedError]:
          "Quelque chose ne va pas avec le serveur. Veuillez réessayer plus tard.",
        [PrescribeError.InvalidBirthDate]:
          "Veuillez entrer une date de naissance valide",
      },
      step: {
        [Step.EMAIL]: "Profil",
        [Step.CARE_PLAN]: "Communication",
      },
      clear: "Effacer",
      send: "Envoyer",
      communicationLanguage: "Langue",
      languages: {
        en: "Anglais",
        fr: "Français",
      },
      stepRequirements: {
        [StepRequirement.OPTIONAL]: "Optionnel",
        [StepRequirement.REQUIRED]: "Requis",
      },
      createCarePlan: "Envoyer au patient via MedHelper",
      prescriptionConfirmation: "Confirmation",
      enterInstructionsHere: "Entrez les instructions ici...",
      placeholderNotes: "Entrez les notes que vous désirez partager avec le patient.",
      wouldYouLikeToSend:
        "Voulez-vous envoyer ce plan de soins à <strong>{{email}}</strong>" +
        " pour effectuer les activités <nobr>suivantes:</nobr>",
      wouldYouLikeToSendNoInstructions:
        "Voulez-vous envoyer ce plan de soins à <strong>{{email}}</strong>?",
      medesyncConfirmation:
        "Si vous avez accédé à MedHelper à partir de Notes, une copie de votre plan de soins " +
        "sera ajoutée à votre Note dans Medesync.",
      instructionsSent: "Vos instructions ont été envoyées à {{email}}.",
      instructions: "Instructions",
      emailAddress: "Adresse courriel",
      lastName: "Nom",
      firstName: "Prénom",
      birthDate: "Date de naissance",
      allergies: "Allergies",
      gender: "Genre",
      selectGender: "Sélectionner un genre",
      notSpecified: "Non spécifié",
      patientContactInformation: "Coordonnées du patient",
      customProgram: "Programme personnalisé",
      section: {
        title: {
          measurement: "Suivez un indice de Santé",
          activity: "Recommandez une action",
          medication: "Surveillez un médicament",
          reminder: "Planifiez un rappel",
        },
        subText: {
          measurement: "En requérant des données du patient",
          activity: "En suggérant une activité personnalisée",
          medication: "En choisissant dans la base de données de RxVigilance",
          reminder: "En programmant un événement ponctuel",
        },
      },
      editCustomProgram: "Modifier le programme personalisé",
      deleteCustomProgram: "Supprimer le programme personalisé",
      proximBloodGlucoseMonitoringProgram:
        "Proxim programme de suivi de la glycémie",
      instructionLabel: "instructionLabel",
      bloodGlucoseMonitoringProgramTitle:
        "Proxim programme de suivi de la glycémie",
      add: "Ajouter",
      addDose: "+ Ajouter une dose",
      update: "Modifier",
      back: "Retour",
      timeOfDay: {
        [TimeOfDay.UPON_WAKING]: "Au réveil",
        [TimeOfDay.BEFORE_MEAL]: "Avant",
        [TimeOfDay.AFTER_MEAL]: "Après",
        [TimeOfDay.AT_BEDTIME]: "Au coucher",
      },
      meal: {
        [Meal.BREAKFAST]: "Déjeuner",
        [Meal.LUNCH]: "Diner",
        [Meal.DINNER]: "Souper",
      },
      bloodGlucoseTargetRange: "Plage cible de glycémie",
      tooltip: {
        medication: "Le patient peut voir des médicaments spécifiques et enregistrer la prise",
        customActivity: "Ajoutez toute activité souhaitée, le patient peut enregistrer l'achèvement",
        measurement: "Demander des données au patient, ex: tension artérielle, journal de l'humeur, etc.",
      },
    },
  },
};

export default prescribe;

import { useEffect, useState } from "react";
import DateTime from "../../main/dateTime/DateTime";
import { fetchMedicationResults } from "../api/ReportResultsApi";
import MedicationResults from "report/domain/medications/MedicationResults";

export interface MedicationResultsResult {
  errors: boolean;
  results: MedicationResults | null;
}

export interface MedicationResultResultsProps {
  code: string;
  reportCode: string;
}

const useMedicationResults = ({
  reportCode,
  code,
}: MedicationResultResultsProps): MedicationResultsResult => {
  const [results, setResults] = useState<MedicationResults | null>(null);
  const [errors, setErrors] = useState<boolean>(false);

  useEffect(() => {
    const fetchMedication = async (): Promise<void> => {
      setErrors(false);
      const timeZone = DateTime.getCurrentTimezone();
      try {
        const medicationResults = await fetchMedicationResults(reportCode, code, timeZone);
        setResults(medicationResults);
      } catch (e) {
        setErrors(true);
      }
    };

    fetchMedication();
  }, [code, reportCode]);

  return { results, errors };

};

export default useMedicationResults;

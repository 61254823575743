import { CognitoUserAttribute } from "amazon-cognito-identity-js";

export default class CognitoUserAttributes {
  private collection: CognitoUserAttribute[];

  constructor(collection: CognitoUserAttribute[] = []) {
    this.collection = collection;
  }

  public getAttribute(key: string): string | undefined {
    return this.collection.find((attribute) => attribute.getName() === key)?.getValue();
  }
}

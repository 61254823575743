import { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core";
import React from "react";
import theme from "main/theme/theme";

interface Props {
  className?: string;
}

export const ModalBody = ({ children, className }: PropsWithChildren<Props>): JSX.Element => {
  const styles = useStyle();

  return (
    <div className={[styles.body, className].join(" ")}>
      {children}
    </div>
  );
};

const useStyle = makeStyles(() => ({
  body: {
    padding: `${theme.metrics.margin.medium} ${theme.metrics.margin.normal}`,
    margin: "0 2px",
    overflowY: "auto",
    maxHeight: "70vh",
    overflowX: "hidden",
    ...theme.scrollbar,
  },
}));

import theme from "main/theme/theme";
import { ChartDataset } from "chart.js";
import { lighten } from "@material-ui/core";

export const getDatasetStyle = (color = theme.colors.charts.primary): ChartDataset<"line"> => ({
  data: [],
  borderColor: color,
  backgroundColor: lighten(color, 0.4),
  pointRadius: 5,
  pointHoverRadius: 6,
});

export const getGuidelineStyle = (color = theme.colors.charts.primary): ChartDataset<"line"> => ({
  ...getDatasetStyle(color),
  showLine: true,
  pointRadius: 0,
  borderDash: [5, 10],
  borderWidth: 2,
});

import React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ChartDateRangeSelect } from "./ChartDateRangeSelect";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import ChartDateRange from "../domain/ChartDateRange";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  container: {
    width: "100%",
  },
  rightText: {
    display: "flex",
    marginLeft: "auto",
    justifyContent: "flex-end",
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
  },
}));
interface Props {
  name: string;
  value: ChartDateRange;
  onChartDateRangeChange: (value: ChartDateRange) => void;
}

export const ChartHeaderField: React.FunctionComponent<Props> = ({ name, value, onChartDateRangeChange }) => {
  const classes = useStyles();
  const { t } = useTranslation("report");

  return (
    <div className={classes.wrapper}>
      <Grid item xs={12} sm={8}>
        <Typography component="span" variant="h3" color="textPrimary" className={classes.title}>
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Typography component="span" color="textPrimary" className={classes.rightText}>
          {t("chartDateRange.label")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={2}>
        <ChartDateRangeSelect value={value} onChartDateRangeChange={onChartDateRangeChange} />
      </Grid>
    </div>
  );
};

const medication = {
  en: {
    medication: {
      addMedication: "Add a medication",
      editMedication: "Edit medication",
      save: "Save",
      medication_name: "Medication Name",
      medication_din: "DIN",
      enter_name: "Enter the medication name",
      enter_din: "Enter the DIN",
      noResults: "No results",
      details: "Details",
      inventory: "Inventory",
      alias: "Alias",
      asShownInNotifications: "As shown in notifications",
      quantityInHand: "Quantity in Hand",
      numberOfPills: "Number of pills",
      every: "Every",
      add: "Add",
      remove: "Remove",
      quantity: "Quantity",
      deleteMedication: "Delete medication",
      eventSummaryTimeFormat: "h:mm a",
    },
  },
  fr: {
    medication: {
      addMedication: "Ajouter un médicament",
      editMedication: "Modifier un médicament",
      save: "Sauvegarder",
      medication_name: "Nom du médicament",
      medication_din: "DIN",
      enter_din: "Entrez le DIN",
      enter_name: "Entrez le nom du médicament",
      noResults: "Aucun résultat",
      details: "Détails",
      inventory: "Inventaire",
      alias: "Nom  d'affichage",
      asShownInNotifications: "Figurant dans les rappels",
      quantityInHand: "Quantité en main",
      numberOfPills: "Nombre de pilule",
      every: "Chaque",
      add: "Ajouter",
      remove: "Retirer",
      quantity: "Quantité",
      deleteMedication: "Supprimer médicament",
      eventSummaryTimeFormat: "T",
    },
  },
};

export default medication;

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  CircularProgress, Grid, Theme,
} from "@material-ui/core";
import { useParams } from "react-router";
import theme from "../main/theme/theme";
import ReportHeader from "./components/ReportHeader";
import ReportFooter from "./components/ReportFooter";
import PatientInfo from "./components/PatientInfo";
import useMedicationResults from "./hooks/useMedicationResults";
import { MedicationResultsTable } from "./components/MedicationResultsTable";

const REPORT_MAX_WIDTH = 960;

const MedicationResultsPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const { code, reportCode } = useParams<{ code: string; reportCode: string }>();

  const { results: medication } = useMedicationResults({ reportCode, code });

  if (medication === null) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div>
          <ReportHeader />

          <Grid container className={classes.row}>
            <Grid item sm={12} md={6}>
              <PatientInfo patient={medication.patient} />
            </Grid>
          </Grid>

          <MedicationResultsTable medication={medication} name={medication.name} />
        </div>

        <ReportFooter />
      </div>
    </div>
  );
};

export default MedicationResultsPage;

const useStyles = makeStyles((muiTheme: Theme) => ({
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: `${theme.metrics.margin.medium} ${theme.metrics.margin.large}`,
    [muiTheme.breakpoints.down("sm")]: {
      padding: theme.metrics.margin.medium,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: REPORT_MAX_WIDTH,
    width: "100%",
    minHeight: "100vh",
  },
  loadingContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
  },
  row: {
    margin: `${theme.metrics.margin.medium} 0`,
  },
}));

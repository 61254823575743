import React from "react";
import {
  Authenticator,
  ConfirmSignIn,
  Loading,
  VerifyContact,
} from "aws-amplify-react";
import theme from "../../main/theme/theme";
import AmplifiedLoginForm from "./AmplifiedLoginForm";
import AmplifiedConfirmSignUp from "./AmplifiedConfirmSignUp";
import AmplifiedSignUpForm from "./AmplifiedSignUpForm";
import AmplifiedForgotPassword from "./AmplifiedForgotPassword";
import AmplifiedResetPassword from "./AmplifiedResetPassword";
import { AmplifiedPasswordExpired } from "./AmplifiedPasswordExpired";

const AmplifyAuthenticatorCustomTheme = {
  container: {
    height: "100%",
  },
  button: { backgroundColor: theme.colors.primary.normal },
  a: {
    color: theme.colors.primary.normal,
  },
};

const AmplifyAuthenticationContainer: React.FunctionComponent = () => (
  <Authenticator hideDefault theme={AmplifyAuthenticatorCustomTheme}>
    <AmplifiedLoginForm override="SignIn" />
    <AmplifiedConfirmSignUp override="ConfirmSignUp" />
    <ConfirmSignIn />
    <AmplifiedPasswordExpired override="RequireNewPassword" />
    <AmplifiedSignUpForm override="SignUp" />
    <VerifyContact />
    <AmplifiedForgotPassword override="ForgotPassword" />
    <AmplifiedResetPassword override="ResetPassword" />
    <Loading />
  </Authenticator>
);

export default AmplifyAuthenticationContainer;

import { Typography, makeStyles } from "@material-ui/core";

import React from "react";
import { TreatmentPlanRegimenSummary } from "../TreatmentPlanRegimenSummary";
import { carePlanTheme } from "../CarePlanSection";
import { useTranslation } from "react-i18next";
import ReminderFormData from "reminders/domain/ReminderFormData";
import theme from "main/theme/theme";

interface Props {
  isReadOnly: boolean;
  reminder: ReminderFormData;
  onDeleteClick: (reminder: ReminderFormData) => void;
  onEditClick: (reminder: ReminderFormData) => void;
}

const ReminderSummarySection = ({
  isReadOnly,
  reminder,
  onDeleteClick,
  onEditClick,
}: Props): JSX.Element => {

  const classes = useStyles();
  const { t } = useTranslation("reminder");

  return (
    <TreatmentPlanRegimenSummary
      isReadOnly={isReadOnly}
      instructions={reminder.instructions}
      schedule={reminder.schedule}
      onDeleteClick={(): void => onDeleteClick(reminder)}
      deleteTitle={t("deleteReminder")}
      editTitle={t("editReminder")}
      onEditClick={(): void => onEditClick(reminder)}
    >

      <div className={classes.spacer} />

      <div className={classes.detail}>
        <Typography
          component="h4"
          variant="h4"
          className={classes.name}
        >
          {reminder.details.name}
        </Typography>
      </div>

    </TreatmentPlanRegimenSummary>
  );
};

const useStyles = makeStyles(() => ({
  detail: {
    display: "flex",
    alignItems: "center",
    flex: 3,
  },
  name: {
    flex: 2,
    fontWeight: "bold",
    color: theme.colors.text.primary,
    wordBreak: "break-word",
  },
  spacer: {
    width: carePlanTheme.spacerWidth + "px",
  },
}));

export default ReminderSummarySection;

import { useState } from "react";
import UserSignUpFormData from "../domain/UserSignUpFormData";
import UserSignUpValidator from "../domain/UserSignUpValidator";
import AuthenticationService from "../../services/authentication";
import { UserSignUpFormErrors } from "../domain/UserSignUpFormErrors";
import DateTime from "../../../main/dateTime/DateTime";
import FailedToSignUpException from "../domain/exceptions/FailedToSignUpException";

interface Props {
  onSuccess: (signUp: UserSignUpFormData) => void;
}

export interface SignUpFormResult {
  errors: UserSignUpFormErrors;
  userSignUp: UserSignUpFormData;
  onSubmit: (agreementDate: DateTime) => Promise<void>;
  onChange: (userSignUp: UserSignUpFormData) => void;
}

export const useSignUpForm = ({ onSuccess }: Props): SignUpFormResult => {
  const [userSignUp, setUserSignUp] = useState<UserSignUpFormData>(UserSignUpFormData.empty());
  const [errors, setErrors] = useState<UserSignUpFormErrors>(UserSignUpFormErrors.empty());

  const onChange = (newUserSignUp: UserSignUpFormData): void => {
    setUserSignUp(newUserSignUp);
  };

  const onSubmit = async (agreementDate: DateTime): Promise<void> => {
    const validationErrors = UserSignUpValidator.validate(userSignUp);
    if (validationErrors.isEmpty()) {
      setErrors(validationErrors);
      return;
    }

    try {
      await AuthenticationService.signUp(userSignUp, agreementDate);
      setErrors(UserSignUpFormErrors.empty());
      onSuccess(userSignUp);
    } catch (error) {
      const authenticationErrorWithLink = (error as FailedToSignUpException).authenticationErrorWithLink;
      setErrors(UserSignUpFormErrors.empty().add(authenticationErrorWithLink.error));
    }
  };

  return { onChange, onSubmit, userSignUp, errors };
};

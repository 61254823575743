interface DrugLabelAttributes {
  priority: number;
  color: string;
  text: string;
}

class DrugLabel {
  public priority: number;
  public color: string;
  public text: string;

  constructor({ priority, color, text }: DrugLabelAttributes) {
    this.priority = priority;
    this.color = color;
    this.text = text;
  }
}

export default DrugLabel;

import ModalHeaderCarePlan from "../../main/modal/ModalHeaderCarePlan";
import Modal from "../../main/modal/Modal";
import React, {
  Ref, useImperativeHandle, useState,
} from "react";
import { useTranslation } from "react-i18next";
import CustomActivityFormData from "../domain/CustomActivityFormData";
import { CustomActivityForm } from "./forms/CustomActivityForm";
import { makeStyles } from "@material-ui/core";
import { ModalBody } from "main/modal/ModalBody";
import { pxToRem } from "main/theme/theme";
import analyticsClient, {
  AnalyticCategory, AnalyticEvent, AnalyticTreatment,
} from "../../main/services/analytics";

export interface CustomActivityModalRef {
  show: (customActivity?: CustomActivityFormData) => void;
  close: () => void;
}

interface Props {
  onCustomActivityAdd: (formData: CustomActivityFormData) => void;
  onEditCustomActivity: (customActivity: CustomActivityFormData) => void;
}

export const CustomActivityFormModal = React.forwardRef((
  { onCustomActivityAdd, onEditCustomActivity }: Props,
  ref: Ref<CustomActivityModalRef>
): JSX.Element => {
  const [open, setOpened] = useState(false);
  const classes = useStyle();
  const { t } = useTranslation("customActivity");
  const [customActivityToEdit, setCustomActivityToEdit] = useState<CustomActivityFormData | undefined>();

  const show = (customActivity?: CustomActivityFormData): void => {
    setCustomActivityToEdit(customActivity);
    setOpened(true);
  };

  const close = (): void => setOpened(false);

  useImperativeHandle(ref, () => ({
    show,
    close,
  }));

  const submitCustomActivity = (formData: CustomActivityFormData): void => {
    onCustomActivityAdd(formData);

    analyticsClient.logEvent(
      AnalyticCategory.CARE_PLAN,
      AnalyticEvent.ADD_CAREPLAN,
      { care_plan_items: AnalyticTreatment.ACTIVITY }
    );

    close();
  };

  const submitEditCustomActivity = (customActivity: CustomActivityFormData): void => {
    if (!customActivityToEdit) {
      return;
    }

    const newCustomActivity = { ...customActivity };
    onEditCustomActivity(newCustomActivity);
  };

  return (
    <Modal
      isOpen={open}
      onClose={close}
      disableOutsideClick
      className={classes.modal}
      labelledBy="customActivityFormModal"
    >
      <ModalHeaderCarePlan
        id="customActivityFormModal"
        onClose={close}
        title={customActivityToEdit ? t("editCustomActivity") : t("addCustomActivity")}
      />

      <ModalBody className={classes.body}>
        <CustomActivityForm
          customActivity={customActivityToEdit}
          submitLabel={customActivityToEdit
            ? t("save")
            : t("add")
          }
          onSubmit={customActivityToEdit ? submitEditCustomActivity : submitCustomActivity}
        />
      </ModalBody>
    </Modal>
  );
});

const useStyle = makeStyles(() => ({
  modal: {
    padding: 0,
  },
  body: {
    width: pxToRem(500),
  },
}));

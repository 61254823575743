import Medication from "../domain/medications/Medication";
import DrugTypes from "../domain/medications/DrugTypes";
import Reasons from "../domain/Reasons";
import MedicationSchedule from "../domain/medications/MedicationSchedule";
import { MedicationResponse } from "./responses/TreatmentResponse";
import AdherencesAssembler from "./AdherencesAssembler";
import DateTime from "main/dateTime/DateTime";
import ScheduleEventAssembler from "./ScheduleEventAssembler";

class TreatmentMedicationAssembler {
  public static toDomain(medication: MedicationResponse): Medication {
    return new Medication({
      name: medication.name,
      dosage: medication.dosage,
      type: DrugTypes[medication.type as keyof typeof DrugTypes],
      purpose: Reasons[medication.purpose as keyof typeof Reasons],
      din: medication.din,
      prescribingDoctor: medication.prescribingDoctor,
      schedule: medication.schedule && new MedicationSchedule({
        recurrenceRule: medication.schedule.recurrenceType.rule,
        dailyDosesCount: medication.schedule.dailyDosesCount,
        dailyPillsCount: medication.schedule.dailyPillsCount,
        events: ScheduleEventAssembler.fromResponses(medication.schedule.events),
      }),
      adherence: AdherencesAssembler.assembleToInternal(medication.adherence),
      reportCode: {
        code: medication.code.code,
        expiry: DateTime.fromISO(medication.code.expiry),
      },
    });
  }
}

export default TreatmentMedicationAssembler;

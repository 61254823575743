import React from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../theme/theme";
import classNames from "classnames";

const useStyle = makeStyles(() => ({
  button: {
    "textDecoration": "underline",
    "border": "none",
    "backgroundColor": "transparent",
    "alignSelf": "center",
    "color": theme.colors.primary.normal,
    "&:hover": {
      color: theme.colors.primary.light,
      cursor: "pointer",
    },
    "fontSize": theme.fonts.h5.size,
  },
}));

type Props = React.HTMLProps<HTMLButtonElement>;

export const TextButton: React.FC<Props> = (props) => {
  const classes = useStyle();

  return (
    <button
      {...props}
      type="button"
      className={classNames(classes.button, props.className)}
    />
  );
};

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Medication from "../domain/medications/Medication";
import theme, { pxToRem } from "../../main/theme/theme";
import useLanguageSelection from "../../main/hooks/useLanguageSelection";
import { TreatmentRow } from "./TreatmentRow";
import PastAdherence from "./adherence/PastAdherence";
import TreatmentMedicationPicture from "./TreatmentMedicationPicture";
import { generatePath, useParams } from "react-router";
import NavigationPath from "router/NavigationPath";
import analyticsClient, {
  AnalyticCategory, AnalyticEvent, AnalyticTreatment,
} from "../../main/services/analytics";

const useStyles = makeStyles(() => ({
  treatmentAttribute: {
    fontWeight: "bold",
  },
  emphasis: {
    fontWeight: 350,
  },
  value: {
    marginLeft: theme.metrics.margin.tiny,
  },
  openLeaflet: {
    "border": "none",
    "background": `linear-gradient(${theme.colors.primary.light},${theme.colors.primary.normal})`,
    "color": "white",
    "padding": theme.colors.button.primary.padding,
    "boxShadow": theme.colors.button.primary.boxShadow,
    "borderRadius": theme.colors.button.primary.borderRadius,
    "&:hover": {
      cursor: "pointer",
    },
    "&:active, &:focus": {
      boxShadow: `0 0 3pt 2pt ${theme.colors.primary.normal}`,
      outline: "none",
    },
  },
  openLeafletContainer: {
    marginTop: theme.metrics.margin.normal,
  },
  informationContainer: {
    justifyContent: "space-between",
    flexDirection: "column",
    display: "flex",
  },
  treatmentSectionContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    paddingLeft: pxToRem(36),
    paddingBottom: theme.metrics.margin.normal,
  },
}));

interface Props {
  expanded: boolean;
  toggleExpanded: () => void;
  medication: Medication;
  openDrugSheet: (medication: Medication) => void;
}

export const TreatmentMedication: React.FunctionComponent<Props> = ({
  medication,
  expanded,
  toggleExpanded,
  openDrugSheet,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("report");
  const { currentLanguage } = useLanguageSelection();
  const { code } = useParams<{ code: string }>();

  const viewResults = (): void => {
    analyticsClient.logEvent(
      AnalyticCategory.REPORT,
      AnalyticEvent.VIEW_RESULTS,
      { care_plan_items: AnalyticTreatment.MEDICATION, medication: medication.din }
    );

    const reportCode = medication.reportCode.code;

    window.open(generatePath(NavigationPath.PATIENT_REPORT_MEDICATIONS, { code, reportCode }));
  };

  return (
    <TreatmentRow
      title={medication.name}
      subtitle={`${medication.dosage} ${t([`drugTypes:${medication.type}`, ""])}`}
      schedule={medication.getFormattedSchedule(currentLanguage)}
      purpose={t([`reasons:${medication.purpose}`, "notReported"], { context: "female" })}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      onViewResult={viewResults}
      picture={<TreatmentMedicationPicture din={medication.din} />}
    >
      <Grid container>
        <Grid item xs={6} className={classes.treatmentSectionContainer}>
          <div>
            <div>
              <Typography
                component="span"
                variant="h5"
                className={classes.treatmentAttribute}
              >
                {t("din").toUpperCase()}:
              </Typography>

              <Typography
                component="span"
                variant="h5"
                className={[classes.emphasis, classes.value].join(" ")}
              >
                {medication.din || t("notAvailable")}
              </Typography>
            </div>

            <div>
              <Typography
                component="span"
                variant="h5"
                className={classes.treatmentAttribute}
              >
                {t("prescribingDoctor")}:
              </Typography>

              <Typography
                component="span"
                variant="h5"
                className={[classes.emphasis, classes.value].join(" ")}
              >
                {medication.prescribingDoctor || t("notReported", { context: "male" })}
              </Typography>
            </div>
          </div>

          {!!medication.din &&
            <div className={classes.openLeafletContainer}>
              <button
                className={classes.openLeaflet}
                type="button"
                onClick={(): void => openDrugSheet(medication)}
              >
                {t("medicationLeaflet").toUpperCase()}
              </button>
            </div>
          }
        </Grid>

        <Grid item xs={6}>
          <PastAdherence
            adherence={medication.adherence}
            type="medication"
          />
        </Grid>
      </Grid>
    </TreatmentRow>
  );
};

export default TreatmentMedication;

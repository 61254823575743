import AdherenceStatus from "../../domain/AdherenceStatus";
import CustomProgramResponse from "./CustomProgramResponse";
import TreatmentRegimenStatus from "report/domain/TreatmentRegimenStatus";

export enum AdherenceType {
  COUNT = "COUNT",
  PERCENTAGE = "PERCENTAGE",
}

interface MedicationScheduleResponse {
  recurrenceType: {
    rule: string;
  };
  dailyDosesCount: number;
  dailyPillsCount: number;
  events: PatientTreatmentScheduleEventResponse[];
}

interface OtherActivityScheduleResponse {
  recurrenceType: {
    rule: string;
  };
  dailySessions: number;
  events: PatientTreatmentScheduleEventResponse[];
}

interface ReminderScheduleResponse {
  recurrenceType: {
    rule: string;
  };
  dailySessions: number;
  events: PatientTreatmentScheduleEventResponse[];
}

export interface MeasurementScheduleResponse {
  recurrenceType: {
    rule: string;
  };
  duration: {
    startDate: string;
    endDate: string;
  };
  dailySessions: number;
  events: PatientTreatmentScheduleEventResponse[];
}

export interface PatientTreatmentScheduleEventResponse {
  amount: number;
  alarmIntervalInMinutes: number | null;
  eventDate: string;
}

interface AdherenceCalendarResponse {
  days: {
    date: string;
    status: keyof typeof AdherenceStatus;
  }[];
}

export interface AdherenceResponse {
  value: number | null;
  type: string;
}

export interface TreatmentAdherenceResponse {
  duration: string;
  adherence: AdherenceResponse;
}

export interface PatientTreatmentAdherenceResponse {
  adherences: TreatmentAdherenceResponse[];
  calendar: AdherenceCalendarResponse;
}

export interface RegimenReportCodeResponse {
  code: string;
  expiry: string;
}

export interface OtherActivityResponse {
  name: string;
  schedule: OtherActivityScheduleResponse;
  adherence: PatientTreatmentAdherenceResponse;
  code: RegimenReportCodeResponse;
}

export interface MedicationsResponse {
  status: string;
  results: MedicationResponse[];
}

export interface OtherActivitiesResponse {
  status: string;
  results: OtherActivityResponse[];
}

export interface MeasurementsResponse {
  status: string;
  results: SchedulableMeasurementResponse[];
}

export interface RemindersResponse {
  status: TreatmentRegimenStatus;
  results: PatientTreatmentReminderResponse[];
}

export interface MedicationResponse {
  name: string;
  dosage: string | undefined;
  type: string | undefined;
  purpose: string | undefined;
  din: string | undefined;
  prescribingDoctor: string | undefined;
  schedule: MedicationScheduleResponse | undefined;
  adherence: PatientTreatmentAdherenceResponse;
  code: RegimenReportCodeResponse;
}

export interface SchedulableMeasurementResponse {
  name: string;
  schedule: MeasurementScheduleResponse | null;
  customProgram: CustomProgramResponse | null;
  adherence: PatientTreatmentAdherenceResponse;
  code: RegimenReportCodeResponse;
}

export interface PatientTreatmentReminderResponse {
  name: string;
  schedule: ReminderScheduleResponse;
  adherence: PatientTreatmentAdherenceResponse;
  code: RegimenReportCodeResponse;
}

interface TreatmentResponse {
  medications: MedicationsResponse;
  activities: OtherActivitiesResponse;
  measurements: MeasurementsResponse;
  reminders: RemindersResponse;
}

export default TreatmentResponse;

export class DrugPicture {
  private picture: string;

  constructor({ picture }: { picture: string }) {
    this.picture = picture;
  }

  public asUri(): string {
    return `data:image/gif;base64,${this.picture}`;
  }
}

export default DrugPicture;

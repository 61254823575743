export interface DrugLeafletAttributes {
  description?: string;
  instructions?: string;
  sideEffects?: string;
  additionalInformation?: string;
  storageInformation?: string;
  generalInformation?: string;
}

const order: (keyof DrugLeafletAttributes)[] = [
  "description",
  "instructions",
  "sideEffects",
  "storageInformation",
  "additionalInformation",
  "generalInformation",
];

export default class DrugLeaflet {
  constructor(drug: DrugLeafletAttributes) {
    this.description = drug.description;
    this.instructions = drug.instructions;
    this.sideEffects = drug.sideEffects;
    this.additionalInformation = drug.additionalInformation;
    this.storageInformation = drug.storageInformation;
    this.generalInformation = drug.generalInformation;
  }

  public description?: string;
  public instructions?: string;
  public sideEffects?: string;
  public additionalInformation?: string;
  public storageInformation?: string;
  public generalInformation?: string;

  public getOrderedLeafletAttributes(): (keyof DrugLeafletAttributes)[] {
    return order;
  }
}

import React, { useImperativeHandle, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Modal from "../../main/modal/Modal";
import MedicationSummary, { createNewMedicationSummary, updateMedicationSummary } from "../domain/MedicationSummary";
import MedicationForm from "../MedicationForm";
import { useTranslation } from "react-i18next";
import MedicationFormData from "../domain/MedicationFormData";
import SearchDrug from "../../drugs/domain/SearchDrug";
import ModalHeaderCarePlan from "main/modal/ModalHeaderCarePlan";
import { ModalBody } from "main/modal/ModalBody";
import { pxToRem } from "main/theme/theme";
import analyticsClient, {
  AnalyticCategory, AnalyticEvent, AnalyticTreatment,
} from "../../main/services/analytics";

interface Props {
  onAddMedication: (medicationSummary: MedicationSummary) => void;
  onEditMedication: (updatedMedicationSummary: MedicationSummary) => void;
}

export interface MedicationFormModalRef {
  open: (medication?: MedicationSummary) => void;
  close: () => void;
}

const MedicationFormModal = React.forwardRef((
  { onAddMedication, onEditMedication }: Props,
  ref: React.Ref<MedicationFormModalRef>
) => {
  const classes = useStyle();
  const { t } = useTranslation("medication");
  const [opened, setOpened] = useState(false);
  const [medicationToEdit, setMedicationToEdit] = useState<MedicationSummary | undefined>(undefined);

  const open = (medication?: MedicationSummary): void => {
    setMedicationToEdit(medication);
    setOpened(true);
  };

  const close = (): void => setOpened(false);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  const submitNewMedicationSummary = (medication: MedicationFormData, drug: SearchDrug): void => {
    analyticsClient.logEvent(
      AnalyticCategory.CARE_PLAN,
      AnalyticEvent.ADD_CAREPLAN,
      { care_plan_items: AnalyticTreatment.MEDICATION, medication: drug.id }
    );

    const medicationSummary = createNewMedicationSummary(medication, drug);
    onAddMedication(medicationSummary);
  };

  const submitMedicationSummaryUpdate = (medication: MedicationFormData, drug: SearchDrug): void => {
    if (!medicationToEdit) {
      return;
    }

    const updatedMedicationSummary = updateMedicationSummary(medicationToEdit, medication, drug);
    onEditMedication(updatedMedicationSummary);
  };

  return (
    <Modal
      isOpen={opened}
      onClose={close}
      disableOutsideClick
      className={classes.modal}
      labelledBy="medication-form-modal"
    >
      <ModalHeaderCarePlan
        id="medication-form-modal"
        title={medicationToEdit
          ? t("editMedication")
          : t("addMedication")
        }
        onClose={close}
      />

      <ModalBody className={classes.body}>
        <MedicationForm
          submitLabel={medicationToEdit
            ? t("save")
            : t("add")
          }
          medicationSummary={medicationToEdit}
          onSubmit={medicationToEdit
            ? submitMedicationSummaryUpdate
            : submitNewMedicationSummary
          }
        />
      </ModalBody>
    </Modal>
  );
});

export default MedicationFormModal;

const useStyle = makeStyles(() => ({
  modal: {
    padding: 0,
  },
  body: {
    width: pxToRem(500),
  },
}));

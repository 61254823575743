import CodeFormErrors from "../../main/domain/CodeFormErrors";

const home = {
  en: {
    home: {
      welcome: "Welcome!",
      welcomeWithName: "Welcome, {{name}}!",
      loginRequired: "Please login",
      logout: "Sign out",
      enterPatientCode: "Enter Your Patient's Code",
      connectNow: "Connect Now",
      codeTooltip: "The patient should be prepared to provide a code from the MedHelper " +
        "application to share medication in realtime.",
      errors: {
        [CodeFormErrors.INVALID_CODE]: "This code is not valid.",
        [CodeFormErrors.INVALID_CHARACTER]: "The code must contain only alphanumerical characters.",
        [CodeFormErrors.EMPTY_CHARACTER]: "The code must contain exactly {{length}} characters.",
      },
    },
  },
  fr: {
    home: {
      welcome: "Bienvenue!",
      welcomeWithName: "Bienvenue, {{name}}!",
      loginRequired: "Veuillez vous connecter",
      logout: "Déconnexion",
      enterPatientCode: "Entrez le code du patient",
      connectNow: "Connectez maintenant",
      codeTooltip: "Le patient doit être prêt à fournir son code généré à partir de l’application " +
        "MédAide afin de partager ses médicaments en temps réel.",
      errors: {
        [CodeFormErrors.INVALID_CODE]: "Ce code est invalide.",
        [CodeFormErrors.INVALID_CHARACTER]: "Le code doit uniquement contenir des caractères alphanumériques.",
        [CodeFormErrors.EMPTY_CHARACTER]: "Le code doit avoir exactement {{length}} caractères.",
      },
    },
  },
};

export default home;

import { v4 as uuid } from "uuid";
import MedicationSummary from "../../medication/domain/MedicationSummary";
import CustomProgramFormData from "./CustomProgramFormData";
import { MeasurementFormData } from "../../measurement/domain/MeasurementFormData";
import CustomActivityFormData from "../../customActivities/domain/CustomActivityFormData";
import { MeasurementDetail } from "../../measurement/domain/MeasurementDetail";
import ReminderFormData from "reminders/domain/ReminderFormData";

interface PendingTreatmentPlan {
  pendingCustomPrograms: { list: CustomProgramFormData[] };
  medications: { list: MedicationSummary[] };
  measurements: { list: MeasurementFormData<MeasurementDetail>[] };
  customActivities: { list: CustomActivityFormData[] };
  reminders: { list: ReminderFormData[] };
  localId: string;
}

export const createEmpty = (): PendingTreatmentPlan => ({
  pendingCustomPrograms: {
    list: [],
  },
  medications: {
    list: [],
  },
  measurements: {
    list: [],
  },
  customActivities: {
    list: [],
  },
  reminders: {
    list: [],
  },
  localId: uuid(),
});

export default PendingTreatmentPlan;

import { Select, Theme } from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import HealthProfession from "../domain/HealthProfession";
import InputLabel from "@material-ui/core/InputLabel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../../main/theme/theme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((muiTheme: Theme) => ({
  container: {
    width: "100%",
    margin: muiTheme.spacing(1),
  },
  icon: {
    marginRight: theme.metrics.margin.small,
    top: "auto",
  },
}));

interface Props {
  onChange: (profession: HealthProfession) => void;
  profession: HealthProfession | undefined;
  className?: string;
  label: string;
  error?: string;
}

const ProfessionPicker: React.FunctionComponent<Props> =
  ({ className, label, error, profession: selectedProfession, onChange }) => {
    const classes = useStyles();
    const { t } = useTranslation("profile");

    const onProfessionChange = (event: React.ChangeEvent<{ value: unknown }>): void =>
      onChange(HealthProfession[event.target.value as keyof typeof HealthProfession]);

    return (
      <FormControl variant="outlined" className={[classes.container, className].join(" ")} error={!!error}>
        <InputLabel id="profession-picker-label">{label}</InputLabel>
        <Select
          labelId="profession-picker-label"
          value={selectedProfession || ""}
          label={label}
          role="button"
          onChange={onProfessionChange}
          IconComponent={({ className: iconClassName, ...otherProps }): JSX.Element => (
            <KeyboardArrowDownIcon
              {...otherProps}
              className={classNames(iconClassName, classes.icon)}
              fontSize="large"
            />
          )}
        >
          {Object.values(HealthProfession).map((profession) =>
            (
              <MenuItem
                value={profession}
                key={profession}>
                {t(`professions.${profession}`)}
              </MenuItem>
            )
          )}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    );
  };

export default ProfessionPicker;

import { AuthenticationErrorWithLink } from "./AuthenticationErrorWithLink";
import AuthenticationError from "./AuthenticationError";

export const MAIL_TO_SUPPORT_MEDHELPER = "mailto:support@medhelper.com";

export class AuthenticationErrorWithLinkFactory {
  public static createFrom(error: { code: string; message?: string }): AuthenticationErrorWithLink {
    if (error.message === "User is disabled.") {
      return {
        error: AuthenticationError.UserIsDisabledException,
        link: MAIL_TO_SUPPORT_MEDHELPER,
      };
    }

    if (error.message === "PreAuthentication failed with error LoginAttemptsUserDisabled.") {
      return {
        error: AuthenticationError.LoginAttemptsUserDisabled,
        link: MAIL_TO_SUPPORT_MEDHELPER,
      };
    }

    return {
      error: AuthenticationError[error.code as keyof typeof AuthenticationError] ||
            AuthenticationError.AuthenticationDefault,
    };

  }
}

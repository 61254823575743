import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { pxToRem } from "../../../../main/theme/theme";

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 600,
  },
  days: {
    "whiteSpace": "nowrap",
    "height": pxToRem(20),
    "backgroundColor": "white",
    "borderRadius": pxToRem(10),
    "padding": `0 ${pxToRem(15)}`,
    "boxShadow": `0 ${pxToRem(1)} ${pxToRem(10)} ${pxToRem(1)} rgba(0,0,0,0.3)`,
    "&::before": {
      width: 0,
      border: `${pxToRem(5)} solid transparent`,
      borderBottomColor: "white",
      content: "\"\"",
      height: 0,
      top: `-${pxToRem(10)}`,
      left: "50%",
      transform: "translate(-50%,0)",
      position: "absolute",
    },
  },
}));

interface Props {
  numberOfDays: number;
  className?: string;
}

export const DaysTooltip: React.FunctionComponent<Props> = ({ numberOfDays, className }) => {
  const { t } = useTranslation("report");
  const classes = useStyles();

  return (
    <div className={[classes.days, className].join(" ")}>
      <Typography className={classes.bold} component="b">
        {t("previousDay", { count: numberOfDays })}
      </Typography>
    </div>
  );
};

export default DaysTooltip;

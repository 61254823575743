import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import DrugSheetTitle from "./DrugSheetTitle";

export const DrugSheetNotFound: React.FunctionComponent = () => {
  const { t } = useTranslation("report");
  return (
    <div>
      <DrugSheetTitle>
        {t("medicationDetailSubtitles.description")}
      </DrugSheetTitle>

      <Typography>
        {t("descriptionNotAvailableMessage")}
      </Typography>
    </div>
  );
};

export default DrugSheetNotFound;

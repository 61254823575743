import TemporarySessionContextDocumentDto from "authentication/clientConnect/api/response/TemporarySessionContextDocumentDto";
import { ConsultationDocumentDto } from "./ConsultationDocumentDto";

export default class ConsultationDocumentAssembler {
  public static fromTemporaryDocuments(
    temporaryDocuments: TemporarySessionContextDocumentDto[]
  ): ConsultationDocumentDto[] {
    return temporaryDocuments;
  }
}

import ScheduleFormData, { assembleScheduleFormData } from "../../schedule/domain/ScheduleFormData";
import MeasureFormData from "./MeasureFormData";
import PendingRegimenFormData from "./PendingRegimenFormData";
import { ProposedSchedulableRegimenResponse } from "consultationSummary/api/response/ProposedSchedulableRegimenResponse";
import { MeasurementTreatmentPlanResponse } from "consultationSummary/api/response/MeasurementTreatmentPlanResponse";
import PendingSchedulableMeasurementFormData from "./PendingSchedulableMeasurementFormData";

export default interface SchedulableRegimenFormData {
  regimen: PendingRegimenFormData;
  schedule: ScheduleFormData<MeasureFormData>;
};

export const assembleSchedulableRegimenFormData = (
  customProgram: ProposedSchedulableRegimenResponse<MeasurementTreatmentPlanResponse>
): SchedulableRegimenFormData => ({
  regimen: new PendingSchedulableMeasurementFormData(customProgram.regimen.measurement.name),
  schedule: assembleScheduleFormData(customProgram, MeasureFormData.fromHistoricalEvents),
});

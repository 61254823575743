import { useState } from "react";

export interface UseConfirmationResult {
  isOpen: boolean;
  openConfirmation: (e: React.FormEvent<HTMLFormElement>) => void;
  closeConfirmation: () => void;
}

const useConfirmation = (): UseConfirmationResult => {
  const [isOpen, setOpen] = useState(false);

  const openConfirmation = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setOpen(true);
  };

  const closeConfirmation = (): void => {
    setOpen(false);
  };

  return {
    isOpen,
    openConfirmation,
    closeConfirmation,
  };
};

export default useConfirmation;

import { useCallback, useState } from "react";
import AuthenticationService from "../../services/authentication/";
import { AuthenticationErrorWithLink } from "../../domain/AuthenticationErrorWithLink";
import FailedToResetPasswordException from "../domain/FailedToResetPasswordException";

export interface PasswordResetResult {
  requestPasswordReset: (email: string) => void;
  errors: AuthenticationErrorWithLink[];
}

export interface Props {
  onSuccess: () => void;
}

const useRequestPasswordResetForm = ({ onSuccess }: Props): PasswordResetResult => {
  const [errors, setErrors] = useState<AuthenticationErrorWithLink[]>([]);

  const requestPasswordReset = useCallback(async (email: string): Promise<void> => {
    try {
      await AuthenticationService.requestPasswordReset(email);
      setErrors([]);
      onSuccess();
    } catch (error) {
      setErrors([(error as FailedToResetPasswordException).authenticationErrorWithLink]);
    }
  }, [onSuccess]);

  return { errors, requestPasswordReset };
};

export default useRequestPasswordResetForm;

import React from "react";
import {
  Link, makeStyles, Typography,
} from "@material-ui/core";
import theme from "../../main/theme/theme";
import { AuthenticationErrorWithLink } from "../domain/AuthenticationErrorWithLink";
import { useTranslation, Trans } from "react-i18next";

interface Props {
  errors: AuthenticationErrorWithLink[];
}

const useStyles = makeStyles(() => ({
  errors: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.metrics.margin.normal,
  },
}));

export const AuthenticationFormErrorsWithLink: React.FunctionComponent<Props> = ({ errors }) => {
  const classes = useStyles();
  const { t } = useTranslation("authentication");

  return (
    <div className={classes.errors}>
      {errors.map(({ error, link }) => (
        <Typography key={error} component="span" color="error">
          <Trans
            t={t}
            i18nKey={`authenticationErrors:${error}`}
            color="error"
            components={link ?
              [<Link key="0" href={link} />]
              : []}
          />
        </Typography>
      ))}
    </div>
  );
};

import { useCallback } from "react";
import * as UserActions from "../UserActions";
import useGlobalState from "../../../store/hooks/useGlobalState";
import {
  isAuthenticated, authenticationErrors, getUser,
} from "../selectors/UserSelectors";
import LogInFormErrors from "../../login/domain/LogInFormErrors";
import User from "../../../main/domain/User";

export interface UseUserRepositoryResult {
  setUser: (user: User|undefined) => void;
  setErrors: (errors: LogInFormErrors) => void;
  clearUserStore: () => void;
  authenticated: boolean;
  errors: LogInFormErrors;
  user: User | undefined;
}

const useUserRepository = (): UseUserRepositoryResult => {
  const { dispatch, state } = useGlobalState();
  const user = getUser(state);
  const authenticated = isAuthenticated(state);
  const errors = authenticationErrors(state);

  const setUser = useCallback((newUser: User|undefined): void => {
    UserActions.setUser(dispatch, newUser);
  }, [dispatch]);

  const clearUserStore = useCallback((): void => {
    UserActions.clearUserStore(dispatch);
  }, [dispatch]);

  const setErrors = useCallback((newErrors: LogInFormErrors): void => {
    UserActions.setErrors(dispatch, newErrors);
  }, [dispatch]);

  return {
    setUser,
    setErrors,
    clearUserStore,
    authenticated,
    errors,
    user,
  };
};

export default useUserRepository;

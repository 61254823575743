import isPasswordValid from "../../services/validators/passwordValidator";
import ChangePasswordFormData from "./ChangePasswordFormData";
import ChangePasswordFormErrors from "./ChangePasswordFormErrors";
import ChangePasswordError from "./ChangePasswordError";

class ChangePasswordFormValidator {
  public static validate(changePasswordFormData: ChangePasswordFormData): ChangePasswordFormErrors {
    let errors = ChangePasswordFormErrors.empty();

    if (!changePasswordFormData.currentPassword) {
      errors = new ChangePasswordFormErrors({
        ...errors,
        currentPassword: [...errors.currentPassword, ChangePasswordError.CurrentPasswordEmpty],
      });
    }

    if (!isPasswordValid(changePasswordFormData.newPassword)) {
      errors = new ChangePasswordFormErrors({
        ...errors,
        newPassword: [...errors.newPassword, ChangePasswordError.InvalidNewPassword],
      });
    }

    return errors;
  }
}

export default ChangePasswordFormValidator;

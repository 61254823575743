import React, { PropsWithChildren } from "react";
import {
  makeStyles, Paper, Table, TableContainer,
} from "@material-ui/core";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  table: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    marginTop: theme.metrics.margin.medium,
  },
}));

export const ReportResultsTable = ({ children }: PropsWithChildren<any>): JSX.Element => {
  const styles = useStyles();
  return (
    <TableContainer component={Paper} className={styles.table}>
      <Table stickyHeader>
        {children}
      </Table>
    </TableContainer>
  );
};

import { AuthenticationErrorWithLink } from "../../domain/AuthenticationErrorWithLink";

export class FailedToResendConfirmationCodeException extends Error {

  constructor(
    public readonly authenticationErrorWithLink: AuthenticationErrorWithLink,
  ) {
    super(`Failed to resend confirmation code : ${authenticationErrorWithLink.error}`);
    Object.setPrototypeOf(this, FailedToResendConfirmationCodeException.prototype);
  }
}

const consultationSummary = {
  en: {
    consultationSummary: {
      carePlan: "Care Plan",
      additionalInfo: "Additional Information",
      documents: "Documents",
      loading: "Loading care plan",
      noCarePlan: "No items sent",
      noInstructions: "No Instructions sent",
      noDocuments: "No documents sent",
    },
  },
  fr: {
    consultationSummary: {
      carePlan: "Plan de soin",
      additionalInfo: "Informations supplémentaires",
      documents: "Documents",
      loading: "Chargement du plan de soin",
      noCarePlan: "Aucun élément envoyé",
      noInstructions: "Aucune instruction envoyée",
      noDocuments: "Aucun document envoyé",
    },
  },
};

export default consultationSummary;

import isPasswordValid from "../../services/validators/passwordValidator";
import AuthenticationError from "../../domain/AuthenticationError";
import PasswordResetFormData from "./ResetPasswordFormData";
import PasswordResetFormErrors from "./PasswordResetFormErrors";

class PasswordResetFormValidator {

  public static validate(form: PasswordResetFormData): PasswordResetFormErrors {
    let errors: PasswordResetFormErrors = PasswordResetFormErrors.empty();

    if (!isPasswordValid(form.newPassword)) {
      errors = new PasswordResetFormErrors({
        ...errors,
        password: [...errors.password, AuthenticationError.InvalidPassword],
      });
    }

    return errors;
  }
}

export default PasswordResetFormValidator;

import { Button, makeStyles } from "@material-ui/core";
import Modal from "../../main/modal/Modal";
import ModalHeader from "../../main/modal/ModalHeader";
import theme, { pxToRem } from "../../main/theme/theme";
import React, {
  useImperativeHandle, useRef, useState,
} from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: pxToRem(400),
  },
  actions: {
    width: "100%",
    margin: `${theme.metrics.margin.large} 0 ${theme.metrics.margin.medium}`,
    justifyContent: "space-around",
    display: "flex",
  },
  errorButton: theme.colors.button.error,
}));

export interface DeletionModalRef {
  open: (onConfirm: () => void) => void;
  close: () => void;
}

const DeletionModal = React.forwardRef((
  _,
  ref: React.Ref<DeletionModalRef>,
) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [opened, setOpened] = useState(false);
  const confirm = useRef<(() => void) | null>(null);

  const open = (onConfirm: () => void): void => {
    confirm.current = onConfirm;
    setOpened(true);
  };

  const close = (): void => {
    setOpened(false);
  };

  const confirmDeletion = (): void => {
    if (!!confirm.current) {
      confirm.current();
      close();
    }
  };

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Modal
      className={classes.modal}
      labelledBy="deletionModal"
      isOpen={opened}
      onClose={close}
    >
      <ModalHeader
        id="deletionModal"
        onClose={close}
        title={t("areYouSureYouWantToDeleteItem")}
      />

      <div className={classes.actions}>
        <Button
          aria-label={t("delete")}
          type="button"
          className={classes.errorButton}
          variant="contained"
          onClick={confirmDeletion}
        >
          {t("delete")}
        </Button>

        <Button
          aria-label={t("cancel")}
          type="button"
          variant="contained"
          onClick={close}
          color="secondary"
        >
          {t("cancel")}
        </Button>
      </div>
    </Modal>
  );
});

export default DeletionModal;

import React from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  fieldLabel: {
    fontWeight: "bold",
  },
}));

interface Props {
  label: string;
  value: string;
  className?: string;
}

const AuthenticationReadOnlyField: React.FunctionComponent<Props> = ({ label, value, className }) => {
  const classes = useStyles();

  return (
    <div className={[classes.container, className].join(" ")}>
      <Typography component="div" variant="h5" color="textPrimary" className={classes.fieldLabel}>
        {label}
      </Typography>

      <Typography component="div" variant="h5" color="textPrimary">
        {value}
      </Typography>
    </div>
  );
};

export default AuthenticationReadOnlyField;

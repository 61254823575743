import React, { ChangeEvent } from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PrescribeError from "../domain/PrescribeError";
import DateTime from "../../main/dateTime/DateTime";
import { PatientProfileField } from "./PatientProfileField";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textField: {
    margin: 0,
  },
}));

interface Props {
  name: string;
  errors?: PrescribeError[];
  onChange?: (value: string) => void;
  id: string;
  value?: DateTime;
}

export const PatientProfileDatePicker: React.FunctionComponent<Props> = ({ name, errors, id, onChange, value }) => {
  const { t } = useTranslation("prescribe");
  const classes = useStyles();
  const change = (event: ChangeEvent<HTMLInputElement>): void => onChange?.(event.target.value);

  return (
    <PatientProfileField name={name} htmlFor={id}>
      <TextField
        className={classes.textField}
        id={id}
        type="date"
        variant="outlined"
        name={id}
        fullWidth
        inputProps={{
          max: DateTime.now().toISODate(),
        }}
        onChange={change}
        error={!!errors && errors.length > 0}
        helperText={!!errors && errors.length > 0 ? t(`errors.${errors[0]}`) : null}
        disabled={!onChange}
        value={value?.toISODate()|| ""}
      />
    </PatientProfileField>
  );
};

export default PatientProfileDatePicker;

import { useEffect, useState } from "react";
import DrugPicture from "../../report/domain/DrugPicture";
import DrugPictureApi from "../../report/api/DrugPictureApi";

interface Props {
  din: string | undefined;
  maxWidth: number;
}

export interface UseDrugPictureResult {
  loading: boolean;
  error: boolean;
  picture: DrugPicture | undefined;
}

const useDrugPicture = ({ din, maxWidth }: Props): UseDrugPictureResult => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState<DrugPicture | undefined>();

  useEffect(() => {
    const fetchPicture = async (): Promise<void> => {
      if (!din) {
        return;
      }

      setLoading(true);
      setError(false);

      try {
        setPicture(await DrugPictureApi.fetchPicture(din, maxWidth));
      } catch {
        setError(true);
      }

      setLoading(false);
    };

    fetchPicture();
  }, [din, maxWidth]);

  return { picture, error, loading };
};

export default useDrugPicture;

import { ConsultationDocumentErrors } from "../internationalization/ConsultationDocumentErrors";
import i18next from "i18next";
import { IdentifierGenerator } from "../../main/services/IdentifierGenerator";

export const ACCEPTED_FILE_EXTENSION = [".jpg", ".jpeg", ".gif", ".png", ".pdf"];
const MAX_FILE_SIZE = 20000000;

export const MAX_FILE_SIZE_MB = MAX_FILE_SIZE / 1000000;

export const MAX_NAME_LENGTH = 255;

export class ConsultationDocument {
  public readonly localId: string;
  public readonly name: string;
  public readonly size: number;
  private readonly file: File;
  public readonly errors: string[];

  constructor(file: File) {
    this.localId = IdentifierGenerator.generate();
    this.name = file.name;
    this.size = file.size;
    this.file = file;
    this.errors = this.validate();
  }

  public async readFile(): Promise<ArrayBuffer> {
    return await this.file.arrayBuffer();
  }

  public isValid(): boolean {
    return this.errors.length === 0;
  }

  public describeErrors(): string[] {
    return this.errors.reduce<string[]>((errors, acc) =>
      [...errors, i18next.t(`consultationDocument:errors:${acc}`)], []);
  }

  private validate(): string[] {
    let errors: string[] = [];
    if (!this.hasValidExtension()) {
      errors = [...errors, ConsultationDocumentErrors.InvalidExtension];
    }

    if (!this.hasValidSize()) {
      errors = [...errors, ConsultationDocumentErrors.InvalidSize];
    }

    if (!this.hasValidNameLength()) {
      errors = [...errors, ConsultationDocumentErrors.InvalidNameLength];
    }

    return errors;
  }

  private hasValidExtension(): boolean {
    return ACCEPTED_FILE_EXTENSION.includes(this.getExtension());
  }

  private hasValidSize(): boolean {
    return this.size <= MAX_FILE_SIZE;
  }

  private hasValidNameLength(): boolean {
    return this.name.length <= MAX_NAME_LENGTH;
  }

  private getExtension(): string {
    return "." + this.name.split(".").pop();
  }
}

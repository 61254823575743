import { useFormData } from "@nexapp/nexapp-react-forms";
import { canSubmitReminder } from "../domain/DetailFormData";
import ReminderFormData, { createEmpty as createEmptyFormData } from "reminders/domain/ReminderFormData";

export interface UseReminderFormResult {
  formData: ReminderFormData;
  onChange: <T extends keyof ReminderFormData>(
    section: T
  ) => (
    field: keyof ReminderFormData[T]
  ) => (value: ReminderFormData[T][typeof field]) => void;
  hasChanged: boolean;
  canSubmit: boolean;
}

export const useReminderForm = (reminder?: ReminderFormData): UseReminderFormResult => {
  const { formData, onChange, hasChanged } = useFormData({
    initialData: reminder || createEmptyFormData(),
  });

  return {
    formData,
    onChange,
    hasChanged,
    canSubmit: canSubmitReminder(formData.details),
  };
};

export default useReminderForm;

import Modal from "../../main/modal/Modal";
import React, {
  Ref, useImperativeHandle, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { initializeMeasurementFormData, MeasurementFormData } from "../domain/MeasurementFormData";
import { MeasurementForm } from "./forms/MeasurementForm";
import { MeasurementDetail } from "../domain/MeasurementDetail";
import { CustomMeasurementDetail } from "../domain/CustomMeasurementDetail";
import { MeasurementTypeSelector } from "./selector/MeasurementTypeSelector";
import ModalHeaderCarePlan from "main/modal/ModalHeaderCarePlan";
import { makeStyles } from "@material-ui/core/styles";
import { ModalBody } from "main/modal/ModalBody";
import { pxToRem } from "main/theme/theme";
import { MeasurementType } from "measurement/domain/MeasurementType";
import { BaseMeasurementDetail } from "measurement/domain/BaseMeasurementDetail";
import analyticsClient, {
  AnalyticCategory, AnalyticEvent, AnalyticTreatment,
} from "../../main/services/analytics";

export interface MeasurementFormModalRef {
  add: () => void;
  edit: (measurement: MeasurementFormData<MeasurementDetail>) => void;
}

interface Props {
  onMeasurementAdd: (formData: MeasurementFormData<MeasurementDetail>) => void;
  onMeasurementEdit: (formData: MeasurementFormData<MeasurementDetail>) => void;
}

enum ModalState {
  "ADD",
  "EDIT",
}

export const MeasurementFormModal = React.forwardRef((
  { onMeasurementAdd, onMeasurementEdit }: Props,
  ref: Ref<MeasurementFormModalRef>
): JSX.Element => {
  const styles = useStyle();
  const { t } = useTranslation("measurement");
  const [measurement, setMeasurement] = useState<MeasurementFormData<MeasurementDetail>>();
  const [modalState, setModalState] = useState<ModalState>();
  const [visible, setVisible] = useState(false);

  const close = (): void => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    add: (): void => {
      setMeasurement(undefined);
      setModalState(ModalState.ADD);
      setVisible(true);
    },
    edit: (measurementToEdit: MeasurementFormData<MeasurementDetail>): void => {
      setMeasurement(measurementToEdit);
      setModalState(ModalState.EDIT);
      setVisible(true);
    },
  }));

  const onMeasureSelected = (type: MeasurementType): void => {
    const details = type === MeasurementType.CUSTOM
      ? new CustomMeasurementDetail()
      : new BaseMeasurementDetail(type);

    const instructions = t(["instruction." + type, ""]);

    setMeasurement(initializeMeasurementFormData(details, instructions));
  };

  const submitAddMeasurement = (formData: MeasurementFormData<MeasurementDetail>): void => {
    onMeasurementAdd(formData);

    analyticsClient.logEvent(
      AnalyticCategory.CARE_PLAN,
      AnalyticEvent.ADD_CAREPLAN,
      { care_plan_items: AnalyticTreatment.MEASUREMENT, measurement: measurement?.details.measurementDetail.type }
    );

    close();
  };

  const submitEditedMeasurement = (updatedMeasurement: MeasurementFormData<MeasurementDetail>): void => {
    onMeasurementEdit(updatedMeasurement);
    close();
  };

  return (
    <Modal
      isOpen={visible}
      onClose={close}
      disableOutsideClick
      labelledBy="measurementFormModal"
      className={styles.modal}
    >
      <ModalHeaderCarePlan
        id="measurementFormModal"
        onClose={close}
        title={modalState === ModalState.EDIT ? t("editMeasurement") : t("addMeasurement")}
      />

      <ModalBody className={styles.body}>
        {
          measurement
            ? <MeasurementForm
              measurement={measurement}
              submitLabel={modalState === ModalState.EDIT ? t("save") : t("add")}
              onSubmit={modalState === ModalState.EDIT ? submitEditedMeasurement : submitAddMeasurement}
            />
            : <MeasurementTypeSelector onSelect={onMeasureSelected} />
        }
      </ModalBody>
    </Modal>
  );
});

const useStyle = makeStyles(() => ({
  modal: {
    padding: 0,
  },
  body: {
    width: pxToRem(500),
  },
}));

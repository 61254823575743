import ScheduleFormData from "../../schedule/domain/ScheduleFormData";
import SessionFormData from "../../prescribe/domain/SessionFormData";
import Frequency from "../../schedule/domain/Frequency";
import ScheduleType from "../../schedule/domain/ScheduleType";
import Weekday, { getCurrentWeekday } from "../../schedule/domain/Weekday";
import DateTime from "../../main/dateTime/DateTime";

interface UseScheduleForm<T extends SessionFormData> {
  toggle: () => void;
  active: boolean;
  addNewEvent: () => void;
  updateEvent: (updatedEvent: T) => void;
  deleteEvent: (eventToDelete: T) => void;
  changeFrequency: (frequency: Frequency) => void;
  changeInterval: (interval: number | null) => void;
  changeWeekdays: (weekdays: Weekday[]) => void;
  changeMonthDays: (monthDays: number[]) => void;
}
type OnChange<M extends SessionFormData> = <T extends keyof ScheduleFormData<M>>(
  field: T,
  value: ScheduleFormData<M>[T]
) => void;

export const useScheduleForm = <T extends SessionFormData>(
  schedule: ScheduleFormData<T>,
  onChange: OnChange<T>,
  createEmptyEvent: () => T
): UseScheduleForm<T> => {
  const isPunctual = schedule.frequency === Frequency.PUNCTUAL;

  const initializeRegularSchedule = (): void => {
    onChange("events", [createEmptyEvent()]);
    onChange("frequency", Frequency.DAILY);
    onChange("type", ScheduleType.REGULAR);
    onChange("interval", 1);
  };

  const resetToAsNeededSchedule = (): void => {
    onChange("frequency", Frequency.PUNCTUAL);
    onChange("type", ScheduleType.AS_NEEDED);
    onChange("events", []);
    onChange("interval", null);
    onChange("weekdays", []);
    onChange("monthDays", []);
  };

  const toggle = (): void => {
    if (isPunctual) {
      initializeRegularSchedule();
    } else {
      resetToAsNeededSchedule();
    }
  };

  const addNewEvent = (): void => {
    const updatedEvents = [...schedule.events, createEmptyEvent()];
    onChange("events", updatedEvents);
  };

  const updateEvent = (updatedEvent: T): void => {
    const updatedEvents = schedule.events.map((event: T) => event.id === updatedEvent.id ? updatedEvent : event);
    onChange("events", updatedEvents);
  };

  const deleteEvent = (eventToDelete: T): void => {
    const updatedEvents = schedule.events.filter((event: T) => event.id !== eventToDelete.id);
    onChange("events", updatedEvents);
  };

  const changeFrequency = (frequency: Frequency): void => {
    onChange("frequency", frequency);

    switch (frequency) {
      case Frequency.DAILY: {
        onChange("weekdays", []);
        onChange("monthDays", []);
        break;
      }
      case Frequency.WEEKLY: {
        onChange("weekdays", [getCurrentWeekday()]);
        onChange("monthDays", []);
        break;
      }
      case Frequency.MONTHLY: {
        onChange("monthDays", [DateTime.now().getDayOfMonth()]);
        onChange("weekdays", []);
        break;
      }

      default:
        return;
    }
  };

  const changeInterval = (interval: number | null): void => {
    onChange("interval", interval);
  };

  const changeWeekdays = (weekdays: Weekday[]): void => {
    onChange("weekdays", weekdays);
  };

  const changeMonthDays = (monthDays: number[]): void => {
    if (monthDays.length === 0) {
      return;
    }

    onChange("monthDays", monthDays);
  };

  return {
    toggle,
    active: !isPunctual,
    addNewEvent,
    updateEvent,
    deleteEvent,
    changeFrequency,
    changeInterval,
    changeWeekdays,
    changeMonthDays,
  };
};

import SupportedLanguages from "main/domain/SupportedLanguages";
import ScheduleEvent from "../ScheduleEvent";
import i18next from "i18next";
import { recurrenceStringToText } from "report/service/RecurrenceRuleService";
import { DateTime as LuxonDateTime } from "luxon";

interface OtherActivityScheduleAttributes {
  recurrenceRule: string;
  dailySessions: number;
  events: ScheduleEvent[];
}

export default class OtherActivitySchedule {
  public recurrenceRule: string;
  public dailySessions: number;
  public events: ScheduleEvent[];

  constructor(plain: OtherActivityScheduleAttributes) {
    this.recurrenceRule = plain.recurrenceRule;
    this.dailySessions = plain.dailySessions;
    this.events = plain.events;
  }

  public toText(language: SupportedLanguages): string {
    return recurrenceStringToText(this.recurrenceRule, language)
      + this.events.map(this.getEventTime).join(` ${i18next.t("schedule:and")} `);
  }

  private getEventTime(event: ScheduleEvent): string {
    const alarmDescription = !!event.alarmIntervalInMinutes
      ? " (" + i18next.t("schedule:alarmDescription", { interval: event.alarmIntervalInMinutes }) + ")"
      : "";

    return " " + i18next.t("schedule:at")
    + " " + event.eventDate.formatLocale(LuxonDateTime.TIME_SIMPLE)
    + alarmDescription;
  }
}

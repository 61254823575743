export interface LogInFormDataField {
  email: string;
  password: string;
}

class LogInFormData {
  public readonly email: string;
  public readonly password: string;

  constructor({ email, password }: LogInFormDataField) {
    this.email = email;
    this.password = password;
  }

  public static empty(): LogInFormData {
    return new LogInFormData({
      email: "",
      password: "",
    });
  }

  public set(field: keyof LogInFormDataField, value: string): LogInFormData {
    return new LogInFormData({ ...this, [field]: value });
  }
}

export default LogInFormData;

import Client from "../../main/services/ApiClient";
import TemporaryPatientResponse from "./responses/TemporaryPatientResponse";

class TemporaryPatientApi {

  public async get(profileKey: string): Promise<TemporaryPatientResponse> {
    try {
      return await new Client({
        endpoint: "/doctors/invite-form",
        queryParams: { profileKey },
      })
        .get<TemporaryPatientResponse>();
    } catch (e) {
      throw e;
    }
  }
}

export default new TemporaryPatientApi();

import {
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, {
  useCallback,
  useMemo,
  useState,
} from "react";
import Snackbar, { SnackbarType } from "../main/components/Snackbar";
import StepListItem, { StepRequirement } from "./components/StepListItem";
import analyticsClient, { AnalyticCategory, AnalyticEvent } from "../main/services/analytics";
import theme, { pxToRem } from "../main/theme/theme";

import { CarePlanForm } from "./components/CarePlanForm";
import CarePlanStepFormData from "./domain/CarePlanStepFormData";
import { CarePlanUrl } from "./api/InvitationApi";
import ConfirmationModal from "./components/ConfirmationModal";
import { ConsultationDocumentsFormData } from "../document/domain/ConsultationDocumentsFormData";
import CustomActivityFormData from "../customActivities/domain/CustomActivityFormData";
import CustomProgramFormData from "./domain/CustomProgramFormData";
import { MeasurementDetail } from "../measurement/domain/MeasurementDetail";
import { MeasurementFormData } from "../measurement/domain/MeasurementFormData";
import MedicationSummary from "../medication/domain/MedicationSummary";
import { PatientProfileForm } from "./components/PatientProfileForm";
import PrescribeError from "./domain/PrescribeError";
import useConfirmation from "./hooks/useConfirmationModal";
import useInvitation from "./hooks/useInvitation";
import useLanguageSelection from "../main/hooks/useLanguageSelection";
import { usePrescribeForm } from "./hooks/usePrescribeForm";
import useProfileRepository from "../authentication/store/hooks/useProfileRepository";
import { useTranslation } from "react-i18next";
import ReminderFormData from "reminders/domain/ReminderFormData";

interface ClientConnectMessage {
  carePlanURL: string;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    flexDirection: "column",
    flex: 1,
    marginTop: theme.metrics.margin.normal,
    padding: `0 ${theme.metrics.margin.large}`,
  },
  textField: {
    margin: `${theme.metrics.margin.normal} 0`,
  },
  button: {
    margin: `0 ${theme.metrics.margin.normal}`,
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: theme.metrics.margin.huge,
  },
  contactInformationStep: {
  },
  confirmationModal: {
    maxWidth: pxToRem(400),
  },
}));

export enum Step {
  EMAIL = 1,
  CARE_PLAN = 2,
}

const PrescribePage: React.FunctionComponent = () => {
  const { currentLanguage, supportedLanguages } = useLanguageSelection();

  const {
    errors,
    prescribeFormData,
    onChange: updateProfileForm,
    canSubmit,
    clearForm,
    resetForm,
  } = usePrescribeForm(currentLanguage);

  const { profile } = useProfileRepository();
  const classes = useStyles();
  const { t } = useTranslation("prescribe");

  const completeForm = (url: CarePlanUrl): void => {
    resetForm();
    setSnackbarType("success");
    setSnackbarText(t("instructionsSent", { email: prescribeFormData.email }));
    setShowSnackbar(true);

    if (window.opener) {
      const message: ClientConnectMessage = { carePlanURL: url };
      const referrer = document.referrer;

      if (new RegExp(/\.medesync\.com/).test(referrer)) {
        window.opener.postMessage(message, referrer);
      }

      window.close();
    }
  };

  const displayError = (): void => {
    setSnackbarType("error");
    setSnackbarText(t(`errors.${PrescribeError.UnexpectedError}`));
    setShowSnackbar(true);
  };

  const { sendInvitation, loading } = useInvitation({
    onSuccess: completeForm,
    onError: displayError,
  });

  const changeCarePlan = useCallback(
    (carePlan: CarePlanStepFormData) => updateProfileForm(prescribeFormData.withCarePlan(carePlan)),
    [prescribeFormData, updateProfileForm]
  );

  const isStepCompleted = useMemo(() => ({
    [Step.EMAIL]: errors.email.length === 0 && errors.birthDate.length === 0 && !!prescribeFormData.email,
    [Step.CARE_PLAN]: prescribeFormData.carePlan.isFilled(),
  }), [errors, prescribeFormData]);

  const currentStep = (): Step => {
    if (isStepCompleted[Step.EMAIL]) {
      return Step.CARE_PLAN;
    }
    return Step.EMAIL;
  };

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarType, setSnackbarType] = useState<SnackbarType>("success");

  const submitInvitation = async (): Promise<void> => {
    await sendInvitation(prescribeFormData);
    analyticsClient.logEvent(AnalyticCategory.CARE_PLAN, AnalyticEvent.SEND_CAREPLAN);
    closeConfirmation();
  };

  const changeCustomPrograms = (customPrograms: CustomProgramFormData[]): void => {
    changeCarePlan(prescribeFormData.carePlan.updatePendingTreatmentPlan({
      pendingCustomPrograms: { list: customPrograms },
    }));
  };

  const changeMedications = (medications: MedicationSummary[]): void => {
    changeCarePlan(prescribeFormData.carePlan.updatePendingTreatmentPlan({
      medications: { list: medications },
    }));
  };

  const changeCustomActivities = (customActivities: CustomActivityFormData[]): void => {
    changeCarePlan(prescribeFormData.carePlan.updatePendingTreatmentPlan({
      customActivities: { list: customActivities },
    }));
  };

  const changeMeasurements = (measurements: MeasurementFormData<MeasurementDetail>[]): void => {
    changeCarePlan(prescribeFormData.carePlan.updatePendingTreatmentPlan({
      measurements: { list: measurements },
    }));
  };

  const changeConsultationDocuments = (consultationDocuments: ConsultationDocumentsFormData): void => {
    changeCarePlan(prescribeFormData.carePlan.updateConsultationDocuments(consultationDocuments));
  };

  const changeReminders = (reminders: ReminderFormData[]): void => {
    changeCarePlan(prescribeFormData.carePlan.updatePendingTreatmentPlan({
      reminders: { list: reminders },
    }));
  };

  const {
    isOpen,
    openConfirmation,
    closeConfirmation,
  } = useConfirmation();

  return (
    <div className={classes.wrapper}>
      <form onSubmit={openConfirmation}>
        <Grid item xs={12} xl={10}>
          <StepListItem
            step={Step.EMAIL}
            isCompleted={isStepCompleted[Step.EMAIL]}
            isError={errors.email.length > 0}
            isCurrent={currentStep() === Step.EMAIL}
            requirement={StepRequirement.REQUIRED}
          >
            <PatientProfileForm
              form={prescribeFormData}
              onChange={updateProfileForm}
              errors={errors}
              supportedLanguages={supportedLanguages}
            />
          </StepListItem>
        </Grid>

        <Grid item xs={12} xl={10}>
          <StepListItem
            step={Step.CARE_PLAN}
            isCompleted={isStepCompleted[Step.CARE_PLAN]}
            isCurrent={currentStep() === Step.CARE_PLAN}
            disabled={!isStepCompleted[Step.EMAIL]}
            requirement={StepRequirement.OPTIONAL}
          >
            <CarePlanForm
              onChangeCustomActivities={changeCustomActivities}
              carePlan={prescribeFormData.carePlan}
              profile={profile}
              onCarePlanChange={changeCarePlan}
              onMedicationsChange={changeMedications}
              onCustomProgramsChange={changeCustomPrograms}
              onMeasurementsChange={changeMeasurements}
              onConsultationDocumentsChange={changeConsultationDocuments}
              onRemindersChange={changeReminders}
              disabled={!isStepCompleted[Step.EMAIL]}
            />
          </StepListItem>
        </Grid>

        <Grid item xs={12} xl={10} className={classes.buttonContainer}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={(): void => {
              clearForm();
            }}
          >
            {t("clear")}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!canSubmit}
            className={classes.button}
          >
            {t("send")}
          </Button>
        </Grid>
      </form>

      <ConfirmationModal
        className={classes.confirmationModal}
        isOpen={isOpen}
        onClose={closeConfirmation}
        onConfirm={submitInvitation}
        form={prescribeFormData}
        loading={loading}
      />

      <Snackbar
        visible={showSnackbar}
        withCloseButton
        onClose={(): void => setShowSnackbar(false)}
        type={snackbarType}
      >
        {snackbarText}
      </Snackbar>
    </div>
  );
};

export default PrescribePage;

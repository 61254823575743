import i18next from "i18next";
import { MeasurementDetail } from "./MeasurementDetail";
import { MeasurementType } from "./MeasurementType";

export class BaseMeasurementDetail implements MeasurementDetail {
  public readonly type: MeasurementType;

  constructor(type: MeasurementType) {
    this.type = type;
  }

  get name(): string {
    return i18next.t(`measurement:measurementType.${this.type}`);
  }

  public canSubmitMeasurement(): boolean {
    return true;
  }
}

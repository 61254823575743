import LogInFormErrors from "../../login/domain/LogInFormErrors";
import User from "../../../main/domain/User";
import { AppState } from "../../../store/GlobalStore";
import DateTime from "../../../main/dateTime/DateTime";
import { PASSWORD_CHANGE_DAYS_THRESHOLD } from "../../services/authentication/AuthenticationService";
import { getSessionContext, isClientConnectContext } from "main/selectors/AppContextSelector";

const millisToDaysConversion = 1000 * 3600 * 24;

export const isAuthenticated = (state: AppState): boolean => {
  const user = getUser(state);

  if (isClientConnectContext()) {
    return Boolean(user && user.id === getSessionContext()?.professionalIdentifier);
  }

  return !!user && !isRequiredToResetPassword(state);
};

export const isRequiredToResetPassword = (state: AppState): boolean => Math.floor(
  DateTime.now().getDate().getTime() -
      DateTime.fromISO(state.user.user?.latestPasswordChange as string).getDate().getTime()
) / (millisToDaysConversion) >= PASSWORD_CHANGE_DAYS_THRESHOLD;
export const authenticationErrors = (state: AppState): LogInFormErrors => state.user.errors;
export const getUser = (state: AppState): User | undefined => state.user.user;

import CustomProgramFormData from "../../domain/CustomProgramFormData";
import CustomProgramDto from "../dtos/CustomProgramDto";
import PendingSchedulableRegimenAssembler from "./PendingSchedulableRegimenAssembler";

class PendingCustomProgramAssembler {
  public toDto(pendingCustomProgram: CustomProgramFormData): CustomProgramDto {
    return {
      id: pendingCustomProgram.id,
      owner: pendingCustomProgram.owner.toString(),
      schedulableRegimen: PendingSchedulableRegimenAssembler.toDto(pendingCustomProgram.schedulableRegimen),
    };
  }
}

export default new PendingCustomProgramAssembler();

import ProposedScheduleDto from "../dtos/ProposedScheduleDto";
import ScheduleFormData from "../../../schedule/domain/ScheduleFormData";
import SessionFormData from "../../domain/SessionFormData";
import { EventAssembler } from "./events/EventAssembler";

class ProposedScheduleAssembler {
  public toDto<T extends SessionFormData>(
    schedule: ScheduleFormData<T>,
    assembler: EventAssembler<T>
  ): ProposedScheduleDto {
    return {
      type: schedule.type,
      duration: {
        startDate: schedule.duration.startDate.formatToLocalDateTime(),
        endDate: schedule.duration.endDate && schedule.duration.endDate.formatToLocalDateTime(),
      },
      events: schedule.events.map((eventFormData: T) => assembler.toDto(eventFormData, schedule)),
    };
  }
}

export default new ProposedScheduleAssembler();

import Patient from "../Patient";
import ReminderResult from "./ReminderResult";
import NotesReport from "../note/NotesReport";

export default class ReminderResults {
  public patient: Patient;
  public results: ReminderResult[];
  public reminderName: string;
  public notes: NotesReport;

  constructor(
    patient: Patient,
    results: ReminderResult[],
    reminderName: string,
    notes: NotesReport,
  ) {
    this.patient = patient;
    this.results = results;
    this.reminderName = reminderName;
    this.notes = notes;
  }
}

import React, { useRef, useState } from "react";
import {
  Box, Button, makeStyles, Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CodeFormData from "../../main/domain/CodeFormData";
import theme, { pxToRem } from "../../main/theme/theme";
import useOutsideClick from "../hooks/useOutsideClick";
import AlertCard from "../../main/components/AlertCard";
import CodeInput from "../../main/components/CodeInput";
import HelpButton from "./HelpButton";

const useStyles = makeStyles(() => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    flex: 1,
  },
  tooltipContainer: {
    "position": "absolute",
    "top": "-7rem",
    "right": 0,
    "left": 0,
    "visibility": "hidden",
    "transition": "opacity ease-in 0.2s, visibility ease-in 0.2s",
    "opacity": 0,

    "&.active": {
      opacity: 1,
      visibility: "visible",
    },

    "& .tooltip - message": {
      fontWeight: 600,
    },
  },
  label: {
    textAlign: "center",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: pxToRem(25),
  },
  title: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface Props {
  code: CodeFormData;
  errors: string[];
  onChange: (code: CodeFormData) => void;
  onSubmit: () => void;
}

export const CodeForm: React.FunctionComponent<Props> = ({ code, onChange, onSubmit, errors }: Props) => {
  const { t } = useTranslation("home");
  const classes = useStyles();
  const tooltipRef = useRef(null);
  const [visible, setTooltipVisible] = useState(false);
  const closeTooltip = (): void => {
    visible && setTooltipVisible(false);
  };

  useOutsideClick(
    tooltipRef,
    closeTooltip,
    visible
  );

  return (
    <div className={classes.formContainer}>
      <form style={{ position: "relative" }}>
        <div className={classes.titleContainer}>
          <label className={classes.label}>
            <div className={classes.title}>
              <Typography
                component="span"
                variant="h1"
                color="textPrimary"
                display="block"
                align="center"
              >
                {t("enterPatientCode")}
              </Typography>
            </div>
          </label>

          <Box marginLeft={theme.metrics.margin.small}>
            <HelpButton onClick={(): void => setTooltipVisible(!visible)} active={visible} />
          </Box>

          <AlertCard forwardRef={tooltipRef} className={`${classes.tooltipContainer} ${visible ? "active" : ""}`}>
            <Typography className="tooltip-message" component="p" color="textPrimary">
              {t("codeTooltip")}
            </Typography>
          </AlertCard>
        </div>

        <Box marginBottom={theme.metrics.margin.large}>
          <CodeInput
            size="large"
            code={code}
            onChange={onChange}
            withClearButton
          />
        </Box>

        {errors.length > 0 &&
          <div>
            {errors.map((e) => (
              <Typography
                key={e}
                component="span"
                variant="subtitle1"
                color="error"
                display="block"
              >
                {t(`errors.${e}`, { length: code.length })}
              </Typography>
            ))}
          </div>
        }

        <Box marginTop={theme.metrics.margin.large} marginBottom={theme.metrics.margin.large}>
          <Button
            onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
              event.preventDefault();
              onSubmit();
            }}
            variant="contained"
            type="submit"
            color="primary"
          >
            <Typography
              component="span"
              variant="h4"
              display="block"
            >
              {t("connectNow")}
            </Typography>
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default CodeForm;

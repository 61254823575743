enum Images {
  ProfessionalIcon = "/assets/Professional_Icon.svg",
  MedHelperLogoSvgEn = "/assets/logo-horizontal-English-Session.svg",
  MedHelperLogoSvgFr = "/assets/logo-horizontal-French-Session.svg",
  Background = "/assets/background.svg",
  SideMenuTop = "/assets/SideMenuTopBackground.svg",
  SideMenuBottom = "/assets/SideMenuBottomBackground.svg",
  BlueLogo = "/assets/blue_logo.png",
  NoDrugPicturePlaceholder = "/assets/NoDrugPicturePlaceholder.png",
}

export default Images;

import Client from "../../main/services/ApiClient";
import InvitationAssembler from "./assemblers/InvitationAssembler";
import PrescribeFormData from "../domain/PrescribeFormData";
import { DocumentClient } from "../../document/api/DocumentClient";
import InviteResponse from "./responses/InviteResponse";

export type CarePlanUrl = string;

class InvitationApi {
  public async sendInvitation(prescribeFormData: PrescribeFormData): Promise<CarePlanUrl> {
    const documents = prescribeFormData.carePlan.consultationDocuments;

    const uploadedDocuments = [
      ...await Promise.all(
        documents.valid.map((document) => DocumentClient.upload(document))
      ),
      ...documents.imported,
    ];

    const invitationDto = InvitationAssembler.toDto(prescribeFormData, uploadedDocuments);

    const { proposedTreatmentPlanUrl } = await new Client({
      endpoint: "/doctors/invite",
      body: { ...invitationDto },
    })
      .post<InviteResponse>();

    return proposedTreatmentPlanUrl;
  }
}

export default new InvitationApi();

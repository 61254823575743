import IndexOutOfBoundsException from "./exceptions/IndexOutOfBoundsException";

export default class CodeFormData {
  public codeCharacters: string[];

  private constructor(characters: string[]) {
    this.codeCharacters = characters;
  }

  public static withLength = (length: number): CodeFormData =>
    new CodeFormData(new Array(length).fill(""));
  // Creating an array from length creates an array with no index, which makes methods like map not working:
  // See https://itnext.io/heres-why-mapping-a-constructed-array-doesn-t-work-in-javascript-f1195138615a

  public get length(): number {
    return this.codeCharacters.length;
  }

  public describe(): string {
    return this.codeCharacters.map((c) => !c ? " " : c).join("");
  }

  public withCharacter(character: string, position: number): CodeFormData {
    if (position >= this.codeCharacters.length || position < 0) {
      throw new IndexOutOfBoundsException(this.codeCharacters, position);
    }

    const characters = [...this.codeCharacters];
    characters[position] = character.toUpperCase();
    return new CodeFormData(characters);
  }

  public isEmpty(): boolean {
    return this.codeCharacters.every((c) => c === "");
  }

  public clear(): CodeFormData {
    return CodeFormData.withLength(this.codeCharacters.length);
  }
}

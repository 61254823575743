import React from "react";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: "1.3rem",
    fontWeight: 700,
    display: "block",
    textAlign: "center",
    color: theme.palette.primary.light,
    transition: "color ease-in 0.2s",
  },
  button: {
    "&:active, &:focus": {
      outline: "none",
      boxShadow: `0 0 6px 2px ${theme.palette.primary.light}`,
    },

    "&:hover": {
      "backgroundColor": theme.palette.primary.light,
      "cursor": "pointer",
      "& $icon": {
        color: theme.palette.primary.contrastText,
      },
    },

    "&.active": {
      "backgroundColor": theme.palette.primary.light,

      "& $icon": {
        color: theme.palette.primary.contrastText,
      },

      "&:hover": {
        "backgroundColor": "transparent",
        "& $icon": {
          color: theme.palette.primary.light,
        },
      },
    },
    "display": "flex",
    "justifyContent": "center",
    "padding": 0,
    "alignItems": "center",
    "backgroundColor": "transparent",
    "height": "1.8rem",
    "width": "1.8rem",
    "border": `1.5px solid ${theme.palette.primary.light}`,
    "borderRadius": "50%",
    "transition": "background-color ease-in 0.2s",
  },
}));

interface Props {
  onClick: () => void;
  active: boolean;
}

const HelpButton = ({ onClick, active }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <button className={`${classes.button} ${active ? "active" : ""}`} onClick={onClick} type="button">
      <span className={classes.icon}>?</span>
    </button>
  );
};

export default HelpButton;

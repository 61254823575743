import { Typography, makeStyles } from "@material-ui/core";

import React from "react";
import SearchDrug from "../../drugs/domain/SearchDrug";
import TreatmentMedicationPicture from "../../report/components/TreatmentMedicationPicture";
import theme from "../../main/theme/theme";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    fontWeight: "bold",
    color: theme.colors.text.primary,
  },
  dosage: {
    color: theme.colors.text.emphasis,
  },
  din: {
    fontWeight: "bold",
    marginTop: theme.metrics.margin.tiny,
    color: theme.colors.text.primary,
  },
  informationSection: {
    justifyContent: "center",
    alignItems: "flex-start",
    flex: 2,
  },
  pictureSection: {
    marginRight: theme.metrics.margin.normal,
  },
}));

interface Props {
  drug: SearchDrug;
  className?: string;
}

export const SearchDrugCard: React.FC<Props> = ({
  drug,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={[classes.container, className].join(" ")}>
      <div className={[classes.pictureSection].join(" ")}>
        <TreatmentMedicationPicture
          din={drug.id}
        />
      </div>

      <div className={classes.informationSection}>
        <Typography
          id={drug.id}
          component="h4"
          variant="h4"
          className={classes.name}
        >
          {drug.name}
        </Typography>

        <Typography variant="h5" className={classes.dosage}>
          {drug.dosage}
        </Typography>

        <Typography variant="h5" className={classes.din}>
          DIN: {drug.id}
        </Typography>
      </div>
    </div>
  );
};
export default SearchDrugCard;

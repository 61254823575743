import RegimenType from "../../domain/RegimenType";
import PendingRegimenDto from "./PendingRegimenDto";

class PendingMeasurementDto implements PendingRegimenDto {
  public readonly name: string;
  public readonly type: RegimenType;

  constructor(name: string) {
    this.name = name;
    this.type = RegimenType.MEASUREMENT;
  }
}

export default PendingMeasurementDto;


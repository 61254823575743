import Profile from "../profile/domain/Profile";

interface ProfileStore {
  profile: Profile | undefined;
}

export const profileInitialState: ProfileStore = {
  profile: undefined,
};

export default ProfileStore;

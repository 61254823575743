import React, { useCallback } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PlusIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import MinusIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";

const useStyles = makeStyles(() => ({
  buttonText: {
    padding: 0,
  },
}));

interface Props {
  onClick: () => void;
  allExpanded: boolean;
};

export const ToggleExpandAllButton: React.FunctionComponent<Props> = ({ onClick, allExpanded }) => {
  const classes = useStyles();
  const { t } = useTranslation("report");

  const buttonContent = useCallback(() => {
    if (allExpanded) {
      return <>{t("collapseAll")} <MinusIcon /></>;
    } else {
      return <>{t("expandAll")} <PlusIcon /></>;
    }
  }, [allExpanded, t]);

  return (

    <Button color="primary" onClick={onClick} classes={{ text: classes.buttonText }}>
      {buttonContent()}
    </Button>

  );
};

export default ToggleExpandAllButton;

import { PatientTreatmentReminderResponse } from "./responses/TreatmentResponse";
import AdherencesAssembler from "./AdherencesAssembler";
import DateTime from "main/dateTime/DateTime";
import ScheduleEventAssembler from "./ScheduleEventAssembler";
import Reminder from "report/domain/reminders/Reminder";
import ReminderSchedule from "report/domain/reminders/ReminderSchedule";

class TreatmentReminderAssembler {
  public static toDomain(reminder: PatientTreatmentReminderResponse): Reminder {
    return new Reminder({
      name: reminder.name,
      schedule: new ReminderSchedule({
        recurrenceRule: reminder.schedule.recurrenceType.rule,
        dailySessions: reminder.schedule.dailySessions,
        events: ScheduleEventAssembler.fromResponses(reminder.schedule.events),
      }),
      adherence: AdherencesAssembler.assembleToInternal(reminder.adherence),
      reportCode: {
        code: reminder.code.code,
        expiry: DateTime.fromISO(reminder.code.expiry),
      },
    });
  }
}

export default TreatmentReminderAssembler;

import { useCallback, useState } from "react";
import ProfileFormData from "../domain/ProfileFormData";
import ProfileService from "../services/profile";
import ProfileAssembler from "../domain/ProfileAssembler";
import { ProfileFormErrors } from "../domain/ProfileFormErrors";
import CouldNotAssembleProfileError from "../domain/exceptions/CouldNotAssembleProfileError";
import ProfileError from "../domain/ProfileError";
import useProfileRepository from "../../store/hooks/useProfileRepository";
import useUserRepository from "../../store/hooks/useUserRepository";
import DateTime from "main/dateTime/DateTime";
import { useUserProfile } from "authentication/hooks/useUserProfile";

export interface UseProfileFormResult {
  errors: ProfileFormErrors;
  profileFormData: ProfileFormData;
  onSubmit: () => Promise<void>;
  onSubmitWithAgreement: (agreementDate: DateTime) => Promise<void>;
  onChange: (updatedProfileFormData: ProfileFormData) => void;
  hasChanged: boolean;
}

interface Props {
  onProfileUpdated?: () => void;
}

export const useProfileForm = ({ onProfileUpdated }: Props = {}): UseProfileFormResult => {
  const { profile, setProfile } = useProfileRepository();
  const { user } = useUserRepository();
  const { updateUserProfileStore } = useUserProfile();

  const defaultProfileFormData = profile ? ProfileAssembler.toFormData(profile) : ProfileFormData.empty(user);

  const [profileFormData, setProfileFormData] = useState<ProfileFormData>(defaultProfileFormData);
  const [errors, setErrors] = useState<ProfileFormErrors>(ProfileFormErrors.empty());
  const [hasChanged, setHasChanged] = useState(false);

  const onChange = useCallback((updatedProfileFormData: ProfileFormData): void => {
    setHasChanged(!defaultProfileFormData.isEqualTo(updatedProfileFormData));

    setProfileFormData(updatedProfileFormData);
  }, [defaultProfileFormData]);

  const handleSubmitError = (error: unknown): void => {
    const newErrors = error instanceof CouldNotAssembleProfileError
      ? error.errors
      : new ProfileFormErrors({ body: [ProfileError.DefaultError] });

    setErrors(newErrors);
  };

  const onSubmit = useCallback(async (): Promise<void> => {
    setErrors(ProfileFormErrors.empty());

    try {
      const assembledProfile = ProfileAssembler.fromFormData(profileFormData);

      await ProfileService.updateProfile(assembledProfile);

      const updatedProfile = await ProfileService.findProfile();

      setHasChanged(false);
      setProfile(updatedProfile);

      onProfileUpdated?.();
    } catch (e) {
      handleSubmitError(e);
    }
  }, [profileFormData, setProfile, onProfileUpdated]);

  const onSubmitWithAgreement = useCallback(async (agreementDate: DateTime): Promise<void> => {
    setErrors(ProfileFormErrors.empty());

    try {
      const assembledProfile = ProfileAssembler.fromFormData(profileFormData);
      assembledProfile.agreementDate = agreementDate.toISO();

      await ProfileService.updateProfile(assembledProfile);

      setHasChanged(false);
      await updateUserProfileStore();
    } catch (e) {
      handleSubmitError(e);
    }
  }, [profileFormData, updateUserProfileStore]);

  return { onChange, onSubmit, onSubmitWithAgreement, profileFormData, errors, hasChanged };
};

export default useProfileForm;

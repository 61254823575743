import React, { useState } from "react";
import { Collapse, makeStyles } from "@material-ui/core";
import RequiredFieldLabel from "../../main/components/RequiredFieldLabel";
import { useTranslation } from "react-i18next";
import theme from "../../main/theme/theme";
import { TextButton } from "../../main/components/buttons/TextButton";
import DayToggleButton from "./DayToggleButton";

const useStyles = makeStyles(() => ({
  subtitle: {
    margin: `${theme.metrics.margin.normal} 0`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column: {
    "display": "flex",
    "flexDirection": "column",
    "& > *": {
      marginBottom: theme.metrics.margin.normal,
    },
  },
  row: {
    "display": "flex",
    "flexDirection": "row",
    "marginBottom": theme.metrics.margin.normal,
    "& > div": {
      width: "calc(100% / 7)",
      display: "flex",
      justifyContent: "center",
    },
  },
  toggleButton: {
    textAlign: "right",
    maxWidth: "50%",
  },
}));

interface Props {
  days: number[];
  onChange: (days: number[]) => void;
}

const dateRows: number[][] = [
  [1, 2, 3, 4, 5, 6, 7],
  [8, 9, 10, 11, 12, 13, 14],
  [15, 16, 17, 18, 19, 20, 21],
  [22, 23, 24, 25, 26, 27, 28],
  [29, 30, 31],
];

const MonthlyFrequencyPicker: React.FC<Props> = ({ days, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation(["schedule"]);
  const [expanded, setExpanded] = useState(true);

  const sortedDays = days
    .sort((day1: number, day2: number) => day1 - day2)
    .join(", ");

  const toggleDay = (day: number, selected: boolean): void => {
    const updatedDays = selected
      ? [...days, day]
      : days.filter((selectedDay) => selectedDay !== day);

    onChange(updatedDays);
  };

  return (
    <section>
      <section
        aria-labelledby="month-picker-header"
        className={classes.subtitle}
        aria-expanded={expanded}
      >
        <RequiredFieldLabel id="month-picker-header" bold label={t("medication:every")} />

        <TextButton className={classes.toggleButton} onClick={(): void => setExpanded(!expanded)}>
          {t("ofTheMonth", { monthDays: sortedDays })}
        </TextButton>
      </section>

      <Collapse in={expanded}>
        <section>
          {dateRows.map((row: number[], index: number) => (
            <div className={classes.row} key={`row-${index}`}>
              {row.map((date: number) => (
                <div key={date}>
                  <DayToggleButton
                    label={`${date}`}
                    onToggle={(selected: boolean): void => toggleDay(date, selected)}
                    value={days.includes(date)}
                  />
                </div>
              ))}
            </div>
          ))}
        </section>
      </Collapse>
    </section>
  );
};

export default MonthlyFrequencyPicker;

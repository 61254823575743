export enum MoodKey {
  GREAT = "GREAT",
  GOOD = "GOOD",
  OK = "OK",
  BAD = "BAD",
  TERRIBLE = "TERRIBLE",
}

export type MoodValue = 1 | 2 | 3 | 4 | 5;

export class MoodFeeling {
  public static readonly GREAT = new MoodFeeling(MoodKey.GREAT, 5);
  public static readonly GOOD = new MoodFeeling(MoodKey.GOOD, 4);
  public static readonly OK = new MoodFeeling(MoodKey.OK, 3);
  public static readonly BAD = new MoodFeeling(MoodKey.BAD, 2);
  public static readonly TERRIBLE = new MoodFeeling(MoodKey.TERRIBLE, 1);

  private constructor(private readonly key: MoodKey, public readonly value: MoodValue) { }

  public static fromKey(key: MoodKey): MoodFeeling {
    switch (key) {
      case MoodKey.GREAT:
        return this.GREAT;
      case MoodKey.GOOD:
        return this.GOOD;
      case MoodKey.OK:
        return this.OK;
      case MoodKey.BAD:
        return this.BAD;
      case MoodKey.TERRIBLE:
        return this.TERRIBLE;
    }
  }

  public static fromValue(key: MoodValue): MoodFeeling {
    switch (key) {
      case 5:
        return this.GREAT;
      case 4:
        return this.GOOD;
      case 3:
        return this.OK;
      case 2:
        return this.BAD;
      case 1:
        return this.TERRIBLE;
    }
  }

  public toString(): string {
    return this.key;
  }
}

import React, { PropsWithChildren, useState } from "react";
import {
  Collapse, Divider,
  IconButton, makeStyles, Typography,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import theme from "../../main/theme/theme";
import SessionFormData from "../domain/SessionFormData";
import { useTranslation } from "react-i18next";

interface Props {
  session: SessionFormData;
  label: string;
  index: number;
  canDelete: boolean;
  onDelete: any;
  ariaLabel: string;
}

export const ReminderForm = ({
  session,
  index,
  label,
  onDelete,
  canDelete,
  children,
  ariaLabel,
}: PropsWithChildren<Props>): JSX.Element => {
  const classes = useStyle();
  const [expanded, setExpanded] = useState(true);
  const { t } = useTranslation("common");

  return (
    <section aria-labelledby={`${ariaLabel}-${session.id}`} aria-expanded={expanded}>
      <div className={classes.header}>
        <Typography className={classes.title} variant="h4" id={`${ariaLabel}-${session.id}`}>
          {label} {index + 1}
        </Typography>

        <IconButton
          aria-label={expanded ? t("collapse") : t("expand")}
          onClick={(): void => setExpanded(!expanded)}
          className={classes.toggleButton}
        >
          {expanded ?
            <ExpandLess fontSize="large" />
            :
            <ExpandMore fontSize="large" />
          }
        </IconButton>
      </div>

      <Collapse in={expanded}>
        {children}

        {!!canDelete &&
          <div className={classes.removeButtonContainer}>
            <button
              className={classes.removeButton}
              onClick={(): void => onDelete(session)}
            >
              {t("medication:remove")}
            </button>
          </div>
        }

        <Divider classes={{ root: classes.bottomDivider }} light />
      </Collapse>
    </section>
  );
};

const useStyle = makeStyles(() => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.metrics.margin.normal,
  },
  title: {
    fontWeight: "bold",
  },
  toggleButton: {
    color: theme.colors.button.icon.color,
    padding: theme.metrics.margin.tiny,
  },
  removeButtonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.metrics.margin.normal,
  },
  removeButton: {
    "textDecoration": "underline",
    "border": "none",
    "backgroundColor": "transparent",
    "alignSelf": "center",
    "color": theme.colors.text.primary,
    "cursor": "pointer",
    "&:hover": {
      color: theme.colors.text.emphasis,
    },
    "fontSize": theme.fonts.h5.size,
  },
  bottomDivider: {
    "margin-top": theme.metrics.margin.normal,
  },
}));


import React from "react";
import { makeStyles } from "@material-ui/core";
import { MeasuresForDayFormData } from "../../prescribe/domain/MeasureFormData";
import DailyScheduleSection from "./DailyScheduleSection";

const useStyles = makeStyles(() => ({
  rowContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  scheduleMeasures: MeasuresForDayFormData[];
}

const ScheduleSectionSummary: React.FunctionComponent<Props> = ({ scheduleMeasures }) => {
  const classes = useStyles();
  return (
    <div className={classes.rowContainer}>
      {
        scheduleMeasures.map((dayMeasures, index) => {
          const day = index + 1;
          return (
            <DailyScheduleSection key={dayMeasures.id} measuresForDayFormData={dayMeasures} day={day} />
          );
        })
      }
    </div>
  );
};

export default ScheduleSectionSummary;

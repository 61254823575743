import React from "react";
import { Redirect } from "react-router-dom";
import AuthenticationContainer from "../../containers/AuthenticationContainer";
import CompleteProfileForm from "../components/CompleteProfileForm";
import useAppExceptionRepository from "../../../store/hooks/useAppExceptionRepository";
import AppExceptions from "../../../main/components/AppExceptions";
import useUserRepository from "../../store/hooks/useUserRepository";
import useProfileRepository from "../../store/hooks/useProfileRepository";
import NavigationPath from "router/NavigationPath";

const CompleteProfilePage: React.FunctionComponent = () => {
  const { errors } = useAppExceptionRepository();
  const { authenticated } = useUserRepository();
  const { profile } = useProfileRepository();

  if (!authenticated) {
    return <Redirect to={NavigationPath.AUTHENTICATION} />;
  }

  if (profile) {
    return <Redirect to={NavigationPath.PRESCRIBE} />;
  }

  return (
    <AuthenticationContainer>
      {
        errors.length > 0
          ? <AppExceptions />
          : <CompleteProfileForm />
      }
    </AuthenticationContainer>
  );
};

export default CompleteProfilePage;

import { MeasurementFormModal, MeasurementFormModalRef } from "../../measurement/components/MeasurementFormModal";
import React, { useRef } from "react";

import CarePlanSection from "./CarePlanSection";
import { Divider } from "@material-ui/core";
import { MeasurementDetail } from "../../measurement/domain/MeasurementDetail";
import { MeasurementFormData } from "../../measurement/domain/MeasurementFormData";
import { ReactComponent as MeasurementIcon } from "measurement/assets/measurement.svg";
import { MeasurementSummary } from "../../measurement/components/MeasurementSummary";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

interface Props {
  disabled?: boolean;
  measurements: MeasurementFormData<MeasurementDetail>[];
  onMeasurementsChange?: (measurements: MeasurementFormData<MeasurementDetail>[]) => void;
  onDeleteClick?: (onConfirm: () => void) => void;
}

export const MeasurementsSection = ({
  disabled,
  measurements,
  onMeasurementsChange,
  onDeleteClick,
}: Props): JSX.Element => {
  const { t } = useTranslation("prescribe");
  const measurementModalRef = useRef<MeasurementFormModalRef | null>(null);
  const classes = useStyles();
  const isReadOnly = !Boolean(onMeasurementsChange);

  const addMeasurement = (measurement: MeasurementFormData<MeasurementDetail>): void => {
    const updatedMeasurements = [...measurements, measurement];
    onMeasurementsChange?.(updatedMeasurements);
  };

  const deleteMeasurement = (measurement: MeasurementFormData<MeasurementDetail>): void => {
    const updatedMeasurements = measurements.filter(
      (formDataMeasurement) => formDataMeasurement.localId !== measurement.localId
    );
    onMeasurementsChange?.(updatedMeasurements);
  };

  const onDeleteMeasurement = (measurement: MeasurementFormData<MeasurementDetail>): void => {
    onDeleteClick?.(() => deleteMeasurement(measurement));
  };

  const editMeasurement = (measurement: MeasurementFormData<MeasurementDetail>): void => {
    const updatedMeasurements = measurements.map((m) => m.localId === measurement.localId ? measurement : m);
    onMeasurementsChange?.(updatedMeasurements);
  };

  const onEditClick = (measurementToEdit: MeasurementFormData<MeasurementDetail>): void => {
    measurementModalRef.current?.edit(measurementToEdit);
  };

  const onAddClick = (): void => measurementModalRef.current?.add();

  return (
    <CarePlanSection
      Icon={MeasurementIcon}
      title={t("section.title.measurement")}
      text={t("section.subText.measurement")}
      tooltipText={t("tooltip.measurement")}
      onClick={isReadOnly ? undefined : onAddClick}
      disabled={disabled}
    >
      <ul className={classes.measurements}>
        {measurements.map((measurement, index: number) => (
          <li
            aria-label={measurement.details.measurementDetail.name}
            key={measurement.localId}
          >
            <MeasurementSummary
              isReadOnly={isReadOnly}
              measurement={measurement}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteMeasurement}
            />

            {index !== measurements.length - 1 &&
              <Divider light />
            }
          </li>
        )
        )}
      </ul>

      <MeasurementFormModal
        onMeasurementEdit={editMeasurement}
        ref={measurementModalRef}
        onMeasurementAdd={addMeasurement}
      />
    </CarePlanSection>
  );
};

const useStyles = makeStyles(() => ({
  measurements: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
}));

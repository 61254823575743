import React from "react";
import AuthenticationPageWrapper from "../../components/AuthenticationPageWrapper";
import AuthenticationForm from "../../components/AuthenticationForm";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import PasswordField from "../../components/PasswordField";
import theme from "../../../main/theme/theme";
import AuthenticationIcon from "../../components/AuthenticationIcon";
import { usePasswordExpired } from "../hooks/usePasswordExpired";
import { CognitoUser } from "amazon-cognito-identity-js";
import { PasswordExpiredFormData } from "../domain/PasswordExpiredFormData";
import { AuthenticationFormErrorsWithLink } from "../../components/AuthenticationFormErrorsWithLink";

interface Props {
  user?: CognitoUser;
}

export const PasswordExpiredForm = ({ user }: Props): JSX.Element => {
  const { t } = useTranslation("authentication");
  const classes = useStyles();
  const { form, errors, submitPasswordExpired, onChange } = usePasswordExpired({ user });

  const submitForm = (event: React.ChangeEvent<HTMLFormElement>): void => {
    event.preventDefault();
    submitPasswordExpired();
  };

  const changePassword = (newPassword: string): void => onChange(new PasswordExpiredFormData({ ...form, newPassword }));

  return (
    <AuthenticationPageWrapper>
      <AuthenticationIcon className={classes.icon} />

      <Typography component="h2" variant="h3" className={classes.passwordExpiredHeaderText}>
        {t("passwordExpired")}
      </Typography>

      <Typography component="h2" variant="h5" className={classes.passwordExpiredHeaderText} color="textPrimary">
        {t("passwordExpiredInstructions")}
      </Typography>

      <AuthenticationForm onSubmit={submitForm}>
        <PasswordField
          autoFocus
          className={classes.formRow}
          error={errors.password.length > 0 ? t(`authenticationErrors:${errors.password[0]}`) : ""}
          password={form.newPassword}
          onChange={changePassword}
          label={t("newPassword")}
        />

        <AuthenticationFormErrorsWithLink errors={errors.body} />

        <Button
          role="button"
          type="submit"
          variant="contained"
          color="primary"
          className={classes.sendButton}
          disabled={!form.isFilled()}
        >
          {t("confirm")}
        </Button>
      </AuthenticationForm>
    </AuthenticationPageWrapper>
  );
};

const useStyles = makeStyles(() => ({
  formRow: {
    marginBottom: theme.metrics.margin.medium,
  },
  sendButton: {
    marginRight: theme.metrics.margin.tiny,
  },
  icon: {
    marginBottom: theme.metrics.margin.small,
  },
  passwordExpiredHeaderText: {
    marginBottom: theme.metrics.margin.small,
  },
}));

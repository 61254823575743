import CodeFormData from "../../../main/domain/CodeFormData";

class PasswordResetFormData {

  public readonly newPassword: string;
  public readonly code: CodeFormData;

  constructor({ newPassword, code }: { newPassword: string; code: CodeFormData }) {
    this.newPassword = newPassword;
    this.code = code;
  }

  public static empty(): PasswordResetFormData {
    return new PasswordResetFormData({ newPassword: "", code: CodeFormData.withLength(6) });
  }
}

export default PasswordResetFormData;

import {
  Collapse, makeStyles, Switch, Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "../../main/theme/theme";
import InventoryFormData from "../../medication/domain/InventoryFormData";
import NumericInput from "../../main/components/NumericInput";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
  },
  sectionTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: `${theme.metrics.margin.small} 0`,
  },
  sectionTitle: {
    fontWeight: "bold",
  },
  textFieldTitle: {
    marginBottom: theme.metrics.margin.small,
  },
}));

interface Props {
  inventory: InventoryFormData;
  onChangeFormData: (key: keyof InventoryFormData, value: number | null) => void;
}

const MAX_VALUE = 99999;
const MIN_VALUE = 0;

const InventorySection: React.FC<Props> = ({ inventory, onChangeFormData }) => {
  const [displayed, setDisplayed] = useState(inventory.quantity !== null);
  const { t } = useTranslation("medication");
  const classes = useStyles();

  const toggleSection = (): void => {
    setDisplayed(!displayed);
    onChangeFormData("quantity", null);
  };

  const changeValue = (value: number | null): void => {
    onChangeFormData("quantity", value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.sectionTitleContainer}>
        <Typography className={classes.sectionTitle} variant="h4" id="inventoryToggle">
          {t("inventory")}
        </Typography>

        <Switch
          checked={displayed}
          inputProps={{
            "aria-labelledby": "inventoryToggle",
          }}
          color="primary"
          onChange={toggleSection}
        />
      </div>

      <section aria-labelledby="inventoryToggle" aria-expanded={displayed}>
        <Collapse in={displayed}>
          <NumericInput
            minValue={MIN_VALUE}
            maxValue={MAX_VALUE}
            title={t("quantityInHand")}
            placeholder={t("numberOfPills")}
            value={inventory.quantity || MIN_VALUE}
            onValueChange={changeValue}
            id="inventoryInput"
          />
        </Collapse>
      </section>
    </div>
  );
};

export default InventorySection;


import DrugLabel from "./DrugLabel";

interface DrugLabelsAttributes {
  auxiliary: DrugLabel[];
  mandatory: DrugLabel[];
}

class DrugLabels {
  public auxiliary: DrugLabel[];
  public mandatory: DrugLabel[];

  constructor({ auxiliary, mandatory }: DrugLabelsAttributes) {
    this.auxiliary = auxiliary;
    this.mandatory = mandatory;
  }

  public isEmpty(): boolean {
    return this.auxiliary.length === 0 && this.mandatory.length === 0;
  }
}

export default DrugLabels;

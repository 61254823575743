import { SchedulableMeasurementResponse } from "./responses/TreatmentResponse";
import Measurement, { MeasurementAttributes } from "../domain/measurements/Measurement";
import CustomProgramAssembler from "./CustomProgramAssembler";
import MeasurementScheduleAssembler from "./MeasurementScheduleAssembler";
import AdherencesAssembler from "./AdherencesAssembler";
import { MeasurementType } from "../../measurement/domain/MeasurementType";
import BloodGlucoseMeasurement from "../domain/measurements/bloodGlucoseMeasurement/BloodGlucoseMeasurement";
import CustomMeasurement from "../domain/measurements/customMeasurement/CustomMeasurement";
import DateTime from "../../main/dateTime/DateTime";
import MigraineMeasurement from "report/domain/measurements/migraineMeasurement/MigraineMeasurement";
import MoodMeasurement from "../domain/measurements/moodMeasurement/MoodMeasurement";
import BloodPressureMeasurement from "../domain/measurements/bloodPressureMeasurement/BloodPressureMeasurement";

class TreatmentMeasurementAssembler {
  public static toDomain(measurement: SchedulableMeasurementResponse): Measurement {
    const measurementAttributes: Omit<MeasurementAttributes, "type"> = {
      name: measurement.name,
      adherence: AdherencesAssembler.assembleToInternal(measurement.adherence),
      schedule: measurement.schedule ? MeasurementScheduleAssembler.toDomain(measurement.schedule) : null,
      reportCode: {
        code: measurement.code.code,
        expiry: DateTime.fromISO(measurement.code.expiry),
      },
    };

    switch (measurement.name) {
      case MeasurementType.BLOOD_GLUCOSE: {
        const customProgram = measurement.customProgram
          ? CustomProgramAssembler.assembleToInternal(measurement.customProgram)
          : null;

        return new BloodGlucoseMeasurement({ ...measurementAttributes, customProgram });
      }
      case MeasurementType.MIGRAINE:
        return new MigraineMeasurement(measurementAttributes);
      case MeasurementType.MOOD:
        return new MoodMeasurement(measurementAttributes);
      case MeasurementType.BLOOD_PRESSURE:
        return new BloodPressureMeasurement(measurementAttributes);
      default:
        return new CustomMeasurement(measurementAttributes);
    }
  }
}

export default TreatmentMeasurementAssembler;

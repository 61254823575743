import { ConsultationDocument } from "../domain/ConsultationDocument";
import IconButton from "../../main/components/buttons/IconButton";
import React from "react";
import { ReactComponent as Remove } from "../assets/Remove.svg";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../main/theme/theme";
import { useTranslation } from "react-i18next";

interface Props {
  consultationDocuments: ConsultationDocument[];
  onDeleteInvalidDocumentsErrors: () => void;
}

export const InvalidConsultationDocuments = ({
  consultationDocuments,
  onDeleteInvalidDocumentsErrors,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("consultationDocument");

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h3" color="error">{t("attachFileErrors")}</Typography>
        <div className={classes.errorsContainer}>
          <IconButton
            title={t("deleteErrors")}
            onClick={onDeleteInvalidDocumentsErrors}
            className={classes.deleteErrorsButton}
          >
            <Remove width={20} height={20} />
          </IconButton>
        </div>
      </div>

      <ul className={classes.consultationDocumentsErrors}>
        {
          consultationDocuments.map((consultationDocument, index) => (
            <li
              key={consultationDocument.localId}
              className={classes.consultationDocumentsListItem}
              aria-label={`${consultationDocument.name} ${t("error")} ${index}`}
            >
              <Typography variant="h4" color="error">{consultationDocument.name}</Typography>
              {
                consultationDocument.describeErrors().map((error, errorIndex) => (
                  <Typography key={`${consultationDocument.name} ${errorIndex}`} color="error">{error}</Typography>
                ))
              }
            </li>
          ))
        }
      </ul>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${theme.colors.error}`,
    padding: theme.metrics.margin.small,
    backgroundColor: theme.colors.backgroundError,
    boxShadow: theme.boxShadow,
    marginTop: theme.metrics.margin.normal,
  },
  consultationDocumentsErrors: {
    listStyleType: "none",
    marginLeft: theme.metrics.margin.small,
    padding: 0,
  },
  consultationDocumentsListItem: {
    "marginTop": theme.metrics.margin.medium,
    "&:first-child": {
      marginTop: 0,
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  errorsContainer: {
    marginLeft: theme.metrics.margin.small,
  },
  deleteErrorsButton: {
    padding: 0,
    justifyContent: "flex-end",
  },
}));


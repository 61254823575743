import { useFormData } from "@nexapp/nexapp-react-forms";
import MedicationFormData, { createEmpty as createEmptyFormData } from "../domain/MedicationFormData";

export interface UseMedicationFormResult {
  formData: MedicationFormData;
  onChange: <T extends keyof MedicationFormData>(
    section: T,
    field: keyof MedicationFormData[typeof section],
    value: MedicationFormData[T][typeof field]) => void;
  hasChanged: boolean;
}

const useMedicationForm = (medication?: MedicationFormData): UseMedicationFormResult => {
  const { formData, onChange: onChangeFormData, hasChanged } = useFormData({
    initialData: medication || createEmptyFormData(),
  });

  const onChange: UseMedicationFormResult["onChange"] = (section, field, value): void =>
    onChangeFormData(section)(field)(value);

  return {
    formData,
    onChange,
    hasChanged,
  };
};

export default useMedicationForm;

import { EventAssembler } from "./EventAssembler";
import SessionFormData from "../../../domain/SessionFormData";
import ScheduleFormData from "../../../../schedule/domain/ScheduleFormData";
import ProposedEventDto from "../../dtos/ProposedEventDto";
import Frequency from "../../../../schedule/domain/Frequency";
import DateTime from "../../../../main/dateTime/DateTime";

class ProposedSessionEventAssembler extends EventAssembler<SessionFormData> {
  public toDto(eventFormData: SessionFormData, schedule: ScheduleFormData<SessionFormData>): ProposedEventDto {
    const shouldIncludeWeekly = schedule.frequency === Frequency.WEEKLY;
    const shouldIncludeMonthly = schedule.frequency === Frequency.MONTHLY;

    return {
      id: eventFormData.id,
      frequency: schedule.frequency.toString(),
      amount: 0,
      interval: schedule.interval,
      creationDate: DateTime.now().formatToUtc(),
      expectedTimestamp: eventFormData.time.formatToLocalDateTime(),
      weekdays: shouldIncludeWeekly ? schedule.weekdays : [],
      monthDays: shouldIncludeMonthly ? schedule.monthDays : [],
      alarmIntervalInMinutes: eventFormData.alarm?.intervalInMinutes,
    };
  }
}

export default new ProposedSessionEventAssembler();

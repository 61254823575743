import isEmailValid from "../../authentication/services/validators/emailValidator";
import DateTime from "../../main/dateTime/DateTime";
import PrescribeFormData from "./PrescribeFormData";
import { PrescribeFormErrors } from "./PrescribeFormErrors";
import PrescribeError from "./PrescribeError";

class PrescribeValidator {
  public validate(prescribeFormData: PrescribeFormData): PrescribeFormErrors {
    let errors = PrescribeFormErrors.empty();

    if (prescribeFormData.email && !isEmailValid(prescribeFormData.email)) {
      errors = errors.add("email", PrescribeError.InvalidEmail);
    }

    const birthDate = prescribeFormData.birthDate;
    if (birthDate && this.isAfterToday(birthDate)) {
      errors = errors.add("birthDate", PrescribeError.InvalidBirthDate);
    }

    return errors;
  }

  private isAfterToday(date: DateTime): boolean {
    const now = DateTime.now();
    return !(date.isBefore(now) || date.isSame(now, "day"));
  }
}

export default new PrescribeValidator();

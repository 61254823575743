import MedicationSummary from "../../../medication/domain/MedicationSummary";
import RegimenType from "../../domain/RegimenType";
import PendingMedicationDto from "../dtos/PendingMedicationDto";

class PendingMedicationAssembler {
  public toDto(medicationSummary: MedicationSummary): PendingMedicationDto {
    const { medication, drug } = medicationSummary;
    return {
      id: medication.id,
      din: drug.id,
      name: drug.name,
      type: RegimenType.MEDICATION,
      inventory: medication.inventory.quantity,
      dosage: drug.dosage,
      alias: medication.details.displayName,
      medicationType: drug.type,
    };
  }
}

export default new PendingMedicationAssembler();

import { useState } from "react";
import InvitationApi, { CarePlanUrl } from "../api/InvitationApi";
import PrescribeFormData from "../domain/PrescribeFormData";

export interface UseInvitationResult {
  loading: boolean;
  error: boolean;
  sendInvitation: (prescribeFormData: PrescribeFormData) => Promise<void>;
}

interface Props {
  onSuccess: (url: CarePlanUrl) => void;
  onError: () => void;
}

const useInvitation = ({ onSuccess, onError }: Props): UseInvitationResult => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendInvitation = async (prescribeFormData: PrescribeFormData): Promise<void> => {
    setLoading(true);
    setError(false);

    try {
      const carePlanUrl = await InvitationApi.sendInvitation(prescribeFormData);
      onSuccess(carePlanUrl);
    } catch {
      setError(true);
      onError();
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, sendInvitation };
};

export default useInvitation;
